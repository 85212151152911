
import zIndex from "@mui/material/styles/zIndex";
import Select from "react-select";
import TextTag from "../TextTag/TextTag";
import { twMerge } from 'tailwind-merge';



export const multiValueStyles = {
    container: (base: any) => ({
        ...base,
        width: "100%",
    }),
    control: (base: any) => ({
        ...base,
        textAlign: "start",
        borderRadius: "10px",
        height: "4rem",
    }),
    valueContainer: (base: any) => ({
        ...base,
        gap: "0.5rem",
        height: "100%",
        overflow: "auto",
        position: "unset",
        padding: "0.4rem",
        minWidth: "fit-content",
        display: "flex",
        flexWrap: "no-wrap",
    }),
    placeholder: (base: any) => ({
        ...base,
        display: "flex",
        gridArea: "unset",
        fontSize: "1.5rem",
        padding: "0 0.5rem",
        gridColumn: "1/-1",
    }),
    multiValue: (base: any) => ({
        ...base,
        margin: "0",
        width: "100%",
        height: "2.4rem",
        fontSize: "1.5rem",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: "fit-content",
    }),
    multiValueLabel: (base: any) => ({
        ...base,
        marginLeft: "0.5rem",
        padding: "0.3rem 0.5rem",
    }),
    multiValueRemove: (base: any) => ({
        ...base,
        padding: "0",
    }),
    input: (base: any) => ({
        ...base,
        width: "0",
        height: "0",
        margin: "0",
        padding: "0",
        zIndex: "-1",
        display: "flex",
        gridArea: "unset",
        position: "absolute",
    }),
    indicatorSeparator: (base: any) => ({
        ...base,
        display: "none",
    }),
    menu: (base: any) => ({
        ...base,
        fontSize: "1.5rem",
        textAlign: "start",
        borderRadius: "10px",
    }),
    option: (base: any) => ({
        ...base,
        padding: "1rem",
        fontSize: "1.2rem",
    }),
};
export const maskSelectStyles = {
    container: (base: any) => ({
        ...base,
        width: "15rem",
    }),
    control: (base: any) => ({
        ...base,
        textAlign: "start",
        borderRadius: "10px",
        height: "4rem",
    }),
    valueContainer: (base: any) => ({
        ...base,
        gap: "0.5rem",
        height: "100%",
        overflow: "auto",
        position: "unset",
        padding: "0.4rem",
        minWidth: "fit-content",
        display: "flex",
        flexWrap: "no-wrap",
        fontSize: '1.2rem',
    }),
    placeholder: (base: any) => ({
        ...base,
        display: "flex",
        gridArea: "unset",
        fontSize: "1.5rem",
        padding: "0 0.5rem",
        gridColumn: "1/-1",
    }),
    multiValue: (base: any) => ({
        ...base,
        margin: "0",
        width: "100%",
        height: "2.4rem",
        fontSize: "1.9rem",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: "fit-content",
    }),
    multiValueLabel: (base: any) => ({
        ...base,
        marginLeft: "0.5rem",
        padding: "0.3rem 0.5rem",
    }),
    multiValueRemove: (base: any) => ({
        ...base,
        padding: "0",
    }),
    input: (base: any) => ({
        ...base,
        width: "0",
        height: "0",
        margin: "0",
        padding: "0",
        zIndex: "-1",
        display: "flex",
        gridArea: "unset",
        position: "absolute",
    }),
    indicatorSeparator: (base: any) => ({
        ...base,
        display: "none",
    }),
    menu: (base: any) => ({
        ...base,
        fontSize: "1.5rem",
        textAlign: "start",
        padding: '0.3rem 0',
        borderRadius: "10px",
    }),
    option: (base: any) => ({
        ...base,
        padding: "1rem",
        fontSize: "1.3rem",
    }),
};
export const statisticsSelectStyles = {
    container: (base: any) => ({
        ...base,
        width: "10rem",
    }),
    control: (base: any) => ({
        ...base,
        textAlign: "start",
        borderRadius: "10px",
        height: "3.5rem",
    }),
    valueContainer: (base: any) => ({
        ...base,
        gap: "0.5rem",
        height: "100%",
        overflow: "auto",
        position: "unset",
        padding: "0.4rem",
        minWidth: "fit-content",
        display: "flex",
        flexWrap: "no-wrap",
        fontSize: '1.2rem',
    }),
    placeholder: (base: any) => ({
        ...base,
        display: "flex",
        gridArea: "unset",
        fontSize: "1.5rem",
        padding: "0 0.5rem",
        gridColumn: "1/-1",
        color: 'rgb(180, 180, 180)',
    }),
    multiValue: (base: any) => ({
        ...base,
        margin: "0",
        width: "100%",
        height: "2.4rem",
        fontSize: "1.9rem",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: "fit-content",
    }),
    multiValueLabel: (base: any) => ({
        ...base,
        marginLeft: "0.5rem",
        padding: "0.3rem 0.5rem",
    }),
    multiValueRemove: (base: any) => ({
        ...base,
        padding: "0",
    }),
    input: (base: any) => ({
        ...base,
        width: "0",
        height: "0",
        margin: "0",
        padding: "0",
        zIndex: "-1",
        display: "flex",
        gridArea: "unset",
        position: "absolute",
    }),
    indicatorSeparator: (base: any) => ({
        ...base,
        display: "none",
    }),
    menu: (base: any) => ({
        ...base,
        fontSize: "1.5rem",
        textAlign: "start",
        padding: '0.3rem 0',
        borderRadius: "10px",
        width: '100%',
        minWidth: 'fit-content'
    }),
    option: (base: any) => ({
        ...base,
        padding: "1rem",
        fontSize: "1.3rem",
    }),
};
export const statisticsOptSelectStyles = {
    ...statisticsSelectStyles,
    control: (base: any) => ({
        ...base,
        height: '3rem',
        borderRadius: '10px',
    }),

    container: (base: any) => ({
        ...base,
        width: "8rem",
    }),
    placeholder: (base: any) => ({
        ...base,
        fontSize: "1rem",
    }),
    valueContainer: (base: any) => ({
        ...base,
        height: "100%",
        display: "flex",
        padding: "0 0.5rem",
        alignItems: "center",
    }),
}






export const singleValueStyles = {
    container: (base: any) => ({
        ...base,
        // width: "100%",
    }),
    control: (base: any) => ({
        ...base,
        backgroundColor: "#e5e7eb",
        borderColor: '#9ca3af', // Disable border
        boxShadow: 'none', // Remove shadow
        outline: 'none', // Disable focus ring
        '&:hover': {
          borderColor: '#9ca3af', // Ensure hover state has no border
        },
        // textAlign: "start",
        // padding: "0 0.5rem",
        // borderRadius: "10px",
        // height: "4rem",
    }),
    valueContainer: (base: any) => ({
        ...base,
        // height: "100%",
        // display: "flex",
        // padding: "0 0.5rem",
        // alignItems: "center",
    }),
    placeholder: (base: any) => ({
        ...base,
        // display: "flex",
        // fontSize: "1.5rem",
    }),
    singleValue: (base: any) => ({
        ...base,
        // fontSize: "1.5rem",
        // padding: "0.7rem 0",
    }),
    indicatorSeparator: (base: any) => ({
        ...base,
        // display: "none",
    }),
    menu: (base: any) => ({
        ...base,
        backgroundColor: "#e5e7eb",
        zIndex: 99,
        textAlign: "start",
        // fontSize: "1.5rem",
        // borderRadius: "10px",
    }),
    option: (base: any, state: any) => ({
        ...base,
        // padding: "1rem",
        // backgroundColor: "#e5e7eb",
        transition: "0.2s",
        fontSize: "0.8rem",
        // fontSize: "1.2rem",
        '&:hover': {
            backgroundColor: '#d1d5db', 
            cursor: "pointer",
        },
        backgroundColor: state.isSelected ? '#d1d5db' : "#e5e7eb", // تغییر رنگ پس زمینه آیتم انتخاب شده در منو
        color: "black"
    }),
    multiValue: (base: any) => ({
        ...base,
        // margin: "0",
        // width: "100%",
        // height: "2.4rem",
        // fontSize: "1.5rem",
        // alignItems: "center",
        // justifyContent: "space-between",
        // maxWidth: "fit-content",
        backgroundColor: "#d1d5db"
    }),
    multiValueLabel: (base: any) => ({
        ...base,
        // margin: "0",
        // width: "100%",
        // height: "2.4rem",
        // fontSize: "1.5rem",
        // alignItems: "center",
        // justifyContent: "space-between",
        // maxWidth: "fit-content",
        // backgroundColor: "#374151",
        // color: "white"
    }),
    input: (base: any) => ({
        ...base,
        // width: "0",
        // height: "0",
        // margin: "0",
        // padding: "0",
        // zIndex: "-1",
        // display: "flex",
        // gridArea: "unset",
        // position: "absolute",
        // color: "red"
    }),
    // dropdownIndicator: (base: any) => ({
    //     ...base,
    //     rotate: "180deg"
    // }),
};

export const singleValueStylesDark = {
    container: (base: any) => ({
        ...base,
        // width: "100%",
    }),
    control: (base: any) => ({
        ...base,
        backgroundColor: "#1f2937",
        borderColor: '#030712', // Disable border
        boxShadow: 'none', // Remove shadow
        outline: 'none', // Disable focus ring
        '&:hover': {
          borderColor: '#030712', // Ensure hover state has no border
        },
        // textAlign: "start",
        // padding: "0 0.5rem",
        // borderRadius: "10px",
        // height: "4rem",
    }),
    valueContainer: (base: any) => ({
        ...base,
        // height: "100%",
        // display: "flex",
        // padding: "0 0.5rem",
        // alignItems: "center",
    }),
    placeholder: (base: any) => ({
        ...base,
        // display: "flex",
        // fontSize: "1.5rem",
    }),
    singleValue: (base: any) => ({
        ...base,
        color: "white",
        // fontSize: "1.5rem",
        // padding: "0.7rem 0",
    }),
    indicatorSeparator: (base: any) => ({
        ...base,
        display: "none",
    }),
    menu: (base: any) => ({
        ...base,
        backgroundColor: "#374151",
        zIndex: 99,
        textAlign: "start",
        boxShadow: "0 10px 30px #1e293b",
        // fontSize: "1.5rem",
        // borderRadius: "10px",
    }),
    option: (base: any, state: any) => {
        return {
            ...base,
            // padding: "1rem",
            // backgroundColor: "#1f2937",
            transition: "0.2s",
            fontSize: "0.8rem",
            // fontSize: "1.2rem",
            '&:hover': {
                backgroundColor: '#1f2937', 
                cursor: "pointer",
            },
            backgroundColor: state.isSelected ? '#1f2937' : "#374151", // تغییر رنگ پس زمینه آیتم انتخاب شده در منو
            color: "white",
            opacity: state.isDisabled ? 0.2 : 1
            // zIndex: 999

        }
    },
    multiValue: (base: any) => ({
        ...base,
        // margin: "0",
        // width: "100%",
        // height: "2.4rem",
        // fontSize: "1.5rem",
        // alignItems: "center",
        // justifyContent: "space-between",
        // maxWidth: "fit-content",
        backgroundColor: "#374151",
        color: "white"
    }),
    multiValueLabel: (base: any) => ({
        ...base,
        // margin: "0",
        // width: "100%",
        // height: "2.4rem",
        // fontSize: "1.5rem",
        // alignItems: "center",
        // justifyContent: "space-between",
        // maxWidth: "fit-content",
        backgroundColor: "#374151",
        color: "white"
    }),
    input: (base: any) => ({
        ...base,
        // width: "0",
        // height: "0",
        // margin: "0",
        // padding: "0",
        // zIndex: "-1",
        // display: "flex",
        // gridArea: "unset",
        // position: "absolute",
        color: "white"
    }),
    // dropdownIndicator: (base: any) => ({
    //     ...base,
    //     rotate: "180deg"
    // }),
};

export default function ReactSelect({
    theme, 
    label, 
    onChange, 
    name, 
    error,
    act,
    className,
    ...otherProps
}: any) {

    
    const containerDefaultClass = "select-none"
    const containerOverwriteClass = className ? " " + className : ""
    const disableClass = otherProps.isDisabled ? "opacity-50" : ""
    const containerTwMerge = twMerge(containerDefaultClass, containerOverwriteClass, disableClass)

    return (
        <div className={containerTwMerge}>
            <TextTag className="px-2 mb-1 text-sm text-start">{label}</TextTag>
            <Select
                // isMulti={true}
                // isSearchable={false}
                
                // inputValue=""
                // className="h-[55px]"
                styles={theme === "light" ? singleValueStyles : singleValueStylesDark}
                onChange={(e) => onChange(e, name)}
                {...otherProps}
            />
            {(error && act) && 
                <TextTag
                    as='small'
                    className={`w-full p-2 text-rose-500 dark:text-rose-500 text-end`}
                    // style={styles?.error}
                >
                    {error}
                </TextTag>
            }
        </div>
    )
}