import React, {PropsWithChildren, useEffect, useState} from "react";
import { ThemeProvider, useTheme } from "next-themes";

const ThemesProvider = ({children}: PropsWithChildren) => {
    
    return <ThemeProvider attribute={"class"}>{children}</ThemeProvider>

}


export default ThemesProvider