import { useState } from "react"
import { useTheme } from "next-themes"
import moment from "moment-jalaali"
import { durationsToTime, DurationToTime, TimestampToTime, TimeToTimestamp } from "../../../Utils/convertTime"
import { faWorkType } from "../../Works/Works"
import TextTag from "../../../Components/Common/TextTag/TextTag"
import Button from "../../../Components/Common/Button/Button"
import Dialog from "../../../Components/Common/Dialog/Dialog"
import { getDailyLog } from "../../../axios/DailyLog.api"
import WorksComponent from "../../../Components/Works/Works"
import Textarea from "../../../Components/Common/Textarea/Textarea"
import { confirmActivity, notConfirmActivity } from "../../../axios/Confirm.api"
import { useRecoilState } from "recoil"
import { AllNotificationsStateAtom, notificationType } from "../../../Recoil/confirmsAtom"
import { Toast } from "../../../Utils/Toast"
import { enterExitSensitivityStateAtom } from "../../../Recoil/Atoms"

const faTitle = {
    inOutLog: "زمان ورود و خروج",
    remote: "دورکاری",
    mission: "ماموریت",
    work: "رویداد کاری در شرکت"
}

const BeConfirmed = ({
    notification,
    // onAccept,
    // onUnAccept,
    // loading,
}: {
    notification: notificationType;
    // onAccept: (notif: any) => void;
    // onUnAccept: (notif: any, message: string) => void;
    // loading: boolean;
}) => {
    const faNotificationType = {
        WorkModel: 'کار',
        DailyLogsModel: "ساعت ورود و خروج",
    }
    const [allNotifications, setAllNotifications] = useRecoilState(AllNotificationsStateAtom)
    const [enterExitSensitivityState, setEnterExitSensitivityState] = useRecoilState(enterExitSensitivityStateAtom)
    const [accordion, setAccordion] = useState(false)
    const {theme, setTheme} = useTheme()
    const [modalOpen, setModalOpen] = useState(false)
    const [showMessageBox, setShowMessageBox] = useState(false)
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [deletedItem, setDeletedItem] = useState(0)
    
    const workEvent = (notification: notificationType) => {
        const {type, date, dailyLog, user} = notification
        const workDuration = dailyLog.Works?.reduce((a: any, b: any) => b.type === type ? (a + (b.end - b.start)) : a , 0)
        return (
            <div className="flex items-start justify-start flex-wrap gap-2">
                <span>{faTitle[type as "inOutLog"]}</span>
                <span>|</span>
                <span className="whitespace-nowrap">{`برای تاریخ : ${moment(date*1000).format('jYYYY/jM/jD')}`}</span>
                <span>|</span>
                <span className="whitespace-nowrap">{`به مدت ${durationsToTime(workDuration)}`}</span>
            </div>
        )
    }
    const description = {
        inOutLog: (notification: notificationType) => {
            const {type, date, dailyLog, user} = notification
            const cloneInOutLog = dailyLog ? [...dailyLog.InOutLogs].sort((a, b) => a.enter - b.enter) : []
            const firstEnter = [...cloneInOutLog]?.shift()?.enter
            const lastEnter = [...cloneInOutLog]?.pop()?.exit
            let entryTimeExceeded = 0
            let exitTimeExceeded = 0
            const userLogicEnter = TimeToTimestamp(user?.logicEnter, date) 
            const userLogicExit = TimeToTimestamp(user?.logicExit, date)
            if (firstEnter < (userLogicEnter - enterExitSensitivityState)) entryTimeExceeded = ((userLogicEnter) - firstEnter)
            if (lastEnter > (userLogicExit + enterExitSensitivityState)) exitTimeExceeded = (lastEnter - (userLogicExit))
            return (
                <div className="flex items-start justify-start flex-wrap gap-2">
                    <span>{faTitle[type as "inOutLog"]}</span>
                    <span>|</span>
                    <span className="whitespace-nowrap">{`برای تاریخ : ${moment(date*1000).format('jYYYY/jM/jD')}`}</span>
                    <span>|</span>
                    <span className="whitespace-nowrap">{`ورود : ${TimestampToTime(firstEnter)}`}</span>
                    <span>|</span>
                    <span className="whitespace-nowrap">{`خروج ${TimestampToTime(lastEnter)}`}</span>
                    <span>|</span>
                    <span className="whitespace-nowrap">{`به مدت ${durationsToTime(lastEnter - firstEnter)}`}</span>
                    <span>|</span>
                    <span className="whitespace-nowrap">{`ورود زود هنگام : ${durationsToTime(entryTimeExceeded)}`}</span>
                    <span>|</span>
                    <span className="whitespace-nowrap">{`خروج دیر هنگام : ${durationsToTime(exitTimeExceeded)}`}</span>
                </div>
            )
        },
        remote: workEvent,
        mission: workEvent,
        work: workEvent,
    } 

    const acceptHandler = (notif: any) => {
        setLoading(true)
        confirmActivity(notif.id)
            .then(res => {
                setDeletedItem(notif.id)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }

    const unAcceptHandler = (notif: any, message: string) => {
        if (!message) return Toast("error", "نوشتن پیام الزامیست")
        setLoading(true)
        notConfirmActivity(notif.id, message)
            .then(res => {
                setDeletedItem(notif.id)
                // const cloneAllNotification = [...allNotifications]
                // const filterAllNotifications = cloneAllNotification.filter(notification => notification.id !== notif.id)
                // setAllNotifications(filterAllNotifications)
                // if (!filterAllNotifications.length) setHasNotification(false) 
                // setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }

    const transitionEndHandler = () => {
        if (!deletedItem) return;
        const cloneAllNotification = [...allNotifications]
        const filterAllNotifications = cloneAllNotification.filter(notification => notification.id !== deletedItem)
        setAllNotifications(filterAllNotifications)
        // if (!filterAllNotifications.length) setHasNotification(false) 
        setLoading(false)
    }

    return (
        <div 
            tabIndex={0}
            onTransitionEnd={transitionEndHandler}
            className={`border border-opacity-50 dark:border-opacity-50 ${deletedItem ? "mt-0 -translate-x-full opacity-0 overflow-hidden" : "mt-2"} border-gray-400 dark:border-gray-800 rounded-md overflow-hidden transition-all duration-500`}
        >
            <div 
                className={`flex items-stretch justify-between ${deletedItem ? "p-0 h-0" : "p-1 h-20"} ${accordion ? "" : ""} bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-800 transition-all duration-500`}
            >
                <div className="flex-auto flex items-center justify-start gap-2">
                    <TextTag as="span" className="w-28 flex-none select-none text-[0.8rem] border-e border-gray-300 dark:border-gray-800 text-center p-1">{notification?.user?.username}</TextTag>
                    <TextTag 
                        as="span" 
                        className={`flex-auto flex items-center justify-start select-none text-[0.8rem] ${deletedItem ? "p-0" : "p-2"} hover:!text-sky-500 transition-colors duration-300 overflow-auto h-full cursor-pointer`}
                        onClick={(e) => {
                            e.stopPropagation()
                            setModalOpen(true)
                        }}
                    >
                        {description?.[notification.type as "inOutLog"](notification)}
                    </TextTag>
                </div>
                <div className="w-20 flex-none flex flex-col items-stretch justify-center gap-1">
                    <Button
                        title="تایید"
                        color={theme === "dark" ? "dark" : "light"}
                        loading={loading}
                        onClick={() => acceptHandler(notification)}
                        classNames={{
                            container: "!w-full !flex-1 !rounded-sm !flex !items-center !justify-center ring-1 ring-gray-400 dark:ring-gray-700",
                            section: "!text-[0.8rem]"
                        }} 
                    />
                    <Button
                        title="رد"
                        color={theme === "dark" ? "dark" : "light"}
                        onClick={() => setShowMessageBox(true)}
                        classNames={{
                            container: "!w-full !flex-1 !rounded-sm !flex !items-center !justify-center ring-1 ring-gray-400 dark:ring-gray-700",
                            section: "!text-sm"
                        }} 
                    />

                </div>
            </div>
            <form className={`w-full transition-[height,padding] duration-500 flex flex-col items-stretch ${(showMessageBox && !deletedItem) ? "h-44" : "h-0"}`}>
                <Textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="پیام خود ، برای کاربر مربوطه را ، در این قسمت وارد کنید"
                    classNames={{
                        container: "h-28 border-t border-gray-300 dark:border-gray-800",
                        inputBox: "h-full bg-gray-200 dark:bg-gray-700 rounded-none border-none",
                        input: "!bg-transparent h-full duration-0"
                    }}
                />
                <div className="flex items-center justify-center gap-3 p-3 bg-gray-200 dark:bg-gray-700">
                    <Button
                        title="ارسال"
                        color={theme === "dark" ? "dark" : "light"}
                        loading={loading}
                        onClick={() => unAcceptHandler(notification, message)}
                        classNames={{
                            container: "!w-28 !h-10 !rounded-[3px]",
                            section: "!text-sm"
                        }}
                    />
                    <Button
                        title="لغو"
                        color={theme === "dark" ? "dark" : "light"}
                        onClick={() => {
                            setMessage("")
                            setShowMessageBox(false)
                        }}
                        classNames={{
                            container: "!w-28 !h-10 !rounded-[3px]",
                            section: "!text-sm"
                        }}
                    />
    
                </div>
            </form>
            {modalOpen &&
                <Dialog
                    open={true}
                    setOpen={setModalOpen}
                >
                    <UserWorkDetails
                        notification={notification}
                        setModalOpen={setModalOpen}
                        acceptHandler={acceptHandler}
                        loading={loading}
                        setShowMessageBox={setShowMessageBox}
                    />
                </Dialog>
            }
        
        </div>
    )
}

const NotificationDesc = ({
    notification
}: {
    notification: notificationType
}) => {

    const [enterExitSensitivityState, setEnterExitSensitivityState] = useRecoilState(enterExitSensitivityStateAtom)
    const {type, date, dailyLog, user} = notification
    const cloneInOutLog = dailyLog ? [...dailyLog.InOutLogs].sort((a, b) => a.enter - b.enter) : []
    const firstEnter = [...cloneInOutLog]?.shift()?.enter
    // console.log(cloneInOutLog);
    const lastEnter = [...cloneInOutLog]?.pop()?.exit
    let entryTimeExceeded = 0
    let exitTimeExceeded = 0
    const userLogicEnter = TimeToTimestamp(user?.logicEnter, date) 
    const userLogicExit = TimeToTimestamp(user?.logicExit, date)
    if (firstEnter < (userLogicEnter - enterExitSensitivityState)) entryTimeExceeded = ((userLogicEnter) - firstEnter)
    if (lastEnter > (userLogicExit + enterExitSensitivityState)) exitTimeExceeded = (lastEnter - (userLogicExit))

    const faTitle = {
        inOutLog: "زمان ورود و خروج",
        remote: "دورکاری",
        mission: "ماموریت",
        work: "رویداد کاری در شرکت"
    }
    return (
        <div className="flex items-start justify-start flex-wrap gap-2">
            <span>{faTitle[type as "inOutLog"]}</span>
            <span>|</span>
            <span className="whitespace-nowrap">{`برای تاریخ : ${moment(date*1000).format('jYYYY/jM/jD')}`}</span>
            <span>|</span>
            <span className="whitespace-nowrap">{`ورود : ${TimestampToTime(firstEnter)}`}</span>
            <span>|</span>
            <span className="whitespace-nowrap">{`خروج ${TimestampToTime(lastEnter)}`}</span>
            <span>|</span>
            <span className="whitespace-nowrap">{`به مدت ${DurationToTime(lastEnter - firstEnter, date)}`}</span>
            <span>|</span>
            <span className="whitespace-nowrap">{`ورود زود هنگام : ${DurationToTime(entryTimeExceeded, date)}`}</span>
            <span>|</span>
            <span className="whitespace-nowrap">{`خروج دیر هنگام : ${DurationToTime(exitTimeExceeded, date)}`}</span>
        </div>
    )
}

const UserWorkDetails = ({
    notification,
    setModalOpen,
    acceptHandler,
    loading,
    setShowMessageBox,
}: {
    notification: any;
    setModalOpen: any;
    acceptHandler: any;
    loading: boolean;
    setShowMessageBox: any;
}) => {
    const { date, user } = notification;
    const {theme, setTheme} = useTheme();
    return (
        <div className="flex flex-col items-stretch w-[calc(100vw-20px)] lg:w-[1000px] xl:w-[1200px] h-[800px] bg-gray-200 dark:bg-gray-700 p-2 rounded-lg">
            <div className="flex-none flex items-center justify-between px-2 pb-2 pt-1">
                <TextTag>{user.username}</TextTag>
                <div className="flex items-center justify-center gap-2">
                    <Button
                        title="تایید"
                        color={theme === "dark" ? "dark" : "light"}
                        loading={loading}
                        onClick={() => acceptHandler(notification)}
                        classNames={{
                            container: "!w-20 !h-8 !rounded-md !flex !items-center !justify-center !overflow-hidden ring-1 ring-gray-400 dark:ring-gray-700",
                            section: "!text-[0.8rem]"
                        }} 
                    />
                    <Button
                        title="رد"
                        color={theme === "dark" ? "dark" : "light"}
                        onClick={() => {
                            setShowMessageBox(true)
                            setModalOpen(false)
                        }}
                        classNames={{
                            container: "!w-20 !h-8 !rounded-md !flex !items-center !justify-center !overflow-hidden ring-1 ring-gray-400 dark:ring-gray-700",
                            section: "!text-[0.8rem]"
                        }} 
                    />
                    <Button
                        icon="x-lg"
                        color={theme === "dark" ? "dark" : "light"}
                        iconWidth="1.3rem"
                        iconHeight="1.3rem"
                        iconColor={theme === "dark" ? "lightgray" : "gray"}
                        iconHover={theme === "dark" ? "white" : "black"}
                        onClick={() => setModalOpen(false)}
                        classNames={{
                            container: "!w-8 !h-8 !rounded-md !flex !items-center !justify-center !overflow-hidden ring-1 ring-gray-400 dark:ring-gray-700",
                        }}
                    />
                </div>
            </div>
            <div className="flex-auto">
                <WorksComponent 
                    userId={user.id}
                    forDate={date}
                    justSee
                />
            </div>
        </div>
    )
}

export default BeConfirmed