import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  DayValue,
  DayRange,
  Day,
  Calendar,
} from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import moment from "moment-jalaali";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTheme } from "next-themes";
import {
  AccessPeriodToCloseTheMonthStateAtom,
  AccessToCloseMonthBeforeEndOfMonthStateAtom,
  GlobalLoadingAtom,
  ServerDateState,
  UserDataState,
} from "../../Recoil/Atoms";
import { BeforeMonthIsClosedStateAtom, MonthlyLogAtom } from "../../Recoil/monthlyLogAtom";
import { yearMonthToTimeStamp } from "../../Utils/convertTime";
import { closeMonth, getMonthlyLogByMonth } from "../../axios/MonthlyLog.api";
import Button from "../Common/Button/Button";
import Dialog from "../Common/Dialog/Dialog";
import Icon from "../Common/Icon/Icon";
import TextTag from "../Common/TextTag/TextTag";

// import DatePicker from "react-multi-date-picker";
// import { Calendar } from "react-multi-date-picker"
// import persian from "react-date-object/calendars/persian"
// import persian_fa from "react-date-object/locales/persian_fa"

const monthList = {
  فروردین: "1",
  اردیبهشت: "2",
  خرداد: "3",
  تیر: "4",
  مرداد: "5",
  شهریور: "6",
  مهر: "7",
  آبان: "8",
  آذر: "9",
  دی: "10",
  بهمن: "11",
  اسفند: "12",
};

const DashboardCalendar = ({
  value,
  onChange,
  onSelect,
}: {
  value: DayValue
  onChange: (value: { year: string; month: string }) => void;
  onSelect: () => void;
}) => {
  const serverDate = useRecoilValue(ServerDateState);
  const { theme, setTheme } = useTheme();
  const [userData, setUserData] = useRecoilState(UserDataState);
  const [accessPeriodToCloseTheMonth, setAccessPeriodToCloseTheMonth] =
    useRecoilState(AccessPeriodToCloseTheMonthStateAtom);
  const [monthlyLog, setMonthlyLog] = useRecoilState(MonthlyLogAtom);
  const [modalOpen, setModalOpen] = useState(false);
  const [accessToCloseMonthBeforeEndOfMonth, setAccessToCloseMonthBeforeEndOfMonth] = useRecoilState(AccessToCloseMonthBeforeEndOfMonthStateAtom)
  //   const [date, setDate] = useState({
  //     year: String(moment(serverDate * 1000).jYear()),
  //     month: String(moment(serverDate * 1000).jMonth() + 1),
  //   });

  // const dateRef = useRef({
  //   year: String(value?.year ?? "0"),
  //   month: String(value?.month ?? "0"),
  // });

  const [dateRef, setDateRef] = useState({
    year: String(value?.year ?? "0"),
    month: String(value?.month ?? "0"),
  })

  const [day, setDay] = useState<DayValue>({
    year: Number(moment(serverDate * 1000).format("jYYYY")),
    month: Number(moment(serverDate * 1000).format("jMM")),
    day: Number(moment(serverDate * 1000).format("jDD")),
  });

  const [globalLoading, setGlobalLoading] = useRecoilState(GlobalLoadingAtom);

  const clicked = useRef<any>();
  const calendarClickHandler = async () => {
    clearTimeout(clicked.current);
    await new Promise((resolve) => {
      clicked.current = setTimeout(() => {
        resolve(true);
      }, 1000);
    });
    const toEnglishNumber = (str: string) => {
      return str?.replace(/[۰-۹]/g, (d) =>
        String.fromCharCode(d.charCodeAt(0) - 1728)
      );
    };
    const cloneDate = { ...dateRef };
    const boxElement = document.getElementsByClassName(
      "Calendar__monthYear -shown"
    )[0]?.children;
    const year = toEnglishNumber(boxElement?.[1]?.innerHTML);
    const month = monthList[boxElement?.[0]?.innerHTML as "فروردین"]?.padStart(
      2,
      "0"
    );
    if (`${cloneDate.year}-${cloneDate.month}` === `${year}-${month}`) return;
    setDateRef({
      year,
      month,
    });
    setGlobalLoading(true);
    onChange({ year, month });
  };

  // const calendarClickHandler = async () => {
  //   const toEnglishNumber = (str: string) => {
  //     return str?.replace(/[۰-۹]/g, (d) =>
  //       String.fromCharCode(d.charCodeAt(0) - 1728)
  //     );
  //   };
  //   const cloneDate = { ...dateRef.current };
  //   await new Promise((resolve, reject) => setTimeout(() => resolve(true), 1000));
  //   const boxElement = document.getElementsByClassName(
  //     "Calendar__monthYear -shown"
  //   )[0]?.children;
  //   const year = toEnglishNumber(boxElement?.[1]?.innerHTML);
  //   const month = monthList[boxElement?.[0]?.innerHTML as "فروردین"]?.padStart(
  //     2,
  //     "0"
  //   );
  //   if (`${cloneDate.year}-${cloneDate.month}` === `${year}-${month}`) return;
  //   dateRef.current = {
  //       year,
  //       month
  //   }
  //   onChange({ year, month });
  //   //   getMonthlyLogHandler(`${year}-${month}`);
  // };

  // const openMonthHandler = () => {
  //   console.log(dateRef);
  // };

  const disableCloseMonthButton = useCallback(() => {
    if (monthlyLog?.closedDate) return false;
    const { from, to } = yearMonthToTimeStamp(`${dateRef.year}-${dateRef.month}`);
    if ((to - accessToCloseMonthBeforeEndOfMonth) >= serverDate || serverDate - to > accessPeriodToCloseTheMonth) return true;
    return false;
  }, [monthlyLog, dateRef]);


  // const getMonthlyLogHandler = (yearMonth: string) => {
  // getMonthlyLogByMonth({ yearMonth })
  //     .then((res) => {
  //       console.log(res.data);
  //       if (!res.data) return setMonthlyLog({});
  //       setMonthlyLog(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const reloadMonthHandler = () => {
    // const toEnglishNumber = (str: string) => {
    //   return str?.replace(/[۰-۹]/g, (d) =>
    //     String.fromCharCode(d.charCodeAt(0) - 1728)
    //   );
    // };
    // const boxElement = document.getElementsByClassName(
    //   "Calendar__monthYear -shown"
    // )[0]?.children;
    // const year = toEnglishNumber(boxElement?.[1]?.innerHTML);
    // const month = monthList[boxElement?.[0]?.innerHTML as "فروردین"]?.padStart(
    //   2,
    //   "0"
    // );
    // onChange({ year, month });

    const boxElement = document.getElementsByClassName(
      "Calendar__monthYear -shown"
    )[0]?.children;
  };

  // useEffect(() => {
  //   const year = String(moment(serverDate * 1000).jYear());
  //   const month = String(moment(serverDate * 1000).jMonth() + 1);
  //   // setDate({ year, month });
  //   dateRef.current = {
  //       year,
  //       month
  //   }
  //   getMonthlyLogHandler(`${year}-${month}`);

  //   // const Calendar__header = document.querySelector(".Calendar__header")
  //   // const Calendar__monthSelectorAnimationWrapper = document.querySelector(".Calendar__monthSelectorAnimationWrapper")
  //   // Calendar__header?.addEventListener('click', calendarClickHandler)
  //   // Calendar__monthSelectorAnimationWrapper?.addEventListener('click', calendarClickHandler)
  //   // console.log(object);
  // }, []);

  //   const [value, setValue] = useState<string | Date | null>(new Date());
  //   const [focusedDate, setFocusedDate] = useState<string | null>(null);
  //   console.log(value?.valueOf());
  //   console.log(focusedDate);


  useEffect(() => {
    setDateRef({
      year: String(value?.year ?? "0"),
      month: String(value?.month ?? "0"),
    })
  }, [value])

  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!divRef.current) return;

    const boxElement = document.querySelector(".Calendar__monthYear -shown");
    // const observer = new MutationObserver((mutations) => {
    //   mutations.forEach((mutation) => {
    //     if (mutation.type === "childList") {
    //       console.log("محتوای تگ تغییر کرد:", divRef.current?.innerText);
    //     }
    //   });
    // });

    // observer.observe(boxElement, { childList: true, subtree: true });

    // return () => observer.disconnect(); // Cleanup on unmount
  }, []);

  return (
    <div
      ref={divRef}
      onClick={calendarClickHandler}
      className="flex-auto lg:flex-none w-full md:w-40 lg:w-[450px] xl:w-[500px] flex flex-col items-center justify-center rounded-lg overflow-hidden bg-gray-50 dark:bg-gray-800/80"
    >
      <Calendar
        locale="fa"
        value={value}
        onChange={(e) => console.log(e)}
        maximumDate={{
          year: Number(moment(serverDate * 1000).format("jYYYY")),
          month: Number(moment(serverDate * 1000).format("jMM")),
          day: Number(moment(serverDate * 1000).format("jDD")),
        }}
        shouldHighlightWeekends
        calendarClassName="!text-gray-800 !w-full !h-[450px] aspect-square !min-w-320px !ring-0 !rounded-none bg-gray-50 dark:!bg-gray-800/20 !text-[13px] 2xl:!text-[14px]"
        calendarTodayClassName="!border-2 !border-gray-500"
        calendarSelectedDayClassName="!bg-gray-700 !text-gray-100 dark:!bg-gray-200 dark:!text-gray-700"
      />

      {/* <Calendar
      calendar={persian}
      locale={persian_fa}
      value={value} 
      onChange={(newDate) => setValue(newDate as string | Date | null)} 
      onFocusedDateChange={(date) => {
        if (date) {
          setFocusedDate(date.format("YYYY-MM"));
        }
      }} 
      format="YYYY/MM/DD"
    /> */}

      <div className="flex-none w-full flex items-center justify-between gap-1 p-3">
        {/* <Button
          color={theme === "dark" ? "dark" : "light"}
          icon="arrow-counterclockwise"
          // loading={loading}
          onClick={reloadMonthHandler}
          classNames={{
            container: "!w-[45px] !h-[38px] !rounded-r-xl !rounded-l-[4px] !px-2 transition-all duration-300",
            section: "!text-sm",
          }}
        /> */}
        <Button
          title={
            !monthlyLog?.closedDate
              ? "بستن ماه"
              : `بسته شده در : ${moment(monthlyLog?.closedDate * 1000)
                  .format("jYYYY/jM/jD - HH:mm")
                  .split(" - ")
                  .reverse()
                  .join(" - ")}`
          }
          // color="success"
          color={
            monthlyLog?.closedDate
              ? theme === "dark"
                ? "dark"
                : "light"
              : "success"
          }
          onClick={() => {
            setModalOpen(true);
          }}
          disabled={disableCloseMonthButton()}
          classNames={{
            container:
              "!w-full !h-[38px] !rounded-[4px] !px-2 !border border-gray-400 dark:border-gray-900 transition-all duration-300",
            section: "!text-sm",
          }}
        />
      </div>
      <Dialog open={modalOpen} setOpen={setModalOpen}>
        <CloseAccess
          setModalOpen={setModalOpen}
          date={`${dateRef.year}-${dateRef.month}`}
        />
      </Dialog>
    </div>
  );
};

const CloseAccess = ({
  date,
  setModalOpen,
}: {
  date: string;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { theme, setTheme } = useTheme();
  const [monthlyLog, setMonthlyLog] = useRecoilState(MonthlyLogAtom);
    const [beforeMonthIsClosed, setBeforeMonthIsClosed] = useRecoilState(
      BeforeMonthIsClosedStateAtom
    );
  const [loading, setLoading] = useState(false);

  const closeMonthHandler = () => {
    if (monthlyLog?.closedDate) return;
    setLoading(true);
    closeMonth({ yearMonth: date })
      .then((res) => {
        setMonthlyLog(res.data);
        setBeforeMonthIsClosed(true)
        setLoading(false);
        setModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setModalOpen(false);
      });
  };

  return (
    <div className="flex flex-col bg-gray-100 dark:bg-gray-700 rounded-lg">
      <div className="flex-none flex items-center justify-center p-2">
        <Icon
          name="exclamation-circle"
          width="5rem"
          height="5rem"
          color="#f43f5e"
        />
      </div>
      <TextTag as="h4" className="py-6 px-16">
        درصورت بستن ماه ، دیگر امکان ایجاد تغییر در ساعات کار و تردد وجود نخواهد
        داشت
      </TextTag>
      <TextTag as="h4" className="py-6 px-16">
        آیا از بستن ماه اطمینان دارید؟!
      </TextTag>
      <div className=" flex items-center justify-center gap-3 p-3">
        <Button
          title="بستن ماه"
          color={theme === "dark" ? "dark" : "light"}
          loading={loading}
          onClick={closeMonthHandler}
          classNames={{
            container: "!w-28 !h-10 !rounded-[3px]",
            section: "!text-sm",
          }}
        />
        <Button
          title="انصراف"
          color={theme === "dark" ? "dark" : "light"}
          onClick={() => setModalOpen(false)}
          classNames={{
            container: "!w-28 !h-10 !rounded-[3px]",
            section: "!text-sm",
          }}
        />
      </div>
    </div>
  );
};

export default DashboardCalendar;
