
import { twMerge } from 'tailwind-merge';

type AllowedTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'small' | 'span' | 'u';
type AllowedElementType = Extract<React.ElementType, AllowedTags>;
type PolymorphicComponentProps<T extends AllowedElementType> = {
    as?: T;
    children: React.ReactNode;
    disableUserSelect?: boolean;
} & React.ComponentPropsWithoutRef<T>;

const fontSize = {
    h1: "text-3xl",
    h2: "text-2xl",
    h3: "text-xl",
    h4: "text-lg",
    h5: "text-md",
    p: "text-sm",
    small: "text-[0.7rem] block",
    span: "text-sm",
    u: ""
}

const TextTag = <T extends AllowedElementType = 'p'>({
    as,
    children,
    className,
    disableUserSelect = false,
    ...props
}: PolymorphicComponentProps<T>) => {
    const Component = as || 'p';
    
    const baseClasses = `text-gray-600 dark:text-gray-300 cursor-[invite] ${fontSize[as as AllowedTags] ?? fontSize.p}`;
    const userSelect = disableUserSelect ? "select-none" : ""
    const mergedClasses = twMerge(baseClasses, className, userSelect);

    return (
        <Component 
            className={mergedClasses}
            {...props}
        >
            {children}
        </Component>
    );
};
export default TextTag