import { get, post } from "./Axios.config"
import Cookies from 'universal-cookie';
const cookies = new Cookies();


export const LogOut = async () => {
  const cookie = cookies.get("ACCESS_TOKEN")
  return await get({
    api: `auth/logout`,
    config: {
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${cookie}`
      } 
    }
  })
}


export const AuthForm = async (type: "login" | "register", data: any) => {
  const cookie = cookies.get("ACCESS_TOKEN")
    return await post({
      api: `auth/${type}`,
      data,
      config: {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${cookie}`
        } 
      }
    })
  }