
const usernameValidation = (value: any) => {
    let error = ""

    if (!value.inputValue.trim()) {
        error = "نام کاربری را وارد کنید"
    } else {
        error = ""
    }
    return error;
}

const emailValidation = (value: any) => {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let error = ""

    if (!value.inputValue.trim()) {
        error = "ایمیل را وارد کنید"
    } else if (!value.inputValue.match(emailRegex)) {
        error = "ساختار ایمیل اشتباه است"
    } else {
        error = ""
    }
    return error;
}

const passwordValidation = (value: any) => {
    let error = "";
    
    if (!value.inputValue.trim()) {
        error = "رمز عبور را وارد کنید"
    } else if (value.inputValue.length < 6) {
        error = "باید بیشتر از 4 المان باشد"
    } else {
        error = ""
    }
    return error;
}

// const confirmPasswordValidation = (value: any, pass?: any) => {
//     let error = "";

//     if (!value.inputValue.trim()) {
//         error = 'تکرار رمز عبور را وارد کنید'
//     } else if (value.inputValue !== pass) {
//         error = "اشتبا ه است"
//     } else {
//         error = ""
//     }
//     return error;
// }

const registerFormValidation = {
    username: usernameValidation,
    email: emailValidation,
    password: passwordValidation,
    // confirmPassword: confirmPasswordValidation,
}

export {registerFormValidation};