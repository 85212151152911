import { useEffect, useState } from "react";
import DashboardAggregation from "../../Components/Dashboard/Aggregations";
import DashboardCalendar from "../../Components/Dashboard/Calendar";
import DashboardCharts from "../../Components/Dashboard/Charts";
import moment from "moment-jalaali";
import { GlobalLoadingAtom, ServerDateState } from "../../Recoil/Atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { getMonthlyLogByMonth } from "../../axios/MonthlyLog.api";
import {
  BeforeMonthIsClosedStateAtom,
  MonthlyLogAtom,
} from "../../Recoil/monthlyLogAtom";
import { DayValue } from "@hassanmojab/react-modern-calendar-datepicker";

export default function Dashboard() {
  const serverDate = useRecoilValue(ServerDateState);
  const [aggregation, setAggregation] = useState<any>(null);
  const [monthlyLog, setMonthlyLog] = useRecoilState(MonthlyLogAtom);
  const [globalLoading, setGlobalLoading] = useRecoilState(GlobalLoadingAtom);
  const [beforeMonthIsClosed, setBeforeMonthIsClosed] = useRecoilState(
    BeforeMonthIsClosedStateAtom
  );
  const [day, setDay] = useState<DayValue>({
    year: Number(moment(serverDate * 1000).format("jYYYY")),
    month: Number(moment(serverDate * 1000).format("jMM")),
    day: Number(moment(serverDate * 1000).format("jDD")),
  });

  const calendarChangeHandler = ({
    year,
    month,
  }: {
    year: string;
    month: string;
  }) => {
    getMonthlyLogHandler(`${year}-${month}`);
  };

  const calendarSelectHandler = () => {};

  const getMonthlyLogHandler = (yearMonth: string) => {
    getMonthlyLogByMonth({ yearMonth })
      .then((res) => {
        const {
          monthlyLog,
          aggregations: {
            aggregations: { username, ...other },
          },
        } = res.data;
        setMonthlyLog(monthlyLog);
        setAggregation(other);
        setGlobalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setMonthlyLog(null);
        setAggregation(null);
        setGlobalLoading(false);
      });
  };

  useEffect(() => {
    const m = moment(serverDate * 1000);
    let month = m.jMonth() + 1;
    let year = m.jYear();
    let day = m.jDate();
    if (!beforeMonthIsClosed) {
      month = m.jMonth() === 0 ? 12 : m.jMonth();
      year = m.jMonth() === 0 ? m.jYear() - 1 : m.jYear();
      day = 1;
    }
    setDay({
      year,
      month,
      day,
    });
    getMonthlyLogHandler(`${year}-${month}`);
  }, [beforeMonthIsClosed]);

  return (
    <div className="flex flex-col gap-10 p-3 lg:p-0">
      <div className="flex items-stretch justify-between gap-3 flex-col md:flex-row ">
        <DashboardCalendar
          value={day}
          onChange={calendarChangeHandler}
          onSelect={calendarSelectHandler}
        />
        <DashboardCharts />
      </div>
      <DashboardAggregation aggregation={aggregation} />
    </div>
  );
}
