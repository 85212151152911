import { RecoilState, atom, selector } from "recoil";
import { typeSearchEmployeesParamsType, UserProps } from "../pages/Users/Types";

export const EmployeesAtom: RecoilState<UserProps[]> = atom<UserProps[]>({
    key: 'EmployeesAtom',
    default: [],
});

export const SearchEmployeesParamsAtom: RecoilState<typeSearchEmployeesParamsType> = atom({
    key: 'SearchEmployeesParamsAtom',
    default: {},
});