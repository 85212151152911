import { UserValuesType } from "../Components/UserForm/Types";
import { get, put, post, deleted, patch } from "./Axios.config"
import Cookies from 'universal-cookie';
const cookies = new Cookies();


export const getSupervisorNotifications = async () => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `confirms/get_supervisor_notifications`,
        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const getUserNotifications = async () => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `confirms/get_user_notifications`,
        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const getAllNotifications = async () => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `confirms/get_all_notifications`,
        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}