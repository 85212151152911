import { useRecoilState } from "recoil";
import { AllNotificationsStateAtom } from "../Recoil/confirmsAtom";

const useNotificationsUpdate = () => {
    const [allNotifications, setAllNotifications] = useRecoilState(AllNotificationsStateAtom)
    return ({
        dailyLogId,
        type,
    }: {
        dailyLogId: number,
        type?: "inOutLog" | "remote" | "mission" | "work",
    }) => {
        if (!type) return;
        const cloneNotifications = [...allNotifications]
        const newNotifications = cloneNotifications.filter(notif => !(notif.dailyLogId === dailyLogId && notif.type === type))
        setAllNotifications(newNotifications)
    } 
};


export default useNotificationsUpdate;
