import { useRecoilState, useRecoilValue } from "recoil"
import useGenerateTimeFrame from "../../../hooks/useGenerateTimeFrame"
import { DailyLogAtom, ExitTempAtom, hasInComponyAtom, LockWorkPageAtom, MissionType, PinsStateAtom, TransmissionTimeAtom, WorkDto, WorksInDayAtom, WorkType } from "../../../Recoil/Works.atom"
import { DateState, GlobalLoadingAtom, MinimumWorkTimeDurationStateAtom, ServerDateState, UserDataState } from "../../../Recoil/Atoms"
import { InOutLogAtom } from "../../../Recoil/InOutLogAtom"
import { useEffect, useRef, useState } from "react"
import { useTheme } from "next-themes"
import { Toasts } from "../../../toasts/toasts"
import { ServerDateToDay, timestampToHours, timestampToMinutes, TimestampToTime, TimeToTimestamp } from "../../../Utils/convertTime"
import { updateTimeLog } from "../../../axios/DailyLog.api"
import { insertTimeLog } from "../../../axios/TimeLog"
import { insertDailyLog, updateInOutLog } from "../../../axios/InOutLog.api"
import { Toast } from "../../../Utils/Toast"
import { addWork } from "../../../axios/Work.api"
import ClockPicker from "../../Common/ClockPicker/ClockPicker"
import TextTag from "../../Common/TextTag/TextTag"
import Icon from "../../Common/Icon/Icon"
import IconColored from "../../Common/Icon/IconColored"
import InOutLogs from "../InOutLogs/InOutLogs"
import Work from "../Work/Work"
import useNotificationsUpdate from "../../../hooks/useNotificationsUpdate"
import Button from "../../Common/Button/Button"
const oneDay = 60*60*24;

const WorksBar = ({
    selectedWork,
    setSelectedWork,
    setOverflow,
    timeFrame,
    setTimeFrame,
    timeSpanDuration,
    setTimeSpanDuration,
    findExitTime,
    justSee,
    addNewWork,
    addWorkByButton
    // generateTimeFrame
}: {
    selectedWork: number,
    setSelectedWork: React.Dispatch<React.SetStateAction<number>>
    setOverflow: React.Dispatch<React.SetStateAction<any>>
    timeFrame: string[],
    setTimeFrame: React.Dispatch<React.SetStateAction<string[]>>
    timeSpanDuration: number,
    setTimeSpanDuration: React.Dispatch<React.SetStateAction<number>>
    justSee: boolean;
    addNewWork: (start: number) => void;
    addWorkByButton: () => void;
    findExitTime: (data: {
        exit?: number;
        works: WorkDto[];
        serverDate: number;
        date: number;
        logicExit: string;
    }) => string
    // generateTimeFrame: ({from, to}: {from: number, to: number}) => void
}) => {

    const updateNotification = useNotificationsUpdate()
    const generateTimeFrame = useGenerateTimeFrame()
    const [timeLog, setTimeLog] = useRecoilState(TransmissionTimeAtom)
    const [works, setWorks] = useRecoilState(WorksInDayAtom)
    const [exitTemp, setExitTemp] = useRecoilState(ExitTempAtom)
    const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom)
    const [inOutLogs, setInOutLogs] = useRecoilState(InOutLogAtom)
    const [pins, setPins] = useRecoilState(PinsStateAtom)
    const [globalLoading, setGlobalLoading] = useRecoilState(GlobalLoadingAtom)
    const [hasInCompony, setHasInCompony] = useRecoilState(hasInComponyAtom)
    // const [isPending, startTransition] = useTransition();
    // const [user, setUser] = useState({
    //     logicEnter: "08:00",
    //     logicExit: "17:00",
    // })
    const [user, setUser] = useRecoilState(UserDataState)
    const worksBoxRef = useRef<any>(null)
    const serverDate = useRecoilValue(ServerDateState)
    const {theme, setTheme} = useTheme();
    const [workBoxSize, setWorkBoxSize] = useState(0)
    const [date, setDate] = useRecoilState(DateState)
    const [timeLogLoading, setTimeLogLoading] = useState(false)
    const [lock, setLock] = useRecoilState(LockWorkPageAtom)
    const [enterClockPickerIsOpen, setEnterclockPickerIsOpen] = useState(false)
    const [exitClockPickerIsOpen, setExitclockPickerIsOpen] = useState(false)

    
    

    // useEffect(() => {
    //     holidayChecker(date)
    // }, [date])
    // const holidayChecker = async (date: number) => {
    //     const inDate = new Date(date*1000)
    //     const year = inDate.getFullYear()
    //     const mounts = inDate.getMonth()+1
    //     const day = inDate.getDate()
    //     const url = `https://holidayapi.ir/gregorian/${year}/${String(mounts).padStart(2, "0")}/${String(day).padStart(2, "0")}`
    //     const holiday = await axios.get(url)
    //     console.log(holiday)
    //     // const data = await holiday
    //     return url
    // }


    const newEnterHandlerBefore = (value: {hours: string, minutes: string}, name?: string) => {
        if (lock) return Toasts.lockWorks()
        setTimeLogLoading(true)
        let hours = timestampToHours(serverDate).toString().padStart(2, "0")
        let minutes = timestampToMinutes(serverDate).toString().padStart(2, "0")
        if (value) {
            hours = value.hours
            minutes = value.minutes
        }

        if (timeLog.enter) {
            updateTimeLog(
                date,
                { enter: date + (+hours*60*60) + (+minutes*60) }
            )
                .then((res) => {
                    console.log(res)
                    // const enterTime = timeLog.enter ? +timeLog.enter.split(":")?.[0] : user.logicExit ? user.logicExit.split(":")?.[0] : 0
                    // const loopFrom = +enterTime
                    // const loopTo = +hours+1
                    // const time_frame = []
                    // for (let i=loopFrom; i<=loopTo; i++) {
                    //     time_frame.push(`${String(i).padStart(2, "0")}:00`)
                    // }
                    // setTimeFrame(time_frame)
                    // setTimeLog({
                    //     ...timeLog,
                    //     exit: `${hours}:${minutes}`
                    // })
                    // const spanDuration = (loopTo - loopFrom) * 60 * 60 * 1000
                    // setTimeSpanDuration(spanDuration)
                    const { 
                        date, 
                        enter, 
                        exit, 
                        idleTime, 
                        confirmed 
                    } = res.data
                    if (!timeLog.enter || !enter) return; 
                    // console.log(timeLog.enter, enter, serverDate)
                    const enterTime = TimestampToTime(enter).split(":")?.[0]
                    // console.log(enterTime)
                    const loopFrom = +enterTime
                    const loopTo = exit ? (timestampToHours(exit)+1) : (user.logicExit ? +user.logicExit.split(":")?.[0] : 24)
                    const time_frame: string[] = []
                    for (let i=loopFrom; i<=loopTo; i++) {
                        time_frame.push(`${String(i).padStart(2, "0")}:00`)
                    }
                    // startTransition(() => )
                    setTimeFrame(time_frame)
                    setTimeLog({
                        ...timeLog,
                        enter
                    })
                    const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
                    setTimeSpanDuration(spanDuration)
                })
                .catch((err) => {
                    console.log(err)
                })
  
        } else {
            insertTimeLog({
                date,
                enter: date + (+hours*60*60) + (+minutes*60),
            })
                .then((res) => {
                    // console.log(res)
                    const { date, enter, idleTime, confirmed } = res.data
                    const exitTime = findExitTime({
                        exit: timeLog.exit,
                        works,
                        serverDate,
                        date,
                        logicExit: user.logicExit
                    })
                    const loopFrom = timestampToHours(enter)
                    const loopTo = +exitTime < 24 ? +exitTime+1 : +exitTime
                    const time_frame: string[] = []
                    for (let i=loopFrom; i<=loopTo; i++) {
                        time_frame.push(`${String(i).padStart(2, "0")}:00`)
                    }
                    // startTransition(() => )
                    setTimeFrame(time_frame)
                    setTimeLog({
                        ...timeLog,
                        enter,
                        confirmed,
                    })
                    const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
                    setTimeSpanDuration(spanDuration)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        

    }

    const newExitHandlerBefore = (value: {hours: string, minutes: string}, name?: string) => {
        if (lock) return Toasts.lockWorks()
        // const hours = value?.get("hours").toString().padStart(2, "0") ?? "00"
        // const minutes = value?.get("m").toString().padStart(2, "0") ?? "00"

        const {hours, minutes} = value
        updateTimeLog(
            date,
            { exit: date + (+hours*60*60) + (+minutes*60) }
        )
            .then((res) => {
                const { date, enter, exit, idleTime, confirmed } = res.data
                if (!timeLog.enter || !enter) return; 
                const enterTime = TimestampToTime(enter).split(":")?.[0]
                const loopFrom = +enterTime
                const loopTo = timestampToHours(exit)+1
                const time_frame: string[] = []
                for (let i=loopFrom; i<=loopTo; i++) {
                    time_frame.push(`${String(i).padStart(2, "0")}:00`)
                }
                setTimeFrame(time_frame)
                setTimeLog({
                    ...timeLog,
                    exit
                })
                setExitTemp(exit)
                const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
                setTimeSpanDuration(spanDuration)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    
    const newEnterHandler = (value: {hours: string, minutes: string}, name?: string) => {
        if (justSee) return;
        if (lock) return Toasts.lockWorks()
        setTimeLogLoading(true)
        setGlobalLoading(true)
        let hours = timestampToHours(serverDate).toString().padStart(2, "0")
        let minutes = timestampToMinutes(serverDate).toString().padStart(2, "0")
        if (value) {
            hours = value.hours
            minutes = value.minutes
        }
        const enter = TimeToTimestamp(`${hours}:${minutes}`, dailyLog.date)
        // const cloneInOutLog = [...inOutLogs]
        // const after = cloneInOutLog.find(i => i.enter > enter)

        
        const outWorks = works?.filter(w => !w.inOutLogId) ?? []
        const outWorksDetails = outWorks.map(ow => ({start: ow.start, end: ow.end}))
        const inOutListDetails = [...inOutLogs].map(io => ({start: io.enter, end: io.exit}))

        const after = [...outWorksDetails, ...inOutListDetails].find(i => i.start > enter)

        const newLogValue: {
            date: number;
            enter: number;
            exit?: number;
        } = {
            date: dailyLog.date,
            enter,
        }
        if (after) {
            newLogValue.exit = enter + 900
        }

        insertDailyLog(newLogValue)
            .then((res) => {
                const {data} = res 
                const cloneInOutLogs = [...data]
                const sortInOutLogs = cloneInOutLogs.sort((a, b) => a.enter - b.enter)
                generateTimeFrame(sortInOutLogs, works, date, pins)
                setInOutLogs(sortInOutLogs)
                setDailyLog({
                    ...dailyLog,
                    finalized: 0
                })
                setGlobalLoading(false)
            })
            .catch(err => {
                console.log(err)
                setGlobalLoading(false)
            })
    }
    
    const newExitHandler = (value: {hours: string, minutes: string}, name?: string) => {
        if (justSee) return;
        if (lock) return Toasts.lockWorks()
        setTimeLogLoading(true)
        setGlobalLoading(true)
        let hours = timestampToHours(serverDate).toString().padStart(2, "0")
        let minutes = timestampToMinutes(serverDate).toString().padStart(2, "0")
        if (value) {
            hours = value.hours
            minutes = value.minutes
        }
        const findInOutLog = inOutLogs.find(i => !i.exit)
        if (!findInOutLog) return
        updateInOutLog(findInOutLog.id, {
            // اگر زمان انتخاب شده صفر بود 24 در نظر گرفته میشه
            exit: TimeToTimestamp(`${hours}:${minutes}`, ((date + (!(+hours + +minutes) ? oneDay : 0)))),
        })
            .then(res => {
                const { data } = res
                const cloneInOutLogs = [...inOutLogs]
                const findWorkIndex = cloneInOutLogs.findIndex(w => w.id === data.id)
                cloneInOutLogs.splice(findWorkIndex, 1)
                cloneInOutLogs.splice(findWorkIndex, 0, data)
                const sortWorks = cloneInOutLogs.sort((a, b) => a.enter - b.enter)
                setInOutLogs(sortWorks)
                updateNotification({dailyLogId: dailyLog.id, type: "inOutLog"})
                generateTimeFrame(sortWorks, works, date, pins)
                setDailyLog({
                    ...dailyLog,
                    finalized: 0
                })
                setGlobalLoading(false)
            })
            .catch(err => {
                console.log(err)
                setGlobalLoading(false)
                // workInstance && setWorks(workInstance)
            })
    }

    const calculateTimeArrowLocation = (span: number) => {
        // if (!timeLog.enter) return;
        // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 60 * 60 // * 1000
        // const startHoursToTimeSpan = timestampToHours(timeLog.enter) * 60 * 60
        const startHoursToTimeSpan = +(timeFrame[0]?.split(":")?.[0]) * 60 * 60
        const distanceFromZeroTime = span - startHoursToTimeSpan;
        // console.log(distanceFromZeroTime)
        return distanceFromZeroTime * workBoxSize / timeSpanDuration;
    }

    const startTimeSpan = () => {
        // const today = ServerDateToDay(serverDate);
        // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
        // return today + startHoursToTimeSpan
        const startFrame = +(timeFrame[0]?.split(":")?.[0])
        // return timeLog.enter ?? 0
        return (startFrame*60*60) + dailyLog.date
    }

    // const endTimeSpan = () => {
    //     const today = ServerDateToDay(serverDate);
    //     const endHoursToTimeSpan = +timeLog.exit.split(":")?.[0] * 1000 * 60 * 60;
    //     return today + endHoursToTimeSpan
    // }

    const pixelToTimeSpan = (px: number) => {
        const startWorkSpan = startTimeSpan()
        // console.log(px)
        const span = px * timeSpanDuration / workBoxSize
        return Math.floor((span + startWorkSpan)/60) * 60
    }
    
    const calculateTop = (span: number) => {
        // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
        // const startHoursToTimeSpan = +(TimestampToTime(timeLog.enter ?? 0).split(":")?.[0]) * 60 * 60
        // const distanceFromZeroTime = span - startHoursToTimeSpan;
        const px = span * workBoxSize / timeSpanDuration;
        return px
    }

    // console.log(calculateTop((timeLog.enter ?? 0) - date))
    const findClickSpan = (clientY: number) => {
        const topDistance = worksBoxRef.current.offsetTop
        const scrollTop = document.documentElement.scrollTop
        const startFrame = +(timeFrame[0]?.split(":")?.[0])
        // const clickY = clientY - topDistance + scrollTop - calculateTop(startFrame*60*60)
        return pixelToTimeSpan(clientY - topDistance)
    }

    const findMaxId = () => {
        // let maxId = 0
        // works.forEach((work) => {
        //     if (maxId < work.id) maxId = work.id
        // })
        const minId = works.reduce((a, b) => a < b.id ? a : b.id ,0)
        return minId-1
    }

    const addTemporaryWork = (e: any) => {
        if (justSee) return;
        if (lock) return Toasts.lockWorks()
        setGlobalLoading(true)
        const clickSpan = findClickSpan(e.clientY)
        addNewWork(clickSpan)
    }
    
    const addInOutLog = (e: any) => {
        if (justSee) return;
        if (lock) return Toasts.lockWorks()
        const clickSpan = findClickSpan(e.clientY)

        const enter = clickSpan
        const outWorks = works?.filter(w => !w.inOutLogId) ?? []
        const outWorksDetails = outWorks.map(ow => ({start: ow.start, end: ow.end}))
        const inOutListDetails = [...inOutLogs].map(io => ({start: io.enter, end: io.exit}))

        const after = [...outWorksDetails, ...inOutListDetails].find(i => i.start > enter)

        const newLogValue: {
            date: number;
            enter: number;
            exit?: number;
        } = {
            date: dailyLog.date,
            enter,
        }
        if (after) {
            newLogValue.exit = enter + 900
        }
        insertDailyLog(newLogValue)
            .then((res) => {
                // console.log(res)
                const {data} = res 
                const cloneInOutLogs = [...data]
                const sortInOutLogs = cloneInOutLogs.sort((a, b) => a.enter - b.enter)
                // const startTime = timestampToHours([...sortInOutLogs].shift()?.enter ?? 0)
                // const endTime = timestampToHours(([...sortInOutLogs].pop()?.exit ?? (date + (24*60*60))), date)
                // generateTimeFrame({
                //     from: startTime,
                //     to: endTime < 24 ? endTime + 1 : endTime
                // })
                generateTimeFrame(sortInOutLogs, works, date, pins)
                setInOutLogs(sortInOutLogs)
                setDailyLog({
                    ...dailyLog,
                    finalized: 0
                })
            })
            .catch(err => {
                console.log(err)
                // workInstance && setWorks(workInstance)
            })
    }

    const windowResize = () => {
        setWorkBoxSize(worksBoxRef.current.clientHeight)
    }

    useEffect(() => {
        setWorkBoxSize(worksBoxRef.current.clientHeight)
        window.addEventListener("resize", windowResize)
        return () => {
            window.removeEventListener("resize", windowResize)
        }
    }, [worksBoxRef.current?.clientHeight, hasInCompony])

    const increaseSensitiveRef = useRef<any>(null)
    const increaseExitTimeInterval = useRef<NodeJS.Timeout>()

    const increaseExitTime = () => {
        if (justSee) return;
        clearInterval(increaseExitTimeInterval.current)
        increaseExitTimeInterval.current = setInterval(() => {
            const exitTime = [...timeFrame].pop()?.split(":")?.[0] ?? 0
            if (+exitTime > 23) return clearInterval(increaseExitTimeInterval.current);
            const loopFrom = timestampToHours(timeLog.enter as number)
            const loopTo = +exitTime < 24 ? +exitTime+1 : +exitTime
            const time_frame: string[] = []
            for (let i=loopFrom; i<=loopTo; i++) {
                time_frame.push(`${String(i).padStart(2, "0")}:00`)
            }
            setTimeFrame(time_frame)
            const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
            setTimeSpanDuration(spanDuration)
        }, 500)

    }

    const exitInOutLog = (e: any) => {
        if (justSee) return;
        if (lock) return Toasts.lockWorks()
        const clickSpan = findClickSpan(e.clientY)
        const findInOutLog = inOutLogs.find(i => !i.exit)
        if (!findInOutLog) return
        updateInOutLog(findInOutLog.id, {
            exit: clickSpan,
        })
            .then(res => {
                const { data } = res
                const cloneInOutLogs = [...inOutLogs]
                const findWorkIndex = cloneInOutLogs.findIndex(w => w.id === data.id)
                cloneInOutLogs.splice(findWorkIndex, 1)
                cloneInOutLogs.splice(findWorkIndex, 0, data)
                const sortWorks = cloneInOutLogs.sort((a, b) => a.enter - b.enter)
                setInOutLogs(sortWorks)
                updateNotification({dailyLogId: dailyLog.id, type: "inOutLog"})
                generateTimeFrame(sortWorks, works, date, pins)
                // setTimeLog({
                //     ...timeLog,
                //     finalized: 0
                // })
            })
            .catch(err => {
                console.log(err)
                // workInstance && setWorks(workInstance)
            })

    }


    return (
        <div className="w-full md:w-[300px] flex-none flex flex-col gap-px">
                        
            {ServerDateToDay(serverDate) === date &&
                <Button
                    color={theme === "dark" ? "dark" : "light"}
                    title="ایجاد رویداد کاری"
                    // color={"primary"}
                    expand="block"
                    icon="plus-lg"
                    // onClick={() => changeDayHandler(btn.type)}
                    // loading={loading}
                    description="ایجاد رویداد کاری برای زمان فعلی"
                    // outLineSize="1px"
                    // outlineColor={theme === "dark" ? "black" : "lightgray"}
                    // direction={btn.type === "plus" ? "row_reverse" : "row"}
                    disabled={justSee}
                    onClick={addWorkByButton}
                    classNames={{
                        container: "!h-8 !flex-none !p-0 !px-0 !m-0 !mb-1 !rounded-md duration-200 sm:!hidden !flex !items-center !justify-center",
                        section: "!text-sm !flex !items-center !justify-center !overflow-hidden",
                        icon: "ps-1"
                    }}
                />
            }
            <div className="w-full flex-none">
                <ClockPicker 
                    name="start" 
                    isOpen={enterClockPickerIsOpen}
                    setIsOpen={setEnterclockPickerIsOpen}
                    insideLabel="ثبت ورود به شرکت"
                    isDisable={!!inOutLogs.find(i => !i.exit) || justSee}
                    value={timeLog.enter ? TimestampToTime(timeLog.enter) : undefined}
                    // button={{
                    //     title: `ورود ${timeLog.enter ? TimestampToTime(timeLog.enter) : ""}`,
                    //     color: theme as "dark",
                    //     fill: "basic",
                    //     classNames: {
                    //         container: "!w-full !h-10 !rounded-t-md !rounded-b-none transition-all duration-200",
                    //         section: "!text-[0.72rem] sm:!text-sm"
                    //     }
                    // }}
                    buttonMode="simple"
                    classNames={{
                        button: "rounded-t-md rounded-b-none"
                    }}
                    picker={{
                        title: "زمان ورود",
                        onAccept: newEnterHandler,
                    }}
                />
            </div>


            <div 
                ref={worksBoxRef}
                className="flex-auto w-full bg-gray-100 dark:bg-gray-600 flex items-center justify-center relative border-x border-gray-400 dark:border-gray-900"
            >
                <div 
                    // onClick={addInOutLog}
                    className="w-8 h-full flex-none border-e border-gray-300 dark:border-gray-700 flex flex-col items-center justify-between relative z-[54]"
                >
                    {user && timeFrame.map((h, i) => 
                        // !!(i && i < duration.length-1) &&
                        <TextTag 
                            key={h}
                            className="h-0 flex items-center justify-center text-[0.6rem] select-none"
                            // style={{writingMode: "vertical-rl"}}
                        >
                            {!!(i && i < timeFrame.length-1) && h}
                        </TextTag>
                    )}
                    {!!(timeFrame.length && date === ServerDateToDay(serverDate) && !globalLoading && !justSee) &&
                        // <div className="w-full h-0 flex items-start justify-center rotate-90 absolute bg-gray-800 group">
                            <div 
                                className="w-full h-0 flex items-start justify-center rotate-90 absolute"
                                title={TimestampToTime(serverDate)}
                                style={{
                                    top: calculateTimeArrowLocation(serverDate - (ServerDateToDay(serverDate)))
                                }}
                            >
                                <Icon
                                    name="caret-down-fill"
                                    width="1.5rem"
                                    height="1.5rem"
                                    color="#e11d48"
                                    classNames=" animate-bounce"
                                />
                            </div>
                        // </div>
                    }
                </div>
                <div className="h-full flex-auto flex flex-col items-center justify-between">
                    {timeFrame.map((h, i) => 
                        // !!(i && i < duration.length-1) &&
                        <div 
                            key={h}
                            className={`w-full h-0 ${!!(i && i < timeFrame.length-1) ? "border-b" : ""} border-gray-300 dark:border-gray-700 flex items-center justify-center`}
                        />
                    )}
                </div>
                
                <div 
                    className="absolute w-[calc(100%-64px)] h-full bg-opacity-20 left-8 z-[55]"
                >
                    {inOutLogs.map((log, index) =>
                        <InOutLogs 
                            key={index}
                            inOutLogs={inOutLogs}
                            setInOutLogs={setInOutLogs}
                            log={log}
                            workBoxSize={workBoxSize}
                            justSee={justSee}
                            // generateTimeFrame={generateTimeFrame}
                        />
                    )}

                </div>

                <div 
                    className="absolute w-[calc(100%-64px)] h-full bg-opacity-50 left-8 z-[55]"
                    onClick={addTemporaryWork}
                >

                {/* <div 
                    ref={increaseSensitiveRef}
                    className='absolute bottom-0 w-full h-5 bg-red-400'
                /> */}
                    {!!(timeFrame.length) && works.map((ele, index) =>
                        <Work 
                            key={String(ele.start+ele.end)} 
                            ele={ele}
                            timeLog={timeLog}
                            workBoxSize={workBoxSize}
                            timeSpanDuration={timeSpanDuration}
                            works={works}
                            setWorks={setWorks}
                            selectedWork={selectedWork}
                            setSelectedWork={setSelectedWork}
                            timeFrame={timeFrame}
                            increaseSensitive={increaseSensitiveRef}
                            increaseExitTimeInterval={increaseExitTimeInterval}
                            increaseExitTime={increaseExitTime}
                            inOutLogs={inOutLogs}
                            justSee={justSee}
                        />
                    )}
                </div>
                <div 
                    // onClick={exitInOutLog}
                    className="w-8 h-full flex-none border-s border-gray-300 dark:border-gray-700 flex flex-col items-center justify-between relative"
                >
                    {pins.map((pin: any) => 
                        // !!(i && i < duration.length-1) &&
                        <div 
                            key={pin.time}
                            title={TimestampToTime(pin.time)}
                            onClick={(e) => e.stopPropagation()}
                            className="w-full h-0 flex items-center justify-center absolute bg-gray-800 group hover:scale-125 transition-transform duration-200"
                            style={{
                                top: calculateTimeArrowLocation(pin.time - date)
                            }}
                        >
                            <TextTag className='absolute -right-10 bg-gray-600 dark:bg-gray-500 text-gray-50 p-1 rounded-md hidden group-hover:block'>{TimestampToTime(pin.time)}</TextTag>
                            <IconColored
                                name="pin2"
                                width="2rem"
                                height="2rem"
                                // color="#e11d48"
                                classNames="group-hover:scale-150 transition-all -translate-y-1"
                            />
                        </div>
                    )}
                    {user && timeFrame.map((h, i) => 
                        // !!(i && i < duration.length-1) &&
                        <TextTag 
                            key={h}
                            className="h-0 flex items-center justify-center text-[0.6rem] select-none"
                            // style={{writingMode: "vertical-rl"}}
                        >
                            {!!(i && i < timeFrame.length-1) && h}
                        </TextTag>
                    )}
                </div>


            </div>


            <div className="w-full flex-none">
                <ClockPicker
                    name="end" 
                    isOpen={exitClockPickerIsOpen}
                    setIsOpen={setExitclockPickerIsOpen}
                    isDisable={!inOutLogs.find(i => !i.exit) || justSee}
                    insideLabel="ثبت خروج از شرکت"
                    value={timeLog.exit ? TimestampToTime(timeLog.exit) : undefined}
                    // button={{
                    //     title: `خروج ${timeLog.exit ? TimestampToTime(timeLog.exit) : ""}`,
                    //     color: theme as "dark",
                    //     fill: "basic",
                    //     disabled: !timeLog.enter,
                    //     classNames: {
                    //         container: "!w-full !h-10 !rounded-b-md !rounded-t-none transition-all duration-200",
                    //         section: "!text-[0.72rem] sm:!text-sm"
                    //     }
                    // }}
                    buttonMode="simple"
                    classNames={{
                        button: "rounded-b-md rounded-t-none"
                    }}
                    picker={{
                        title: "زمان خروج",
                        onAccept: newExitHandler,
                    }}
                />
            </div>
        </div>
    )
}

export default WorksBar