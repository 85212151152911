import { useRecoilState } from "recoil"
import Button from "../Common/Button/Button"
import TextTag from "../Common/TextTag/TextTag"
import { FormType, ProjectValuesType } from "./Types"
import { ModalOpenAtom, ModalParamsAtom } from "../../Recoil/Atoms"
import { FiltersInput } from "../../pages/Users/Users.page"
import Input from "../Common/Input/Input"
import { useEffect, useState } from "react"
import ReactSelect from "../Common/ReactSelect/ReactSelect"
import { useTheme } from "next-themes"
import Switch from "../Common/Switch/Switch"
import Textarea from "../Common/Textarea/Textarea"
import validation from "./Validation"
import { createProject, editProject, getProjectById } from "../../axios/Projects.api"
import { ProjectsAtom } from "../../Recoil/projects.atom"

type ProjectFormProps = {
    title: FormType
}

const ProjectForm = ({
    title
}: ProjectFormProps) => {

    const faTitle = {
        create: "ایجاد پروژه",
        edit: "ویرایش پروژه"
    }
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    const [modalParams, setModalParams] = useRecoilState(ModalParamsAtom);
    const [projects, setProjects] = useRecoilState(ProjectsAtom)
    const [submitLoading, setSubmitLoading] = useState(false)
    const {theme, setTheme} = useTheme()
    const [baseProjectValue, setBaseProjectValue] = useState<ProjectValuesType>({ 
        name: "",
        active: true,
        importance: {value: "3", label: "متوسط"},
        description: "",
    })

    const [values, setValues] = useState<ProjectValuesType>({
        name: "",
        active: true,
        importance: {value: "3", label: "متوسط"},
        description: "",
    })
    
    const [act, setAct] = useState({
        name: false,
    })
    const [error, setError] = useState({
        name: "",
    })
    const inputChangeHandler = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const selectChangeHandler = (e: any, name: string) => {
        setValues({
            ...values,
            [name]: e
        })
    }

    const switchChangeHandler = (e: any, name: string) => {
        setValues({
            ...values,
            [name]: !values[name as "active"]
        })

    }

    const importanceOption = [
        {value: "1", label: "خیلی مهم"},
        {value: "2", label: "مهم"},
        {value: "3", label: "متوسط"},
        {value: "4", label: "کم اهمیت"},
        {value: "5", label: "بی اهمیت"}
    ]
    const inputFields: FiltersInput = [
        {
            type: Input, 
            props: {
                label: "نام پروژه",
                value: values.name,
                name: "name",
                error: error?.name,
                act: act.name,
                placeholder: "نام پروژه",
                onChange: inputChangeHandler,
                // disabled: !editAccess,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "اهمیت",
                name: "importance",
                isSearchable: false,
                theme: theme,
                onChange: selectChangeHandler,
                value: values.importance,
                // isDisabled: !editAccess,
                className: "h-[80px]",
                options: importanceOption
            }
        },
        {
            type: Switch,
            props: {
                label: "وضعیت فعال",
                name: "active",
                onClick: switchChangeHandler,
                value: values.active,
                // disabled: !editAccess,
                classNames: {
                    container: "h-[50px] px-2 pb-2"
                }
            }
        },
        {
            type: Textarea, 
            props: {
                label: "توضیحات",
                value: values.description,
                name: "description",
                placeholder: "توضیحات",
                onChange: inputChangeHandler,
                // disabled: !editAccess,
                classNames: {
                    container: "col-span-full",
                    inputBox: "border-gray-400 dark:border-gray-900 rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },

    ]
    
    const accessSubmit = () => {
        if (Object.values(error).join("")) {
            setAct({
                name: true,
            })
            return false
        }
        return true
    }

    const createHandler = () => {
        const isValid = accessSubmit()
        if (!isValid) return;
        setSubmitLoading(true)
        const newValue = {
            ...values,
            importance: values.importance.value,
        }
        createProject(newValue)
            .then((res) => {
                setSubmitLoading(false)
                setProjects([
                    {...res.data},
                    ...projects
                ])
                setIsModalOpen(false)
                setModalParams(undefined)
            })
            .catch((err) => {
                console.log(err)
            })

    }
    
    const editHandler = () => {
        const isValid = accessSubmit()
        if (!isValid) return;
        setSubmitLoading(true)
        const newValue: any = {
            ...values,
            importance: values.importance.value,
        }
        editProject(modalParams, newValue)
            .then(res => {
                console.log(res)
                const newData = res.data;
                const cloneProjects = [...projects];
                const findIndex = cloneProjects.findIndex(ele => ele.id === newData.id)
                cloneProjects.splice(findIndex, 1, newData)
                setSubmitLoading(false)
                setProjects(cloneProjects)
                setIsModalOpen(false)
                setModalParams(undefined)
            })
            .catch(err => {
                console.log(err)
                setSubmitLoading(false)
            })


    }

    const getProjectData = () => {
        getProjectById(modalParams)
        .then((res) => {
            const {data} = res
            const newValues = {
                ...values,
                ...data,
                importance: importanceOption.find(r => r.value === data.importance),
            }
            setValues(newValues)
            setBaseProjectValue(newValues)
        })
        .catch((err) => {
            console.log(err)
        })
    }
    
    useEffect(() => {
        if (title === "edit") getProjectData()
    }, [])

    useEffect(() => {
        setError(validation(values))
    }, [values])

    return (
        <div className="w-[calc(100vw-20px)] xl:w-[1200px] bg-white dark:bg-gray-600 rounded-md">
            <div className="border-b border-gray-300 dark:border-gray-800 flex items-center justify-between">
                <TextTag as="h4" className="w-fit p-2 text-gray-500 dark:text-gray-300">
                    {faTitle[title as "create"]}
                </TextTag>
                <Button
                    icon="x-lg"
                    color="tertiary"
                    shape="pill"
                    fill="transparent"
                    onClick={() => setIsModalOpen(false)}
                    classNames={{
                        container: "!w-fit !h-fit me-2"
                    }}
                />
            </div>
            <form className="w-full p-2">
                <div className="w-full items-center justify-center text-md grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 px-2 py-4 border-b border-dashed border-gray-300 dark:border-gray-700">
                    {inputFields.map((ele, index) => 
                        <ele.type key={index} {...ele.props}/>
                    )}
                </div>
                
                <Button
                    // icon="x-lg"
                    color="primary"
                    title={title === "edit" ? "ویرایش پروژه" : "افزودن پروژه"}
                    direction="row_reverse"
                    onClick={title === "create" ? createHandler : editHandler}
                    // loading={submitLoading}
                    classNames={{
                        container: "!w-full !h-12 mt-10 !rounded-[4px] !px-3 transition-all",
                        section: "!text-lg"
                    }}
                />

            </form>
        </div>
    )
}

export default ProjectForm