import RegisterForm from "../../../Components/AuthForm/AuthForm";

const LoginPage = () => {

    const formFields = [
        {name: "username", title: "نام کاربری", type: "text"},
        {name: "password", title: "رمز عبور", type: "password"},
    ]
    
    return <RegisterForm formFields={formFields} type="login"/>
}

export default LoginPage;