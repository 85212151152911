
import { RecoilState, atom, selector } from "recoil";
// import { recoilPersist } from 'recoil-persist'
// const { persistAtom } = recoilPersist()

type ddd = {
    UserId: number,
    color: string,
    comments: string,
    createdAt: string,
    end: number,
    icon: string,
    id: number,
    start: number,
    status: string,
    title: string,
    to_day: number,
    updatedAt: string,
    children: any,
}


export const ModalOpenAtom: RecoilState<boolean> = atom({
    key: 'ModalOpenAtom',
    default: false,
});

type Modals = 
    | undefined 
    | "AddUser"
    | "EditUser"
    | "UserFilter"
    | "AddProject"
    | "EditProject"
    | "ProjectFilter"
    | "WorkTimeAggregations"
    | "DeleteWork"
    | "DeleteInOutLog"
    | "DeleteExitInOutLog"
    | "ClosedDay"
    | "UnclosedDays"
    | "LogOut"
    | "CloseDayAccess"
export const ModalTypeAtom: RecoilState<Modals> = atom<Modals>({
    key: 'ModalTypeAtom',
    default: undefined,
});

export const ModalParamsAtom: RecoilState<any> = atom({
    key: 'ModalParamsAtom',
    default: {},
});

export const TaskState: RecoilState<ddd[]> = atom({
    key: 'TaskState',
    default: [] as any,
});

export const ServerDateState: RecoilState<number> = atom({
    key: 'ServerDateState',
    default: 0,
});
export const UserDataState: RecoilState<any> = atom({
    key: 'UserDataState',
    default: {},
});

export const EnterExitState: RecoilState<any> = atom({
    key: 'EnterExitState',
    default: {
        enter: null,
        exit: null,
    },
});


// const lastDaySelected = localStorage.getItem("pfk_time_tracker")
export const DateState: RecoilState<number> = atom({
    key: 'DateState',
    // default: lastDaySelected ? Number(JSON.parse(lastDaySelected).defaultDate) : 0,
    default: 0
});

export const WorksState: RecoilState<any> = atom({
    key: 'WorksState',
    default: {},
});

export const ShowMenuState: RecoilState<any> = atom({
    key: 'ShowMenuState',
    default: false,
});

export const MinimumWorkTimeDurationStateAtom: RecoilState<any> = atom({
    key: 'MinimumWorkTimeDurationStateAtom',
    default: 900,
});

export const MinimumInOutLogTimeDurationStateAtom: RecoilState<any> = atom({
    key: 'MinimumInOutLogTimeDurationStateAtom',
    default: 1800,
});

export const MinimumDistanceBetweenInOutLogsStateAtom: RecoilState<any> = atom({
    key: 'MinimumDistanceBetweenInOutLogsStateAtom',
    default: 1800,
});

export const enterExitSensitivityStateAtom: RecoilState<any> = atom({
    key: 'enterExitSensitivityStateAtom',
    default: 0,
});

export const AccessPeriodToCloseTheMonthStateAtom: RecoilState<any> = atom({
    key: 'AccessPeriodToCloseTheMonthStateAtom',
    default: 0,
});

export const AccessToCloseMonthBeforeEndOfMonthStateAtom: RecoilState<any> = atom({
    key: 'AccessToCloseMonthBeforeEndOfMonthStateAtom',
    default: 0,
});

// export const HasNotificationStateAtom: RecoilState<boolean> = atom({
//     key: 'HasNotificationStateAtom',
//     default: false,
// });

type missionFrames = {
    [K in keyof string]?: {
        frame: Array<string>,
        enName: string,
        faName: string
    }
}
type fff = {
    work?: missionFrames,
    onWay?: missionFrames,
    mandatoryUnemployment?: missionFrames,
    optionalUnemployment?: missionFrames,
    sleep?: missionFrames
}
export const MissionTimeFrameStateAtom: RecoilState<fff> = atom<fff>({
    key: 'MissionTimeFrameStateAtom',
    default: {},
});


export const NumberInputFocusedAtom: RecoilState<any> = atom({
    key: 'NumberInputFocusedAtom',
    default: false,
});

export const GlobalLoadingAtom: RecoilState<any> = atom({
    key: 'GlobalLoadingAtom',
    default: false,
});

export const ShowAggregationTimeStateAtom: RecoilState<"up" | "center" | "down"> = atom<"up" | "center" | "down">({
    key: 'ShowAggregationTimeStateAtom',
    default: "center",
});



// export const imageDescription: RecoilState<{show: any, data: []}> = atom({
//     key: "imageDescription",
//     default: {
//         show: false,
//         data: []
//     },
// });


// // export const directoryState = atom({
// //     key: 'directoryState',
// //     default: {
// //         baseDirectory: "",
// //         count: "1"
// //     },
// //     effects_UNSTABLE: [persistAtom],
// // })

// // export const userNameState = atom({
// //     key: 'userNameState',
// //     default: "",
// //     effects_UNSTABLE: [persistAtom],
// // })

// export const DataInform: RecoilState<any> = atom({
//     key: 'DataInform',
//     default: {
//         imageTop: "",
//         xmlTop: "",
//         docTop: "",
//         imageDown: "",
//         xmlDown: "",
//         docDown: "",
//     },
// });

// export const ShowStateXml: RecoilState<any> = atom({
//     key: 'ShowStateXml',
//     default: {},
// });

// export const AllRecordState: RecoilState<[]> = atom({
//     key: 'AllRecordState',
//     default: [],
// });

// export const LastIdSeenState = atom({
//     key: 'LastIdSeenState',
//     default: 1,
// })

// export const BaseDirectory: RecoilState<string> = atom({
//     key: 'BaseDirectory',
//     default: "",
// });


// export const DirectorySelectedState: RecoilState<string> = atom({
//     key: 'DirectorySelectedState',
//     default: "",
// });

// export const FilterState: RecoilState<{
//     top?: boolean;
//     down?: boolean;
//     status: string;
//   }> = atom({
//     key: 'FilterState',
//     default: {
//         status: "",
//       }
// });


// export const ImageTopFocusState: RecoilState<number> = atom({
//     key: 'ImageTopFocusState',
//     default: 0,
// });

// export const ImageDownFocusState: RecoilState<number> = atom({
//     key: 'ImageDownFocusState',
//     default: 0,
// });


// export const AllRecordState = atom({
//     key: 'AllRecordState',
//     default: [],
// });

// export const ChartDataState = atom({
//     key: 'ChartDataState',
//     default: {},
// });

// export const ChartLengthState = atom({
//     key: 'ChartLengthState',
//     default: 0,
// });

// export const DirectoryValueState = atom({
//     key: 'DirectoryValueState',
//     default: "",
// });

// export const PointSelectedData = atom({
//     key: 'PointSelectedData',
//     default: {
//         e0: undefined, 
//         e1: undefined, 
//         e4: undefined, 
//         e2: undefined, 
//         e3: undefined, 
//         e5: undefined, 
//         e6: undefined, 
//         e7: undefined, 
//         e8: undefined,
//         numsLength: 0
//     },
// });

// export const FilterState = atom({
//     key: 'FilterState',
//     default: {
//         status: "",
//       }
// });

// export const ProgressState = atom({
//     key: 'ProgressState',
//     default: 100
// });

// export const ProgressStateAcc = atom({
//     key: 'ProgressStateAcc',
//     default: 100
// });

// export const ConfigValueState = atom({
//     key: 'ConfigValueState',
//     default: {
//         healthyDir: "",
//         nonHealthyDir: "",
//         // programType: null,
//         // productType: null,
//         influenceTop: "0",
//         influenceDown: "0",
//         // removeBlueBack: false,
//     },
// });

// export const IntensityFilterState = atom({
//     key: 'IntensityFilterState',
//     default: [],
// });

// export const AreaFilterState = atom({
//     key: 'َAreaFilterState',
//     default: [],
// });

// export const IntensityChartValueSelected = atom({
//     key: 'IntensityChartValueSelected',
//     default: [],
// });

// export const AreaChartValueSelected = atom({
//     key: 'AreaChartValueSelected',
//     default: [],
// });

// export const IdealPointMatris = atom({
//     key: 'IdealPointMatris',
//     default: {},
// });

// export const FilesPathState = atom({
//     key: 'FilesPathState',
//     default: {},
// });




