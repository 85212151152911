import { UserValuesType } from "../Components/UserForm/Types";
import { get, put, post, deleted, patch } from "./Axios.config"
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// export const insertDailyLog = async (data: any) => {
//     const cookie = cookies.get("ACCESS_TOKEN")
//     console.log(data)
//     return await post({
//         api: `daily_log`,
//         data,
//         config: {
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${cookie}`
//             } 
//         }
//     })
// }

// export const updateTimeLog = async (date: number, data: any) => {
//     const cookie = cookies.get("ACCESS_TOKEN")
//     return await patch({
//         api: `time_log/${date}`,
//         data,
//         config: {
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${cookie}`
//             } 
//         }
//     })
// }

// export const finalizedUpdate = async (data: any) => {
//     const cookie = cookies.get("ACCESS_TOKEN")
//     const { date, shot } = data
//     console.log(shot.get("image"))
//     return await put({
//         api: `time_log/${date}`,
//         data: shot,
//         config: {
//             headers: {
//                 "Content-Type": "multipart/form-data",
//                 "Authorization": `Bearer ${cookie}`
//             } 
//         }
//     })
// }


export const getUnclosedDays = async () => {
    const cookie = cookies.get("ACCESS_TOKEN")
    // const params: any = { With: ["InOut", "Works", "InOut_Works", "Pins"] }
    // if (userId)  params.userId = userId
    return await get({
        api: `daily_log/unclosed`,
        config: {
            // params,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const getDailyLog = async (date: number, userId?: number) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    const params: any = { With: ["InOut", "Works", "InOut_Works", "Pins"] }
    if (userId)  params.userId = userId
    return await get({
        api: `daily_log/${date}`,
        config: {
            params,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}


export const finalizedUpdate = async (data: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    const { date, shot } = data
    // console.log(shot.get("image"))
    return await put({
        api: `daily_log/${date}`,
        data: shot,
        config: {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const updateTimeLog = async (date: number, data: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await patch({
        api: `daily_log/${date}`,
        data,
        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}