
const validation = ({
    oldPassword,
    newPassword,
    confirmPassword,
}: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}) => {
    const error: {
        oldPassword: string;
        newPassword: string;
        confirmPassword: string;
    } = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    }

    if (!oldPassword.trim()) {
        error.oldPassword = " رمز فعلی را وارد کنید"
    } else if (oldPassword.length < 5 || oldPassword.length > 16) {
        error.oldPassword = "رمز عبور باید بین 5 تا 16 کاراکتر باشد"
    }

    if (!newPassword.trim()) {
        error.newPassword = " رمز جدید را وارد کنید"
    } else if (newPassword.length < 5 || newPassword.length > 16) {
        error.newPassword = "رمز عبور باید بین 5 تا 16 کاراکتر باشد"
    }

    if (newPassword !== confirmPassword) {
        error.confirmPassword = "تایید رمز ورود جدید اشتباه است"
    }

    return error
}

export default validation