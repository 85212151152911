import { useRecoilState, useRecoilValue } from "recoil"
import { DateState, ServerDateState } from "../../../Recoil/Atoms"
import { WorksInDayAtom } from "../../../Recoil/Works.atom"
import WorkForm from "../../WorkForms/WorkForm"

const DescriptionsMobile = ({
    selectedWork,
    setSelectedWork,
    overflow,
    submitWorkForm,
    deleteHandler,
    forDate,
    justSee
}: {
    selectedWork: number,
    setSelectedWork: React.Dispatch<React.SetStateAction<number>>
    overflow: object,
    submitWorkForm: (item: any) => void
    deleteHandler: (id: number) => void,
    forDate?: number
    justSee: boolean
}) => {

    const serverDate = useRecoilValue(ServerDateState)
    const [date, setDate] = useRecoilState(DateState)
    const [works, setWorks] = useRecoilState(WorksInDayAtom)
    // const [work, setWork] = useState<WorkDto>({})
    const activeWork: any = works.find(w => w.id === selectedWork) ?? {}

    // useEffect(() => {
    //     const findWork = works.find(ele => ele.id === selectedWork)
    //     if (findWork) setWork(findWork)
    // }, [selectedWork])

    return (
        <div className={`w-full h-screen fixed right-0 ${selectedWork ? "top-0" : "top-full"} transition-[top] duration-500 z-[999] flex flex-col bg-gray-100 dark:bg-gray-700 md:hidden`}>
            {/* <div 
                className={`flex-none h-16 border-b ${!work.active ? `border-rose-700` : `border-${work.theme}-700`} flex items-center justify-around ${work.active ? `bg-${work.theme}-500` : `bg-rose-500 `}`} 
                onClick={() => setSelectedWork(0)}
            >
                <TextTag as='h5' className="pe-2">{faWorkType[work.type]}</TextTag>
                {(work.type === "mission" && work?.missionType) &&
                    <TextTag as='h5' className="pe-2">{faMissionType[work?.missionType]}</TextTag>
                }
                <TextTag as='h5' className="pe-2">{DurationToTime(work.end - work.start, serverDate)}</TextTag>
            </div>
            <div className='overflow-auto h-10 flex-auto bg-gray-100 dark:bg-gray-700'> */}
            {selectedWork && 
                <WorkForm
                    work={activeWork}
                    active={selectedWork === activeWork.id}
                    sizeChanged={(id) => {
                        // const height = selectedWork === work.id ? (accordionRef.current?.childNodes?.[1].clientHeight+40 ?? 200) : 40
                        // console.log(height)
                        // windowResizeHandler()
                    }}
                    onClose={(work) => {
                        // if (work.type === "temporary") {
                        //     const newWorks = [...works].filter((workItem) => workItem.type !== "temporary")
                        //     setWorks(newWorks)
                        // }
                        setSelectedWork(0)
                    }}
                    onAccept={submitWorkForm}
                    onDelete={deleteHandler}
                    justSee={justSee}
                />
        
            }
            {/* </div> */}
        </div>
    )
}

export default DescriptionsMobile