import { useEffect, useState } from "react"
import { FiltersInput } from "../../pages/Users/Users.page"
import Input from "../Common/Input/Input"
import ReactSelect from "../Common/ReactSelect/ReactSelect"
import { useTheme } from "next-themes"
import TextTag from "../Common/TextTag/TextTag"
import Button from "../Common/Button/Button"
import { ModalOpenAtom } from "../../Recoil/Atoms"
import { useRecoilState } from "recoil"
import { GetEmployees } from "../../axios/Users.api"
import { EmployeesAtom, SearchEmployeesParamsAtom } from "../../Recoil/users.atom"
import { typeSearchEmployeesParamsType } from "../../pages/Users/Types"
import Textarea from "../Common/Textarea/Textarea"
import { ProjectsAtom, SearchProjectsParamsAtom } from "../../Recoil/projects.atom"
import { typeSearchProjectsParamsType } from "./types"
import { GetProjects } from "../../axios/Projects.api"

const ProjectFilter = () => {

    const {theme, setTheme} = useTheme()
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    const [loading, setLoading] = useState(false)
    const [searchProjectsParams, setSearchProjectsParams] = useRecoilState(SearchProjectsParamsAtom)
    const defaultSelectValue = {value: "null", label: "بی تاثیر"}
    const [projects, setProjects] = useRecoilState(ProjectsAtom)
    const [values, setValues] = useState({
        name: "",
        importance: defaultSelectValue,
        active: defaultSelectValue,
        description: "",
    })
    

    const inputChangeHandler = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const selectChangeHandler = (e: any, name: string) => {
        setValues({
            ...values,
            [name]: e
        })
    }

    const importanceOption = [
        defaultSelectValue,
        {value: "1", label: "خیلی مهم"},
        {value: "2", label: "مهم"},
        {value: "3", label: "متوسط"},
        {value: "4", label: "کم اهمیت"},
        {value: "5", label: "بی اهمیت"}
    ]
    const activeOption = [
        defaultSelectValue,
        {value: "1", label: "فعال"},
        {value: "0", label: "غیر فعال"},
    ]
    const publicFilter: FiltersInput = [
        {
            type: Input, 
            props: {
                label: "نام پروژه",
                name: "name",
                placeholder: "نام پروژه",
                value: values.name,
                onChange: inputChangeHandler,
                classNames: {
                    container: "h-[unset]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "اهمیت",
                name: "importance",
                theme: theme,
                onChange: selectChangeHandler,
                isSearchable: false,
                value: values.importance,
                options: importanceOption
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "وضعیت فعال",
                name: "active",
                theme: theme,
                onChange: selectChangeHandler,
                isSearchable: false,
                value: values.active,
                options: activeOption,
            }
        },
        {
            type: Textarea, 
            props: {
                label: "توضیحات",
                value: values.description,
                name: "description",
                placeholder: "توضیحات",
                onChange: inputChangeHandler,
                // disabled: !editAccess,
                classNames: {
                    container: "col-span-full",
                    inputBox: "border-gray-400 dark:border-gray-900 rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
    ]
    
    const getProjectsHandler = (newParams: typeSearchProjectsParamsType) => {
        setLoading(true)
        GetProjects(newParams)
        .then((res) => {
            console.log(res)
            setLoading(false)
            setProjects(res.data.rows)
        })
        .catch((err) => {
            setLoading(false)
            // console.log(err)
        })
    }

    const searchHandler = () => {
        const newParams: any = {}
        Object.entries(values).forEach(([key, value]) => {
            if (typeof value === "object" && value.value !== "null") {
                newParams[key] = value.value
            } else if (typeof value === "string" && value.trim()) {
                newParams[key] = value
            }
        })
        setSearchProjectsParams(newParams)
        getProjectsHandler(newParams)
        setIsModalOpen(false)
    }

    useEffect(() => {
        const name = ("name" in searchProjectsParams && searchProjectsParams.name) ? searchProjectsParams.name : ""
        const active = activeOption.find(key => key.value === searchProjectsParams.active) ?? defaultSelectValue;
        const importance = importanceOption.find(key => key.value === searchProjectsParams.importance) ?? defaultSelectValue;
        const description = ("description" in searchProjectsParams && searchProjectsParams.description) ? searchProjectsParams.description : ""
        setValues({
            ...values,
            name,
            active,
            importance,
            description,
        })
    }, [])

    return (
        <div className="w-[calc(100vw-20px)] xl:w-[1200px] bg-white dark:bg-gray-600 rounded-md">
            <div className="border-b border-gray-300 dark:border-gray-800 flex items-center justify-between">
                <TextTag as="h4" className="w-fit p-2 text-gray-500 dark:text-gray-300">
                    فیلتر پروژه ها
                </TextTag>
                <Button
                    icon="x-lg"
                    color="tertiary"
                    shape="pill"
                    fill="transparent"
                    onClick={() => setIsModalOpen(false)}
                    classNames={{
                        container: "!w-fit !h-fit me-2"
                    }}
                />
            </div>
            
            <form className="w-full p-2">
                <div className="w-full items-center justify-center text-md grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-5 gap-y-10 px-2 py-5 border-b border-dashed border-gray-300 dark:border-gray-700">
                    {publicFilter.map((ele, index) => 
                        <ele.type key={index} {...ele.props}/>
                    )}
                </div>
                
                <Button
                    color="primary"
                    title="جستوجو"
                    direction="row_reverse"
                    onClick={searchHandler}
                    loading={loading}
                    classNames={{
                        container: "!w-full !h-12 mt-10 !rounded-[4px] !px-3 transition-all",
                        section: "!text-lg"
                    }}
                />
            </form>
        </div>
    )
}

export default ProjectFilter