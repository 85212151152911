
import { RecoilState, atom, selector } from "recoil";

type MonthLuLogType = {
    id?: number,
    userId?: number,
    month?: string,
    closedDate?: number,
    createdAt?: string,
    updatedAt?: string,
}
export const MonthlyLogAtom: RecoilState<MonthLuLogType | null> = atom<MonthLuLogType | null>({
    key: 'MonthlyLogAtom',
    default: null,
});

export const BeforeMonthIsClosedStateAtom: RecoilState<boolean> = atom<boolean>({
    key: 'BeforeMonthIsClosedStateAtom',
    default: true,
});