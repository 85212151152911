import { useEffect, useState } from "react";
import TextTag from "../Common/TextTag/TextTag";
import { durationsToTime, DurationToTime } from "../../Utils/convertTime";
import { useRecoilValue } from "recoil";
import { MissionTimeFrameStateAtom, ServerDateState } from "../../Recoil/Atoms";
import Icon from "../Common/Icon/Icon";
import { twMerge } from "tailwind-merge";

const faMission = {
  count: "تعداد روز های ماموریت",
  mandatoryUnemployment: "بیکاری اجباری",
  onWay: "طی مسیر",
  optionalUnemployment: "بیکاری اختیاری",
  sleep: "خواب",
  work: "کار",
};
const faInCompony = {
  presenceInCompony: "مجموع حضور",
  workInCompony: "مجموع رویداد کاری",
  idleTime: "مجموع دراختیار",
};

const faRemote = {
  sumOfRemote: "ساعات دورکاری",
  remote: "ساعات دورکاری خالص",
  remoteIdle: "دراختیار",
};

const DashboardAggregation = ({ aggregation }: any) => {
  const serverDate = useRecoilValue(ServerDateState);
  const [inComponyData, setInCompony] = useState<any>(null);
  const [remote, setRemote] = useState<any>(null);
  const [mission, setMission] = useState<any>(null);
  const missionTimeFrame = useRecoilValue(MissionTimeFrameStateAtom);

  useEffect(() => {
    if (!aggregation) return;
    const {
      mission_count,
      mission_mandatoryUnemployment_6_17,
      mission_mandatoryUnemployment_17_22,
      mission_onWay_6_22,
      mission_onWay_22_6,
      mission_optionalUnemployment_0_24,
      mission_sleep_0_24,
      mission_work_6_22,
      mission_work_22_6,
      presenceInCompony,
      remote,
      remoteIdle,
      workInCompony,
      workInComponyWithRemote,
    } = aggregation;

    setInCompony({
      presenceInCompony,
      workInCompony,
      idleTime: presenceInCompony - workInCompony,
    });
    setRemote({
      remote,
      remoteIdle,
      sumOfRemote: remote + remoteIdle,
    });

    const missionData: any = {
      "تعداد روز های ماموریت": mission_count,
      "بیکاری اجباری": 0,
    };
    Object.entries(missionTimeFrame).forEach(([missionType, Periods]) => {
      Object.entries(Periods).forEach(([index, detail]) => {
        if (missionType === "mandatoryUnemployment") {
          missionData["بیکاری اجباری"] +=
            aggregation[`mission_${missionType}_${detail?.enName}`];
          return;
        }
        missionData[`${faMission[missionType as "count"]} ${detail?.faName}`] =
          aggregation[`mission_${missionType}_${detail?.enName}`];
      });
    });
    setMission(missionData);
  }, [aggregation]);

  if (!aggregation)
    return (
      <span className="w-full h-32 lg:rounded-lg grid place-content-center rounded-lg bg-gray-50 dark:bg-gray-800 shadow-xl animate-pulse" />
    );
  // console.log((inComponyData instanceof Object && Object.entries(inComponyData).length) && inComponyData)

  return (
    <div className="w-full flex flex-col items-center justify-start gap-10 pb-48">
      {mission instanceof Object &&
        !!Number(Object.values(mission).join("")) && (
          <div className="w-full flex flex-col items-stretch justify-start bg-gray-50 dark:bg-gray-800 rounded-lg ring-1 ring-sky-500 ring-opacity-50">
            <TextTag
              as="h5"
              className="flex-none h-16 sm:h-10 text-center p-2 grid place-content-center border-b border-gray-200 dark:border-gray-700"
            >
              جمع بندی ساعات ماموریت
            </TextTag>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-2 p-2">
              {Object.entries(mission)?.map(
                ([keys, value]: any) =>
                  !!value && (
                    <div key={keys} className="h-32 flex flex-col items-center justify-center gap-5 md:col-span-1 bg-sky-500 bg-opacity-10 rounded-[6px] p-2 relative">
                      <TextTag
                        as="h5"
                        className="text-center text-[14px] xl:text-[13px]"
                      >
                        {keys}
                      </TextTag>
                      <TextTag as="h1" className="text-[22px] xl:text-2xl">
                        {keys === "تعداد روز های ماموریت"
                          ? value
                          : durationsToTime(value)}
                      </TextTag>
                    </div>
                  )
              )}
              {/* <TextTag
              as="h5"
              className="!text-gray-800 text-center text-[14px] xl:text-[13px]"
            >
              مجموع ساعت کاری خالص برای حقوق
            </TextTag>
            <TextTag as="h1" className="!text-gray-800 text-[22px] xl:text-2xl">
              {durationsToTime(inComponyData?.workInCompony)}
            </TextTag> */}
            </div>
          </div>
        )}

      <div className="w-full grid grid-cols-2 xl:grid-cols-5 2xl:grid-cols-10 gap-3">
        <div className="h-32 xl:h-full flex xl:flex-col items-center justify-around xl:justify-center col-span-full xl:col-span-1 2xl:col-span-2 p-2 bg-gray-50 dark:bg-gray-800 rounded-lg xl:gap-10">
          <TextTag as="h5" className="text-center text-[17px] xl:text-lg">
            جمع ساعت کار (شرکت و دورکاری)
          </TextTag>
          <TextTag as="h1" className="text-4xl xl:text-4xl">
            {durationsToTime(aggregation?.workInComponyWithRemote)}
          </TextTag>
        </div>
        <div className="col-span-1 xl:col-span-2 2xl:col-span-4 flex flex-col items-stretch justify-start bg-gray-50 dark:bg-gray-800 rounded-lg ring-1 ring-emerald-500 ring-opacity-50">
          <TextTag
            as="h5"
            className="flex-none h-16 sm:h-10 text-center p-2 grid place-content-center border-b border-gray-200 dark:border-gray-700"
          >
            جمع بندی ساعات حضور درشرکت
          </TextTag>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 p-2" dir="ltr">
            <div className="h-32 flex flex-col items-center justify-center gap-5 col-span-1 bg-emerald-500 bg-opacity-10 rounded-[6px] p-2 relative">
              <PluseIcon />
              <TextTag
                as="h5"
                className="text-emerald-600 dark:text-emerald-300 text-center text-[14px] xl:text-[13px]"
              >
                مدت زمان حضور درشرکت
              </TextTag>
              <TextTag
                as="h1"
                className="text-emerald-600 dark:text-emerald-300 text-[22px] xl:text-2xl"
              >
                {durationsToTime(inComponyData?.presenceInCompony)}
              </TextTag>
            </div>
            <div className="h-32 flex flex-col items-center justify-center gap-5 md:col-span-1 bg-rose-500 bg-opacity-10 rounded-[6px] p-2 relative">
              <MinusIcon />
              <TextTag
                as="h5"
                className="!text-rose-600 text-center text-[14px] xl:text-[13px]"
              >
                جمع دراختیار در شرکت
              </TextTag>
              <TextTag
                as="h1"
                className="!text-rose-600 text-[22px] xl:text-2xl"
              >
                {durationsToTime(inComponyData?.idleTime)}
              </TextTag>
            </div>
            <div className="h-32 flex flex-col items-center justify-center gap-5 md:col-span-full xl:col-span-1 bg-emerald-500 bg-opacity-100 rounded-[6px] p-2 relative">
              <Equal />
              <TextTag
                as="h5"
                className="!text-gray-800 text-center text-[14px] xl:text-[13px]"
              >
                مجموع ساعت کاری خالص برای حقوق
              </TextTag>
              <TextTag
                as="h1"
                className="!text-gray-800 text-[22px] xl:text-2xl"
              >
                {durationsToTime(inComponyData?.workInCompony)}
              </TextTag>
            </div>
          </div>
        </div>
        <div className="col-span-1 xl:col-span-2 2xl:col-span-4 flex flex-col items-stretch justify-start bg-gray-50 dark:bg-gray-800 rounded-lg ring-1 ring-purple-500 ring-opacity-50">
          <TextTag
            as="h5"
            className="flex-none h-16 sm:h-10 text-center p-2 grid place-content-center border-b border-gray-200 dark:border-gray-700"
          >
            جمع بندی ساعات دورکاری
          </TextTag>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 p-2" dir="ltr">
            <div className="h-32 flex flex-col items-center justify-center gap-5 col-span-1 bg-emerald-500 bg-opacity-10 rounded-[6px] p-2 relative">
              <PluseIcon />
              <TextTag
                as="h5"
                className="text-emerald-600 dark:text-emerald-300 text-center text-[14px] xl:text-[13px]"
              >
                مدت زمان دورکاری
              </TextTag>
              <TextTag
                as="h1"
                className="text-emerald-600 dark:text-emerald-300 text-[22px] xl:text-2xl"
              >
                {durationsToTime(remote?.sumOfRemote)}
              </TextTag>
            </div>
            <div className="h-32 flex flex-col items-center justify-center gap-5 md:col-span-1 bg-rose-500 bg-opacity-10 rounded-[6px] p-2 relative">
              <MinusIcon />
              <TextTag
                as="h5"
                className="!text-rose-600 text-center text-[14px] xl:text-[13px]"
              >
                جمع دراختیار در دورکاری
              </TextTag>
              <TextTag
                as="h1"
                className="!text-rose-600 text-[22px] xl:text-2xl"
              >
                {durationsToTime(remote?.remoteIdle)}
              </TextTag>
            </div>
            <div className="h-32 flex flex-col items-center justify-center gap-5 md:col-span-full xl:col-span-1 bg-purple-500 bg-opacity-100 rounded-[6px] p-2 relative">
              <Equal />
              <TextTag
                as="h5"
                className="!text-gray-800 text-center text-[14px] xl:text-[13px]"
              >
                مجموع دورکاری خالص برای حقوق
              </TextTag>
              <TextTag
                as="h1"
                className="!text-gray-800 text-[22px] xl:text-2xl"
              >
                {durationsToTime(remote?.remote)}
              </TextTag>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PluseIcon = ({ className }: { className?: string }) => (
  <TextTag
    className={twMerge(
      "absolute left-1 bottom-1 text-emerald-600 dark:text-emerald-300 px-2 text-xl opacity-60",
      className
    )}
  >
    +
  </TextTag>
);
const MinusIcon = ({ className }: { className?: string }) => (
  <TextTag
    className={twMerge(
      "absolute left-1 bottom-1 !text-rose-600 px-2 py-1 text-3xl opacity-60",
      className
    )}
  >
    _
  </TextTag>
);
const Equal = ({ className }: { className?: string }) => (
  <TextTag
    className={twMerge(
      "absolute left-1 bottom-0 !text-gray-800 px-2 text-2xl opacity-60",
      className
    )}
  >
    =
  </TextTag>
);

export default DashboardAggregation;
