import React, { ChangeEvent, ChangeEventHandler, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import Button from "../Button/Button"
import TextTag from "../TextTag/TextTag"
import { twMerge } from "tailwind-merge"
import { ButtonType } from "../Button/Types"
import { useTheme } from "next-themes"
import Sortable from 'sortablejs';
import { LockWorkPageAtom } from "../../../Recoil/Works.atom"
import { useRecoilState } from "recoil"
import { Toasts } from "../../../toasts/toasts"


interface InputListProps extends Omit<React.HTMLAttributes<HTMLInputElement>, "onChange"> {
    error?: string,
    act?: boolean,
    onChange?: (note: Array<string>) => void,
    classNames?: {
        label?: string,
        error?: string,
    };
    disabled?: boolean;
    value: string[];
    workId: number;
    label?: string;
    input?: React.InputHTMLAttributes<HTMLInputElement>;
    form?: React.FormHTMLAttributes<HTMLFormElement>;
    button?: ButtonType
}

const InputList = forwardRef<HTMLInputElement, InputListProps>((props, ref) => {
    const {
        error,
        act,
        classNames, 
        label,
        onChange,
        value = [],
        workId,
        disabled = false,
        input: {
            className: inputClassName,
            ...otherInput
        } = {className: ""},
        form: {
            className: formClassName,
            ...otherForm
        } = {className: ""},
        button,
    } = props;

    const notPrefix = "workNote_"
    const [lock, setLock] = useRecoilState(LockWorkPageAtom)
    const [notes, setNotes] = useState<string[]>([])
    const {theme} = useTheme()
    const [inputValue, setInputValue] = useState("")
    const [noteChanged, setNoteChanged] = useState<any>(null)
    const noteRef = useRef(notes)
    const inputRef = useRef<any>(null)
    noteRef.current = notes

    const addNewComment = () => {
        if (lock) return Toasts.lockWorks()
        const existNote = notes.find(note => note === inputValue)
        if (!inputValue) return
        if (existNote) return setInputValue("")
        const newNotes = [
            ...notes,
            inputValue,
        ]
        setNotes(newNotes)
        setInputValue("")
        localStorage.removeItem(`${notPrefix}${workId}`)
        if (onChange) onChange(newNotes)
    }

    const submitHandler = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        addNewComment()
    }

    const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault()
        addNewComment()
    }

    const deleteNote = (comment: string) => {
        if (lock) return Toasts.lockWorks()
        const cloneNotes = [...notes]
        const newNotes = cloneNotes.filter(note => note !== comment)
        setNotes(newNotes)
        onChange && onChange(newNotes)
    }

    const editNote = (comment: string) => {
        if (lock) return Toasts.lockWorks()
            const cloneNotes = [...notes]
        const noteIndex = cloneNotes.findIndex(note => note === comment)
        const spliceNote = cloneNotes.splice(noteIndex, 1)
        localStorage.setItem(`${notPrefix}${workId}` as string, JSON.stringify(spliceNote[0]))
        setNotes(cloneNotes)
        setInputValue(spliceNote.join(""))
        
    }

    useEffect(() => {
        setNotes(value)
    }, [value])

    useEffect(() => {
        if (noteChanged && onChange) onChange(noteChanged)
    }, [noteChanged])
    

    const labelDefaultClass = "mb-1 px-2 capitalize text-start text-sm flex-none"
    const labelOverwriteClass = classNames?.label ? " " + classNames.label : ""
    const labelTwMerge = twMerge(labelDefaultClass, labelOverwriteClass)

    const errorDefaultClass = "w-full h-5 px-2 flex-none flex items-start justify-end text-rose-500 dark:text-rose-500 text-end"
    const errorOverwriteClass = classNames?.error ? " " + classNames.error : ""
    const errorTwMerge = twMerge(errorDefaultClass, errorOverwriteClass)

    // const formBoxDefaultClass = `w-full h-[40px] relative flex items-center justify-center overflow-hidden rounded-md bg-red-400 border bg-gray-200 dark:bg-gray-800 ${(error && act) ? "border-rose-500 " : "dark:border-charcoal-900 border-solitude-200"}`
    // const formBoxOverwriteClass = formClassName ? " " + formClassName : ""
    // const formBoxTwMerge = twMerge(formBoxDefaultClass, formBoxOverwriteClass)

    // const deleteArrowInput = "disable-input-arrow"
    // const inputDefaultClass = `w-full h-full flex-auto py-2 px-4 outline-none transition-all text-md placeholder:text-solitude-300 dark:placeholder:text-charcoal-500 ${(error && act) ? "bg-rose-400 bg-opacity-10" : "bg-solitude-200 dark:bg-charcoal-900"}`
    // const inputOverwriteClass = inputClassName ? " " + inputClassName : ""
    // const inputTwMerge = twMerge(deleteArrowInput, inputDefaultClass, inputOverwriteClass)

    const listRef = useRef(null);

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
        localStorage.setItem(`${notPrefix}${workId}` as string, JSON.stringify(e.target.value))
    }
    // const blurHandler = (e: ChangeEvent<HTMLInputElement>) => {
    //     if (!e.target.value) return
    //     if (lock) return Toasts.lockWorks()
    //     const existNote = notes.find(note => note === inputValue)
    //     if (!inputValue) return
    //     if (existNote) return setInputValue("")
    //     const newNotes = [
    //         ...notes,
    //         inputValue,
    //     ]
    //     setNotes(newNotes)
    //     setInputValue("")
    //     localStorage.removeItem(`${notPrefix}${workId}`)
    //     if (onChange) onChange(newNotes)
    // }

    useEffect(() => {
        const existNote = localStorage.getItem(`${notPrefix}${workId}`)
        if (existNote) setInputValue(JSON.parse(existNote ?? ""))
        if (listRef.current) {
            Sortable.create(listRef.current, {
                handle: '.handle', // handle's class
                animation: 150,
                ghostClass: 'blue-background-class',
                onEnd: (event) => {
                    if (disabled) return
                    const cloneNotes = [...noteRef.current]
                    const { oldIndex, newIndex } = event
                    if (
                        (oldIndex !== 0 && !oldIndex) ||
                        (newIndex !== 0 && !newIndex)
                    ) return; 
                    const spliceNote = cloneNotes.splice(oldIndex, 1)?.[0]
                    cloneNotes.splice((newIndex), 0, spliceNote)
                    setNotes(cloneNotes)
                    setNoteChanged(cloneNotes)
                },
            });
        }
    }, []);

    return (
        <div className="w-full flex flex-col col-span-full">
            {label && 
                <TextTag
                    as='h5'
                    className={labelTwMerge}
                    // style={styles?.label}
                >
                    {label}
                </TextTag>
            }
            <div className="w-full flex-auto rounded-[4px] overflow-hidden flex flex-col bg-gray-300 border border-gray-400 dark:bg-gray-800 dark:border-gray-950">
                <form 
                    // className={formBoxTwMerge} 
                    className="flex h-[40px] flex-none"
                    onSubmit={submitHandler}
                    {...otherForm}
                >
                    <input
                        ref={ref}
                        id="InputListId"
                        type="text"
                        placeholder={label}
                        disabled={disabled}
                        className="flex-auto bg-transparent border-none outline-none px-2 text-yellow-700 dark:text-yellow-400"
                        {...otherInput}
                        value={inputValue}
                        onChange={changeHandler}
                        onBlur={blurHandler}
                    />
                    <Button
                        expand="block"
                        color="gray"
                        icon="plus-lg"
                        fill="transparent"
                        iconColor={theme === "dark" ? "white" : "gray"}
                        type="submit"
                        disabled={disabled}
                        classNames={{
                            container: "w-10 h-10 !rounded-none !flex m-auto px-2 duration-200 !flex-none",
                            section: "!text-lg"
                        }}
                        {...button}
                    />
                </form>
                {!!notes.length && 
                    <ul
                        ref={listRef}
                        className="flex-auto border-t border-gray-400 dark:border-gray-900"
                    >
                        {notes.map((note, index) => 
                            <li key={note} className={`flex items-center justify-start gap-2 py-2 text-sm leading-6 transition-colors px-2 ${index !== notes.length-1 ? "border-b border-dashed border-gray-400 dark:border-gray-600" : ""} `}>
                                <Button
                                    expand="block"
                                    color="gray"
                                    icon="x"
                                    iconWidth="1.4rem"
                                    iconHeight="1.4rem"
                                    disabled={disabled}
                                    fill="transparent"
                                    iconColor={theme === "dark" ? "lightgray" : "gray"}
                                    iconHover={theme === "dark" ? "white" : "black"}
                                    onClick={() => deleteNote(note)}
                                    classNames={{
                                        container: "!w-4 !h-4 !flex !items-center !justify-center !rounded-full m-auto px-2 duration-200 !flex-none",
                                        section: "!text-lg"
                                    }}
                                />
                                <Button
                                    expand="block"
                                    color="gray"
                                    icon="pencil-fill"
                                    iconWidth="0.8rem"
                                    // iconHeight="0.8rem"
                                    disabled={disabled}
                                    fill="transparent"
                                    iconColor={theme === "dark" ? "lightgray" : "gray"}
                                    iconHover={theme === "dark" ? "white" : "black"}
                                    onClick={() => {
                                        editNote(note)
                                        const element = document.getElementById("InputListId")
                                        element?.focus()
                                    }}
                                    classNames={{
                                        container: "!w-4 !h-4 !flex !items-center !justify-center !rounded-full m-auto px-2 duration-200 !flex-none",
                                        section: "!text-lg"
                                    }}
                                />
                                <TextTag 
                                    as="span" 
                                    onDoubleClick={() => {
                                        if (disabled) return
                                        editNote(note)
                                        const element = document.getElementById("InputListId")
                                        element?.focus()
                                    }}
                                    className="flex-auto cursor-move select-none"
                                >
                                    {note}
                                </TextTag>
                                <Button
                                expand="block"
                                color="gray"
                                icon="arrows-move"
                                iconWidth="1.5rem"
                                    disabled={disabled}
                                // iconHeight="0.8rem"
                                fill="transparent"
                                iconColor={theme === "dark" ? "lightgray" : "gray"}
                                iconHover={theme === "dark" ? "white" : "black"}
                                onClick={() => editNote(note)}
                                classNames={{
                                    container: "handle !w-7 !h-7 !flex !items-center !justify-center !rounded-full m-auto px-2 duration-200 !flex-none",
                                    section: "!text-lg"
                                }}
                            />
                            </li>
                        )}
                    </ul>
                }
            </div>
            <TextTag
                as='span'
                className={errorTwMerge}
                // style={styles?.error}
            >
                {(error && act) && error}
            </TextTag>
        </div>
    )
})

InputList.displayName = 'InputList';

export default InputList;