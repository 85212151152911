import { useTheme } from "next-themes";
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { LogOut } from "../../axios/Auth.api";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { GlobalLoadingAtom, ModalOpenAtom, ModalTypeAtom, ServerDateState, ShowMenuState, UserDataState } from "../../Recoil/Atoms";
import Button from "../Common/Button/Button";
import Icon from "../Common/Icon/Icon";
import Dialog from "../Common/Dialog/Dialog";
import { ElementType, useEffect, useState } from "react";
import UserForm from "../UserForm/UserForm";
import { AllDialog } from "../Common/Dialog/Dialog.aggrigation";
import TextTag from "../Common/TextTag/TextTag";
import moment from 'moment-jalaali'
import { AllNotificationsStateAtom } from "../../Recoil/confirmsAtom";
moment.loadPersian({dialect: 'persian-modern'});
const cookies = new Cookies();


const NavbarComponent = () => {

    const navigation = useNavigate()
    const serverDate = useRecoilValue(ServerDateState)
    const [userData, setUserData] = useRecoilState(UserDataState)
    const resetUserDate = useResetRecoilState(UserDataState)
    const {theme, setTheme} = useTheme()
    const [showMenu, setShowMenu] = useRecoilState(ShowMenuState)
    const [modalType, setModalType] = useRecoilState(ModalTypeAtom)
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    // const [hasNotification, setHasNotification] = useRecoilState(HasNotificationStateAtom)
    const [allNotifications, setAllNotifications] = useRecoilState(AllNotificationsStateAtom)
    const [globalLoading, setGlobalLoading] = useRecoilState(GlobalLoadingAtom)
    const dayName = moment(serverDate*1000);

    const ActiveModal: ElementType = AllDialog?.[modalType as "AddUser"]?.element

    return (
        <div 
            className="
                w-full h-16 fixed top-0 right-0 z-[220] backdrop-blur-2xl border-b border-gray-400 dark:border-gray-900 shadow-lg shadow-[#00000029]
                bg-gray-300 bg-opacity-70
                dark:bg-gray-900 dark:bg-opacity-70"
            // style={{boxShadow: "0 10px 30px rgba(0,0,0,0.7)"}}
        >

            <div className="max-w-[1900px] h-full m-auto px-5 flex items-center justify-between font-bold">
                <div className="flex items-center justify-start">
                    
                    <div 
                        className="!w-10 !h-10 !m-0 lg:hidden flex items-center justify-center cursor-pointer relative"
                        onClick={() => setShowMenu(!showMenu)}
                    >
                        {!!allNotifications.length &&
                            <span className="absolute flex items-center justify-center right-0 top-1 h-4 w-4 z-10">
                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-500 opacity-75"></span>
                                <span className="relative inline-flex rounded-full h-2 w-2 bg-rose-600"></span>
                            </span>
                        }
                        <Icon
                            name="list"
                            width="2rem"
                            height="2rem"
                            color={theme === "dark" ? "lightgray" : "#363636"}
                        />
                    </div>
                    
                    <Button 
                        icon={theme === "dark" ? "brightness-high-fill" : "moon-fill"}
                        expand="block"
                        fill="transparent"
                        iconWidth="1.7rem"
                        iconHeight="1.7rem"
                        iconColor={theme === "dark" ? "#f6b902" : "#363636"}
                        iconHover={theme === "dark" ? "#f6b902" : "black"}
                        classNames={{
                            container: "!w-10 !h-10 !m-0"
                        }}
                        onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
                    />
                    
                </div>

                <div className="flex items-stretch justify-center gap-2">
                    
                    <div className="flex flex-col items-end justify-center border-e border-gray-400 dark:border-gray-600 px-3 gap-2">
                        <TextTag as="span" className=" font-thin">
                            {dayName.format('dddd')}
                        </TextTag>
                        <TextTag as="span" className="whitespace-nowrap font-thin">
                            {moment(serverDate*1000).format('jYYYY/jMM/jDD')}
                        </TextTag>
                    </div>
                    <TextTag as="h1" className="w-24 whitespace-nowrap flex items-center justify-center font-thin">
                        {moment(serverDate*1000).format('HH:mm').split(":").reverse().join(" : ")}
                    </TextTag>
                </div>

                {/* <div className="w-full flex items-center justify-end">
                    <div className=" opacity-70">
                        {userData.username || userData.email}
                    </div>
                </div> */}

            </div>



            
            {/* <h1 onClick={() => setTheme(theme === "dark" ? "light" : "dark")} className="dark:bg-gray-900 bg-gray-300 h-[2000px]">Home</h1> */}

            <Dialog
                open={isModalOpen}
                setOpen={globalLoading ? () => {} : setIsModalOpen}
            >
                {ActiveModal && <ActiveModal {...AllDialog[modalType as "AddUser"].props}/> }
            </Dialog>
        </div>
    )
}

export default NavbarComponent;