import { useRecoilState } from "recoil";
import Button from "../../Button/Button"
import TextTag from "../../TextTag/TextTag"
import { DateState, ModalOpenAtom } from "../../../../Recoil/Atoms";
import { useTheme } from "next-themes";
import { UnClosedDaysState } from "../../../../Recoil/dailyLogAtom";
import { Link } from "react-router-dom";
import moment from "moment-jalaali";
import { InOutLogAtom } from "../../../../Recoil/InOutLogAtom";
import { DailyLogAtom, PinsStateAtom, WorksInDayAtom } from "../../../../Recoil/Works.atom";

const UnclosedDays = () => {
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    const [unClosedDays, setUnClosedDays] = useRecoilState(UnClosedDaysState)
    const [inOutLogs, setInOutLogs] = useRecoilState(InOutLogAtom)
    const [works, setWorks] = useRecoilState(WorksInDayAtom)
    const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom)
    const [pins, setPins] = useRecoilState(PinsStateAtom)
        const [date, setDate] = useRecoilState(DateState)
    const {theme} = useTheme()

    const get = (date: number) => {
        
        localStorage.setItem("pfk_time_tracker", JSON.stringify({defaultDate: String(date)}))
        
        setDate(date)
        
    // const getDailyLogHandler = (todayTimestamp: number) => {
        // setGlobalLoading(true)
        // getDailyLog(todayTimestamp, userId)
        //     .then(res => {
        //         const {InOutLogs, Works, Pins, ...otherData} = res.data
        //         const sortInOutLog = ([...InOutLogs] as InOutLogDto[]).sort((a, b) => a.enter - b.enter)
        //         const pinSort = Pins.sort((a: any, b: any) => a.time - b.time)
        //         setInOutLogs(sortInOutLog)
        //         generateTimeFrame(sortInOutLog, Works, todayTimestamp, pinSort)
        //         setPins(pinSort) 
        //         setDailyLog(otherData)
        //         const sortWorks = (Works as WorkDto[]).sort((a, b) => a.start - b.start)
        //         setWorks(sortWorks)
        //         setGlobalLoading(false)
        //         if (otherData.finalized) {
        //             setLock(true)
        //         }
        //         else {
        //             setLock(false)
        //         }
        //     })
        //     .catch(err => {
        //         console.log(err) 
        //         generateTimeFrame(inOutLogs, works, date, pins)
        //         setInOutLogs([])
        //         setDailyLog({})
        //         setGlobalLoading(false)
        //         setLock(false)
        //     })

    // }
    }

    console.log(unClosedDays);
    return (
        <div className="w-[calc(100vw-40px)] max-w-[700px] h-96 flex flex-col bg-gray-100 dark:bg-gray-700 rounded-md">
            <div className="flex-none flex items-center justify-between p-2 border-b border-gray-200 dark:border-gray-800">
                <TextTag as="span">
                    روزهای بسته نشده
                </TextTag>
                <Button
                    icon="x-lg"
                    color="gray"
                    fill="transparent"
                    iconWidth="1.3rem"
                    iconHeight="1.3rem"
                    iconColor={theme === "dark" ? "lightgray" : "gray"}
                    iconHover={theme === "dark" ? "white" : "black"}
                    onClick={() => setIsModalOpen(false)}
                    classNames={{
                        container: "!w-5 !h-5"
                    }}
                />
            </div>  
            <div className="flex flex-col items-center justify-start">
                <TextTag as="span" className="p-4">
                    روزهای کاری که توسط شما بسته نشده است به شرح زیر میباشد
                </TextTag>
                <div className="flex flex-wrap items-start justify-center gap-3 p-2">
                    {unClosedDays.map(day =>
                        <div 
                            onClick={() => {
                                get(day.date)
                            }}
                            className="w-32 border border-gray-400 dark:border-gray-800 bg-gray-300 dark:bg-gray-800 !bg-opacity-50 hover:!bg-opacity-100 transition-all duration-200 rounded-md p-4"
                        >
                            <TextTag>{moment(day?.date*1000).format('jYYYY/jM/jD').split(" - ").reverse().join(" - ")}</TextTag>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default UnclosedDays