import { RecoilState, atom, selector } from "recoil";
import { ProjectDatabaseDto } from "../pages/Projects/dto/databaseStructure";
import { typeSearchProjectsParamsType } from "../Components/ProjectFilter/types";

export const ProjectsAtom: RecoilState<ProjectDatabaseDto[]> = atom<ProjectDatabaseDto[]>({
    key: 'ProjectsAtom',
    default: [],
});

export const SearchProjectsParamsAtom: RecoilState<typeSearchProjectsParamsType> = atom({
    key: 'SearchProjectsParamsAtom',
    default: {},
});