import * as React from 'react';
import { useState, useEffect, useRef, useTransition } from "react";
import Button from "../../Components/Common/Button/Button";
import TextTag from "../../Components/Common/TextTag/TextTag";
import styles from "./Progress.module.scss"
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileTimePicker, PickerChangeHandlerContext, StaticTimePicker, TimeClock, TimeValidationError } from "@mui/x-date-pickers";
import Dialog from "../../Components/Common/Dialog/Dialog";
import dayjs, { Dayjs } from "dayjs";
import { DateState, GlobalLoadingAtom, MinimumDistanceBetweenInOutLogsStateAtom, MinimumInOutLogTimeDurationStateAtom, MinimumWorkTimeDurationStateAtom, ModalOpenAtom, ModalParamsAtom, ModalTypeAtom, NumberInputFocusedAtom, ServerDateState, ShowAggregationTimeStateAtom, UserDataState } from "../../Recoil/Atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { distanceTimes, distanceTimesByTime, distanceTimesToDuration, DurationToTime, durationToTimeString, ServerDateToDay, timestampToHours, timestampToMinutes, TimestampToTime, timeToDuration, TimeToTimestamp, todayCalculate } from "../../Utils/convertTime";
import { Toast } from "../../Utils/Toast";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import WorkForm from "../../Components/WorkForms/WorkForm";
import ClockPicker from "../../Components/Common/ClockPicker/ClockPicker";
import { DayValue, DayRange, Day } from '@hassanmojab/react-modern-calendar-datepicker'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import TimePicker from '../../Components/Common/TimePicker/TimePicker';
import WorkTimeAggregations from '../../Components/Common/WorkTimeAggregations/WorkTimeAggregations';
import { DailyLogAtom, ExitTempAtom, LockWorkPageAtom, MissionType, PinsStateAtom, TimeFrameAtom, TimeSpanDurationAtom, TransmissionTimeAtom, TransmissionTimesAtom, WorkDto, WorksInDayAtom, WorkType } from '../../Recoil/Works.atom';
import TimePickerTouch from '../../Components/Common/TimePickerTouch/TimePickerTouch';
import DatePicker from '../../Components/Common/DatePicker/DatePicker';
import { getTimeLog, insertTimeLog } from '../../axios/TimeLog';
import { addWork, updateWork } from '../../axios/Work.api';
import notFound from "../../assets/Not_Found_5.png"
import moment from 'moment-jalaali'
import domtoimage from 'dom-to-image';
import { useTheme } from 'next-themes'; 
import { Toasts } from '../../toasts/toasts';
import { findExitTime } from '../../Utils/FindExiteTime';
import Icon from '../../Components/Common/Icon/Icon';
import { promises } from 'dns';
import { finalizedUpdate, getDailyLog, updateTimeLog } from '../../axios/DailyLog.api';
import { deleteInOutLog, insertDailyLog, updateInOutLog } from '../../axios/InOutLog.api';
import { InOutLogAtom, InOutLogDto } from '../../Recoil/InOutLogAtom';
import useGenerateTimeFrame from '../../hooks/useGenerateTimeFrame';
import { addPin } from '../../axios/Pin.api';
import IconColored from '../../Components/Common/Icon/IconColored';
import WorksBar from '../../Components/Works/WorksBar/WorksBar';
import DescriptionsMobile from '../../Components/Works/DescriptionsMobile/DescriptionsMobile';
import Descriptions from '../../Components/Works/Descriptions/Descriptions';
import Projects from '../../Components/Works/Projects/Projects';
import WorksComponent from '../../Components/Works/Works';
import authorizedLogo from "../../assets/Authentication.png"
import { Link } from 'react-router-dom';
moment.loadPersian({dialect: 'persian-modern'});

const oneMinutes = 1 * 60
const _15Minutes = oneMinutes * 15
const oneHours = 1 * 60 * 60
const hours = 3600
const oneDay = 60*60*24;
const now = Math.floor(Date.now()/60000)*60000

export const faWorkType: any = {
    sumOfInCompony: "جمع حضور", 
    work: "درشرکت",
    mission: "ماموریت",
    remote: "دورکاری",
    leave: "کار شخصی",
    temporary: "بلاتکلیف",
    idleTime: "مجموع دراختیار",
    dayDuration: "طول مدت حضور",
    useful: "کار مفید",
    sumOfRemoteDuration: "مجموع دورکاری"
}
export const faMissionType: any = {
    work1: "کار دوره اول",
    work2: "کار دوره دوم",
    onWay1: "طی مسیر دوره اول",
    onWay2: "طی مسیر دوره دوم",
    work: "کار",
    onWay: "طی مسیر",
    sleep: "خواب",
    optionalUnemployment: "بیکاری اختیاری",
    mandatoryUnemployment: "بیکاری اجباری",
}

const tailwind = [
    "bg-yellow-500",
    "bg-purple-500",
    "bg-emerald-500",
    "bg-rose-500",
    "bg-sky-500",
    "bg-orange-500",
    "bg-teal-500",
    "bg-yellow-600",
    "bg-purple-600",
    "bg-emerald-600",
    "bg-rose-600",
    "bg-sky-600",
    "bg-orange-600",
    "bg-teal-600",
    "border-yellow-700",
    "border-purple-700",
    "border-emerald-700",
    "border-rose-700",
    "border-sky-700",
    "border-orange-700",
    "border-teal-700",
    "ring-yellow-700",
    "ring-purple-700",
    "ring-emerald-700",
    "ring-rose-700",
    "ring-sky-700",
    "ring-orange-700",
    "ring-teal-700",
    "text-yellow-500",
    "text-purple-500",
    "text-emerald-500",
    "text-rose-500",
    "text-sky-500",
    "text-orange-500",
    "after:!bg-yellow-900",
    "after:!bg-purple-900",
    "after:!bg-emerald-900",
    "after:!bg-rose-900",
    "after:!bg-sky-900",
    "after:!bg-orange-900",
    "ring-gray-700",
]

export const workTheme = {
    work: "emerald",
    mission: "sky",
    remote: "purple",
    leave: "yellow",
    temporary: "rose",
}

export default function Works() {
    const [userData, setUserData] = useRecoilState(UserDataState)
    if (!userData.chatId) return <TelegramBot></TelegramBot>
    return <WorksComponent />
}

const TelegramBot = () => {
    return (
        <div className='w-full h-full bg-gray-100 dark:bg-gray-600 rounded-md border border-gray-400 dark:border-gray-800 flex items-center justify-center flex-col gap-5 p-2'>
            <img src={authorizedLogo} className='w-96 '/>
            <Link to={"https://t.me/pfkTimeTrackerBot"} target='_blank' className='ring ring-gray-400 dark:ring-gray-700 dark:ring-opacity-100 rounded-xl gap-5 text-center flex items-center justify-center p-2 sm:p-5 bg-gray-500 bg-opacity-20 hover:bg-opacity-40 transition-all cursor-pointer group'>
                <TextTag as='h3' className='text-gray-500 dark:text-gray-800'>
                    جهت دریافت گزارش روزانه ، در ربات تلگرام ثبت نام کنید
                </TextTag>
                <Icon
                    name="telegram"
                    color='#374151'
                    width="100px"
                    height="100px"
                    classNames='flex-none group-hover:scale-110 transition-all duration-200 group-hover:rotate-12'
                />
            </Link>
        </div>
    )
}

// const WorksBar = ({
//     selectedWork,
//     setSelectedWork,
//     setOverflow,
//     timeFrame,
//     setTimeFrame,
//     timeSpanDuration,
//     setTimeSpanDuration,
//     findExitTime,
//     // generateTimeFrame
// }: {
//     selectedWork: number,
//     setSelectedWork: React.Dispatch<React.SetStateAction<number>>
//     setOverflow: React.Dispatch<React.SetStateAction<any>>
//     timeFrame: string[],
//     setTimeFrame: React.Dispatch<React.SetStateAction<string[]>>
//     timeSpanDuration: number,
//     setTimeSpanDuration: React.Dispatch<React.SetStateAction<number>>
//     findExitTime: (data: {
//         exit?: number;
//         works: WorkDto[];
//         serverDate: number;
//         date: number;
//         logicExit: string;
//     }) => string
//     // generateTimeFrame: ({from, to}: {from: number, to: number}) => void
// }) => {

//     const generateTimeFrame = useGenerateTimeFrame()
//     const [timeLog, setTimeLog] = useRecoilState(TransmissionTimeAtom)
//     const [works, setWorks] = useRecoilState(WorksInDayAtom)
//     const [exitTemp, setExitTemp] = useRecoilState(ExitTempAtom)
//     const minimumWorkTimeDuration = useRecoilValue(MinimumWorkTimeDurationStateAtom)
//     const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom)
//     const [inOutLogs, setInOutLogs] = useRecoilState(InOutLogAtom)
//     const [pins, setPins] = useRecoilState(PinsStateAtom)
//     // const [isPending, startTransition] = useTransition();
//     // const [user, setUser] = useState({
//     //     logicEnter: "08:00",
//     //     logicExit: "17:00",
//     // })
//     const [user, setUser] = useRecoilState(UserDataState)
//     const worksBoxRef = useRef<any>(null)
//     const serverDate = useRecoilValue(ServerDateState)
//     const {theme, setTheme} = useTheme();
//     const [workBoxSize, setWorkBoxSize] = useState(0)
//     const [date, setDate] = useRecoilState(DateState)
//     const [timeLogLoading, setTimeLogLoading] = useState(false)
//     const [lock, setLock] = useRecoilState(LockWorkPageAtom)
//     const [enterClockPickerIsOpen, setEnterclockPickerIsOpen] = useState(false)
//     const [exitClockPickerIsOpen, setExitclockPickerIsOpen] = useState(false)
//     const cloneWorksRef = useRef<WorkDto[]>([])

    
    

//     // useEffect(() => {
//     //     holidayChecker(date)
//     // }, [date])
//     // const holidayChecker = async (date: number) => {
//     //     const inDate = new Date(date*1000)
//     //     const year = inDate.getFullYear()
//     //     const mounts = inDate.getMonth()+1
//     //     const day = inDate.getDate()
//     //     const url = `https://holidayapi.ir/gregorian/${year}/${String(mounts).padStart(2, "0")}/${String(day).padStart(2, "0")}`
//     //     const holiday = await axios.get(url)
//     //     console.log(holiday)
//     //     // const data = await holiday
//     //     return url
//     // }


//     const newEnterHandlerBefore = (value: {hours: string, minutes: string}, name?: string) => {
//         if (lock) return Toasts.lockWorks()
//         setTimeLogLoading(true)
//         let hours = timestampToHours(serverDate).toString().padStart(2, "0")
//         let minutes = timestampToMinutes(serverDate).toString().padStart(2, "0")
//         if (value) {
//             hours = value.hours
//             minutes = value.minutes
//         }

//         if (timeLog.enter) {
//             updateTimeLog(
//                 date,
//                 { enter: date + (+hours*60*60) + (+minutes*60) }
//             )
//                 .then((res) => {
//                     console.log(res)
//                     // const enterTime = timeLog.enter ? +timeLog.enter.split(":")?.[0] : user.logicExit ? user.logicExit.split(":")?.[0] : 0
//                     // const loopFrom = +enterTime
//                     // const loopTo = +hours+1
//                     // const time_frame = []
//                     // for (let i=loopFrom; i<=loopTo; i++) {
//                     //     time_frame.push(`${String(i).padStart(2, "0")}:00`)
//                     // }
//                     // setTimeFrame(time_frame)
//                     // setTimeLog({
//                     //     ...timeLog,
//                     //     exit: `${hours}:${minutes}`
//                     // })
//                     // const spanDuration = (loopTo - loopFrom) * 60 * 60 * 1000
//                     // setTimeSpanDuration(spanDuration)
//                     const { 
//                         date, 
//                         enter, 
//                         exit, 
//                         idleTime, 
//                         confirmed 
//                     } = res.data
//                     if (!timeLog.enter || !enter) return; 
//                     // console.log(timeLog.enter, enter, serverDate)
//                     const enterTime = TimestampToTime(enter).split(":")?.[0]
//                     // console.log(enterTime)
//                     const loopFrom = +enterTime
//                     const loopTo = exit ? (timestampToHours(exit)+1) : (user.logicExit ? +user.logicExit.split(":")?.[0] : 24)
//                     const time_frame: string[] = []
//                     for (let i=loopFrom; i<=loopTo; i++) {
//                         time_frame.push(`${String(i).padStart(2, "0")}:00`)
//                     }
//                     // startTransition(() => )
//                     setTimeFrame(time_frame)
//                     setTimeLog({
//                         ...timeLog,
//                         enter
//                     })
//                     const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
//                     setTimeSpanDuration(spanDuration)
//                 })
//                 .catch((err) => {
//                     console.log(err)
//                 })
  
//         } else {
//             insertTimeLog({
//                 date,
//                 enter: date + (+hours*60*60) + (+minutes*60),
//             })
//                 .then((res) => {
//                     // console.log(res)
//                     const { date, enter, idleTime, confirmed } = res.data
//                     const exitTime = findExitTime({
//                         exit: timeLog.exit,
//                         works,
//                         serverDate,
//                         date,
//                         logicExit: user.logicExit
//                     })
//                     const loopFrom = timestampToHours(enter)
//                     const loopTo = +exitTime < 24 ? +exitTime+1 : +exitTime
//                     const time_frame: string[] = []
//                     for (let i=loopFrom; i<=loopTo; i++) {
//                         time_frame.push(`${String(i).padStart(2, "0")}:00`)
//                     }
//                     // startTransition(() => )
//                     setTimeFrame(time_frame)
//                     setTimeLog({
//                         ...timeLog,
//                         enter,
//                         confirmed,
//                     })
//                     const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
//                     setTimeSpanDuration(spanDuration)
//                 })
//                 .catch((err) => {
//                     console.log(err)
//                 })
//         }
        

//     }

//     const newExitHandlerBefore = (value: {hours: string, minutes: string}, name?: string) => {
//         if (lock) return Toasts.lockWorks()
//         // const hours = value?.get("hours").toString().padStart(2, "0") ?? "00"
//         // const minutes = value?.get("m").toString().padStart(2, "0") ?? "00"

//         const {hours, minutes} = value
//         updateTimeLog(
//             date,
//             { exit: date + (+hours*60*60) + (+minutes*60) }
//         )
//             .then((res) => {
//                 const { date, enter, exit, idleTime, confirmed } = res.data
//                 if (!timeLog.enter || !enter) return; 
//                 const enterTime = TimestampToTime(enter).split(":")?.[0]
//                 const loopFrom = +enterTime
//                 const loopTo = timestampToHours(exit)+1
//                 const time_frame: string[] = []
//                 for (let i=loopFrom; i<=loopTo; i++) {
//                     time_frame.push(`${String(i).padStart(2, "0")}:00`)
//                 }
//                 setTimeFrame(time_frame)
//                 setTimeLog({
//                     ...timeLog,
//                     exit
//                 })
//                 setExitTemp(exit)
//                 const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
//                 setTimeSpanDuration(spanDuration)
//             })
//             .catch((err) => {
//                 console.log(err)
//             })
//     }
    
//     const newEnterHandler = (value: {hours: string, minutes: string}, name?: string) => {
//         if (lock) return Toasts.lockWorks()
//         setTimeLogLoading(true)
//         let hours = timestampToHours(serverDate).toString().padStart(2, "0")
//         let minutes = timestampToMinutes(serverDate).toString().padStart(2, "0")
//         if (value) {
//             hours = value.hours
//             minutes = value.minutes
//         }
//         const enter = TimeToTimestamp(`${hours}:${minutes}`, dailyLog.date)
//         const cloneInOutLog = [...inOutLogs]
//         const after = cloneInOutLog.find(i => i.enter > enter)
//         const newLogValue: {
//             date: number;
//             enter: number;
//             exit?: number;
//         } = {
//             date: dailyLog.date,
//             enter,
//         }
//         if (after) {
//             newLogValue.exit = enter + 900
//         }

//         insertDailyLog(newLogValue)
//             .then((res) => {
//                 console.log(res)
//                 const {data} = res 
//                 const cloneInOutLogs = [...data]
//                 const sortInOutLogs = cloneInOutLogs.sort((a, b) => a.enter - b.enter)
//                 // const startTime = timestampToHours([...sortInOutLogs].shift()?.enter ?? 0)
//                 // const endTime = timestampToHours(([...sortInOutLogs].pop()?.exit ?? (date + (24*60*60))), date)
//                 // generateTimeFrame({
//                 //     from: startTime,
//                 //     to: endTime < 24 ? endTime + 1 : endTime
//                 // })
//                 generateTimeFrame(sortInOutLogs, works, date, pins)
//                 setInOutLogs(sortInOutLogs)
//                 setDailyLog({
//                     ...dailyLog,
//                     finalized: 0
//                 })
//             })
//             .catch(err => {
//                 console.log(err)
//                 // workInstance && setWorks(workInstance)
//             })
//     }
    
//     const newExitHandler = (value: {hours: string, minutes: string}, name?: string) => {
//         if (lock) return Toasts.lockWorks()
//         setTimeLogLoading(true)
//         let hours = timestampToHours(serverDate).toString().padStart(2, "0")
//         let minutes = timestampToMinutes(serverDate).toString().padStart(2, "0")
//         if (value) {
//             hours = value.hours
//             minutes = value.minutes
//         }
//         const findInOutLog = inOutLogs.find(i => !i.exit)
//         if (!findInOutLog) return
//         updateInOutLog(findInOutLog.id, {
//             // اگر زمان انتخاب شده صفر بود 24 در نظر گرفته میشه
//             exit: TimeToTimestamp(`${hours}:${minutes}`, ((date + (!(+hours + +minutes) ? oneDay : 0)))),
//         })
//             .then(res => {
//                 const { data } = res
//                 const cloneInOutLogs = [...inOutLogs]
//                 const findWorkIndex = cloneInOutLogs.findIndex(w => w.id === data.id)
//                 cloneInOutLogs.splice(findWorkIndex, 1)
//                 cloneInOutLogs.splice(findWorkIndex, 0, data)
//                 const sortWorks = cloneInOutLogs.sort((a, b) => a.enter - b.enter)
//                 setInOutLogs(sortWorks)
//                 generateTimeFrame(sortWorks, works, date, pins)
//                 setDailyLog({
//                     ...dailyLog,
//                     finalized: 0
//                 })
//             })
//             .catch(err => {
//                 console.log(err)
//                 // workInstance && setWorks(workInstance)
//             })
//     }

//     const calculateTimeArrowLocation = (span: number) => {
//         // if (!timeLog.enter) return;
//         // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 60 * 60 // * 1000
//         // const startHoursToTimeSpan = timestampToHours(timeLog.enter) * 60 * 60
//         const startHoursToTimeSpan = +(timeFrame[0]?.split(":")?.[0]) * 60 * 60
//         const distanceFromZeroTime = span - startHoursToTimeSpan;
//         // console.log(distanceFromZeroTime)
//         return distanceFromZeroTime * workBoxSize / timeSpanDuration;
//     }

//     const startTimeSpan = () => {
//         // const today = ServerDateToDay(serverDate);
//         // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
//         // return today + startHoursToTimeSpan
//         const startFrame = +(timeFrame[0]?.split(":")?.[0])
//         // return timeLog.enter ?? 0
//         return (startFrame*60*60) + dailyLog.date
//     }

//     // const endTimeSpan = () => {
//     //     const today = ServerDateToDay(serverDate);
//     //     const endHoursToTimeSpan = +timeLog.exit.split(":")?.[0] * 1000 * 60 * 60;
//     //     return today + endHoursToTimeSpan
//     // }

//     const pixelToTimeSpan = (px: number) => {
//         const startWorkSpan = startTimeSpan()
//         // console.log(px)
//         const span = px * timeSpanDuration / workBoxSize
//         return Math.floor((span + startWorkSpan)/60) * 60
//     }
    
//     const calculateTop = (span: number) => {
//         // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
//         // const startHoursToTimeSpan = +(TimestampToTime(timeLog.enter ?? 0).split(":")?.[0]) * 60 * 60
//         // const distanceFromZeroTime = span - startHoursToTimeSpan;
//         const px = span * workBoxSize / timeSpanDuration;
//         return px
//     }

//     // console.log(calculateTop((timeLog.enter ?? 0) - date))
//     const findClickSpan = (clientY: number) => {
//         const topDistance = worksBoxRef.current.offsetTop
//         const scrollTop = document.documentElement.scrollTop
//         const startFrame = +(timeFrame[0]?.split(":")?.[0])
//         // const clickY = clientY - topDistance + scrollTop - calculateTop(startFrame*60*60)
//         return pixelToTimeSpan(clientY - topDistance)
//     }

//     const findMaxId = () => {
//         // let maxId = 0
//         // works.forEach((work) => {
//         //     if (maxId < work.id) maxId = work.id
//         // })
//         const minId = works.reduce((a, b) => a < b.id ? a : b.id ,0)
//         return minId-1
//     }

//     const addTemporaryWork = (e: any) => {
//         if (lock) return Toasts.lockWorks()
//         // if (!timeLog.enter) return Toast("error", "ابتدا زمان ورود را ثبت کنید");
//         // const existWork = works.find((work) => work.type === "temporary")
//         let cloneWorks = [...works]
//         cloneWorksRef.current = [...works]
//         // console.log(cloneWorks)
//         // let id = findMaxId()
//         // if (existWork) {
//         //     cloneWorks = [...works].filter((work) => work.type !== "temporary")
//         // }
//         // const startWorkSpan = timeLog.enter ?? 0
//         // const endOfThisDay = timeLog.exit ?? (date + (24*60*60) - 60)
//         // const endWorkSpan = timeLog.exit ?? endOfThisDay // TimeToTimestamp(user.logicExit, date) ?? 24
//         const clickSpan = findClickSpan(e.clientY)
//         const beforeWork = [...cloneWorks].sort((a, b) => b.end - a.end)?.find((work) => work.end < clickSpan)
//         const afterWork = [...cloneWorks].sort((a, b) => a.end - b.end)?.find((work) => work.start > clickSpan)
//         const beforeIndex = [...cloneWorks].findIndex((work) => work.id === (beforeWork?.id ?? 0))
//         const findInOutLog = inOutLogs.find(i => i.enter <= clickSpan && (i.exit ?? (date + oneDay)) >= clickSpan)
//         let start = clickSpan;
//         let inOutLogId: number | null = null
//         let type: WorkType = "work"
        
//         if (findInOutLog) {
//             inOutLogId = findInOutLog.id
//             const insideWorks = works.filter(iw => iw.inOutLogId === findInOutLog.id) ?? []
//             const insideWorksDetails = insideWorks.map(iwd => ({start: iwd.start, end: iwd.end}))
//             const allInsideLog = [
//                 {start: findInOutLog.enter, end: findInOutLog.enter},
//                 ...insideWorksDetails,
//                 {start: (findInOutLog.exit ?? (date+oneDay)), end: (findInOutLog.exit ?? (date+oneDay))}
//             ]
//             const beforeWork = allInsideLog.sort((a, b) => b.end - a.end)?.find((work) => work.end < clickSpan)?.end ?? findInOutLog.enter
//             const afterWork = allInsideLog.sort((a, b) => a.end - b.end)?.find((work) => work.start > clickSpan)?.start ?? (findInOutLog.exit ?? (date + oneDay))
//             if (afterWork-beforeWork < minimumWorkTimeDuration) return Toast("error", "فضای کافی برای ایجاد کار موجود نمیباشد")
//             const conflictEndWithAfter = (clickSpan+minimumWorkTimeDuration) - afterWork
//             if (conflictEndWithAfter > 0) {
//                 start -= conflictEndWithAfter
//             }
//         } else {
//             type = "remote"
//             const outsideWork = works.filter(iw => !iw.inOutLogId) ?? []
//             const outsideWorkDetails = outsideWork.map(iwd => ({start: iwd.start, end: iwd.end, type: "outsideWork"}))
//             const inOutLogsDetails = inOutLogs.map(iwd => ({start: iwd.enter, end: (iwd.exit ?? (date + oneDay)), type: "inOutLogs"}))
//             const allLogs = [...outsideWorkDetails, ...inOutLogsDetails].sort((a, b) => a.start - b.start)
//             const beforeWork = allLogs.sort((a, b) => b.end - a.end)?.find((work) => work.end < clickSpan)?.end ?? date
//             const afterWork = allLogs.sort((a, b) => a.end - b.end)?.find((work) => work.start > clickSpan)?.start ?? (date + oneDay)
//             if (afterWork-beforeWork < minimumWorkTimeDuration) return Toast("error", "فضای کافی برای ایجاد کار موجود نمیباشد")
//             const conflictEndWithAfter = (clickSpan+minimumWorkTimeDuration) - afterWork
//             if (conflictEndWithAfter > 0) {
//                 start -= conflictEndWithAfter
//             }
//         }

//         const newWork: WorkDto = {
//             id: -1, 
//             date,
//             type, 
//             start, 
//             inOutLogId,
//             end: start+minimumWorkTimeDuration, 
//             projects: null,
//             idleTime: 0,
//             notes: "",
//             confirmed: false
//         }
//         cloneWorks.splice(beforeIndex+1, 0, newWork)
//         // setWorks(cloneWorks)

//         const {id, ...requestWork} = newWork
//         addWork(requestWork)
//         .then(res => {
//             const { newWorksList, lastWork} = res.data
//             const data: WorkDto[] = Array.from(newWorksList)
//             const newWorks = data.sort((a, b) => a.start - b.start)
//             setSelectedWork(0)
//             setWorks(newWorks)

//             setSelectedWork(lastWork.id)
//             setOverflow({
//                 [lastWork.id]: true
//             })
//             generateTimeFrame(inOutLogs, newWorks, date, pins)
//             setDailyLog({
//                 ...dailyLog,
//                 finalized: 0
//             })
//         })
//         .catch(err => {
//             console.log(err)
//             setWorks(cloneWorksRef.current)
//         })
//     }
    
//     const addInOutLog = (e: any) => {
//         if (lock) return Toasts.lockWorks()
//         const clickSpan = findClickSpan(e.clientY)

//         const enter = clickSpan
//         const outWorks = works?.filter(w => !w.inOutLogId) ?? []
//         const outWorksDetails = outWorks.map(ow => ({start: ow.start, end: ow.end}))
//         const inOutListDetails = [...inOutLogs].map(io => ({start: io.enter, end: io.exit}))

//         const after = [...outWorksDetails, ...inOutListDetails].find(i => i.start > enter)

//         const newLogValue: {
//             date: number;
//             enter: number;
//             exit?: number;
//         } = {
//             date: dailyLog.date,
//             enter,
//         }
//         if (after) {
//             newLogValue.exit = enter + 900
//         }
//         insertDailyLog(newLogValue)
//             .then((res) => {
//                 // console.log(res)
//                 const {data} = res 
//                 const cloneInOutLogs = [...data]
//                 const sortInOutLogs = cloneInOutLogs.sort((a, b) => a.enter - b.enter)
//                 // const startTime = timestampToHours([...sortInOutLogs].shift()?.enter ?? 0)
//                 // const endTime = timestampToHours(([...sortInOutLogs].pop()?.exit ?? (date + (24*60*60))), date)
//                 // generateTimeFrame({
//                 //     from: startTime,
//                 //     to: endTime < 24 ? endTime + 1 : endTime
//                 // })
//                 generateTimeFrame(sortInOutLogs, works, date, pins)
//                 setInOutLogs(sortInOutLogs)
//                 setDailyLog({
//                     ...dailyLog,
//                     finalized: 0
//                 })
//             })
//             .catch(err => {
//                 console.log(err)
//                 // workInstance && setWorks(workInstance)
//             })
//     }

//     const windowResize = () => {
//         setWorkBoxSize(worksBoxRef.current.clientHeight)
//     }

//     useEffect(() => {
//         setWorkBoxSize(worksBoxRef.current.clientHeight)
//         window.addEventListener("resize", windowResize)
//         return () => {
//             window.removeEventListener("resize", windowResize)
//         }
//     }, [worksBoxRef])

//     const increaseSensitiveRef = useRef<any>(null)
//     const increaseExitTimeInterval = useRef<NodeJS.Timeout>()

//     const increaseExitTime = () => {
//         clearInterval(increaseExitTimeInterval.current)
//         increaseExitTimeInterval.current = setInterval(() => {
//             const exitTime = [...timeFrame].pop()?.split(":")?.[0] ?? 0
//             if (+exitTime > 23) return clearInterval(increaseExitTimeInterval.current);
//             const loopFrom = timestampToHours(timeLog.enter as number)
//             const loopTo = +exitTime < 24 ? +exitTime+1 : +exitTime
//             const time_frame: string[] = []
//             for (let i=loopFrom; i<=loopTo; i++) {
//                 time_frame.push(`${String(i).padStart(2, "0")}:00`)
//             }
//             setTimeFrame(time_frame)
//             const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
//             setTimeSpanDuration(spanDuration)
//         }, 500)

//     }

//     const exitInOutLog = (e: any) => {
//         if (lock) return Toasts.lockWorks()
//         const clickSpan = findClickSpan(e.clientY)
//         const findInOutLog = inOutLogs.find(i => !i.exit)
//         if (!findInOutLog) return
//         updateInOutLog(findInOutLog.id, {
//             exit: clickSpan,
//         })
//             .then(res => {
//                 const { data } = res
//                 const cloneInOutLogs = [...inOutLogs]
//                 const findWorkIndex = cloneInOutLogs.findIndex(w => w.id === data.id)
//                 cloneInOutLogs.splice(findWorkIndex, 1)
//                 cloneInOutLogs.splice(findWorkIndex, 0, data)
//                 const sortWorks = cloneInOutLogs.sort((a, b) => a.enter - b.enter)
//                 setInOutLogs(sortWorks)
//                 generateTimeFrame(sortWorks, works, date, pins)
//                 // setTimeLog({
//                 //     ...timeLog,
//                 //     finalized: 0
//                 // })
//             })
//             .catch(err => {
//                 console.log(err)
//                 // workInstance && setWorks(workInstance)
//             })

//     }


//     return (
//         <div className="w-full md:w-[300px] flex-none flex flex-col gap-px">
//             <div className="w-full flex-none">
//                 <ClockPicker 
//                     name="start" 
//                     isOpen={enterClockPickerIsOpen}
//                     setIsOpen={setEnterclockPickerIsOpen}
//                     insideLabel="ورود"
//                     isDisable={!!inOutLogs.find(i => !i.exit)}
//                     value={timeLog.enter ? TimestampToTime(timeLog.enter) : undefined}
//                     // button={{
//                     //     title: `ورود ${timeLog.enter ? TimestampToTime(timeLog.enter) : ""}`,
//                     //     color: theme as "dark",
//                     //     fill: "basic",
//                     //     classNames: {
//                     //         container: "!w-full !h-10 !rounded-t-md !rounded-b-none transition-all duration-200",
//                     //         section: "!text-[0.72rem] sm:!text-sm"
//                     //     }
//                     // }}
//                     buttonMode="simple"
//                     classNames={{
//                         button: "rounded-t-md rounded-b-none"
//                     }}
//                     picker={{
//                         title: "زمان ورود",
//                         onAccept: newEnterHandler,
//                     }}
//                 />
//             </div>


//             <div 
//                 ref={worksBoxRef}
//                 className="flex-auto w-full bg-gray-100 dark:bg-gray-600 flex items-center justify-center relative border-x border-gray-400 dark:border-gray-900"
//             >
//                 <div 
//                     onClick={addInOutLog}
//                     className="w-8 h-full flex-none border-e border-gray-300 dark:border-gray-700 flex flex-col items-center justify-between relative z-[54]"
//                 >
//                     {user && timeFrame.map((h, i) => 
//                         // !!(i && i < duration.length-1) &&
//                         <TextTag 
//                             key={h}
//                             className="h-0 flex items-center justify-center text-[0.6rem] select-none"
//                             // style={{writingMode: "vertical-rl"}}
//                         >
//                             {!!(i && i < timeFrame.length-1) && h}
//                         </TextTag>
//                     )}
//                     {!!(timeFrame.length && date === ServerDateToDay(serverDate)) &&
//                         // <div className="w-full h-0 flex items-start justify-center rotate-90 absolute bg-gray-800 group">
//                             <div 
//                                 className="w-full h-0 flex items-start justify-center rotate-90 absolute"
//                                 title={TimestampToTime(serverDate)}
//                                 style={{
//                                     top: calculateTimeArrowLocation(serverDate - (ServerDateToDay(serverDate)))
//                                 }}
//                             >
//                                 <Icon
//                                     name="caret-down-fill"
//                                     width="1.5rem"
//                                     height="1.5rem"
//                                     color="#e11d48"
//                                     classNames=" animate-bounce"
//                                 />
//                             </div>
//                         // </div>
//                     }
//                 </div>
//                 <div className="h-full flex-auto flex flex-col items-center justify-between">
//                     {timeFrame.map((h, i) => 
//                         // !!(i && i < duration.length-1) &&
//                         <div 
//                             key={h}
//                             className={`w-full h-0 ${!!(i && i < timeFrame.length-1) ? "border-b" : ""} border-gray-300 dark:border-gray-700 flex items-center justify-center`}
//                         />
//                     )}
//                 </div>
                
//                 <div 
//                     className="absolute w-[calc(100%-64px)] h-full bg-opacity-20 left-8 z-[55]"
//                 >
//                     {inOutLogs.map((log, index) =>
//                         <InOutLogs 
//                             key={index}
//                             inOutLogs={inOutLogs}
//                             setInOutLogs={setInOutLogs}
//                             log={log}
//                             workBoxSize={workBoxSize}
//                             // generateTimeFrame={generateTimeFrame}
//                         />
//                     )}

//                 </div>

//                 <div 
//                     className="absolute w-[calc(100%-64px)] h-full bg-opacity-50 left-8 z-[55]"
//                     onClick={addTemporaryWork}
//                 >

//                 {/* <div 
//                     ref={increaseSensitiveRef}
//                     className='absolute bottom-0 w-full h-5 bg-red-400'
//                 /> */}
//                     {!!(timeFrame.length) && works.map((ele, index) =>
//                         <Work 
//                             key={String(ele.start+ele.end)} 
//                             ele={ele}
//                             timeLog={timeLog}
//                             workBoxSize={workBoxSize}
//                             timeSpanDuration={timeSpanDuration}
//                             works={works}
//                             setWorks={setWorks}
//                             selectedWork={selectedWork}
//                             setSelectedWork={setSelectedWork}
//                             timeFrame={timeFrame}
//                             increaseSensitive={increaseSensitiveRef}
//                             increaseExitTimeInterval={increaseExitTimeInterval}
//                             increaseExitTime={increaseExitTime}
//                             inOutLogs={inOutLogs}
//                         />
//                     )}
//                 </div>
//                 <div 
//                     onClick={exitInOutLog}
//                     className="w-8 h-full flex-none border-s border-gray-300 dark:border-gray-700 flex flex-col items-center justify-between relative"
//                 >
//                     {pins.map((pin: any) => 
//                         // !!(i && i < duration.length-1) &&
//                         <div 
//                             key={pin.time}
//                             title={TimestampToTime(pin.time)}
//                             onClick={(e) => e.stopPropagation()}
//                             className="w-full h-0 flex items-center justify-center absolute bg-gray-800 group hover:scale-125 transition-transform duration-200"
//                             style={{
//                                 top: calculateTimeArrowLocation(pin.time - date)
//                             }}
//                         >
//                             <TextTag className='absolute -right-10 bg-gray-600 dark:bg-gray-500 text-gray-50 p-1 rounded-md hidden group-hover:block'>{TimestampToTime(pin.time)}</TextTag>
//                             <IconColored
//                                 name="pin2"
//                                 width="2rem"
//                                 height="2rem"
//                                 // color="#e11d48"
//                                 classNames="group-hover:scale-150 transition-all -translate-y-1"
//                             />
//                         </div>
//                     )}
//                     {user && timeFrame.map((h, i) => 
//                         // !!(i && i < duration.length-1) &&
//                         <TextTag 
//                             key={h}
//                             className="h-0 flex items-center justify-center text-[0.6rem] select-none"
//                             // style={{writingMode: "vertical-rl"}}
//                         >
//                             {!!(i && i < timeFrame.length-1) && h}
//                         </TextTag>
//                     )}
//                 </div>


//             </div>


//             <div className="w-full flex-none">
//                 <ClockPicker
//                     name="end" 
//                     isOpen={exitClockPickerIsOpen}
//                     setIsOpen={setExitclockPickerIsOpen}
//                     isDisable={!inOutLogs.find(i => !i.exit)}
//                     insideLabel="خروج"
//                     value={timeLog.exit ? TimestampToTime(timeLog.exit) : undefined}
//                     // button={{
//                     //     title: `خروج ${timeLog.exit ? TimestampToTime(timeLog.exit) : ""}`,
//                     //     color: theme as "dark",
//                     //     fill: "basic",
//                     //     disabled: !timeLog.enter,
//                     //     classNames: {
//                     //         container: "!w-full !h-10 !rounded-b-md !rounded-t-none transition-all duration-200",
//                     //         section: "!text-[0.72rem] sm:!text-sm"
//                     //     }
//                     // }}
//                     buttonMode="simple"
//                     classNames={{
//                         button: "rounded-b-md rounded-t-none"
//                     }}
//                     picker={{
//                         title: "زمان خروج",
//                         onAccept: newExitHandler,
//                     }}
//                 />
//             </div>
//         </div>
//     )
// }

// const InOutLogs = ({
//     inOutLogs,
//     setInOutLogs,
//     log,
//     workBoxSize,
//     // generateTimeFrame,
// }: {
//     inOutLogs: InOutLogDto[];
//     setInOutLogs: React.Dispatch<React.SetStateAction<InOutLogDto[]>>
//     log: InOutLogDto;
//     workBoxSize: number;
//     // generateTimeFrame: ({from, to}: {from: number, to: number}) => void
// }) => {


//     const calculateTop = (span: number, spanDuration?: number, frame?: string[]) => {
//         // if (!timeLog.enter) return 0
//         // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
//         // const startHoursToTimeSpan = +(TimestampToTime(log.enter)) * 60 * 60
//         const startHoursToTimeSpan = (timeFrame.length ? (+(frame ?? timeFrame)?.[0]?.split(":")?.[0]) : 0) * 60 * 60
//         const distanceFromZeroTime = span - startHoursToTimeSpan;
//         const px = distanceFromZeroTime * workBoxSize / (spanDuration ?? timeSpanDuration);
//         return px
//     }

//     const calculateHeight = (span: number, spanDuration?: number) => {
//         const px = span * workBoxSize / (spanDuration ?? timeSpanDuration)
//         return px
//     }
    
//     const {theme} = useTheme()
//     const generateTimeFrame = useGenerateTimeFrame()
//     const [works, setWorks] = useRecoilState(WorksInDayAtom)
//     const [date, setDate] = useRecoilState(DateState)
//     // const minimumWorkTimeDuration = useRecoilValue(MinimumWorkTimeDurationStateAtom)
//     const minimumInOutLogTimeDuration = useRecoilValue(MinimumInOutLogTimeDurationStateAtom)
//     const [minimumDistanceBetweenInOutLogs, setMinimumDistanceBetweenInOutLogs] = useRecoilState(MinimumDistanceBetweenInOutLogsStateAtom)
//     const [pins, setPins] = useRecoilState(PinsStateAtom)
//     const [timeFrame, setTimeFrame] = useRecoilState(TimeFrameAtom)
//     const [timeSpanDuration, setTimeSpanDuration] = useRecoilState(TimeSpanDurationAtom)
//     const serverDate = useRecoilValue(ServerDateState)
//     const [modalType, setModalType] = useRecoilState(ModalTypeAtom)
//     const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
//     const [modalParams, setModalParams] = useRecoilState(ModalParamsAtom);
//     // const [firstHeight, setFirstHeight] = useState(0)
//     const [firstTop, setFirstTop] = useState(0)
//     const [accessMove, setAccessMove] = useState(false)
//     const [hasTransition, setHasTransition] = useState(true)
//     const [workInstance, setWorkInstance] = useState<InOutLogDto[]>([])
//     const [isFirstInOutLog, setIsFirstInOutLog] = useState(false)
//     const [isLastInOutLog, setIsLastInOutLog] = useState(false)
//     // const [firstStep, setFirstStep] = useState(0)
//     const [lock, setLock] = useRecoilState(LockWorkPageAtom)
//     const [inOutTime, setInOutTime] = useState({
//         start: 0,
//         end: 0,
//     })
//     const [position, setPosition] = useState({
//         top: calculateTop(log.enter - log.date) ?? 0,
//         height: calculateHeight(((log.exit ?? (log.date + (24*60*60))) - log.enter)) ?? 0
//     })
//     const [beforeWork, setBeforeWork] = useState<any>({})
//     const [afterWork, setAfterWork] = useState<any>({})
//     const [startEndLimitation, setStartEndLimitation] = useState({
//         top: 0,
//         height: 0
//     })
//     const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom)
//     const [enterClockPickerIsOpen, setEnterclockPickerIsOpen] = useState(false)
//     const [exitClockPickerIsOpen, setExitclockPickerIsOpen] = useState(false)
//     const time_frame_ref = useRef(timeFrame)
//     time_frame_ref.current = timeFrame
//     const firstHeight = useRef(0)
//     const positionTop = useRef(position.top)
//     const firstStep = useRef(0)
//     const [accessOpenDescription, setAccessOpenDescription] = useState(false)
//     // const [workTime, setWorkTime] = useState({
//     //     start: 0,
//     //     end: 0,
//     // })

//     // const pixelToTimeSpan = (px: number) => {
//     //     if (!timeLog.enter) return 0
//     //     // const today = ServerDateToDay(ele.date);
//     //     const today = ele.date;
//     //     // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
//     //     const startHoursToTimeSpan = +(TimestampToTime(timeLog.enter).split(":")?.[0]) * 60 * 60
//     //     const startWorkSpan = today + startHoursToTimeSpan
//     //     const span = px * timeSpanDuration / workBoxSize
//     //     // return Math.floor((span + startWorkSpan ) / 60000) * 60
//     //     return Math.floor((span + startWorkSpan)/60)*60
//     // }

//     const pixelToTimeSpan = (px: number, spanDuration?: number, frame?: string[]) => {
//         if (!log.enter) return 0
//         // const today = ServerDateToDay(ele.date);
//         const today = log.date;
//         // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
//         // const startHoursToTimeSpan = +(TimestampToTime(log.date).split(":")?.[0]) * 60 * 60
//         const startHoursToTimeSpan = (timeFrame.length ? +((frame ?? timeFrame)?.[0]?.split(":")?.[0]) : 0) * 60 * 60
//         const startWorkSpan = today + startHoursToTimeSpan
//         const span = px * (spanDuration ?? timeSpanDuration) / workBoxSize
//         // return Math.floor((span + startWorkSpan ) / 60000) * 60
//         return Math.floor((span + startWorkSpan)/60)*60
//     }

    
//     const minHeigthCalculate = (duration?: number) => {
//         let minHeight = ((duration ?? minimumInOutLogTimeDuration) * workBoxSize / timeSpanDuration)
//         // if (log.idleTime && +log.idleTime > minimumWorkTimeDuration) minHeight = (log.idleTime * workBoxSize / timeSpanDuration)
//         return minHeight;
//     }
    
//     let accessRunningInterval = useRef(false)
//     let accessRunningRecessive = useRef(false)
//     const pointerDown = (e: any) => {
//         setWorkInstance(inOutLogs)
//         setHasTransition(false)
//         setAccessMove(true)
//         setFirstTop(position.top)
//         // setFirstHeight(position.height)
//         firstHeight.current = position.height
//         positionTop.current = position.top
//         const firstLog = [...inOutLogs].shift()
//         if (log.id === firstLog?.id) { // is first work
//             setIsFirstInOutLog(true)
//             accessRunningInterval.current = true
//             accessRunningRecessive.current = true
//         } else {
//             setIsFirstInOutLog(false)
//             accessRunningInterval.current = false
//             accessRunningRecessive.current = false

//         }
//         const lastLog = [...inOutLogs].pop()
//         if (log.id === lastLog?.id) { // is first work
//             setIsLastInOutLog(true)
//             accessRunningInterval.current = true
//             accessRunningRecessive.current = true
//         } else {
//             setIsLastInOutLog(false)
//             accessRunningInterval.current = false
//             accessRunningRecessive.current = false

//         }
//         if (!isMobile) {
//             firstStep.current = (e.clientY)
//             e.currentTarget.setPointerCapture(e.pointerId);
//         } else {
//             firstStep.current = (e.targetTouches?.[0].clientY)
//         }
//     }

    
//     const firstStart = useRef(0)
//     const firstEnd = useRef(0)
//     const pointerMove = (e: any, type: "top" | "down" | "both") => {
//         if (!accessMove || !log.enter) return;
//         if (lock) {
//             setAccessMove(false)
//             Toasts.lockWorks()
//             return;
//         }
//         // runningFunction.current = false
//         // const beforeRound = beforeWork ? Math.floor(beforeWork.end/60000)*60000 : 0
//         // const afterRound = afterWork ? Math.floor(afterWork.start/60000)*60000 : 0
//         // const serverRound = Math.floor(serverDate/60000)*60000
//         // const minTop = beforeWork ? calculateTop((beforeRound - ServerDateToDay(beforeRound))) : calculateTop(TimeToTimestamp(timeLog.enter, serverRound) - ServerDateToDay(serverRound))
//         // const maxHeight = afterWork ? calculateTop((afterRound - ServerDateToDay(afterRound))) : calculateTop(TimeToTimestamp(timeLog.exit, serverRound) - ServerDateToDay(serverRound)) // workBoxSize
//         let top = position.top;
//         let height = position.height;
//         const thisWorkIndex = inOutLogs.findIndex((e: any) => e.id === log.id)

//         // const beforeWork = inOutLogs[thisWorkIndex-1]
//         // const afterWork = inOutLogs[thisWorkIndex+1]
//         // const beforeRound = beforeWork ? (beforeWork.exit ?? (log.date + (24*60*60))) : 0
//         // const afterRound = afterWork ? afterWork.enter : 0

//         const beforeRound = beforeWork
//         const afterRound = afterWork
//         const serverRound = log.date


//         const minTop = beforeWork ? calculateTop((beforeRound - ServerDateToDay(beforeRound))) : calculateTop(TimeToTimestamp(timeFrame[0] ?? "00:00", log.date) - log.date)
        
//         const hasMission = inOutLogs.find((w: any) => w.type === "mission")
//         const isToday = ServerDateToDay(serverDate) <= log.date
//         const maxHeight = afterWork ? calculateTop((afterRound - ServerDateToDay(afterRound))) : calculateTop(TimeToTimestamp([...timeFrame].pop() ?? "24:00", log.date) - log.date)
//         const minHeight = minHeigthCalculate();

//         const maxTop = calculateTop((startEndLimitation.top - date))
//         const heightMin = minHeigthCalculate(firstEnd.current)
//         // const workBottom = e.target.getBoundingClientRect()?.bottom ?? 0
//         // const sensitiveTop = increaseSensitive.current.getBoundingClientRect()?.top ?? 0
//         // console.log(ServerDateToDay(timeLog.enter))
//         let clientY = e.clientY
//         if (isMobile) {
//             e.preventDefault()
//             clientY = e.targetTouches?.[0]?.clientY ?? firstStep
//         } 
//         const changedTop = clientY - firstStep.current
//         if (type === "top") {
//             const newTop = firstTop + changedTop
//             let newHeight = firstHeight.current - changedTop
//             // console.log(firstEnd.current) 
//             // console.log(newTop)
//             // console.log(minTop)
//             if (newTop <= minTop) {
//                 if (isFirstInOutLog) { // if is first log (inOut or work)
//                     accessRunningRecessive.current = true
//                     // console.log(-0)
//                     if (!accessRunningInterval.current) return
//                     // console.log(0)
//                     accessRunningInterval.current = false


//                     top = minTop;
//                     height = firstHeight.current + firstTop - minTop
//                     const start = pixelToTimeSpan(top)
//                     const end = pixelToTimeSpan(top + height)
                    
//                     if (!accessRunningRecessive.current) return;
//                     setInOutTime({
//                         start,
//                         end
//                     })
                    
//                     if (!accessRunningRecessive.current) return;
//                     setPosition({
//                         top: minTop,
//                         height: firstHeight.current + firstTop - minTop
//                     });

//                     const recessive = async () => {
//                         // console.log(accessRunningRecessive.current)
//                         if (!accessRunningRecessive.current) return;

//                         await new Promise((resolve, reject) => {
//                             setTimeout(() => {resolve(true)}, 200)
//                         })

//                         const startTimeFrame = +([...time_frame_ref.current].shift()?.split(":")?.[0] ?? 0)
//                         const endTimeFrame = +([...time_frame_ref.current].pop()?.split(":")?.[0] ?? 24)
//                         if (+startTimeFrame <= 0) return;
//                         const loopFrom = endTimeFrame
//                         const loopTo = +startTimeFrame - 1
//                         const time_frame: string[] = []
//                         for (let i=loopFrom; i>=loopTo; i--) {
//                             time_frame.unshift(`${String(i).padStart(2, "0")}:00`)
//                         }
//                         if (!accessRunningRecessive.current) return;
//                         setTimeFrame(time_frame)
//                         time_frame_ref.current = time_frame
//                         const spanDuration = (loopFrom - loopTo) * 60 * 60 // * 1000
//                         setTimeSpanDuration(spanDuration)
                        
//                         setTimeout(() => {
//                             const newEnter = pixelToTimeSpan(minTop, spanDuration, time_frame)
//                             const newTop = calculateTop(newEnter - log.date, spanDuration, time_frame)
//                             const heightPosition = calculateHeight(((log.exit ?? (log.date + (24*60*60))) - newEnter), spanDuration)
//                             top = newTop;
//                             height = heightPosition
//                             firstHeight.current = heightPosition - firstTop
//                             setPosition({
//                                 top: newTop,
//                                 // ...position,
//                                 height: heightPosition
//                             })
//                         }, 0)
//                         await recessive()
//                     }
//                     recessive()
//                 } else {
//                     top = minTop;
//                     height = firstHeight.current + firstTop - minTop
//                     const start = pixelToTimeSpan(top)
//                     const end = pixelToTimeSpan(top + height)
//                     setInOutTime({
//                         start,
//                         end
//                     })
//                     setPosition({
//                         top: minTop,
//                         height: firstHeight.current + firstTop - minTop
//                     });
//                 }
//             }
//             else if (newTop > maxTop) {
//                 accessRunningRecessive.current = false
//                 accessRunningInterval.current = true
//                 top = maxTop;
//                 height = minHeight
//                 const start = pixelToTimeSpan(top)
//                 // const end = pixelToTimeSpan(top + height)
//                 setInOutTime({
//                     ...inOutTime,
//                     start,
//                     // end
//                 })
//                 return setPosition({
//                     // ...position,
//                     top: maxTop,
//                     height: minHeigthCalculate(firstStart.current) 
//                 });
//                 // console.log(firstEnd.current)
//                 // return
//             }
//             else {
//                 accessRunningRecessive.current = false
//                 accessRunningInterval.current = true
//                 top = newTop;
//                 height = newHeight
//                 const start = pixelToTimeSpan(top)
//                 const end = pixelToTimeSpan(top + height)
//                 setInOutTime({
//                     start,
//                     end
//                 })
//                 return setPosition({
//                     top: newTop,
//                     height: newHeight
//                 })
//             }
//         } 
//         else if (type === "down") {
//             const newHeight = firstHeight.current + changedTop
            
//             if (newHeight >= workBoxSize - position.top - (workBoxSize - maxHeight)) {
//                 if (isLastInOutLog) {
//                     accessRunningRecessive.current = true
//                     // console.log(-0)
//                     if (!accessRunningInterval.current) return
//                     // console.log(0)
//                     accessRunningInterval.current = false
//                     height = workBoxSize - position.top - (workBoxSize - maxHeight)
//                     const start = pixelToTimeSpan(position.top)
//                     const end = pixelToTimeSpan(top + height)
//                     // console.log(-1)
//                     if (!accessRunningRecessive.current) return;
//                     // console.log(1)
//                     setInOutTime({
//                         ...inOutTime,
//                         start,
//                         end
//                     })
//                     // console.log(-2)
//                     if (!accessRunningRecessive.current) return;
//                     // console.log(2)
//                     setPosition({
//                         ...position,
//                         height: workBoxSize - position.top - (workBoxSize - maxHeight)
//                     });

//                     const recessive = async () => {
//                         if (!accessRunningRecessive.current) return;

//                         await new Promise((resolve, reject) => {
//                             setTimeout(() => {resolve(true)}, 200)
//                         })

//                         const endTimeFrame = +([...time_frame_ref.current].pop()?.split(":")?.[0] ?? 24)
//                         const startTimeFrame = +([...time_frame_ref.current].shift()?.split(":")?.[0] ?? 0)
//                         if (+endTimeFrame > 23) return;
//                         const loopFrom = startTimeFrame // timestampToHours(log.enter as number)
//                         const loopTo = +endTimeFrame + 1
//                         const time_frame: string[] = []
//                         for (let i=loopFrom; i<=loopTo; i++) {
//                             time_frame.push(`${String(i).padStart(2, "0")}:00`)
//                         }
//                         if (!accessRunningRecessive.current) return;
//                         setTimeFrame(time_frame)
//                         time_frame_ref.current = time_frame
//                         const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
//                         setTimeSpanDuration(spanDuration)
//                         setTimeout(() => {
//                             const newTop = calculateTop(log.enter - log.date, spanDuration) ?? 0 // calculateTop((ele.end - ele.start), spanDuration) ?? 0
//                             // const newHeight = calculateHeight((ele.end - ele.start), spanDuration) ?? 0
//                             // if (!accessRunningRecessive.current) return clearTimeout(timeOutRef.current);
//                             // console.log(5)
//                             // console.log(newTop, [...time_frame].pop())
//                             // console.log((newTop))
//                             firstStep.current -= (positionTop.current - newTop)
//                             setPosition({
//                                 top: newTop,
//                                 // ...position,
//                                 height: workBoxSize - newTop - (workBoxSize - maxHeight)
//                             })
//                             positionTop.current = newTop

//                         }, 0)
//                         await recessive()
//                     }
//                     recessive()
                    

//                 } else {
//                     height = workBoxSize - position.top - (workBoxSize - maxHeight)
//                     const start = pixelToTimeSpan(position.top)
//                     const end = pixelToTimeSpan(top + height)
//                     setInOutTime({
//                         ...inOutTime,
//                         start,
//                         end
//                     })
//                     return setPosition({
//                         ...position,
//                         height: workBoxSize - position.top - (workBoxSize - maxHeight)
//                     });
//                 }
//             }
//             //  else if (newHeight <= minHeight) {
//             //     accessRunningRecessive.current = false
//             //     accessRunningInterval.current = true
//             //     height = minHeight
//             //     const start = pixelToTimeSpan(position.top)
//             //     const end = pixelToTimeSpan(top + height)
//             //     setInOutTime({
//             //         ...inOutTime,
//             //         start,
//             //         end
//             //     })
//             //     return setPosition({
//             //         ...position,
//             //         height: minHeight
//             //     });

//             // }
//             else if (newHeight <= heightMin) {
//                 accessRunningRecessive.current = false
//                 accessRunningInterval.current = true
//                 height = minHeight
//                 const start = pixelToTimeSpan(position.top)
//                 const end = pixelToTimeSpan(top + heightMin)
//                 setInOutTime({
//                     ...inOutTime,
//                     start,
//                     end
//                 })
//                 return setPosition({
//                     ...position,
//                     height: heightMin
//                 });
//             }
//              else {
//                 accessRunningRecessive.current = false
//                 accessRunningInterval.current = true
//                 height = newHeight
//                 const start = pixelToTimeSpan(position.top)
//                 const end = pixelToTimeSpan(top + height)
//                 setInOutTime({
//                     ...inOutTime,
//                     start,
//                     end
//                 })
//                 return setPosition({
//                     ...position,
//                     height: newHeight
//                 })
//             }

//         }
        
//     }

//     const pointerUp = (e: any, type: "top" | "down") => {
//         if (!accessMove) return;
//         if (!isMobile) {
//             e.currentTarget.releasePointerCapture(e.pointerId);
//         }
//         accessRunningInterval.current = false
//         accessRunningRecessive.current = false
//         setHasTransition(true)
//         const cloneInOutLogs = [...inOutLogs]
//         const findIndex = cloneInOutLogs.findIndex((item: any) => item.id === log.id)
//         const splitInOutLog = {...cloneInOutLogs.splice(findIndex, 1)[0]}
//         const enter = pixelToTimeSpan(position.top)
//         const exit = log.exit ? pixelToTimeSpan(position.top + position.height) : null
//         splitInOutLog.enter = enter
//         splitInOutLog.exit = exit
//         cloneInOutLogs.splice(findIndex, 0, splitInOutLog)
//         setInOutLogs(cloneInOutLogs)
//         setAccessMove(false)
        
//         const newInOutLog = cloneInOutLogs.find((w: any) => w.id === log.id)
//         const oldInOutLog: any = workInstance.find((w: any) => w.id === log.id)
//         if (((newInOutLog?.enter !== oldInOutLog.start) || (newInOutLog?.enter !== oldInOutLog.end)) && (!accessOpenDescription)) {
//             updateInOutLogHandler({enter, exit})
//         } else {
//             if (type === "top") setEnterclockPickerIsOpen(true)
//             else setExitclockPickerIsOpen(true)
//         } 
//     }

//     const updateInOutLogHandler = ({enter, exit}: any) => {
//         // const newInOutLog = cloneInOutLogs.find((w: any) => w.id === log.id)
//         // if (newInOutLog.type === "temporary") return; 
//         // console.log(enter, exit)
//         updateInOutLog(log.id, {
//             enter,
//             exit,
//         })
//             .then(res => {
//                 const { data } = res
//                 const cloneInOutLogs = [...inOutLogs]
//                 const findWorkIndex = cloneInOutLogs.findIndex(w => w.id === data.id)
//                 cloneInOutLogs.splice(findWorkIndex, 1)
//                 cloneInOutLogs.splice(findWorkIndex, 0, data)
//                 const sortWorks = cloneInOutLogs.sort((a, b) => a.enter - b.enter)
//                 setInOutLogs(sortWorks)

//                 // const existMissionOrRemote = !!sortWorks.find((w: any) => w.type === "mission" || w.type === "remote")
//                 // if (existMissionOrRemote) {
//                 //     generateTimeFrame({
//                 //         from: 0,
//                 //         to: 24
//                 //     })
//                 // } else {
//                 //     const startTime = timestampToHours([...sortWorks].shift()?.enter ?? 0)
//                 //     const endTime = timestampToHours(([...sortWorks].pop()?.exit ?? (dailyLog.date + oneDay)), dailyLog.date)
//                 //     generateTimeFrame({
//                 //         from: startTime,
//                 //         to: endTime < 24 ? endTime + 1 : endTime
//                 //     })
//                 // }
//                 generateTimeFrame(sortWorks, works, date, pins)
//                 setDailyLog({
//                     ...dailyLog,
//                     finalized: 0
//                 })
//                 // setTimeLog({
//                 //     ...timeLog,
//                 //     finalized: 0
//                 // })
//             })
//             .catch(err => {
//                 console.log(err)
//                 setInOutLogs(workInstance)

//                 // workInstance && setWorks(workInstance)
//             })
//     }

//     useEffect(() => {
//         const topPosition = calculateTop(log.enter - log.date)
//         const heightPosition = calculateHeight(((log.exit ?? (log.date + (24*60*60))) - log.enter))
        
//         // console.log(log.id, log.enter)
//         setPosition({
//             top: topPosition,
//             height: heightPosition, 
//         })
//         const start = Math.ceil(pixelToTimeSpan(topPosition))
//         const end = Math.ceil(pixelToTimeSpan(topPosition + heightPosition))
//         setInOutTime({
//             start,
//             end
//         })
//     }, [workBoxSize, timeFrame, inOutLogs, timeSpanDuration])

//     const changeEnterHandler = (value: {hours: string, minutes: string}, name: string) => {
//         if (lock) return Toasts.lockWorks()
//         const timeValue = {
//             [name]: TimeToTimestamp(`${value.hours}:${value.minutes}`, log.date)
//         }
//         updateInOutLogHandler(timeValue)
//     }

//     const changeExitHandler = (value: {hours: string, minutes: string}, name: string) => {
//         if (lock) return Toasts.lockWorks()
//         const timeValue = {
//             [name]: TimeToTimestamp(`${value.hours}:${value.minutes}`, ((log.date + (!(+value.hours + +value.minutes) ? oneDay : 0))))
//         }
//         updateInOutLogHandler(timeValue)
//     }

//     const deleteInOutLogHandler = () => {
//         setIsModalOpen(true)
//         setModalType("DeleteInOutLog")
//         setModalParams(log.id)
//     }


//     useEffect(() => {
//         const insideWork = works.filter(iw => iw.inOutLogId === log.id) ?? []
//         const outsideWork = works.filter(iw => !iw.inOutLogId) ?? []
        
//         const insideWorkDetails = insideWork.map(iwd => ({start: iwd.start, end: iwd.end, type: "insideWork"}))
//         const outsideWorkDetails = outsideWork.map(iwd => ({start: iwd.start, end: iwd.end, type: "outsideWork"}))
//         const inOutLogsDetails = inOutLogs.map(iwd => ({start: iwd.enter, end: (iwd.exit ?? (date + oneDay)), type: "inOutLogs"}))
//         const allLogs = [...outsideWorkDetails, ...inOutLogsDetails].sort((a, b) => a.start - b.start)
//         // console.log(allLogs)
//         // console.log(log)
//         const inOutIndex = allLogs.findIndex(l => l.start === log.enter)
//         const beforeLog = allLogs[inOutIndex-1]
//         const afterLog = allLogs[inOutIndex+1]
//         const startFrameSpan = (timeFrame.length ? (+timeFrame?.[0]?.split(":")?.[0]) : 0) * 60 * 60
//         const minTime = beforeLog ? beforeLog.end + (beforeLog.type === "inOutLogs" ? minimumDistanceBetweenInOutLogs : 0) : 0 // (date + startFrameSpan)
//         const maxTime = afterLog ? afterLog.start - (afterLog.type === "inOutLogs" ? minimumDistanceBetweenInOutLogs : 0) : 0
//         setBeforeWork(minTime)
//         setAfterWork(maxTime)

//         const firstWork: number = [...insideWorkDetails].shift()?.start ?? ((log.exit ?? (date+oneDay)) - minimumInOutLogTimeDuration)
//         const lastWork: number = [...insideWorkDetails].pop()?.end ?? (log.enter + minimumInOutLogTimeDuration)
//         setStartEndLimitation({
//            top: firstWork,
//            height: lastWork
//         })
//         // const sumOfWorks =  insideWorkDetails.reduce((a, b) => a + (b.end - b.start) , 0)
//         firstStart.current = (lastWork - firstWork) + ((log.exit ?? (date+oneDay)) - lastWork)
//         firstEnd.current = (lastWork - firstWork) + (firstWork - log.enter)
//     }, [inOutLogs, works]) 

//     // console.log(startEndLimitation)

//     return (
//         <>
//             {log.enter &&
//                 <div 
//                     onMouseDown={() => {
//                         if (isMobile) return;
//                         setAccessOpenDescription(true)
//                     }}
//                     onMouseMove={() => {
//                         if (isMobile) return;
//                         setAccessOpenDescription(false)
//                     }}
//                     onTouchStart={() => {
//                         if (!isMobile) return;
//                         setAccessOpenDescription(true)
//                     }}
//                     onTouchMove={() => {
//                         if (!isMobile) return;
//                         setAccessOpenDescription(false)
//                     }}


               
//             // onMouseUp={() => {
//             //     if (!accessOpenDescription || isMobile) return;
//             //     if (selectedWork === ele.id) setSelectedWork(0)
//             //     else setSelectedWork(ele.id)
//             // }}
//             onMouseLeave={() => {
//                 if (isMobile) return;
//                 // if (accessOpenDescription) setSelectedWork(ele.id)
//             }}
//             // onTouchMove={() => {
//             //     if (!isMobile) return;
//             //     setAccessOpenDescription(false)
//             // }}
//             onTouchEnd={() => {
//                 if (!isMobile) return;
//                 // if (accessOpenDescription) setSelectedWork(ele.id)
//             }}



//                     onClick={(e) => {
//                         // setSelectedWork(ele.id)
//                         e.preventDefault()
//                         e.stopPropagation()
//                     }}
//                     className={`${styles.presenceArea} w-full bg-gray-900 bg-opacity-10 absolute left-0 flex flex-col items-center justify-between`}
//                     style={position}
//                 >
//                     <div className='w-full h-0 ring-1 ring-green-500 flex items-center justify-between select-none'>
//                         <ClockPicker 
//                             name="enter"
//                             buttonMode="none"
//                             value={TimestampToTime(inOutTime.start)}
//                             isOpen={enterClockPickerIsOpen}
//                             setIsOpen={setEnterclockPickerIsOpen}
//                             customButton={
//                                 <div 
//                                     onPointerDown={!isMobile ? pointerDown : () => {}}
//                                     onPointerMove={!isMobile ? (e: any) => pointerMove(e, "top") : () => {}}
//                                     onPointerUp={!isMobile ? (e) => pointerUp(e, "top") : () => {}}
//                                     onPointerLeave={!isMobile ? (e) => pointerUp(e, "top") : () => {}}
//                                     onTouchStart={isMobile ? pointerDown : () => {}} // For touch devices
//                                     onTouchMove={isMobile ? (e: any) => pointerMove(e, "top") : () => {}}   // For touch devices
//                                     onTouchEnd={isMobile ? (e) => pointerUp(e, "top") : () => {}}
//                                     onContextMenu={(e) => {
//                                         e.preventDefault()
//                                         console.log("first")
//                                     }}
//                                     className='w-[30px] h-[20px] grid place-content-center text-[0.6rem] rounded-full translate-x-[31px] bg-green-500 hover:bg-green-600 transition-color duration-100 ring-1 ring-green-500 cursor-move touch-none'
//                                 >
//                                     {TimestampToTime(inOutTime.start)}
//                                 </div>
//                             }
//                             picker={{
//                                 title: "زمان ورود",
//                                 onAccept: changeEnterHandler,
//                             }}
//                         />
                        
//                         <Button 
//                             icon='trash3'
//                             iconWidth="1.1rem"
//                             iconHeight="1.1rem"
//                             color={theme === "dark" ? "dark" : "light"}
//                             onClick={deleteInOutLogHandler}
//                             classNames={{
//                                 container: '!w-[30px] !h-[20px] !grid place-content-center text-[0.6rem] rounded-full -translate-x-[31px] ring-1 ring-green-500 !transition-color duration-100 z-10'
//                             }}
//                         />
//                         {/* <span /> */}
//                     </div>
//                     {log.exit &&
//                         <div className='w-full h-0 ring-1 ring-red-500 flex items-center justify-end select-none'>
//                             <ClockPicker 
//                                 name="exit"
//                                 buttonMode="none"
//                                 value={TimestampToTime(inOutTime.end, date)}
//                                 isOpen={exitClockPickerIsOpen}
//                                 setIsOpen={setExitclockPickerIsOpen}
//                                 customButton={
//                                     <div 
//                                         onPointerDown={!isMobile ? pointerDown : () => {}}
//                                         onPointerMove={!isMobile ? (e: any) => pointerMove(e, "down") : () => {}}
//                                         onPointerUp={!isMobile ? (e) => pointerUp(e, "down") : () => {}}
//                                         onPointerLeave={!isMobile ? (e) => pointerUp(e, "down") : () => {}}
//                                         onTouchStart={isMobile ? pointerDown : () => {}} // For touch devices
//                                         onTouchMove={isMobile ? (e: any) => pointerMove(e, "down") : () => {}}   // For touch devices
//                                         onTouchEnd={isMobile ? (e) => pointerUp(e, "down") : () => {}}
//                                         className='w-[30px] h-[20px] grid place-content-center text-[0.6rem] rounded-full bg-red-500 hover:bg-red-600 transition-color duration-100 text-gray-900 -translate-x-[31px] cursor-move touch-none'
//                                         // className='w-0 h-0 border-l-[15px] border-r-[15px] border-b-[15px] border-l-transparent border-r-transparent border-b-red-500 flex items-start justify-center -translate-x-[31px]'
//                                     >
//                                         {TimestampToTime(inOutTime.end, date)}
//                                     </div>
//                                 }
//                                 picker={{
//                                     title: "زمان خروج",
//                                     onAccept: changeExitHandler,
//                                 }}
//                             />
//                         </div>
//                     }
//                 </div>
//             }
//             {/* {log.exit &&
//                 <div 
//                     className='h-0 w-[calc(100%+62px)] ring-1 ring-red-500 absolute translate-x-[31px] flex items-center justify-end' 
//                     style={{
//                         top: 500
//                     }}
//                 >
//                     <div className='w-[30px] h-[30px] grid place-content-center text-[0.55rem] rounded-full bg-red-600 text-gray-50 -translate-x-1'>
//                         {TimestampToTime(log.exit)}
//                     </div>
//                 </div>
//             } */}
//         </>
//     )
// }

// const Work = ({
//     ele,
//     workBoxSize,
//     works,
//     setWorks,
//     selectedWork,
//     setSelectedWork,
//     lockTost,
//     increaseSensitive,
//     increaseExitTimeInterval,
//     increaseExitTime,
//     inOutLogs
// }: any) => {

//     const minimumWorkTimeDuration = useRecoilValue(MinimumWorkTimeDurationStateAtom)
//     const [timeFrame, setTimeFrame] = useRecoilState(TimeFrameAtom)
//     const [timeSpanDuration, setTimeSpanDuration] = useRecoilState(TimeSpanDurationAtom)
//     const [lock, setLock] = useRecoilState(LockWorkPageAtom)
//     const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom)
//     const [showAggregationTime, setShowAggregationTime] = useRecoilState(ShowAggregationTimeStateAtom)
//     const time_frame_ref = useRef(timeFrame)
//     time_frame_ref.current = timeFrame
//     const calculateTop = (span: number, spanDuration?: number, frame?: string[]) => {
//         // console.log(span, ele.id)
//         // // if (!timeLog.enter) return 0
//         // // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
//         // // const startHoursToTimeSpan = +(TimestampToTime(timeLog.enter).split(":")?.[0]) * 60 * 60
//         // // const distanceFromZeroTime = span - startHoursToTimeSpan;
//         // const px = span * workBoxSize / (spanDuration ?? timeSpanDuration);
//         // return px


//         const startHoursToTimeSpan = (timeFrame.length ? (+(frame ?? timeFrame)?.[0]?.split(":")?.[0]) : 0) * 60 * 60
//         const distanceFromZeroTime = span - startHoursToTimeSpan;
//         const px = distanceFromZeroTime * workBoxSize / (spanDuration ?? timeSpanDuration);
//         return px
//     }

//     const calculateHeight = (span: number, spanDuration?: number) => {
//         const px = span * workBoxSize / (spanDuration ?? timeSpanDuration)
//         return px
//     }

//     const pixelToTimeSpan = (px: number, spanDuration?: number, frame?: string[]) => {
//         // if (!timeLog.enter) return 0
//         // // const today = ServerDateToDay(ele.date);
//         // const today = ele.date;
//         // // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
//         // const startHoursToTimeSpan = +(TimestampToTime(timeLog.enter).split(":")?.[0]) * 60 * 60
//         // const startWorkSpan = today + startHoursToTimeSpan
//         // const span = px * timeSpanDuration / workBoxSize
//         // // return Math.floor((span + startWorkSpan ) / 60000) * 60
//         // return Math.floor((span + startWorkSpan)/60)*60

//         const today = ele.date;
//         // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
//         // const startHoursToTimeSpan = +(TimestampToTime(log.date).split(":")?.[0]) * 60 * 60
//         const startHoursToTimeSpan = (timeFrame.length ? +((frame ?? timeFrame)?.[0]?.split(":")?.[0]) : 0) * 60 * 60
//         const startWorkSpan = today + startHoursToTimeSpan
//         const span = px * (spanDuration ?? timeSpanDuration) / workBoxSize
//         // return Math.floor((span + startWorkSpan ) / 60000) * 60
//         return Math.floor((span + startWorkSpan)/60)*60
//     }

//     const minHeigthCalculate = () => {
//         let minHeight = (minimumWorkTimeDuration * workBoxSize / timeSpanDuration)
//         if (ele.idleTime && +ele.idleTime > minimumWorkTimeDuration) minHeight = (ele.idleTime * workBoxSize / timeSpanDuration)
//         return minHeight;
//     }
    
//     const serverDate = useRecoilValue(ServerDateState)
//     const [timeLog, setTimeLog] = useRecoilState(TransmissionTimeAtom)
//     const [date, setDate] = useRecoilState(DateState)
//     const [accessOpenDescription, setAccessOpenDescription] = useState(false)
//     const [accessMove, setAccessMove] = useState(false)
//     const [firstStep, setFirstStep] = useState(0)
//     const [firstTop, setFirstTop] = useState(0)
//     const [firstHeight, setFirstHeight] = useState(0)
//     const [hasTransition, setHasTransition] = useState(true)
//     const [user, setUser] = useRecoilState(UserDataState)
//     const [inOutLog, setInOutLog] = useState<any>(null)
//     const [workTime, setWorkTime] = useState({
//         start: 0,
//         end: 0,
//     })
//     const [workInstance, setWorkInstance] = useState([])
//     const [position, setPosition] = useState({
//         top: calculateTop(ele.start - ele.date) ?? 0,
//         height: calculateHeight((ele.end - ele.start)) ?? 0
//     })
//     const [isLastWork, setIsLastWork] = useState(false)
//     const [beforeWork, setBeforeWork] = useState<any>({})
//     const [afterWork, setAfterWork] = useState<any>({})
//     // let workInstance: any;

//     let accessRunningInterval = useRef(false)
//     let accessRunningRecessive = useRef(false)
//     const pointerDown = (e: any) => {
//         setWorkInstance(works)
//         setHasTransition(false)
//         setAccessMove(true)
//         setFirstTop(position.top)
//         setFirstHeight(position.height)
//         const lastWork = [...works].pop()
//         // if (ele.id === lastWork.id) {
//         //     setIsLastWork(true)
//         //     accessRunningInterval.current = true
//         //     accessRunningRecessive.current = true
//         // }
//         if (!isMobile) {
//             setFirstStep(e.clientY)
//             e.currentTarget.setPointerCapture(e.pointerId);
//         } else {
//             setFirstStep(e.targetTouches?.[0].clientY)
//         }
//     }

//     let timeOutRef = useRef<any>(null)
//     const pointerMove = (e: any, type: "top" | "down" | "both") => {
//         // console.log(e)
//         if (!accessMove) return;
//         if (lock) {
//             setAccessMove(false)
//             Toasts.lockWorks()
//             return;
//         }
//         // runningFunction.current = false
//         let top = position.top;
//         let height = position.height;
//         // const thisWorkIndex = works.findIndex((e: any) => e.id === ele.id)
//         // const beforeWork = works[thisWorkIndex-1]
//         // const afterWork = works[thisWorkIndex+1]
//         // const beforeRound = beforeWork ? Math.floor(beforeWork.end/60000)*60000 : 0
//         // const afterRound = afterWork ? Math.floor(afterWork.start/60000)*60000 : 0
//         // const serverRound = Math.floor(serverDate/60000)*60000
//         const beforeRound = beforeWork
//         const afterRound = afterWork 
//         const serverRound = ele.date
//         // const minTop = beforeWork ? calculateTop((beforeRound - ServerDateToDay(beforeRound))) : calculateTop(TimeToTimestamp(timeLog.enter, serverRound) - ServerDateToDay(serverRound))
//         // const maxHeight = afterWork ? calculateTop((afterRound - ServerDateToDay(afterRound))) : calculateTop(TimeToTimestamp(timeLog.exit, serverRound) - ServerDateToDay(serverRound)) // workBoxSize
//         const minTop = beforeWork ? calculateTop((beforeRound - ServerDateToDay(date))) : calculateTop(28000)
        
//         const hasMission = works.find((w: any) => w.type === "mission")
//         const isToday = ServerDateToDay(serverDate) <= date
//         const maxHeight = afterRound ? calculateTop((afterRound - ServerDateToDay(date))) : timeLog.exit ? calculateTop(timeLog.exit - ele.date) : hasMission ? calculateTop((date + (24*60*60)) - ele.date) : calculateTop(TimeToTimestamp([...timeFrame].pop() ?? "00:00", date) - ele.date)
//         const minHeight = minHeigthCalculate();
//         // const workBottom = e.target.getBoundingClientRect()?.bottom ?? 0
//         // const sensitiveTop = increaseSensitive.current.getBoundingClientRect()?.top ?? 0
//         // console.log(afterWork)
//         let clientY = e.clientY
//         if (isMobile) {
//             e.preventDefault()
//             clientY = e.targetTouches?.[0]?.clientY ?? firstStep
//         } 
//         const changedTop = clientY - firstStep
//         if (type === "top") {
//             const newTop = firstTop + changedTop
//             const newHeight = firstHeight - changedTop
//             if (newTop <= minTop) {
//                 top = minTop;
//                 height = firstHeight + firstTop - minTop
//                 const start = pixelToTimeSpan(top)
//                 const end = pixelToTimeSpan(top + height)
//                 setWorkTime({
//                     start,
//                     end
//                 })
//                 return setPosition({
//                     top: minTop,
//                     height: firstHeight + firstTop - minTop
//                 });
//             } else if (newHeight <= minHeight) {
//                 top = firstTop+firstHeight-minHeight;
//                 height = minHeight
//                 const start = pixelToTimeSpan(top)
//                 const end = pixelToTimeSpan(top + height)
//                 setWorkTime({
//                     start,
//                     end
//                 })
//                 return setPosition({
//                     top: firstTop+firstHeight-minHeight,
//                     height: minHeight
//                 });
//             } else {
//                 top = newTop;
//                 height = newHeight
//                 const start = pixelToTimeSpan(top)
//                 const end = pixelToTimeSpan(top + height)
//                 setWorkTime({
//                     start,
//                     end
//                 })
//                 return setPosition({
//                     top: newTop,
//                     height: newHeight
//                 })
//             }
            
//         } else if (type === "down") {
//             const newHeight = firstHeight + changedTop
//             if (newHeight >= workBoxSize - position.top - (workBoxSize - maxHeight)) {
//                 height = workBoxSize - position.top - (workBoxSize - maxHeight)
//                 const start = pixelToTimeSpan(position.top)
//                 const end = pixelToTimeSpan(top + height)
//                 setWorkTime({
//                     ...workTime,
//                     start,
//                     end
//                 })
//                 return setPosition({
//                     ...position,
//                     height: workBoxSize - position.top - (workBoxSize - maxHeight)
//                 });
//             } else if (position.height <= minHeight && newHeight <= minHeight) {
//                 accessRunningRecessive.current = false
//                 accessRunningInterval.current = true
//                 height = minHeight
//                 const start = pixelToTimeSpan(position.top)
//                 const end = pixelToTimeSpan(top + height)
//                 setWorkTime({
//                     ...workTime,
//                     start,
//                     end
//                 })
//                 return setPosition({
//                     ...position,
//                     height: minHeight
//                 });

//             } else {
//                 accessRunningRecessive.current = false
//                 accessRunningInterval.current = true
//                 height = newHeight
//                 const start = pixelToTimeSpan(position.top)
//                 const end = pixelToTimeSpan(top + height)
//                 setWorkTime({
//                     ...workTime,
//                     start,
//                     end
//                 })
//                 return setPosition({
//                     ...position,
//                     height: newHeight
//                 })
//             }

//         } else if (type === "both") {
//             const newTop = firstTop + changedTop
//             if (newTop <= minTop) {
//                 accessRunningRecessive.current = false
//                 accessRunningInterval.current = true
//                 top = minTop
//                 const start = pixelToTimeSpan(top)
//                 const end = pixelToTimeSpan(top + position.height)
//                 setWorkTime({
//                     ...workTime,
//                     start,
//                     end
//                 })
//                 return setPosition({
//                     ...position,
//                     top: minTop,
//                 })
//             } 
//             else if (newTop >= workBoxSize - position.height - (workBoxSize - maxHeight)) {
//                 top = workBoxSize - position.height - (workBoxSize - maxHeight)
//                 const start = pixelToTimeSpan(top)
//                 const end = pixelToTimeSpan(top + position.height)
//                 setWorkTime({
//                     ...workTime,
//                     start,
//                     end
//                 })
//                 return setPosition({
//                     ...position,
//                     top: workBoxSize - position.height - (workBoxSize - maxHeight),
//                 })
//             } 
//             else {
//                 accessRunningRecessive.current = false
//                 accessRunningInterval.current = true
//                 top = newTop
//                 const start = pixelToTimeSpan(top)
//                 const end = pixelToTimeSpan(top + position.height)
//                 setWorkTime({
//                     ...workTime,
//                     start,
//                     end
//                 })
//                 return setPosition({
//                     ...position,
//                     top: newTop,
//                 })
//             }

//         }
        
//     }

//     const updateWorkHandler = (cloneWorks: any) => {
//         const newWork = cloneWorks.find((w: any) => w.id === ele.id)
//         if (newWork.type === "temporary") return; 
//         updateWork(ele.id, {
//             start: newWork.start,
//             end: newWork.end,
//         })
//             .then(res => {
//                 const { data } = res
//                 const cloneWorks = [...works]
//                 const findWorkIndex = cloneWorks.findIndex(w => w.id === data.id)
//                 cloneWorks.splice(findWorkIndex, 1)
//                 cloneWorks.splice(findWorkIndex, 0, data)
//                 const sortWorks = cloneWorks.sort((a, b) => a.start - b.start)
//                 setWorks(sortWorks)
//                 setDailyLog({
//                     ...dailyLog,
//                     finalized: 0
//                 })
//             })
//             .catch(err => {
//                 console.log(err)
//                 workInstance && setWorks(workInstance)
//             })
//     }

//     const pointerUp = (e: any) => {
//         if (!accessMove) return;
//         if (!isMobile) {
//             e.currentTarget.releasePointerCapture(e.pointerId);
//         }
//         accessRunningInterval.current = false
//         accessRunningRecessive.current = false
//         setHasTransition(true)
//         const cloneWorks = [...works]
//         const findIndex = cloneWorks.findIndex((item: any) => item.id === ele.id)
//         const splitWorks = {...cloneWorks.splice(findIndex, 1)[0]}
//         splitWorks.start = pixelToTimeSpan(position.top)
//         splitWorks.end = pixelToTimeSpan(position.top + position.height)
//         cloneWorks.splice(findIndex, 0, splitWorks)
//         setWorks(cloneWorks)
//         setAccessMove(false)
        
//         const newWork = cloneWorks.find((w: any) => w.id === ele.id)
//         const oldWork: any = workInstance.find((w: any) => w.id === ele.id)
//         if (((newWork.start !== oldWork.start) || (newWork.end !== oldWork.end)) && (!accessOpenDescription)) {
//             updateWorkHandler(cloneWorks)
//         }



        
//         // const exitTime = findExitTime({
//         //     exit: timeLog.exit,
//         //     works: cloneWorks,
//         //     serverDate,
//         //     date,
//         //     logicExit: user.logicExit
//         // })
//         // const loopFrom = timestampToHours(timeLog.enter as number)
//         // const loopTo = +exitTime < 24 ? +exitTime+1 : +exitTime
//         // const time_frame: string[] = []
//         // for (let i=loopFrom; i<=loopTo; i++) {
//         //     time_frame.push(`${String(i).padStart(2, "0")}:00`)
//         // }
//         // // setTimeFrame(time_frame)
//         // const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
//         // // setTimeSpanDuration(spanDuration)
//     }

//     useEffect(() => {
//         const topPosition = calculateTop(ele.start - ele.date)
//         const heightPosition = calculateHeight((ele.end - ele.start))
//         setPosition({
//             top: topPosition,
//             height: heightPosition, 
//         })
//         const start = Math.ceil(pixelToTimeSpan(topPosition))
//         const end = Math.ceil(pixelToTimeSpan(topPosition + heightPosition))
//         setWorkTime({
//             start,
//             end
//         })
//     }, [workBoxSize, timeLog, timeFrame, works])

//     useEffect(() => {
//         // console.log(inOutLogs)
//         // console.log(inOutLogs)
//         const isInCompany = ele.inOutLogId
//         if (isInCompany) {
//             const {id, date, enter, exit} = inOutLogs.find((w: any) => w.id === ele.inOutLogId)
//             const inWorks = works.filter((w: any) => w.inOutLogId && w.inOutLogId === id)
//             setInOutLog({id, date, enter, exit})
//             const sortWorks = [...inWorks].sort((a: any, b: any) => a.start - b.start)
//             const thisWorkIndex = sortWorks.findIndex((e: any) => e.id === ele.id)
                
            
//             const beforeWork = sortWorks[thisWorkIndex-1]
//             const afterWork = sortWorks[thisWorkIndex+1]
//             const minTime = beforeWork ? (beforeWork.end > enter ? beforeWork.end : enter) : enter // enter ? enter : beforeWork ? beforeWork.end : 0
//             const maxTime = afterWork ? (afterWork.start < (exit ?? (date + oneDay)) ? afterWork.start : exit) : exit // exit ? exit : afterWork ? afterWork.start : 0
//             setBeforeWork(minTime)
//             setAfterWork(maxTime)
//         } else {
//             const outOfCompony = works.filter((w: any) => !w.inOutLogId)
//             const outWorks = outOfCompony.map((w: any) => ({
//                 start: w.start,
//                 end: w.end,
//                 type: "work"
//             }))
//             const inOuts = inOutLogs.map((w: any) => ({
//                 start: w.enter,
//                 end: w.exit,
//                 type: "inOut"
//             }))
//             const activityDetails = [...outWorks, ...inOuts].sort((a: any, b: any) => a.start - b.start)
//             const findActivityIndex: any = [...activityDetails].findIndex((a: any) => a.start === ele.start)
//             const beforeWork = activityDetails[findActivityIndex-1]
//             const afterWork = activityDetails[findActivityIndex+1]
//             const minTime = beforeWork ? beforeWork.end : date
//             const maxTime = afterWork ? afterWork.start : 0
//             setBeforeWork(minTime)
//             setAfterWork(maxTime)
//         }
//     }, [inOutLogs, works])
    
//     const bothRef = useRef<any>(null)

//     const colors = {
//         emerald: "0 0 3px #059669,0 0 8px #059669,0 0 20px #059669",
//         sky: "0 0 3px #0284c7,0 0 8px #0284c7,0 0 20px #0284c7",
//         purple: "0 0 3px #9333ea,0 0 8px #9333ea,0 0 20px #9333ea",
//         yellow: "0 0 3px #ca8a04,0 0 8px #ca8a04,0 0 20px #ca8a04",
//         rose: "0 0 3px #e11d48,0 0 8px #e11d48,0 0 20px #e11d48",
//     }

// //   useEffect(() => {
// //     // const handleTouchMove = (event) => {
// //     //   // Prevent the default behavior (like pull-to-refresh) only on this element
// //     //   event.preventDefault();

// //     //   const touch = event.touches[0];
// //     //   setPosition({
// //     //     x: touch.clientX,
// //     //     y: touch.clientY,
// //     //   });
// //     // };

// //     // Add a non-passive touchmove event listener to the specific div
// //     const divElement = bothRef.current;
// //     divElement.addEventListener('touchmove', (e: any) => pointerMove(e, "both"), { passive: false });

// //     // Cleanup the event listener when component unmounts
// //     return () => {
// //       divElement.removeEventListener('touchmove', (e: any) => pointerMove(e, "both"));
// //     };
// //   }, [bothRef.current]);

//     return (
//         <div
//             className={`${selectedWork === ele.id ? styles.progress : ""} text-gray-700 w-full absolute border ${ele.confirmed ? `bg-${workTheme[ele.type as "work"]}-500 border-${workTheme[ele.type as "work"]}-700 ring-${workTheme[ele.type as "work"]}-700` : `bg-rose-500 bg-opacity-20 border-rose-700 ring-rose-700`} rounded-md flex flex-col items-start justify-between transition-[box-shadow] duration-300 ${hasTransition ? "" : ""}`}
//             style={{
//                 top: position.top ?? 0,
//                 height: position.height ?? 0,
//             }}
//         >
//             <div 
//                 className={`h-full absolute bg-gray-900 bg-opacity-10 border-e border-${workTheme[ele.type as "work"]}-700`}
//                 style={{
//                     width: `${(ele.idleTime / (ele.end - ele.start)) * 100}%`
//                 }}
//             >

//             </div>
//             <div 
//                 onMouseDown={() => {
//                     if (isMobile) return;
//                     setAccessOpenDescription(true)
//                 }}
//                 onMouseMove={() => {
//                     if (isMobile) return;
//                     setAccessOpenDescription(false)
//                 }}
//                 onMouseUp={() => {
//                     if (!accessOpenDescription || isMobile) return;
//                     if (selectedWork === ele.id) setSelectedWork(0)
//                     else {
//                         setSelectedWork(ele.id)
//                         setShowAggregationTime(false)
//                     }
//                 }}
//                 onMouseLeave={() => {
//                     if (isMobile) return;
//                     if (accessOpenDescription) {
//                         setSelectedWork(ele.id)
//                         setShowAggregationTime(false)
//                     } 
//                 }}
//                 onTouchStart={() => {
//                     if (!isMobile) return;
//                     setAccessOpenDescription(true)
//                 }}
//                 onTouchMove={() => {
//                     if (!isMobile) return;
//                     setAccessOpenDescription(false)
//                 }}
//                 onTouchEnd={() => {
//                     if (!isMobile) return;
//                     if (accessOpenDescription) {
//                         setSelectedWork(ele.id)
//                         setShowAggregationTime(false)
//                     } 
//                 }}
//                 onClick={(e) => {
//                     // setSelectedWork(ele.id)
//                     e.preventDefault()
//                     e.stopPropagation()
//                 }}
//                 className='w-full h-full absolute flex flex-col'
//             >
//                 <div
//                     className="w-full h-1 hidden md:flex items-center justify-center cursor-n-resize flex-none touch-none z-10"
//                     onPointerDown={!isMobile ? pointerDown : () => {}}
//                     onPointerMove={!isMobile ? (e: any) => pointerMove(e, "top") : () => {}}
//                     onPointerUp={!isMobile ? pointerUp : () => {}}
//                     onPointerLeave={!isMobile ? pointerUp : () => {}}
//                     onTouchStart={isMobile ? pointerDown : () => {}} // For touch devices
//                     onTouchMove={isMobile ? (e: any) => pointerMove(e, "top") : () => {}}   // For touch devices
//                     onTouchEnd={isMobile ? pointerUp : () => {}}   
//                 />
//                 <div
//                     ref={bothRef}
//                     className="w-full flex-auto cursor-move touch-none overflow-hidden relative flex items-center justify-center"
//                     onPointerDown={!isMobile ? pointerDown : () => {}}
//                     onPointerMove={!isMobile ? (e: any) => pointerMove(e, "both") : () => {}}
//                     onPointerUp={!isMobile ? pointerUp : () => {}}
//                     onPointerLeave={!isMobile ? pointerUp : () => {}}
//                     onTouchStart={isMobile ? pointerDown : () => {}} // For touch devices
//                     onTouchMove={isMobile ? (e: any) => pointerMove(e, "both") : () => {}}   // For touch devices
//                     onTouchEnd={isMobile ? pointerUp : () => {}}   
//                 >
//                     {/* <TextTag as='small' className='w-full h-full block md:hidden overflow-hidden !text-gray-800 select-none cursor-move'>
//                         {ele.notes && ele.notes.split("@@@").map((note: any) => ` / ${note}`)}
//                     </TextTag> */}
//                     {/* <TextTag>{DurationToTime(ele.idleTime, date)}</TextTag> */}
//                 </div>
//                 <div
//                     className="w-full h-1 hidden md:flex items-center justify-center cursor-n-resize flex-none touch-none z-10"
//                     onPointerDown={!isMobile ? pointerDown : () => {}}
//                     onPointerMove={!isMobile ? (e: any) => pointerMove(e, "down") : () => {}}
//                     onPointerUp={!isMobile ? pointerUp : () => {}}
//                     onPointerLeave={!isMobile ? pointerUp : () => {}}
//                     onTouchStart={isMobile ? pointerDown : () => {}} // For touch devices
//                     onTouchMove={isMobile ? (e: any) => pointerMove(e, "down") : () => {}}   // For touch devices
//                     onTouchEnd={isMobile ? pointerUp : () => {}} 
//                 />
//                 <div
//                     className={`absolute w-10 h-4 right-5 -top-2 touch-none ${ele.confirmed ? `bg-${workTheme[ele.type as "work"]}-600` : `bg-rose-600`} z-[40] rounded-full flex items-center justify-center cursor-pointer p-1 text-[0.6rem] select-none text-white hover:scale-[120%] transition- duration-200`}
                    
//                     onPointerDown={!isMobile ? pointerDown : () => {}}
//                     onPointerMove={!isMobile ? (e: any) => pointerMove(e, "top") : () => {}}
//                     onPointerUp={!isMobile ? pointerUp : () => {}}
//                     onPointerLeave={!isMobile ? pointerUp : () => {}}
//                     onTouchStart={isMobile ? pointerDown : () => {}} // For touch devices
//                     onTouchMove={isMobile ? (e: any) => pointerMove(e, "top") : () => {}}   // For touch devices
//                     onTouchEnd={isMobile ? pointerUp : () => {}}  
//                 >
//                     {TimestampToTime(workTime.start)}
//                 </div>
//                 <div
//                     className={`absolute w-10 h-4 left-5 -bottom-2 touch-none ${ele.confirmed ? `bg-${workTheme[ele.type as "work"]}-600` : `bg-rose-600`} z-[40] rounded-full flex items-center justify-center cursor-pointer p-1 text-[0.6rem] select-none text-white hover:scale-[120%] transition- duration-200`}
                    
//                     onPointerDown={!isMobile ? pointerDown : () => {}}
//                     onPointerMove={!isMobile ? (e: any) => pointerMove(e, "down") : () => {}}
//                     onPointerUp={!isMobile ? pointerUp : () => {}}
//                     onPointerLeave={!isMobile ? pointerUp : () => {}}
//                     onTouchStart={isMobile ? pointerDown : () => {}} // For touch devices
//                     onTouchMove={isMobile ? (e: any) => pointerMove(e, "down") : () => {}}   // For touch devices
//                     onTouchEnd={isMobile ? pointerUp : () => {}} 
//                 >
//                     {TimestampToTime(workTime.end, date)}
//                 </div>
//             </div>
//         </div>

//     )
// }

// const Projects = () => {
//     return (
//         <div className="w-20 sm:w-40 flex-none bg-gray-100 dark:bg-gray-600 rounded-md hidden md:block">
//             <TextTag as="h5" className="p-2 text-center">پروژه ها</TextTag>
//             {/* <TextTag as="h3" className="w-full p-2 text-center rotate-180 grid place-content-center animate-pulse !text-rose-500" style={{writingMode: "vertical-lr"}}>
//                 خیلی باید ببخشید. مجبور شدم اطلاعات رو پاک کنم
//             </TextTag> */}
//         </div>
//     )
// }



// const Descriptions = ({
//     works,
//     setWorks,
//     selectedWork,
//     setSelectedWork,
//     overflow,
//     submitWorkForm,
//     deleteHandler,
//     globalLoading
// }: {
//     works: WorkDto[],
//     setWorks: React.Dispatch<React.SetStateAction<WorkDto[]>>
//     selectedWork: number,
//     setSelectedWork: React.Dispatch<React.SetStateAction<number>>
//     overflow: object,
//     submitWorkForm: (item: any) => void
//     deleteHandler: (id: number) => void,
//     globalLoading: boolean
// }) => {

//     const scrollTpRef = useRef<any>(undefined)
//     const [numberInputFocused, setNumberInputFocused] = useRecoilState(NumberInputFocusedAtom)
//     const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom)
//     const [timeLog, setTimeLog] = useRecoilState(TransmissionTimeAtom)
//     const [lock, setLock] = useRecoilState(LockWorkPageAtom)
//     const descriptionRef = useRef<any>(null)
//     const serverDate = useRecoilValue(ServerDateState)
//     const {theme, setTheme} = useTheme();
//     const temporaryWork = works.find((work) => work.type === "temporary")
//     const [date, setDate] = useRecoilState(DateState)
//     const [showAggregationTime, setShowAggregationTime] = useRecoilState(ShowAggregationTimeStateAtom)



//     // useEffect(() => {
//     //     if (!isMobile) {
//     //         const findIndex = works.findIndex(w => w.id === selectedWork)
//     //         const scrollTo = findIndex*100
//     //         scrollTpRef.current = setTimeout(() => {
//     //             descriptionRef.current?.scrollTo({top: scrollTo, behavior: "smooth"});
//     //         }, 400)
//     //     }
//     //     return () => {
//     //         clearInterval(scrollTpRef.current)
//     //     }
//     // }, [selectedWork])
//     // const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
//     //     event.preventDefault(); // جلوگیری از اسکرول
//     // };
    
    
//     useEffect(() => {
//         const handleWheel = (event: WheelEvent) => {
//             if (descriptionRef.current && descriptionRef.current.contains(event.target as Node) && numberInputFocused) {
//                 event.preventDefault(); // جلوگیری از اسکرول
//             }
//         };

//         const divElement = descriptionRef.current;
//         if (divElement) {
//             divElement.addEventListener("wheel", handleWheel, { passive: false });
//         }

//         return () => {
//             if (divElement) {
//                 divElement.removeEventListener("wheel", handleWheel);
//             }
//         };
//     }, []);

    

//     return (
//         <div className={`flex-auto rounded-md hidden md:flex flex-col ${showAggregationTime ? "gap-0" : " gap-4"} transition-[gap]`}> 
//             <div 
//                 ref={descriptionRef} 
//                 className={`w-full h-5 flex-auto border-b border-gray-200 dark:border-gray-700 ${showAggregationTime ? "" : "pb-2"} transition-[padding] overflow-auto`}
//                 // onWheelCapture={handleWheel}
//             >
//                 {works.length ? 
//                     works.map((work) => 
//                         <DescriptionsAccordion
//                             key={work.id}
//                             work={work}
//                             selectedWork={selectedWork}
//                             setSelectedWork={setSelectedWork}
//                             overflow={overflow}
//                             works={works}
//                             setWorks={setWorks}
//                             submitWorkForm={submitWorkForm}
//                             deleteHandler={deleteHandler}
//                         />
//                     ) :
//                     globalLoading ? <></> :
//                     <div className='w-full h-full grid place-content-center'>
//                         <img src={notFound} className='w-96 '/>
//                     </div> 
//                     // <Placeholder />
//                     // <></>
//                 }
//             </div>
//             <div className={`w-full ${showAggregationTime ? "h-full" : "h-[300px]"} transition-[height] duration-500 flex-none flex flex-col bg-gray-100 dark:bg-gray-600 rounded-md relative`}>
//                 <WorkTimeAggregations />
//                 <Button
//                     icon='chevron-down'
//                     color={theme === "dark" ? "dark" : "light"}
//                     iconWidth="15px"
//                     iconHeight="15px"
//                     iconRotate={showAggregationTime ? "0" : "180"}
//                     onClick={() => setShowAggregationTime(!showAggregationTime)}
//                     classNames={{
//                         container: "absolute !w-10 !h-3 !grid !place-content-center !rounded-t-md !rounded-b-none -top-3 right-4 !bg-ipacity-50"
//                     }}
//                 />
//             </div>

//         </div> 
//     )
// }

// const DescriptionsAccordion = ({
//     work,
//     selectedWork,
//     setSelectedWork,
//     overflow,
//     works,
//     setWorks,
//     submitWorkForm,
//     deleteHandler
// }: {
//     work: WorkDto,
//     selectedWork: number,
//     setSelectedWork: React.Dispatch<React.SetStateAction<number>>
//     overflow: any,
//     works: WorkDto[],
//     setWorks: React.Dispatch<React.SetStateAction<WorkDto[]>>
//     submitWorkForm: (item: any) => void
//     deleteHandler: (id: number) => void
// }) => {

//     const [timeLog, setTimeLog] = useRecoilState(TransmissionTimeAtom)
//     const serverDate = useRecoilValue(ServerDateState)
//     const defaultHeaderHeight = 96 
//     const [height, setHeight] = useState(defaultHeaderHeight)
//     const accordionRef = useRef<any>(null)

//     const windowResizeHandler = () => {
//         setHeight(selectedWork === work.id ? (accordionRef.current?.childNodes?.[0]?.clientHeight) : defaultHeaderHeight)
//     }
//     useEffect(() => {
//         setHeight(selectedWork === work.id ? (accordionRef.current?.childNodes?.[0]?.clientHeight) : defaultHeaderHeight)
//         window.addEventListener("resize", windowResizeHandler)
//         if (!isMobile) {
//             const desc = document.getElementById(`description_${selectedWork}`)
//             // if (desc) {
//             //     const parentOffsetTop = desc?.parentElement?.offsetTop
//             //     const distanceFromTop = desc?.offsetTop - (parentOffsetTop ?? 0)
//             //     accordionRef.current?.scrollTo(distanceFromTop, distanceFromTop);
//             //     console.log(distanceFromTop)
//             //     accordionRef.current?.scrollTop({top: 50, behavior: "smooth"});
//             // }
//             // desc?.scrollIntoView()
//             // console.log(desc?.offsetTop)
//             setTimeout(() => {
//                 desc?.scrollIntoView({behavior: "smooth"})
//             }, 400)
//         }
        
//         return () => {
//             window.removeEventListener("resize", windowResizeHandler)
//         }
//     }, [selectedWork])


//     return (
//         <div 
//             id={`description_${work.id}`}
//             ref={accordionRef}
//             className={`w-full border-2 ${work.confirmed ? `border-${workTheme[work.type as "work"]}-700` : `border-rose-700`} ${overflow[work.id] ? "" : "overflow-hidden"} rounded-md mb-2 transition-[height] duration-300`}
//             style={{
//                 height
//             }}
//         >
//             {/* <div className={`border-t ${!work.active ? `border-rose-700` : `border-${work.theme}-700`}`}> */}
//                 <WorkForm
//                     work={work}
//                     active={selectedWork === work.id}
//                     sizeChanged={(id) => {
//                         // const height = selectedWork === work.id ? (accordionRef.current?.childNodes?.[1]?.clientHeight+defaultHeaderHeight) : 40
//                         // console.log(height)
//                         windowResizeHandler()
//                     }}
//                     setActive={(id: number) => {
//                         setSelectedWork(id)
//                     }}
//                     onClose={(work) => {
//                         if (work.type === "temporary") {
//                             const newWorks = [...works].filter((workItem) => workItem.confirmed)
//                             setWorks(newWorks)
//                         }
//                     }}
//                     onAccept={submitWorkForm}
//                     onDelete={deleteHandler}
//                 />
//             {/* </div> */}
//         </div>
//     )
// }

// const DescriptionsMobile = ({
//     selectedWork,
//     setSelectedWork,
//     overflow,
//     submitWorkForm,
//     deleteHandler
// }: {
//     selectedWork: number,
//     setSelectedWork: React.Dispatch<React.SetStateAction<number>>
//     overflow: object,
//     submitWorkForm: (item: any) => void
//     deleteHandler: (id: number) => void
// }) => {

//     const serverDate = useRecoilValue(ServerDateState)
//     const [date, setDate] = useRecoilState(DateState)
//     const [works, setWorks] = useRecoilState(WorksInDayAtom)
//     // const [work, setWork] = useState<WorkDto>({})
//     const activeWork: any = works.find(w => w.id === selectedWork) ?? {}

//     // useEffect(() => {
//     //     const findWork = works.find(ele => ele.id === selectedWork)
//     //     if (findWork) setWork(findWork)
//     // }, [selectedWork])

//     return (
//         <div className={`w-full h-screen fixed right-0 ${selectedWork ? "top-0" : "top-full"} transition-[top] duration-500 z-[999] flex flex-col bg-gray-100 dark:bg-gray-700 md:hidden`}>
//             {/* <div 
//                 className={`flex-none h-16 border-b ${!work.active ? `border-rose-700` : `border-${work.theme}-700`} flex items-center justify-around ${work.active ? `bg-${work.theme}-500` : `bg-rose-500 `}`} 
//                 onClick={() => setSelectedWork(0)}
//             >
//                 <TextTag as='h5' className="pe-2">{faWorkType[work.type]}</TextTag>
//                 {(work.type === "mission" && work?.missionType) &&
//                     <TextTag as='h5' className="pe-2">{faMissionType[work?.missionType]}</TextTag>
//                 }
//                 <TextTag as='h5' className="pe-2">{DurationToTime(work.end - work.start, serverDate)}</TextTag>
//             </div>
//             <div className='overflow-auto h-10 flex-auto bg-gray-100 dark:bg-gray-700'> */}
//             {selectedWork && 
//                 <WorkForm
//                     work={activeWork}
//                     active={selectedWork === activeWork.id}
//                     sizeChanged={(id) => {
//                         // const height = selectedWork === work.id ? (accordionRef.current?.childNodes?.[1].clientHeight+40 ?? 200) : 40
//                         // console.log(height)
//                         // windowResizeHandler()
//                     }}
//                     onClose={(work) => {
//                         if (work.type === "temporary") {
//                             const newWorks = [...works].filter((workItem) => workItem.type !== "temporary")
//                             setWorks(newWorks)
//                         }
//                         setSelectedWork(0)
//                     }}
//                     onAccept={submitWorkForm}
//                     onDelete={deleteHandler}
//                 />
        
//             }
//             {/* </div> */}
//         </div>
//     )
// }