import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import domtoimage from "dom-to-image";
import Button from "../../Button/Button";
import TextTag from "../../TextTag/TextTag";
import {
  DateState,
  GlobalLoadingAtom,
  ModalOpenAtom,
  ModalTypeAtom,
  ServerDateState,
  UserDataState,
} from "../../../../Recoil/Atoms";
import { useTheme } from "next-themes";
import Icon from "../../Icon/Icon";
import { LogOut } from "../../../../axios/Auth.api";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../../../Utils/Toast";
import {
  DailyLogAtom,
  LockWorkPageAtom,
  SelectedWorkAtom,
  WorksInDayAtom,
} from "../../../../Recoil/Works.atom";
import { InOutLogAtom } from "../../../../Recoil/InOutLogAtom";
import { DurationToTime, ServerDateToDay } from "../../../../Utils/convertTime";
import { finalizedUpdate } from "../../../../axios/DailyLog.api";
import { UnClosedDaysState } from "../../../../Recoil/dailyLogAtom";
const cookies = new Cookies();

const CloseDayAccess = () => {
  const navigation = useNavigate();
  const { theme } = useTheme();
  const serverDate = useRecoilValue(ServerDateState);
  const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
  const resetUserDate = useResetRecoilState(UserDataState);
  const [selectedWork, setSelectedWork] = useRecoilState(SelectedWorkAtom);
  const [works, setWorks] = useRecoilState(WorksInDayAtom);
  const [inOutLogs, setInOutLogs] = useRecoilState(InOutLogAtom);
  const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom);
  const [date, setDate] = useRecoilState(DateState);
  const [globalLoading, setGlobalLoading] = useRecoilState(GlobalLoadingAtom);
  const [modalType, setModalType] = useRecoilState(ModalTypeAtom);
  const [lock, setLock] = useRecoilState(LockWorkPageAtom);
  const [unClosedDays, setUnClosedDays] = useRecoilState(UnClosedDaysState);

  const handleScreenshot = async (ref: HTMLElement) => {
    const base64Image = await domtoimage.toPng(ref);
    const byteString = atob(base64Image.split(",")[1]);
    const mimeType = base64Image.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      arrayBuffer[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeType });
    setIsModalOpen(true);
    setModalType("WorkTimeAggregations");
    return blob;
  };

  const closeDayHandler = async () => {
    setIsModalOpen(false)
    const confirmedChecker = works.filter((work) => !work.confirmed);
    if (confirmedChecker.length > 0) {
      Toast("error", "اول این کار رو تایید کن لطفا");
      setSelectedWork(confirmedChecker[0].id);
      return;
    }
    const noteChecker = works.filter(
      (work) =>
        (work.type === "remote" && !work.notes) ||
        (work.type === "mission" &&
          (work.missionType === "work" ||
            work.missionType === "mandatoryUnemployment") &&
          !work.notes)
    );
    if (noteChecker.length > 0) {
      Toast("error", "این کار به گزارش خلاصه کار نیاز داره");
      setSelectedWork(noteChecker[0].id);
      return;
    }

    if (dailyLog?.finalized) return;

    const NowDate = ServerDateToDay(serverDate);
    const isInToday = NowDate === date;
    const sumOfInCompony = inOutLogs.reduce((a, b) => {
      if (b.exit) {
        return a + (b.exit - b.enter);
      } else {
        const inWorks =
          [...works]
            .filter((w) => w.inOutLogId === b.id)
            .sort((c, d) => c.start - d.start)
            .pop()?.end ?? b.enter;
        const maxExitValue = Math.max(inWorks, isInToday ? serverDate : 0);
        if (b.enter < maxExitValue) {
          return a + (maxExitValue - b.enter);
        }
        return a;
      }
    }, 0);
    let compony_useful = sumOfInCompony - (dailyLog?.idleTime ?? 0);
    works.forEach((work) => {
      if (work.type === "work") {
        compony_useful -= work?.idleTime ?? 0;
      } else if (work.type === "leave") {
        const workDuration = work.end - work.start;
        compony_useful -= workDuration;
      }
    });
    if (compony_useful < 0) {
      return Toast(
        "error",
        `لطفا مقدار دراختیار اضافه را چک کنید\n ساعت کار شما درشرکت ${DurationToTime(
          Math.abs(compony_useful),
          date
        )} منفی شده است`
      );
    }

    setGlobalLoading(true);
    setDailyLog({
      ...dailyLog,
      finalized: serverDate,
    });
    await new Promise((res, rej) =>
      setTimeout(() => {
        res(true);
      }, 1000)
    );
    const workPageDetails = document.getElementById(
      "workPageDetails"
    ) as HTMLElement;
    const shot1: any = await handleScreenshot(workPageDetails);
    await new Promise((res, rej) =>
      setTimeout(() => {
        res(true);
      }, 1000)
    );
    const timeAggregation = document.getElementById(
      "timeAggregationDomToImageId"
    ) as HTMLElement;
    const shot2: any = await handleScreenshot(timeAggregation);
    setIsModalOpen(false);
    await new Promise((res, rej) =>
      setTimeout(() => {
        res(true);
      }, 1000)
    );

    const formData = new FormData();
    formData.append("image1", shot1, "screenshot1.png");
    formData.append("image2", shot2, "screenshot2.png");
    console.log(formData);

    finalizedUpdate({
      date,
      shot: formData,
    })
      .then((res) => {
        const { finalized } = res.data;
        setDailyLog({
          ...dailyLog,
          finalized,
        });
        setIsModalOpen(true);
        setModalType("ClosedDay");
        setLock(true);
        const cloneUnclosedDay = [...unClosedDays];
        const newUnClosedList = cloneUnclosedDay.filter(
          (day) => day.id !== dailyLog.id
        );
        setUnClosedDays(newUnClosedList);
        setGlobalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDailyLog({
          ...dailyLog,
          finalized: 0,
        });
        setGlobalLoading(false);
      });
  };

  return (
    <div className="flex flex-col bg-gray-100 dark:bg-gray-700 rounded-md">
      <div className="flex-none flex items-center justify-center p-2">
        <Icon
          name="exclamation-circle"
          width="5rem"
          height="5rem"
          color="#f43f5e"
        />
      </div>
      <TextTag as="h4" className="py-6 px-16">
        از بستن روز اطمینان دارید!؟
      </TextTag>
      <div className=" flex items-center justify-center gap-3 p-3">
        <Button
          title="بستن روز"
          color={theme === "dark" ? "dark" : "light"}
          onClick={closeDayHandler}
          classNames={{
            container: "!w-28 !h-10 !rounded-[3px]",
            section: "!text-sm",
          }}
        />
        <Button
          title="انصراف"
          color={theme === "dark" ? "dark" : "light"}
          onClick={() => setIsModalOpen(false)}
          classNames={{
            container: "!w-28 !h-10 !rounded-[3px]",
            section: "!text-sm",
          }}
        />
      </div>
    </div>
  );
};

export default CloseDayAccess;
