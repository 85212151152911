import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import LoginPage from "./pages/Auth/Login/Login.page";
import RegisterPage from "./pages/Auth/Register/Register.page";
import HomePage from "./pages/Home/Home.page";
// import TaskPage from "./pages/task/task.page";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { GlobalLoadingAtom, ShowMenuState, UserDataState } from "./Recoil/Atoms";
import Icon from "./Components/Common/Icon/Icon";
import { useTheme } from "next-themes";
import { Link } from "react-router-dom";
import Button from "./Components/Common/Button/Button";
import { LogOut } from "./axios/Auth.api";
import Cookies from 'universal-cookie';
import Dashboard from "./pages/Dashboard/Dashboard.page";
import Users from "./pages/Users/Users.page";
import Projects from "./pages/Projects/Projects.page";
import Works from "./pages/Works/Works";
import TextTag from "./Components/Common/TextTag/TextTag";
import UserForm from "./Components/UserForm/UserForm";
import Notification from "./pages/Notification/Notification";
import Profile from "./pages/Profile/Profile";
import { AllNotificationsStateAtom } from "./Recoil/confirmsAtom";
import MenuComponent from "./Components/MenuComponent/MenuComponent";
import Reports from "./pages/Reports/Reports";
import Finance from "./pages/Reports/Finance/Finance";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import DailyReport from "./pages/Reports/DailyReport/DailyReport";
const cookies = new Cookies();

// const themes = require('./config/config.theme')


const AllRoutes = () => {

    const [globalLoading, setGlobalLoading] = useRecoilState(GlobalLoadingAtom)
    return (
        <div className="max-w-[1900px] min-h-[calc(100dvh-64px)] m-auto flex items-stretch justify-between">
            <Menu />
            <div className="flex-auto lg:ps-0 lg:pe-3 lg:py-4">
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/worksReport" element={<Works />} />
                    <Route path="/finance" element={<Finance />} />
                    <Route path="/daily-report" element={<DailyReport />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/users/add" element={<UserForm title="create"/>} />
                    <Route path="/users/update/:userId" element={<UserForm title="edit"/>} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/notifications" element={<Notification />} />
                </Routes>
            </div>
            { globalLoading && <div className="fixed w-screen h-screen right-0 top-0 z-[1000]"></div> }
            <div className={`${globalLoading ? "globalLoading" : ""} fixed w-full h-1 top-0 z-[1010]`}></div>
        </div>
    )
} 

export default AllRoutes;


const Menu = () => {
    const location = useLocation()
    if (!location.pathname.includes("/login") && !location.pathname.includes("/register")) {
    return <>
        <MenuComponent />
    </>
    } else return <></>
}


