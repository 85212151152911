import { UserValuesType } from "../Components/UserForm/Types";
import { get, put, post, deleted, patch } from "./Axios.config"
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const createProject = async (data: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await post({
        api: `project`,
        data,
        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const GetProjects = async (params?: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `project`,
        config: {
            params,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const getProjectById = async (id: number) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `project/${id}`,
        config: {
            // params: { With: ["Supervisors"] },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const editProject = async (id: number, data: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await patch({
        api: `project/${id}`,
        data,
        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}