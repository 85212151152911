import { UserValuesType } from "../Components/UserForm/Types";
import { get, put, post, deleted, patch } from "./Axios.config"
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const insertTimeLog = async (data: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await post({
        api: `time_log`,
        data,
        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

// export const updateTimeLog = async (date: number, data: any) => {
//     const cookie = cookies.get("ACCESS_TOKEN")
//     return await patch({
//         api: `time_log/${date}`,
//         data,
//         config: {
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${cookie}`
//             } 
//         }
//     })
// }

// export const finalizedUpdate = async (data: any) => {
//     const cookie = cookies.get("ACCESS_TOKEN")
//     const { date, shot } = data
//     console.log(shot.get("image"))
//     return await put({
//         api: `time_log/${date}`,
//         data: shot,
//         config: {
//             headers: {
//                 "Content-Type": "multipart/form-data",
//                 "Authorization": `Bearer ${cookie}`
//             } 
//         }
//     })
// }

export const getTimeLog = async (date: number) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `time_log/${date}`,
        config: {
            params: { With: ["Works"] },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

// export const GetEmployees = async (paramsData?: any) => {
//     const cookie = cookies.get("ACCESS_TOKEN")
//     return await get({
//         api: `users/getEmployees`,
//         config: {
//             params: { With: ["Supervisors"], ...paramsData },
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${cookie}`
//             } 
//         }
//     })
// }

// export const getUserById = async (id: number) => {
//     const cookie = cookies.get("ACCESS_TOKEN")
//     return await get({
//         api: `users/${id}`,
//         config: {
//             params: { With: ["Supervisors"] },
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${cookie}`
//             } 
//         }
//     })
// }

// export const editUser = async (id: number, data: any) => {
//     const cookie = cookies.get("ACCESS_TOKEN")
//     return await patch({
//         api: `users/${id}`,
//         data,
//         config: {
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${cookie}`
//             } 
//         }
//     })
// }