import { RecoilState, atom, selector } from "recoil";


export type InOutLogDto = {
    id: number,
    date: number,
    enter: number,
    exit: number | null,
    userId: number,
    works: any[],
    createdAt: Date,
    updatedAt: Date
}

export const InOutLogAtom: RecoilState<InOutLogDto[]> = atom<InOutLogDto[]>({
    key: 'InOutLogAtom',
    default: []
});