import { PropsWithChildren } from "react"
import "./Loading.css"

const Loading = ({
    className
}: {
    className?: string
}) => {
    return (
        <div className={className ?? className}>
            <div className="loader"/>
        </div>
    )
}

export default Loading
