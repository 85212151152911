import { useRecoilState } from "recoil";
import { ModalOpenAtom, ModalParamsAtom, UserDataState } from "../../Recoil/Atoms";
import TextTag from "../Common/TextTag/TextTag";
import Button from "../Common/Button/Button";
import { FiltersInput } from "../../pages/Users/Users.page";
import Input from "../Common/Input/Input";
import ReactSelect from "../Common/ReactSelect/ReactSelect";
import { ChangeEvent, useEffect, useRef, useState, useTransition } from "react";
import { useTheme } from "next-themes";
import Switch from "../Common/Switch/Switch";
import validation from "./Validation";
import { FormType, SelectInputType, UserValuesType } from "./Types";
import { createUser, editUser, getUserById, GetUsers } from "../../axios/Users.api";
import { EmployeesAtom } from "../../Recoil/users.atom";
import { Link, useNavigate, useParams } from "react-router-dom";
import Icon from "../Common/Icon/Icon";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";

type UserFormProps = {
    title: FormType
}
const UserForm = ({
    title
}: UserFormProps) => {
    
    const faTitle = {
        create: "ایجاد کاربر",
        edit: "ویرایش کاربر"
    }

    const defaultSelectLabel = "بی تاثیر"
    const defaultSelectValue = {value: 0, label: defaultSelectLabel}
    const { userId } = useParams()
    const navigate = useNavigate()
    const {theme, setTheme} = useTheme()
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    const [modalParams, setModalParams] = useRecoilState(ModalParamsAtom);
    const [usersSelect, setUsersSelect] = useState<UserValuesType[] | never[]>([])
    const [loading, setLoading] = useState(false)
    const [editAccess, setEditAccess] = useState(true)
    const [userData, setUserData] = useRecoilState(UserDataState)
    const [users, setUsers] = useRecoilState(EmployeesAtom)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [baseUserValue, setBaseUserValue] = useState<UserValuesType>({ // برای نگهداشتن مقدار اولیه اطلاعات کاربر
        username: "",
        password: "",
        // confirmPassword: "",
        role: {value: "user", label: "کاربر"},
        chatId: "",
        phone: "",
        active: true,

        accessTimeChange: 5,
        timeRecordDelays: 10,
        inComponyApprovalMethod: {value: "auto", label: "اتوماتیک"},
        missionApprovalMethod: {value: "auto", label: "اتوماتیک"},
        remoteApprovalMethod: {value: "auto", label: "اتوماتیک"},
        remoteEligibility: true,
        missionEligibility: true,
        // delayTracking: true,
        // remoteWorkExplanation: true,
        
        logicEnter: {value: "08:00", label: "08:00"},
        logicExit: {value: "17:00", label: "17:00"},

        supervisors: [],
        monitored: []
    })
    const [values, setValues] = useState<UserValuesType>({
        username: "",
        password: "",
        // confirmPassword: "",
        role: {value: "user", label: "کاربر"},
        chatId: "",
        phone: "",
        active: true,

        accessTimeChange: 5,
        timeRecordDelays: 10,
        inComponyApprovalMethod: {value: "auto", label: "اتوماتیک"},
        missionApprovalMethod: {value: "auto", label: "اتوماتیک"},
        remoteApprovalMethod: {value: "auto", label: "اتوماتیک"},
        remoteEligibility: true,
        missionEligibility: true,
        // delayTracking: true,
        // remoteWorkExplanation: true,
        
        logicEnter: {value: "08:00", label: "08:00"},
        logicExit: {value: "17:00", label: "17:00"},

        supervisors: [],
        monitored: []
    })
    const [act, setAct] = useState({
        username: false,
        password: false,
        confirmPassword: false,
    })
    const [error, setError] = useState({
        username: "",
        password: "",
        confirmPassword: "",
    })

    const inputChangeHandler = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const selectChangeHandler = (e: any, name: string) => {
        setValues({
            ...values,
            [name]: e
        })
    }

    const switchChangeHandler = (e: any, name: string) => {
        setValues({
            ...values,
            [name]: !values[name as "active"]
        })
    }

    const RoleOptions = [
        // {value: "admin", label: "ادمین"},
        {value: "user", label: "کاربر"},
        {value: "manager", label: "مدیر"},
    ]
    const ApprovalOptions = [
        {value: "auto", label: "اتوماتیک"},
        {value: "supervisor", label: "ناظر"},
    ]
    const TimeOptions = [...Array(24).keys()].map(t => ({value: `${String(t).padStart(2, "0")}:00`, label: `${String(t).padStart(2, "0")}:00`}))
    const publicFilter: FiltersInput = [
        {
            type: Input, 
            props: {
                label: "نام کاربری",
                value: values.username,
                name: "username",
                error: error?.username,
                act: act.username,
                placeholder: "نام کاربری",
                onChange: inputChangeHandler,
                disabled: !editAccess,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: Input, 
            props: {
                label: "رمز عبور",
                name: "password",
                value: values.password,
                error: error.password,
                act: act.password,
                type: "password",
                placeholder: "رمز عبور",
                onChange: inputChangeHandler,
                theme: theme,
                disabled: !editAccess,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        // {
        //     type: Input, 
        //     props: {
        //         label: "تایید رمز عبور",
        //         name: "confirmPassword",
        //         value: values.confirmPassword,
        //         error: error?.confirmPassword,
        //         act: act.confirmPassword,
        //         type: "password",
        //         placeholder: "تایید رمز عبور",
        //         onChange: inputChangeHandler,
        //         theme: theme,
        //         disabled: !editAccess,
        //         classNames: {
        //             container: "h-[80px]",
        //             inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
        //             input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
        //             label: "text-sm font-thin mb-1"
        //         }
        //     }
        // },
        {
            type: ReactSelect,
            props: {
                label: "نقش کاربر",
                name: "role",
                theme: theme,
                onChange: selectChangeHandler,
                value: values.role,
                isDisabled: !editAccess,
                className: "h-[80px]",
                isSearchable: false,
                // act: act.role,
                // error: "رول را وارد کنید",
                options: RoleOptions
            }
        },
        {
            type: Input, 
            props: {
                label: "شماره موبایل",
                name: "phone",
                type: "number",
                placeholder: "شماره موبایل",
                value: values.phone,
                onChange: inputChangeHandler,
                min: 0,
                disabled: !editAccess,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400 no-spinner",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: Input, 
            props: {
                label: "چت آیدی",
                name: "chatId",
                type: "number",
                placeholder: "چت آیدی",
                value: values.chatId,
                onChange: inputChangeHandler,
                min: 0,
                disabled: !editAccess,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400 no-spinner",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: Switch,
            props: {
                label: "وضعیت فعال",
                name: "active",
                onClick: switchChangeHandler,
                value: values.active,
                disabled: !editAccess,
                classNames: {
                    container: "h-[50px] px-2 pb-2"
                }
            }
        },
        // {
        //     type: ReactSelect,
        //     props: {
        //         label: "ناظرین",
        //         name: "supervisors",
        //         theme: theme,
        //         onChange: selectChangeHandler,
        //         value: values.supervisors,
        //         isMulti: true,
        //         isSearchable: true,
        //         placeholder: "انتخاب...",
        //         className: "h-[80px] col-span-full",
        //         isLoading: loading,
        //         isDisabled: !editAccess,
        //         // act: act.role,
        //         // error: "رول را وارد کنید",
        //         options: usersSelect
        //     }
        // },
    ]
    const delayPermissions = [
        {
            type: Input,
            props: {
                label: "تعداد روزهای مجاز تغییر گزارش روزانه",
                name: "accessTimeChange",
                placeholder: "برحسب روز",
                onChange: inputChangeHandler,
                value: values.accessTimeChange,
                type: "number",
                min: 0,
                disabled: !editAccess,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: Input,
            props: {
                label: "تعداد روزهای مجاز تاخیر ثبت گزارش روزانه",
                name: "timeRecordDelays",
                placeholder: "برحسب روز",
                onChange: inputChangeHandler,
                value: values.timeRecordDelays,
                type: "number",
                min: 0,
                disabled: !editAccess,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
    ]
    const workPermission: FiltersInput = [
        {
            type: Switch,
            props: {
                label: "مجوز دورکاری",
                name: "remoteEligibility",
                onClick: switchChangeHandler,
                value: values.remoteEligibility,
                disabled: !editAccess,
                classNames: {
                    container: "h-[50px] px-2 pb-2"
                }
            }
        },
        {
            type: Switch,
            props: {
                label: "مجوز ماموریت رفتن",
                name: "missionEligibility",
                onClick: switchChangeHandler,
                value: values.missionEligibility,
                disabled: !editAccess,
                classNames: {
                    container: "h-[50px] px-2 pb-2"
                }
            }
        },
        // {
        //     type: Switch,
        //     props: {
        //         label: "هشدار ساعت ورود زودهنگام، خروج دیرهنگام",
        //         name: "delayTracking",
        //         onClick: switchChangeHandler,
        //         value: values.delayTracking,
        //         disabled: !editAccess,
        //         classNames: {
        //             container: "h-[50px] px-2 pb-2"
        //         }
        //     }
        // },
        // {
        //     type: Switch,
        //     props: {
        //         label: "توصیف دورکاری",
        //         name: "remoteWorkExplanation",
        //         onClick: switchChangeHandler,
        //         value: values.remoteWorkExplanation,
        //         disabled: !editAccess,
        //         classNames: {
        //             container: "h-[50px] px-2 pb-2"
        //         }
        //     }
        // },
    ]
    const confirms = [
        {
            type: ReactSelect,
            props: {
                label: "روش تایید گزارش دورکاری",
                name: "remoteApprovalMethod",
                theme: theme,
                onChange: selectChangeHandler,
                value: values.remoteApprovalMethod,
                isSearchable: false,
                className: "h-[80px]",
                isDisabled: !editAccess || !values.remoteEligibility,
                options: ApprovalOptions
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "روش تایید گزارش ماموریت",
                name: "missionApprovalMethod",
                theme: theme,
                onChange: selectChangeHandler,
                value: values.missionApprovalMethod,
                isSearchable: false,
                className: "h-[80px]",
                isDisabled: !editAccess || !values.missionEligibility,
                options: ApprovalOptions
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "روش تایید گزارش کار حضوری",
                name: "inComponyApprovalMethod",
                theme: theme,
                onChange: selectChangeHandler,
                value: values.inComponyApprovalMethod,
                isSearchable: false,
                className: "h-[80px]",
                isDisabled: !editAccess,
                options: ApprovalOptions
            }
        },
    ]
    const logicFilter: FiltersInput = [
        {
            type: ReactSelect,
            props: {
                label: "مرز ساعت ورود زودهنگام",
                name: "logicEnter",
                theme: theme,
                onChange: selectChangeHandler,
                isSearchable: false,
                value: values.logicEnter,
                options: TimeOptions,
                isDisabled: !editAccess,
                className: "h-[80px]",
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "مرز ساعت خروج دیرهنگام",
                name: "logicExit",
                theme: theme,
                onChange: selectChangeHandler,
                isSearchable: false,
                value: values.logicExit,
                options: TimeOptions,
                isDisabled: !editAccess,
                className: "h-[80px]",
            }
        }
    ]

    const accessSubmit = () => {
        if (Object.values(error).join("")) {
            setAct({
                username: true,
                password: true,
                confirmPassword: true,
            })
            return false
        }
        return true
    }

    const createHandler = () => {
        const isValid = accessSubmit()
        if (!isValid) return;
        // setSubmitLoading(true)
        const newValue = {
            ...values,
            chatId: values.chatId,
            role: values.role.value,
            inComponyApprovalMethod: values.inComponyApprovalMethod.value,
            missionApprovalMethod: values.missionApprovalMethod.value,
            remoteApprovalMethod: values.remoteApprovalMethod.value,
            logicEnter: values.logicEnter.value,
            logicExit: values.logicExit.value,
            supervisors: values.supervisors
        }
        // console.log(newValue)
        createUser(newValue)
            .then(res => {
                setSubmitLoading(false)
                setUsers([
                    {...res.data},
                    ...users
                ])
                setIsModalOpen(false)
                setModalParams(undefined)
                navigate("/users")
            })
            .catch(err => {
                setSubmitLoading(false)
            })
    }
    
    // const deleteUnchangedValues = () => {
    //     const newValue: any = {}
    //     Object.entries(baseUserValue).forEach(([key, value]) => {
    //         if (values[key as keyof UserValuesType] !== value)
    //     })
    // }
    
    const editHandler = () => {
        const isValid = accessSubmit()
        if (!isValid) return;
        setSubmitLoading(true)
        const newValue: any = {
            ...values,
            chatId: values.chatId,
            role: values.role.value,
            inComponyApprovalMethod: values.inComponyApprovalMethod.value,
            missionApprovalMethod: values.missionApprovalMethod.value,
            remoteApprovalMethod: values.remoteApprovalMethod.value,
            logicEnter: values.logicEnter.value,
            logicExit: values.logicExit.value,
            supervisors: values.supervisors,
            monitored: values.monitored
        }
        if (baseUserValue.password === newValue.password) {
            delete newValue.password;
            delete newValue.confirmPassword;
        }
        editUser((typeof userId !== "undefined" ? +userId : 0), newValue)
            .then(res => {
                const newData = res.data;
                const cloneUsers = [...users];
                const findIndex = cloneUsers.findIndex(ele => ele.id === newData.id)
                cloneUsers.splice(findIndex, 1, newData)
                setSubmitLoading(false)
                setUsers(cloneUsers)
                setIsModalOpen(false)
                setModalParams(undefined)
                navigate("/users")
            })
            .catch(err => {
                console.log(err)
                setSubmitLoading(false)
            })
    }
    
    const getAllUsers = () => {
        setLoading(true)
        GetUsers()
        .then((res) => {
            // const usersSelectStyle: SelectInputType[] = []
            const allUsers: UserValuesType[] = res.data.rows
            // allUsers.map((ele: UserValuesType) => usersSelectStyle.push({value: String(ele.id), label: ele.username}))
            const filterThisUser = allUsers.filter(user => title === "edit" ? (user.id !== (Number(userId) ?? 0)) : user )
            // setUsersSelect(usersSelectStyle)
            setUsersSelect(filterThisUser)
            setLoading(false)
        })
        .catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }

    const getUserData = () => {
        getUserById((typeof userId !== "undefined" ? +userId : 0))
        .then((res) => {
            const {Monitored, Supervisors, ...otherData} = res.data
            const newValues = {
                ...values,
                ...otherData,
                role: RoleOptions.find(r => r.value === otherData.role),
                logicEnter: TimeOptions.find(r => r.value === otherData.logicEnter),
                logicExit: TimeOptions.find(r => r.value === otherData.logicExit),
                supervisors: Supervisors.map((ele: UserValuesType) => ele.id),
                monitored: Monitored.map((ele: UserValuesType) => ele.id),
                inComponyApprovalMethod: ApprovalOptions.find(r => r.value === otherData.inComponyApprovalMethod),
                remoteApprovalMethod: ApprovalOptions.find(r => r.value === otherData.remoteApprovalMethod),
                missionApprovalMethod: ApprovalOptions.find(r => r.value === otherData.missionApprovalMethod)
            }
            setValues(newValues)
            setBaseUserValue(newValues)
        })
        .catch((err) => {
            console.log(err)
        })
    }
    
    useEffect(() => {
        if (title === "edit") getUserData()
        if (userData.role === "admin" || userData.role === "manager") getAllUsers()
        else setEditAccess(false)
    }, [userId])

    useEffect(() => {
        setError(validation(values, title))
    }, [values])


    return (
        <div className="lg:px-2 border bg-gray-100 dark:bg-gray-600 border-zinc-400 dark:border-zinc-800 rounded-none lg:rounded-md milad-m">
            <div className="w-full p-2 flex items-center justify-between border-b border-zinc-300 dark:border-zinc-700">
                    <TextTag as="h4" className="w-fit p-2 text-gray-500 dark:text-gray-300">
                        {faTitle[title as "create"]}
                    </TextTag>
                <div className="flex items-center justify-center gap-2">
                    <Link
                        to="/users"
                        className="w-16 h-10 rounded-[4px] flex items-center justify-center px-2 py-0 transition-all duration-200 bg-gray-300 hover:bg-gray-400 dark:bg-gray-800 hover:dark:bg-gray-900 cursor-pointer group"
                    >
                        <Icon
                            name="arrow-left"
                            width="1.7rem"
                            height="1.7rem"
                            color={theme === "dark" ? "#cbd5e1" : "#475569"}
                            classNames="flex-none group-hover:-translate-x-2 transition-all"
                        />
                    </Link>
                </div>
            </div>

            <form className="w-full lg:px-2 lg:py-4">
            <TextTag as="h5" className="text-start p-3"> اطلاعات هویتی </TextTag>
                <div className="w-full items-center justify-center text-md grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-3 px-2 py-4 border-b border-dashed border-gray-300 dark:border-gray-700">
                    {publicFilter.map((ele, index) => 
                        <ele.type key={index} {...ele.props}/>
                    )}
                </div>
                <TextTag as="h5" className="text-start p-3"> مجوزها </TextTag>
                <div className="w-full items-center justify-center text-md grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-3 px-2 py-4">
                    {delayPermissions.map((ele, index) => 
                        <ele.type key={index} {...ele.props}/>
                    )}
                </div>
                <div className="w-full items-center justify-center text-md grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-3 px-2 py-4">
                    {workPermission.map((ele, index) => 
                        <ele.type key={index} {...ele.props}/>
                    )}
                </div>
                <div className="w-full items-center justify-center text-md grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-3 px-2 py-4 border-b border-dashed border-gray-300 dark:border-gray-700">
                    {confirms.map((ele, index) => 
                        <ele.type key={index} {...ele.props}/>
                    )}
                </div>
                <TextTag as="h5" className="text-start p-3"> حدود طبیعی ورود و خروج </TextTag>
                <div className="w-full items-center justify-center text-md grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-3 px-2 py-4 border-b border-dashed border-gray-300 dark:border-gray-700">
                    {logicFilter.map((ele, index) => 
                        <ele.type key={index} {...ele.props}/>
                    )}
                </div>
                
                <TextTag as="h5" className="text-start p-3"> وضعیت نظارت </TextTag>
                <div className="h-[600px] flex items-stretch justify-center gap-2 p-2">
                    <Supervisors 
                        users={usersSelect}
                        values={values}
                        setValues={setValues}
                    />
                </div>
                <Button
                    // icon="x-lg"
                    color="primary"
                    title={title === "edit" ? "ویرایش کاربر" : "افزودن کاربر"}
                    direction="row_reverse"
                    onClick={title === "create" ? createHandler : editHandler}
                    loading={submitLoading}
                    classNames={{
                        container: "!w-full !h-12 mt-20 !rounded-[4px] !px-3 transition-all sticky bottom-5 shadow-xl",
                        section: "!text-lg"
                    }}
                />

            </form>
            
        </div>
    )
}

const Supervisors = ({
    users, 
    values,
    setValues
}: any) => {

    const [searchUser, setSearchUser] = useState(users)
    const [isPending, searchTransition] = useTransition()
    const [activeUsers, setActiveUsers] = useState(users)
    const [searchValue, setSearchValue] = useState("")
    // const searchUser = useRef<any>([])
    // searchUser.current = users
    const [filteredState, setFilteredState] = useState([
        {
            id: "supervisors",
            title: "نظارت میکند",
            list: []
        },
        {
            id: "users",
            title: "کاربران",
            list: searchUser
        },
        {
            id: "monitored",
            title: "نظارت میشود",
            list: []
        }
    ])
    
    const searchHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
        // const { value } = e.target;
        // const findUsers = activeUsers.filter((u: any) => u.username.includes(value))
        // const cloneFilteredState = [...filteredState]
        // const getUsersIndex = cloneFilteredState.findIndex(c => c.id === "users")
        // const spliceUser = cloneFilteredState.splice(getUsersIndex, 1)?.[0]
        // const userData = {
        //     ...spliceUser,
        //     list: findUsers
        // }
        // cloneFilteredState.splice(getUsersIndex, 0, userData)
        // searchTransition(() => {
        //     setFilteredState(cloneFilteredState)
        // })
    }

    const handleDrop = (result: DropResult) => {
        const {source, destination} = result
        if (!destination) return;
        if (
            source.droppableId === destination.droppableId && 
            source.index === destination.index 
        ) return;

        const storeSourceIndex = filteredState.findIndex((store) => store.id === source.droppableId);
        const storeDestinationIndex = filteredState.findIndex((store) => store.id === destination.droppableId);
    
        const newSourceItem = [...filteredState[storeSourceIndex].list];
        const newDestinationItem = 
          source.droppableId !== destination.droppableId
          ? [...filteredState[storeDestinationIndex].list]
          : newSourceItem;
    
        const [deletedItem] = newSourceItem.splice(source.index, 1);
        newDestinationItem.splice(destination.index, 0, deletedItem);
    
        const newStore = [...filteredState];
        newStore[storeSourceIndex] = {
          ...filteredState[storeSourceIndex],
          list: newSourceItem
        }
        newStore[storeDestinationIndex] = {
          ...filteredState[storeDestinationIndex],
          list: newDestinationItem
        }
    
        setFilteredState(newStore)
        setActiveUsers(newStore[1].list)
        const supervisors = newStore[0].list.map((i: any) => i.id)
        const monitored = newStore[2].list.map((i: any) => i.id)
        setValues({
            ...values,
            supervisors,
            monitored
        })
    }

    useEffect(() => {
        setSearchUser(users)
        setActiveUsers(users)
        // const cloneFilteredState = [...filteredState]
        // const getUsersIndex = cloneFilteredState.findIndex(c => c.id === "users")
        // const spliceUser = cloneFilteredState.splice(getUsersIndex, 1)?.[0]
        // const userData = {
        //     ...spliceUser,
        //     list: users
        // }
        // cloneFilteredState.splice(getUsersIndex, 0, userData)
        const supervisors: any[] = []
        const usersList: any[] = []
        const monitored: any[] = []
        users.forEach((u: any) => {
            if (values.supervisors.includes(u.id) || u.role === "manager") supervisors.push(u)
            else if (values.monitored.includes(u.id)) monitored.push(u)
            else usersList.push(u)
        })
        const newFilteredState = [
            {
                id: "supervisors",
                title: "بر این کاربر نظارت میکنند",
                list: supervisors
            },
            {
                id: "users",
                title: "کاربران",
                list: usersList
            },
            {
                id: "monitored",
                title: "توسط این کاربر نظارت میشود",
                list: monitored
            }
        ]
        setFilteredState(newFilteredState)
    }, [users, values])


    return (
        <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="ROOT" type='group' direction="horizontal">
                {(provided) => (
                    <div 
                        className={"w-full h-full flex items-stretch justify-between over gap-2"} 
                        {...provided.droppableProps} 
                        ref={provided.innerRef}
                    >
                        {filteredState.map((item, index) => (
                            <Draggable key={String(item.id)} draggableId={String(item.id)} index={index} >
                                {(provided) => (
                                    <div 
                                        className="flex-1 flex flex-col rounded-[5px] bg-opacity-20 bg-gray-400 dark:bg-gray-700 border border-gray-500 dark:border-gray-900 overflow-hidden border-opacity-70"
                                    >
                                        <h3 className={"flex-none flex items-center justify-center bg-gray-300 dark:bg-gray-800 bg-opacity-70 p-3 capitalize border-b border-stone-500 border-opacity-30 backdrop-blur-xl"}>
                                            <TextTag className="h-10 md:h-6 text-center flex items-center justify-center">
                                                {item.title}
                                            </TextTag>
                                        </h3>
                                        {item.id === "users" &&
                                            <Input 
                                                type="text"
                                                placeholder="جست‌جو..."
                                                onChange={searchHandler}
                                                classNames={{
                                                    container: "h-fit",
                                                    inputBox: "border-none border-gray-400 dark:border-gray-900 h-[38px] rounded-none",
                                                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400 bg-gray-300",
                                                    label: "text-sm font-thin mb-1"
                                                }}
                                            />
                                        }
                                        <div 
                                            className={`h-full flex flex-col overflow-auto`}
                                            {...provided.dragHandleProps}
                                            // {...provided.draggableProps}
                                            ref={provided.innerRef}
                                        >
                                            <Droppable droppableId={String(`${item.id}`)}>
                                                {(provided) => (
                                                    <div 
                                                        className={"flex-auto p-1"}
                                                        {...provided.droppableProps} 
                                                        ref={provided.innerRef}
                                                    >
                                                        {item.list.map((e: any, index: number) => 
                                                            item.id === "users" ?
                                                                (e.username.includes(searchValue) &&
                                                                <Draggable key={String(e.id)} draggableId={String(e.id)} index={index} >
                                                                    {(provided) => (
                                                                        <div 
                                                                            className={`mt-1 rounded-md border border-gray-300 bg-gray-100 dark:border-gray-800 dark:bg-gray-600 p-1 grid place-content-center`}
                                                                            {...provided.dragHandleProps}
                                                                            {...provided.draggableProps}
                                                                            ref={provided.innerRef}
                                                                        >
                                                                            <TextTag className="py-2 whitespace-nowrap overflow-hidden">
                                                                                {e.username}
                                                                            </TextTag> 
                                                                        </div>
                                                                    )}
                                                                </Draggable>) :
                                                                <Draggable key={String(e.id)} draggableId={String(e.id)} index={index} >
                                                                    {(provided) => (
                                                                        <div 
                                                                            className={`mt-1 rounded-md border border-gray-300 bg-gray-100 dark:border-gray-800 dark:bg-gray-600 p-1 grid place-content-center`}
                                                                            {...provided.dragHandleProps}
                                                                            {...provided.draggableProps}
                                                                            ref={provided.innerRef}
                                                                        >
                                                                            <TextTag className="py-2 whitespace-nowrap overflow-hidden">
                                                                                {e.username}
                                                                            </TextTag> 
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                        
                                                        )}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>

                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        
                        
                    </div>
                )}
            </Droppable>
        </DragDropContext>

    )
}
export default UserForm