import moment from "moment-jalaali";

const oneDaySpan = (24*60*60)
export function ServerDateToDay(serverDate: number) {
    const timestampValue = serverDate * 1000
    const year = new Date(timestampValue).getFullYear();
    const month = new Date(timestampValue).getMonth()+1;
    const day = new Date(timestampValue).getDate();
    const today = new Date(`${year}-${month < 10 ? "0"+month : month}-${day < 10 ? "0"+day : day}T00:00:00`).getTime()
    return today / 1000
}

export const TimestampToTime = (timestamp: number, date?: number) => {
    if (!timestamp) return "00:00";
    const timestampValue = timestamp * 1000
    const hours = new Date(timestampValue).getHours()
    const minutes = new Date(timestampValue).getMinutes()
    if (date && ((date + oneDaySpan) === timestamp)) return "24:00"
    const time = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
    return time;
}

export const timestampToHours = (timestamp: number, date?: number) => {
    if (!timestamp) return 0;
    const hours = new Date(timestamp*1000).getHours()
    if (!date) return hours
    if (timestamp >= (date + oneDaySpan) && hours === 0) return 24
    return hours
}

export const timestampToMinutes = (timestamp: number) => {
    if (!timestamp) return 0;
    return new Date(timestamp*1000).getMinutes()
}

// const defaultWidth = (24*60*60*1000) - 60000 // 1 Day - 1 minutes


export function TimeToTimestamp(time: string, serverDate: number) {
    const year = new Date(serverDate*1000).getFullYear();
    const month = new Date(serverDate*1000).getMonth()+1;
    const day = new Date(serverDate*1000).getDate();
    const today = new Date(`${year}-${month < 10 ? "0"+month : month}-${day < 10 ? "0"+day : day}T${time}:00`).getTime()
    return today / 1000
}


// export function TimeToDuration(hours: string, minutes: string) {
//     return ((+hours * 60) + +minutes) * 60 * 1000;
// }

export function DurationToTime(duration: number, serverDate: number, type: boolean = false) {
    const yearServer = new Date(serverDate*1000).getFullYear();
    const monthServer = new Date(serverDate*1000).getMonth()+1;
    const dayServer = new Date(serverDate*1000).getDate();
    const todayServer = new Date(`${yearServer}-${monthServer < 10 ? "0"+monthServer : monthServer}-${dayServer < 10 ? "0"+dayServer : dayServer}T00:00:00`).getTime()

    const timestamp = Math.ceil((todayServer+Math.abs(duration*1000)))
    const hours = new Date(timestamp).getHours();
    const minutes = new Date(timestamp).getMinutes();
    if (isNaN(hours) || isNaN(hours)) return "00:00"
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`
}

// export function EnterExitCalculate(
//     date: any[], 
//     setValue: React.Dispatch<React.SetStateAction<{enter: null | number, exit: null | number}>>,
//     serverDate: number
// ) {
//     if (!date.length) {
//         return setValue({
//             enter: null,
//             exit: null,
//         })
//     }
//     const taskLength = date.length;
//     const enter = date?.[0]?.start;
//     const exit = date?.[taskLength-1]?.end ?? serverDate
//     setValue({
//         enter,
//         exit,
//     })
// }

export function distanceTimes(start: number, end: number, day: number) {
    if (!start || !end || !day) return "00:00";
    const year = new Date(day).getFullYear()
    const month = new Date(day).getMonth()+1
    const dayInMouth = new Date(day).getDate()
    const timeDate = `${year}-${month < 10 ? "0"+month : month}-${dayInMouth < 10 ? "0"+dayInMouth : dayInMouth}T`;
    var resultDate = new Date(end - start + (new Date(`${timeDate}00:00:00`).getTime()));
    var resultTimeString = resultDate.toTimeString().slice(0, 5);
    return resultTimeString
}

export function todayCalculate(serverDate: number) {
    const timestampValue = serverDate * 1000
    const year = new Date(timestampValue).getFullYear();
    const month = new Date(timestampValue).getMonth()+1;
    const day = new Date(timestampValue).getDate();
    return new Date(`${year}-${month < 10 ? "0"+month : month}-${day < 10 ? "0"+day : day}T00:00:00`).getTime() / 1000
}

// export function TimestampRoundSecond(timestamp: number) {
//     const year = new Date(timestamp).getFullYear()
//     const month = new Date(timestamp).getMonth()+1
//     const day = new Date(timestamp).getDate();
//     const hours = new Date(timestamp).getHours()
//     const minutes = new Date(timestamp).getMinutes()
//     return new Date(`${year}-${month < 10 ? "0"+month : month}-${day < 10 ? "0"+day : day}T${hours < 10 ? "0"+hours : hours}:${minutes < 10 ? "0"+minutes : minutes}:00`).getTime() + 60000
// }

// export function TimestampToPixel(timestamp: number, parentWidth: number) {
//     return Math.round(timestamp / defaultWidth * (parentWidth))
// }

// export function PixelToTimestamp(pixel: number, parentWidth: number, date?: number) {
//     return Math.round((pixel * defaultWidth) / parentWidth + (date ?? 0))
// }

export const distanceTimesByTime = (start: string, end: string, serverDate: number) => {
    const startStamp = TimeToTimestamp(start, serverDate)
    const endStamp = TimeToTimestamp(end, serverDate)
    const distanceSpan = endStamp - startStamp
    const time = DurationToTime(distanceSpan, serverDate)
    return time;
}

export const distanceTimesToDuration = (start: string, end: string, serverDate: number) => {
    const startStamp = TimeToTimestamp(start, serverDate)
    const endStamp = TimeToTimestamp(end, serverDate)
    return endStamp - startStamp
}

export const timeToDuration = (time: string) => {
    const hours = time.split(":")[0]
    const minutes = time.split(":")[1]
    return (+hours*60*60) + (+minutes*60)
}

export const durationToTimeString = (time: string) => {

}

export function yearMonthToTimeStamp(yearMonth: string) {
    const day = 1
    const [year, month] = yearMonth.split("-")
    const startThisMonth = month;
    const endAfterMonth = (+month === 12) ? 1 : (+month + 1)
    const forYear = (+month === 12) ? (+year + 1) : year
    const startSolarDate = `${year}/${startThisMonth}/${day}`
    const endSolarDate = `${forYear}/${endAfterMonth}/${day}`
    const startDateToEpoch = moment(startSolarDate, "jYYYY/jM/jD").unix()
    const endDateToEpoch = moment(endSolarDate, "jYYYY/jM/jD").unix() - 60
    return {from: startDateToEpoch, to: endDateToEpoch}
}

export function durationsToTime (data: number) {
    const minutes = data / 60
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(remainingMinutes).padStart(2, "0")}`

    // const newData: any = {}
    // for (const key in data) {
    //     if (!isNaN(data[key])) {
    //         const minutes = data[key] / 60
    //         const hours = Math.floor(minutes / 60);
    //         const remainingMinutes = minutes % 60;
    //         newData[key] = `${String(hours).padStart(2, "0")}:${String(remainingMinutes).padStart(2, "0")}`
    //     }
    //     else newData[key] = data[key]
    // }
    // return newData
}