import { FormType, ProjectValuesType } from "./Types"

const validation = (values: ProjectValuesType) => {
    const error: {
        name: string;
    } = {
        name: "",
    }

    if (!values.name.trim()) {
        error.name = "نام پروژه را وارد کنید"
    } else if (values.name.length < 5 || values.name.length > 25) {
        error.name = "نام پروژه باید بین 5 تا 25 کاراکتر باشد"
    }

    return error
}

export default validation