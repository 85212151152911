import { useTheme } from "next-themes"
import Button from "../../Components/Common/Button/Button"
import Icon from "../../Components/Common/Icon/Icon"
import Switch from "../../Components/Common/Switch/Switch"
import { Fragment, useEffect, useRef, useState } from "react"
// import Select from "../../Components/Common/Select/Select"
import InputValidate from "../../Components/Common/InputValidate/InputValidate"
import ReactSelect from "../../Components/Common/ReactSelect/ReactSelect"
import Input from "../../Components/Common/Input/Input"
import Dialog from "../../Components/Common/Dialog/Dialog"
import { ModalOpenAtom, ModalParamsAtom, ModalTypeAtom, UserDataState } from "../../Recoil/Atoms"
import { useRecoilState } from "recoil"
import { GetEmployees, GetUsers } from "../../axios/Users.api"
import Loading from "../../Components/Common/Loading/Loading"
import { Link, Outlet } from "react-router-dom"
import { typeSearchEmployeesParamsType, UserProps } from "./Types"
import { EmployeesAtom, SearchEmployeesParamsAtom } from "../../Recoil/users.atom"
import TextTag from "../../Components/Common/TextTag/TextTag"
// import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react"
    
export type FiltersInput = {
    type: React.ElementType;
    hidden?: boolean;
    props: object
}[]


export default function Users() {

    const {theme, setTheme} = useTheme()
    const [userData, setUserData] = useRecoilState(UserDataState)
    const [modalType, setModalType] = useRecoilState(ModalTypeAtom)
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    const [modalParams, setModalParams] = useRecoilState(ModalParamsAtom);
    const [employees, setEmployees] = useRecoilState(EmployeesAtom)
    const [searchEmployeesParams, setSearchEmployeesParams] = useRecoilState(SearchEmployeesParamsAtom)
    const [loading, setLoading] = useState(false)
    
    const addUser = () => {
        setModalType("AddUser")
        setIsModalOpen(true)
    }

    const faRolesTitle = {
        admin: "ادمین",
        manager: "مدیر",
        user: "کاربر",
    }
    const viewUserHandler = (user: UserProps) => {
        setIsModalOpen(true)
        setModalType("EditUser")
        setModalParams(user.id)
    }

    const getEmployeesHandler = (newParams?: typeSearchEmployeesParamsType) => {
        setLoading(true)
        GetEmployees(newParams)
        .then((res) => {
            // console.log(res)
            setLoading(false)
            setEmployees(res.data.rows)
        })
        .catch((err) => {
            setLoading(false)
            // console.log(err)
        })
    }

    useEffect(() => {
        getEmployeesHandler()
    }, [])

    const deleteFilterHandler = (key: keyof typeSearchEmployeesParamsType) => {
        const newParams = {...searchEmployeesParams}
        delete newParams[key]
        setSearchEmployeesParams(newParams)
        getEmployeesHandler(newParams)
    }
    
    const faFilterValue = {
        username: "نام کاربری",
        chatId: "چت آیدی",
        phone: "موبایل",
        role: "نقش کاربر",
        active: "وضعیت فعال",
        accessTimeChange: "تغییر تایم تردد",
        timeRecordDelays: "تاخیر ثبت تردد",
        remoteEligibility: "دسترسی دورکاری",
        missionEligibility: "دسترسی ماموریت",
        inComponyApprovalMethod: "نوع بررسی کار درشرکت",
        remoteApprovalMethod: "نوع بررسی دورکاری",
        missionApprovalMethod: "نوع بررسی ماموریت",
        logicEnter: "منطق ورود",
        logicExit: "منطق خروج",
    }

    return (
        <div className="px-2 border bg-gray-100 dark:bg-gray-600 border-zinc-400 dark:border-zinc-800 rounded-none lg:rounded-md">
            <div className="w-full py-2 flex items-center justify-between border-b border-zinc-300 dark:border-zinc-700">
                <h3 className="text-xl text-gray-500 dark:text-gray-300">کاربران</h3>
                <div className="flex items-center justify-center gap-2">
                    <Button
                        title="فیلتر"
                        icon="text-right"
                        direction="row_reverse"
                        color={theme as "dark"}
                        // expand="full"
                        loading={false}
                        iconWidth="1.8rem"
                        iconHeight="1.8rem"
                        onClick={() => {
                            setIsModalOpen(true)
                            setModalType("UserFilter")
                        }}
                        classNames={{
                            container: "!w-32 !h-10 !rounded-[4px] !flex !px-2 !py-0 transition-all duration-200",
                            section: "!text-sm"
                        }}
                    />
                    {(userData.role === "admin" || userData.role === "manager") &&
                        <Link
                            to="add"
                            className="w-32 h-10 rounded-[4px] flex items-center justify-center px-2 py-0 transition-all duration-200 bg-gray-300 hover:bg-gray-400 dark:bg-gray-800 hover:dark:bg-gray-900 cursor-pointer"
                        >
                            <TextTag as="span" className="flex-auto flex items-center justify-center cursor-pointer">
                                افزودن کاربر
                            </TextTag>
                            <Icon
                                name="plus-lg"
                                width="1.7rem"
                                height="1.7rem"
                                color={theme === "dark" ? "#cbd5e1" : "#475569"}
                                classNames="flex-none"
                            />
                        </Link>
                    }
                </div>
            </div>

            <div className="w-full flex flex-wrap items-center justify-start gap-4 p-2">
                {Object.entries(searchEmployeesParams).map(([keyy, value]) =>
                    <div key={keyy} className="p-2 gap-2 flex items-center justify-between bg-sky-500 bg-opacity-50 rounded-full ring-1 ring-sky-600 hover:bg-opacity-60 transition-all">
                        <TextTag as="span">{faFilterValue[keyy as "username"]}</TextTag>
                        <Button
                            icon="x"
                            color="gray"
                            fill="transparent"
                            iconColor={theme === "dark" ? "white" : "#075985"}
                            iconHover={theme === "dark" ? "white" : "#075985"}
                            onClick={() => deleteFilterHandler(keyy as keyof typeSearchEmployeesParamsType)}
                            classNames={{
                                container: "!w-6 !h-4 !rounded-full"
                            }}
                        />
                    </div>
                )}
            </div>

            <div className="w-full">
                <div className="w-full h-10 sticky top-20 bg-gray-500 bg-opacity-30 backdrop-blur-lg items-center justify-center sm:px-2 py-2 text-sm grid grid-cols-3 shadow-md shadow-[#00000026] rounded-[4px] z-10">
                    <TextTag className="col-span-1 grid place-content-center border-l border-zinc-400 dark:border-zinc-700 whitespace-nowrap">نام</TextTag>
                    <TextTag className="col-span-1 grid place-content-center border-l border-zinc-400 dark:border-zinc-700">نقش</TextTag>
                    <TextTag className="col-span-1 grid place-content-center">وضعیت</TextTag>
                    {/* <span className="col-span-2 place-content-center border-l border-zinc-400 dark:border-zinc-700 hidden sm:grid">عملکرد</span>
                    <span className="col-span-2 place-content-center border-l border-zinc-400 dark:border-zinc-700 hidden sm:grid">کار روزانه</span>
                    <span className="col-span-2 place-content-center border-l border-zinc-400 dark:border-zinc-700 hidden sm:grid">ساعات تردد</span> */}
                    {/* <span className="grid place-content-center"></span> */}
                </div>
                {!loading ? 
                    employees.length ?
                        employees.map((user, index) => 
                            <div key={index} className={`h-14 ${index !== employees.length - 1 ? "border-b border-zinc-300 dark:border-zinc-700" : ""} items-center justify-start sm:px-2 py-2 text-sm grid grid-cols-3`}>
                                <Link
                                    to={`update/${user.id}`}
                                    className="col-span-1 grid place-content-center border-l border-zinc-300 dark:border-zinc-700 whitespace-nowrap overflow-hidden text-gray-600 dark:text-gray-300 hover:!text-sky-500 cursor-pointer transition-colors"
                                >
                                    {user.username}
                                </Link>
                                <TextTag className="col-span-1 grid place-content-center border-l border-zinc-300 dark:border-zinc-700">
                                    {faRolesTitle[user.role as "manager"]}
                                </TextTag>
                                <span className="col-span-1 grid place-content-center">
                                    {/* <Switch
                                        value={user.active}
                                        disabled
                                    /> */}
                                    <Icon
                                        name={user.active ? "check-circle" : "x-circle"}
                                        width="2.2rem"
                                        height="2.2rem"
                                        color={user.active ? "#10b981" : "#f43f5e"}
                                        // classNames="w-10 text-gray-800"
                                    />
                                </span>
                                {/* <Link to={`/${user.id}`} className="col-span-2 place-content-center border-l border-zinc-300 dark:border-zinc-700 text-sky-400 hidden sm:grid">عملکرد</Link>
                                <Link to={`/${user.id}`} className="col-span-2 place-content-center border-l border-zinc-300 dark:border-zinc-700 text-sky-400 hidden sm:grid">کار روزانه</Link>
                                <Link to={`/${user.id}`} className="col-span-2 place-content-center border-l border-zinc-300 dark:border-zinc-700 text-sky-400 hidden sm:grid">ترددها</Link>
                                <span className="grid place-content-center cursor-pointer group">
                                    <Button
                                        icon="eye-fill"
                                        color="gray"
                                        fill="transparent"
                                        iconColor={"#9ca3af"}
                                        iconHover={"#6b7280"}
                                        onClick={() => viewUserHandler(user)}
                                        classNames={{
                                            container: "!w-10 !h-10 "
                                        }}
                                    />
                                </span> */}
                            </div>
                        ) :
                        <TextTag as="h3" className="w-full text-center py-32 text-gray-400">کاربر یافت نشد</TextTag> :
                    <Loading 
                        className="w-full h-52 grid place-content-center opacity-20"
                    />
                }
            </div>
            <Outlet />
        </div>
    )
}