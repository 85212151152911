import { useRecoilState, useRecoilValue } from "recoil"
import { TransmissionTimeAtom, WorkDto } from "../../../Recoil/Works.atom"
import { ServerDateState } from "../../../Recoil/Atoms"
import { useEffect, useRef, useState } from "react"
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { workTheme } from "../../../pages/Works/Works";
import WorkForm from "../../WorkForms/WorkForm";

const DescriptionsAccordion = ({
    work,
    selectedWork,
    setSelectedWork,
    overflow,
    works,
    setWorks,
    submitWorkForm,
    deleteHandler,
    justSee
}: {
    work: WorkDto,
    selectedWork: number,
    setSelectedWork: React.Dispatch<React.SetStateAction<number>>
    overflow: any,
    works: WorkDto[],
    setWorks: React.Dispatch<React.SetStateAction<WorkDto[]>>
    submitWorkForm: (item: any) => void
    deleteHandler: (id: number) => void
    justSee: boolean
}) => {

    const [timeLog, setTimeLog] = useRecoilState(TransmissionTimeAtom)
    const serverDate = useRecoilValue(ServerDateState)
    const defaultHeaderHeight = 96 
    const [height, setHeight] = useState(defaultHeaderHeight)
    const accordionRef = useRef<any>(null)

    const windowResizeHandler = () => {
        setHeight(selectedWork === work.id ? (accordionRef.current?.childNodes?.[0]?.clientHeight+4) : defaultHeaderHeight)
    }
    useEffect(() => {
        setHeight(selectedWork === work.id ? (accordionRef.current?.childNodes?.[0]?.clientHeight+4) : defaultHeaderHeight)
        window.addEventListener("resize", windowResizeHandler)
        if (!isMobile) {
            const desc = document.getElementById(`description_${selectedWork}`)
            // if (desc) {
            //     const parentOffsetTop = desc?.parentElement?.offsetTop
            //     const distanceFromTop = desc?.offsetTop - (parentOffsetTop ?? 0)
            //     accordionRef.current?.scrollTo(distanceFromTop, distanceFromTop);
            //     console.log(distanceFromTop)
            //     accordionRef.current?.scrollTop({top: 50, behavior: "smooth"});
            // }
            // desc?.scrollIntoView()
            // console.log(desc?.offsetTop)
            setTimeout(() => {
                desc?.scrollIntoView({behavior: "smooth"})
            }, 400)
        }
        
        return () => {
            window.removeEventListener("resize", windowResizeHandler)
        }
    }, [selectedWork])


    return (
        <div 
            id={`description_${work.id}`}
            ref={accordionRef}
            className={`w-full border-2 ${work.confirmed ? `border-${workTheme[work.type as "work"]}-700` : `border-rose-700`} ${overflow[work.id] ? "" : "overflow-hidden"} rounded-md mb-2 transition-[height] duration-300`}
            style={{
                height
            }}
        >
            {/* <div className={`border-t ${!work.active ? `border-rose-700` : `border-${work.theme}-700`}`}> */}
                <WorkForm
                    work={work}
                    active={selectedWork === work.id}
                    sizeChanged={(id) => {
                        // const height = selectedWork === work.id ? (accordionRef.current?.childNodes?.[1]?.clientHeight+defaultHeaderHeight) : 40
                        // console.log(height)
                        windowResizeHandler()
                    }}
                    setActive={(id: number) => {
                        setSelectedWork(id)
                    }}
                    onClose={(work) => {
                        setSelectedWork(0)
                        // if (work.type === "temporary") {
                        //     const newWorks = [...works].filter((workItem) => workItem.confirmed)
                        //     setWorks(newWorks)
                        // }
                    }}
                    onAccept={submitWorkForm}
                    onDelete={deleteHandler}
                    justSee={justSee}
                />
            {/* </div> */}
        </div>
    )
}

export default DescriptionsAccordion