// Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { FreeMode, Pagination, Mousewheel } from 'swiper/modules';
// Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/pagination';



import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


import './style.scss';
import { useEffect, useRef, useState } from 'react';
import TextTag from '../TextTag/TextTag';
import Button from '../Button/Button';
import { useTheme } from 'next-themes';

const TimePickerTouch = ({
    label,
    value,
    onAccess,
    onCancel,
}: {
    label?: string
    value: string
    onAccess?: (time: string) => void
    onCancel?: () => void
}) => {

    
    const {theme, setTheme} = useTheme();
    const minutesRef = useRef<any>(null);
    const hoursRef = useRef<any>(null);
    // const [time, setTime] = useState({
    //     hours: 0,
    //     minutes: 0,
    // })
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [touch, setTouch] = useState({
        hours: false,
        minutes: false,
    })
    // const handleSlideChange = (data: any, name: "hours" | "minutes") => {
    //     const number = data.activeIndex
    //     setTime({
    //         ...time,
    //         [name]: number
    //     })
    // };
    const hoursChangeHandler = (data: any) => {
        const number = data.activeIndex
        setHours(number)
    }

    const minutesChangeHandler = (data: any) => {
        const number = data.activeIndex
        setMinutes(number)
    }

    const changeHoursByClick = (hours: number) => {
        if (hours < 0) return
        if (hoursRef.current) {
            // setTimeout(() => {
                hoursRef.current.swiper.slideTo(hours);
            // }, 100)
        }
    }
    const changeMinutesByClick = (minutes: number) => {
        if (minutes < 0) return
        if (hoursRef.current) {
            // setTimeout(() => {
                minutesRef.current.swiper.slideTo(minutes);
            // }, 100)
        }
    }

    useEffect(() => {
        if (minutesRef.current && hoursRef.current) {
            setTimeout(() => {
                minutesRef.current.swiper.slideTo(+value?.split(":")?.[1]);
                hoursRef.current.swiper.slideTo(+value?.split(":")?.[0]);
            }, 100)
        }
    }, [])

    return (
        <div className='w-80 bg-gray-100 dark:bg-gray-800 rounded-md flex flex-col items-center justify-center'>
            {label &&
                <TextTag as='h4' className="w-full flex-none p-2 border-b border-gray-200 dark:border-gray-700">{label}</TextTag>
            }
            <div className='w-full flex items-center justify-between px-[75px] py-3'>
                <TextTag>ساعت</TextTag>
                <TextTag>دقیقه</TextTag>
            </div>
            <div 
                className='w-60 flex-none flex items-center justify-center mb-5'
            >
                <div
                    onTouchStart={() => setTouch({
                        ...touch,
                        hours: true,
                    })}
                    onTouchEnd={() => setTouch({
                        ...touch,
                        hours: false,
                    })} 
                    onPointerDown={() => setTouch({
                        ...touch,
                        hours: true,
                    })}
                    onPointerUp={() => setTouch({
                        ...touch,
                        hours: false,
                    })}
                    onPointerOut={() => setTouch({
                        ...touch,
                        hours: false,
                    })}
                    className='w-20 h-80'
                >
                <Swiper
                    ref={hoursRef}
                    direction={'vertical'}
                    cssMode
                    mousewheel={true}
                    // scrollbar={{
                    //   hide: true,
                    // }}
                    // initialSlide={+value?.split(":")?.[1] ?? 0}                        
                    slidesPerView={3}
                    spaceBetween={20}
                    // freeMode={true}
                    onSlideChange={hoursChangeHandler} // add the event handler here
                    pagination={false}
                    modules={[Pagination, Mousewheel]}
                    className="mySwiper !bg-transparent"
                >
                    {[-1, ...Array(24).keys(), -1].map((e, index) => 
                            <SwiperSlide 
                                key={index} 
                                onClick={() => changeHoursByClick(e)}
                                // className={`select-none !bg-transparent !text-4xl ${touch.hours ? "text-sky-300" : (time.hours === e ? "!text-gray-700 dark:!text-gray-200" : "!text-gray-300 dark:!text-gray-600") }`}
                                className={`select-none !bg-transparent !text-4xl ${hours === e ? "!text-gray-700 dark:!text-gray-200" : "!text-gray-300 dark:!text-gray-600"} cursor-pointer rounded-md hover:!bg-opacity-10 hover:!bg-gray-500`}
                            >{+e >= 0 ? String(e).padStart(2, "0") : ""}</SwiperSlide>
                    )}
                </Swiper>
                </div>
                <div className='text-5xl p-5 text-gray-700 dark:text-gray-200 select-none'>:</div>
                <div 
                    onTouchStart={() => setTouch({
                        ...touch,
                        minutes: true,
                    })}
                    onTouchEnd={() => setTouch({
                        ...touch,
                        minutes: false,
                    })}
                    onPointerDown={() => setTouch({
                        ...touch,
                        minutes: true,
                    })}
                    onPointerUp={() => setTouch({
                        ...touch,
                        minutes: false,
                    })}
                    onPointerOut={() => setTouch({
                        ...touch,
                        minutes: false,
                    })}
                    className={`w-20 h-80`}
                >
                    <Swiper
                        ref={minutesRef}
                        direction={'vertical'}
                        cssMode
                        mousewheel={true}
                        // scrollbar={{
                        //   hide: true,
                        // }}
                        // initialSlide={+value?.split(":")?.[1] ?? 0}                        
                        slidesPerView={3}
                        spaceBetween={20}
                        // freeMode={true}
                        onSlideChange={minutesChangeHandler} // add the event handler here
                        pagination={false}
                        modules={[Pagination, Mousewheel]}
                        className="mySwiper !bg-transparent"
                    >
                        {[-1, ...Array(60).keys(), -1].map((e, index) => 
                                <SwiperSlide 
                                    key={index} 
                                    onClick={() => changeMinutesByClick(e)}
                                    className={`select-none !bg-transparent !text-4xl ${minutes === e ? "!text-gray-700 dark:!text-gray-200" : "!text-gray-300 dark:!text-gray-600"} cursor-pointer rounded-md hover:!bg-opacity-10 hover:!bg-gray-500`}
                                >{+e >= 0 ? String(e).padStart(2, "0") : ""}</SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
            <div className='w-full h-14 flex-none border-t border-gray-200 dark:border-gray-600 flex items-center justify-end gap-2 px-2'>
                <Button
                    title="انصراف"
                    titleColor={theme === "dark" ? "#d1d5db" : "#374151"}
                    titleHover={theme === "dark" ? "#d1d5db" : "#374151"}
                    color='gray'
                    fill='transparent'
                    onClick={() => {
                        onCancel && onCancel()
                    }}
                    classNames={{
                        container: "!w-16 !h-10 hover:!bg-gray-200 dark:hover:!bg-gray-900 !rounded-md transition-all duration-200",
                        section: "!text-sm"
                    }}
                />
                <Button
                    title="ذخیره"
                    titleColor={theme === "dark" ? "#d1d5db" : "#374151"}
                    titleHover={theme === "dark" ? "#d1d5db" : "#374151"}
                    color='gray'
                    fill='transparent'
                    onClick={() => {
                        onAccess && onAccess(`${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`)
                    }}
                    classNames={{
                        container: "!w-16 !h-10 hover:!bg-gray-200 dark:hover:!bg-gray-900 !rounded-md transition-all duration-200",
                        section: "!text-sm"
                    }}
                />
            </div>
        </div>

    )
}

export default TimePickerTouch;