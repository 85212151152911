
import { Dialog as DialogComponent, Transition } from "@headlessui/react";
import { AnyMxRecord } from "dns";
import { Fragment, PropsWithChildren, useEffect, useRef, useState } from "react"

const Dialog = ({children, open, setOpen, className, dir}: PropsWithChildren & {open: any, setOpen: any, className?: any, dir?: any}) => {
    
    const cancelButtonRef = useRef<null | HTMLParagraphElement>(null);

    return (
        <Transition.Root show={open} as={Fragment}>
        <DialogComponent as="div" className="relative z-[999]" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 backdrop-blur-sm bg-zinc-600 dark:bg-zinc-800 dark:bg-opacity-70 bg-opacity-50 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0" dir={dir ?? "rtl"}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-300"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogComponent.Panel className={"w-fit"}>
                            {children}
                        </DialogComponent.Panel>
                    </Transition.Child>
                </div>
            </div>
        </DialogComponent>
    </Transition.Root>
    )
}

export default Dialog