import { useRecoilState, useRecoilValue } from "recoil"
import Button from "../../Button/Button"
import TextTag from "../../TextTag/TextTag"
import WorkTimeAggregations from "../../WorkTimeAggregations/WorkTimeAggregations"
import { DateState, ModalOpenAtom, ModalParamsAtom, ServerDateState, UserDataState } from "../../../../Recoil/Atoms"
import { useTheme } from "next-themes"
import Icon from "../../Icon/Icon"
import { deleteWork } from "../../../../axios/Work.api"
import { DailyLogAtom, PinsStateAtom, TimeFrameAtom, TimeSpanDurationAtom, TransmissionTimeAtom, WorksInDayAtom } from "../../../../Recoil/Works.atom"
import { findExitTime } from "../../../../Utils/FindExiteTime"
import { timestampToHours } from "../../../../Utils/convertTime"
import { deleteInOutLog, updateInOutLog } from "../../../../axios/InOutLog.api"
import { InOutLogAtom } from "../../../../Recoil/InOutLogAtom"
import useGenerateTimeFrame from "../../../../hooks/useGenerateTimeFrame"
import useNotificationsUpdate from "../../../../hooks/useNotificationsUpdate"

const DeleteExitInOutLog = () => {
    const serverDate = useRecoilValue(ServerDateState)
    const [user, setUser] = useRecoilState(UserDataState)
    const [date, setDate] = useRecoilState(DateState)
    const [works, setWorks] = useRecoilState(WorksInDayAtom)
    const [timeLog, setTimeLog] = useRecoilState(TransmissionTimeAtom)
    const [timeFrame, setTimeFrame] = useRecoilState(TimeFrameAtom)
    const [timeSpanDuration, setTimeSpanDuration] = useRecoilState(TimeSpanDurationAtom)
    
    const updateNotification = useNotificationsUpdate()
    const generateTimeFrame = useGenerateTimeFrame()
    const [modalParams, setModalParams] = useRecoilState(ModalParamsAtom);
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    const {theme} = useTheme()
    const [inOutLogs, setInOutLogs] = useRecoilState(InOutLogAtom)
    const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom)
    const [pins, setPins] = useRecoilState(PinsStateAtom)
    const oneDay = 60*60*24;

    const deleteHandler = () => {
        updateInOutLog(modalParams, {exit: null})
            .then((res) => {
                const inOutLogsClone = [...inOutLogs];
                const updatedLogIndex = inOutLogsClone.findIndex(log => log.id === modalParams)
                inOutLogsClone.splice(updatedLogIndex, 1)
                inOutLogsClone.splice(updatedLogIndex, 0, res.data)
                setInOutLogs(inOutLogsClone)
                updateNotification({dailyLogId: dailyLog.id, type: "inOutLog"})
                generateTimeFrame(inOutLogsClone, works, date, pins)
                setDailyLog({
                    ...dailyLog,
                    finalized: 0
                })
                setIsModalOpen(false) 
            })
            .catch((error) => {
                console.log(error)
                setIsModalOpen(false) 
            })
    }
    return (
        <div className="flex flex-col bg-gray-100 dark:bg-gray-700 rounded-md">
            <div className="flex-none flex items-center justify-center p-2">
                <Icon
                    name="exclamation-circle"
                    width="5rem"
                    height="5rem"
                    color="#f43f5e"
                />
            </div>
            <TextTag as="h4" className="py-6 px-16">
                واقعا میخوای حذفش کنی?!
            </TextTag>
            <div className=" flex items-center justify-center gap-3 p-3">
                <Button
                    title="آره"
                    color={theme === "dark" ? "dark" : "light"}
                    onClick={deleteHandler}
                    classNames={{
                        container: "!w-28 !h-10 !rounded-[3px]",
                        section: "!text-sm"
                    }}
                />
                <Button
                    title="نه"
                    color={theme === "dark" ? "dark" : "light"}
                    onClick={() => setIsModalOpen(false)}
                    classNames={{
                        container: "!w-28 !h-10 !rounded-[3px]",
                        section: "!text-sm"
                    }}
                />

            </div>
        </div>
    )
}

export default DeleteExitInOutLog