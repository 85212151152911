import { LocalizationProvider, TimePicker as MuiTimePicker, PickerChangeHandlerContext, TimeValidationError } from "@mui/x-date-pickers";
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { useTheme } from "next-themes";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GlobalStyles, TextField } from "@mui/material";
import TextTag from "../TextTag/TextTag";
import dayjs, { Dayjs } from "dayjs";
import { timestampToHours, timestampToMinutes } from "../../../Utils/convertTime";
import { useRecoilState, useRecoilValue } from "recoil";
import { NumberInputFocusedAtom, ServerDateState } from "../../../Recoil/Atoms";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Dialog from "../Dialog/Dialog";
import Button from "../Button/Button";
import TimePickerTouch from "../TimePickerTouch/TimePickerTouch";
import Input from "../Input/Input";
import { inputSubmitAtom } from "../../../Recoil/dirtyAtom";

  
const TimePicker = ({
    name,
    insideLabel,
    label,
    error,
    act,
    value,
    onChange,
    disabled = false,
    picker,
    // formSubmit
}: {
    name: string
    insideLabel?: string
    label?: string
    error?: string
    act?: boolean
    value: string
    onChange?: (value: any) => void
    disabled?: boolean
    picker: {
        onAccept: (time: string, name: string, cb?: Function) => void
    },
    // formSubmit?: boolean
}) => {

    const {theme, setTheme} = useTheme();
    // const serverDate = useRecoilValue(ServerDateState)
    // const pickerRef = useRef<any>(null); // استفاده از ref برای کنترل رفتار
    const [open, setOpen] = useState(false)
    // این رو مجبور شدم اضافه کنم برای اینکه بفهمم فرم کی سابمیت میشه تا اینپوت های مربوط به شروع و پایان زمان رو آپدیت کنم. بعدا یه فکر بهتری براش میکنم
    const [inputSubmit, setInputSubmit] = useState(false)

    // const acceptHandler = (value: Dayjs | null, context: PickerChangeHandlerContext<TimeValidationError>) => {
    //     const hours = value?.get("hours").toString().padStart(2, "0") ?? "00"
    //     const minutes = value?.get("m").toString().padStart(2, "0") ?? "00"
    //     const time = `${hours}:${minutes}`
    //     picker.onAccept && picker.onAccept(time, name)
    // }

    const mobileAcceptHandler = (time: string) => {
        setOpen(false)
        picker.onAccept && picker.onAccept(time, name, (newData: any) => {
            // console.log(newData)
            setInputSubmit((prev: any) => !prev)
        })
    }

    // const handleOpen = () => {
    //     console.log('Time picker clicked'); // ثبت لاگ در کنسول
    // };


    // const handleClick = (event: any) => {
    //     event.preventDefault(); // جلوگیری از عملکرد پیش‌فرض (باز شدن دراپ‌دان)
    //     console.log('Time picker clicked'); // ثبت لاگ در کنسول
    // };

    return (
        <div>
            {label && <TextTag className="px-2 mb-1 text-sm text-start">{label}</TextTag>}
            {/* {!isMobile ?
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem 
                        label={false}
                        sx={{
                            '& .MuiTypography-root': {
                                // padding: "0 8px",
                            },
                            "& .MuiFormControl-root": {
                                // marginTop: "4px"
                            },
                        }}
                    >
                        
                        <DesktopTimePicker 
                            // defaultValue={dayjs('2022-04-17T15:30')} 
                                ref={pickerRef} // استفاده از ref برای کنترل باز شدن
                                ampm={false}
                                views={['hours', 'minutes']} 
                                onAccept={acceptHandler}
                                disabled={disabled}
                                minutesStep={1}
                                onOpen={handleOpen}
                                // localeText={{}}
                                value={
                                    dayjs()
                                        .hour((value ? Number(value.split(":")?.[0]) : 0))
                                        .minute((value ? Number(value.split(":")?.[1]) : 0))
                                }
                                slotProps={{
                                    popper: {
                                        sx: {
                                            '& .MuiPaper-root': {
                                                backgroundColor: theme === "dark" ? '#1f2937' : "white",
                                                color: theme === "dark" ? 'white!important' : "#374151",
                                                // flexDirection: "row-reverse"
                                            },
                                            '& .MuiPickersClock-root': {
                                            color: 'red', // تغییر رنگ ساعت
                                            },
                                            "& .MuiTouchRipple-root": {
                                                color: "white",
                                            },
                                            "& .MuiMultiSectionDigitalClock-root": {
                                                // display: "flex",
                                                flexDirection: "row-reverse",
                                                // backgroundColor: "red",
                                            },
                                            "& .MuiMultiSectionDigitalClockSection-root": {
                                                // overflow: "hidden",
                                            },
                                            "& .MuiDialogActions-root": {
                                                // width: "100%",
                                                // height: "100%",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "0"
                                                // overflow: "hidden",
                                            },
                                            "& .MuiButton-root": {
                                                width: "100%",
                                                height: "40px",
                                                // alignItems: "center",
                                                // justifyContent: "center",
                                                // padding: "0"
                                                // overflow: "hidden",
                                            }
                                        }
                                    }
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: "38px",
                                        backgroundColor: theme === "dark" ? '#1f2937' : "#d1d5db",
                                        padding: "5px",
                                        '& fieldset': {
                                        border: `1px solid ${theme === "dark" ? '#030712' : "#9ca3af"}`
                                        },
                                        '&:hover fieldset': {
                                            border: `1px solid ${theme === "dark" ? '#030712' : "#9ca3af"}`
                                        },
                                        '&.Mui-focused fieldset': {
                                        //   borderColor: 'red', // Change border color when focused
                                        border: `1px solid ${theme === "dark" ? '#030712' : "#9ca3af"}`
                                        },
                                    },
                                    '& .MuiInputBase-input': {
                                        padding: "0 10px",
                                        color: theme === "dark" ? 'white' : "black"
                                    },
                                    '& .MuiIconButton-edgeEnd': {
                                        color: theme === "dark" ? '#9ca3af' : "#4b5563"
                                    },
                                }}
                        /> */}
                            {/* <MuiTimePicker
                                ampm={false}
                                views={['hours', 'minutes']} 
                                onAccept={acceptHandler}
                                value={
                                    dayjs()
                                        .hour((value ? Number(value.split(":")?.[0]) : 0))
                                        .minute((value ? Number(value.split(":")?.[1]) : 0))
                                }
                                slotProps={{
                                    popper: {
                                        sx: {
                                            '& .MuiPaper-root': {
                                                backgroundColor: theme === "dark" ? '#1f2937' : "white",
                                                color: theme === "dark" ? 'white!important' : "#374151"
                                            },
                                            '& .MuiPickersClock-root': {
                                            color: 'red', // تغییر رنگ ساعت
                                            },
                                            "& .MuiTouchRipple-root": {
                                                color: "white"
                                            },
                                            "& .MuiMultiSectionDigitalClockSection-root": {
                                                // overflow: "hidden",
                                            },
                                            "& .MuiDialogActions-root": {
                                                // width: "100%",
                                                // height: "100%",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "0"
                                                // overflow: "hidden",
                                            },
                                            "& .MuiButton-root": {
                                                width: "100%",
                                                height: "40px",
                                                // alignItems: "center",
                                                // justifyContent: "center",
                                                // padding: "0"
                                                // overflow: "hidden",
                                            }
                                        }
                                    }
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: "38px",
                                        backgroundColor: theme === "dark" ? '#1f2937' : "#d1d5db",
                                        padding: "5px",
                                        '& fieldset': {
                                        border: `1px solid ${theme === "dark" ? '#030712' : "#9ca3af"}`
                                        },
                                        '&:hover fieldset': {
                                            border: `1px solid ${theme === "dark" ? '#030712' : "#9ca3af"}`
                                        },
                                        '&.Mui-focused fieldset': {
                                        //   borderColor: 'red', // Change border color when focused
                                        border: `1px solid ${theme === "dark" ? '#030712' : "#9ca3af"}`
                                        },
                                    },
                                    '& .MuiInputBase-input': {
                                        padding: "0 10px",
                                        color: theme === "dark" ? 'white' : "black"
                                    },
                                    '& .MuiIconButton-edgeEnd': {
                                        color: theme === "dark" ? '#9ca3af' : "#4b5563"
                                    },
                                }}
                            /> */}
                    {/* </DemoItem>
                </LocalizationProvider> :
                <> */}
                    {/* <Button
                        // {...button}
                        title={value ?? "00:00"}
                        color={theme === "dark" ? "dark" : "light"}
                        disabled={disabled}
                        onClick={() => setOpen(true)}
                        classNames={{
                            container: "!w-full !h-[38px] !rounded-[3px] !border !border-gray-400 dark:!border-gray-900 transition-all",
                            section: "!text-lg !flex !items-center !justify-center !overflow-hidden"
                        }}
                    /> */}

                    
                <TimeInput 
                    inputName={name}
                    value={value}
                    setOpen={setOpen}
                    onChange={onChange}
                    onAccept={picker.onAccept}
                    insideLabel={insideLabel}
                    disabled={disabled}
                    inputSubmit={inputSubmit}
                    setInputSubmit={setInputSubmit}
                    // className={classNames?.button}
                />

                {/* </>
            } */}
            <Dialog
                open={open}
                setOpen={setOpen}
                dir="ltr"
            >
                <TimePickerTouch 
                    label={label}
                    onAccess={mobileAcceptHandler}
                    onCancel={() => {
                        setOpen(false)
                    }}
                    value={value}
                />
            </Dialog>
            {(error && act) && 
                <TextTag
                    as='small'
                    className={`w-full px-2 py-1 text-rose-500 dark:text-rose-500 text-end block`}
                    // style={styles?.error}
                >
                    {error}
                </TextTag>
            }
        </div>
    )
}


const TimeInput = ({inputName, value, onChange, setOpen, onAccept, insideLabel, className, disabled, inputSubmit, setInputSubmit}: any) => {
    
    const minutesRef = useRef<any>(null)
    const hoursRef = useRef<any>(null)
    const [numberInputFocused, setNumberInputFocused] = useRecoilState(NumberInputFocusedAtom)
    const [time, setTime] = useState({
        hours: "-",
        minutes: "-",
    })
    const [keepTime, setKeepTime] = useState({
        hours: "-",
        minutes: "-",
    })
    const [loading, setLoading] = useState(false)
    const timeChanged = `${keepTime.hours}:${keepTime.minutes}` !== `${time.hours}:${time.minutes}`
    // این رو مجبور شدم اضافه کنم برای اینکه بفهمم فرم کی سابمیت میشه تا اینپوت های مربوط به شروع و پایان زمان رو آپدیت کنم. بعدا یه فکر بهتری براش میکنم
    const [formSubmit, setFormSubmit] = useRecoilState(inputSubmitAtom)

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (disabled) return
        const {hours, minutes} = time
        let {value, name} = e.target
        if (+value < 10) value = String(+value).padStart(2, "0")
        else value = String(+value)
        // if (name === "hours" && +value > 23) value = "00"
        // if (name === "minutes" && +value > 59) value = "00"

        if (name === "hours") {
            if (+value > 23) value = "00"
            else if (+value < 0) value = "23"
        }
        if (name === "minutes") {
            if (+value > 59) {
                if (+hours === 23) {
                    setTime({
                        hours: "00",
                        minutes: "00"
                    })
                    onChange && onChange({
                        name: inputName,
                        hours: "00",
                        minutes: "00"
                    })
                    return;
                } else {
                    setTime({
                        hours: String(+hours+1).padStart(2, "0"),
                        minutes: "00"
                    })
                    onChange && onChange({
                        name: inputName,
                        hours: String(+hours+1).padStart(2, "0"),
                        minutes: "00"
                    })
                    return
                }
            }
            else if (+value < 0) {
                if (+hours === 0) {
                    setTime({
                        hours: "23",
                        minutes: "59"
                    })
                    onChange && onChange({
                        name: inputName,
                        hours: "23",
                        minutes: "59"
                    })
                    return
                } else {
                    setTime({
                        hours: String(+hours-1).padStart(2, "0"),
                        minutes: "59"
                    })
                    onChange && onChange({
                        name: inputName,
                        hours: String(+hours-1).padStart(2, "0"),
                        minutes: "59"
                    })
                    return
                }
            }
        }
        // if (name === "hours" && +value > 10) minutesRef.current.focus()

        setTime({
            ...time,
            [name]: value
        })
        onChange && onChange({
            ...time,
            name: inputName,
            [name]: value
        })
    }

    // const resetHandler = () =>{
    //     const splitValue = value?.split(":") ?? ["-", "-"]
    //     setTime({
    //         hours: splitValue[0],
    //         minutes: splitValue[1],
    //     })
    // }
    const keyDownHandler = (e: any) => {
        if (e.code === "ArrowRight") {
            minutesRef.current.focus()
            setTimeout(() => {
                minutesRef.current.select()
            }, 10)
        } else if (e.code === "ArrowLeft") {
            hoursRef.current.focus()
            setTimeout(() => {
                hoursRef.current.select()
            }, 10)
        }
    }

    useEffect(() => {
        // console.log(formSubmit)
        const splitValue = value?.split(":") ?? ["-", "-"]
        setTime({
            hours: splitValue[0],
            minutes: splitValue[1],
        })
        setKeepTime({
            hours: splitValue[0],
            minutes: splitValue[1],
        })
    }, [formSubmit])

    useEffect(() => {
        const splitValue = value?.split(":") ?? ["-", "-"]
        setTime({
            hours: splitValue[0],
            minutes: splitValue[1],
        })
        setKeepTime({
            hours: splitValue[0],
            minutes: splitValue[1],
        })
    }, [inputSubmit])

    // useEffect(() => {
    //     resetHandler()
    // }, [value])

    
    return(
        <div 
            onKeyDown={keyDownHandler}
            className={`w-full h-[38px] rounded-[4px] flex items-center justify-between transition-all duration-200 !border !border-gray-400 dark:!border-gray-950 bg-gray-300 dark:bg-gray-800 p-1 gap-2 ${className}`}
        >
            {insideLabel && <TextTag className="w-fit flex-none px-1">{insideLabel}</TextTag>}

            {/* چون نمیشه از یک فرم داخل یک فرم دیگه استفاده کرد اینجا مجبور شد موقتا از div استفاده کنم */}
            <div 
                // onSubmit={(e) => {
                //     e.preventDefault()
                //     onAccept(`${time.hours}:${time.minutes}`, inputName, (newData: any) => {
                //         // console.log(newData)
                //         setInputSubmit((prev: any) => !prev)
                //     })
                // }}
                className="flex-auto flex items-center justify-between gap-1 w-20 h-full border-gray-400 dark:border-gray-900"
            >
                
                <div className="flex items-center justify-center gap-1 w-20 h-full" dir="ltr">
                    <Input
                        ref={hoursRef}
                        type="text"
                        title="ساعت"
                        name="hours"
                        onFocus={() => {
                            hoursRef.current.type = "number"
                            hoursRef.current.select()
                            setNumberInputFocused(true)
                        }}
                        onBlur={() => {
                            hoursRef.current.type = "text"
                            setNumberInputFocused(false)
                        }}
                        // defaultValue={hours}
                        // onWheel={handleWheelOnInput}
                        value={time.hours}
                        onChange={changeHandler}
                        classNames={{
                            container: "h-full !border-none",
                            inputBox: "h-full rounded-none border-0 !bg-transparent",
                            input: `!border-gray-900 !bg-transparent p-0 text-center text-lg text-gray-600 dark:text-gray-200 no-spinner ${timeChanged ? "text-yellow-700 dark:text-yellow-500" : ""}`
                        }}
                    />
                    <TextTag>:</TextTag>
                    <Input
                        ref={minutesRef}
                        type="text"
                        title="دقیقه"
                        name="minutes"
                        onFocus={() => {
                            minutesRef.current.type = "number"
                            minutesRef.current.select()
                            setTimeout(() => setNumberInputFocused(true) , 0)
                            
                        }}
                        onBlur={() => {
                            minutesRef.current.type = "text"
                            setNumberInputFocused(false)
                        }}
                        // defaultValue={minutes}
                        value={time.minutes}
                        onChange={changeHandler}
                        classNames={{
                            container: "h-full",
                            inputBox: "h-full rounded-none border-0 !bg-transparent",
                            input: `!border-gray-900 !bg-transparent p-0 text-center text-lg text-gray-600 dark:text-gray-200 no-spinner ${timeChanged ? "text-yellow-700 dark:text-yellow-500" : ""}`
                        }}
                    />
                </div>
                <div className="w-fit flex items-center justify-center gap-1">
                    {(timeChanged && keepTime.hours !== "-" && keepTime.minutes !== "-") &&
                        <Button 
                            icon="check2"
                            iconWidth="1.7rem"
                            iconHeight="1.7rem"
                            color="gray"
                            fill="transparent" 
                            type="submit"
                            loading={loading}
                            onClick={(e) => {
                                e.preventDefault()
                                onAccept(`${time.hours}:${time.minutes}`, inputName, (newData: any) => {
                                    // console.log(newData)
                                    setInputSubmit((prev: any) => !prev)
                                })
                                // setLoading(true)
                            }}
                            classNames={{
                                container: "!w-6 !h-6 !flex !items-center !justify-center !flex-none"
                            }}
                        />
                    }
                    {(timeChanged && keepTime.hours !== "-" && keepTime.minutes !== "-") &&
                        <Button 
                            icon="x"
                            iconWidth="1.7rem"
                            iconHeight="1.7rem"
                            color="gray"
                            fill="transparent" 
                            type="reset"
                            onClick={() => {
                                const splitValue = value?.split(":") ?? ["-", "-"]
                                setTime({
                                    hours: keepTime.hours,
                                    minutes: keepTime.minutes,
                                })
                            }}
                            // loading
                            classNames={{
                                container: "!w-6 !h-6 !flex !items-center !justify-center !flex-none"
                            }}
                        />
                    }
                </div>
            </div>
                <Button 
                    icon="clock"
                    color="gray"
                    fill="transparent" 
                    disabled={disabled}
                    onClick={() => setOpen(true)}
                    classNames={{
                        container: "!w-6 !h-6 !flex !items-center !justify-center"
                    }}
                />
        </div>
    )
}


export default TimePicker;