import { RecoilState, atom, selector } from "recoil";



export interface ClockDto {
    enter?: number,
    exit?: number,
    idleTime?: number,
    confirmed?: boolean,
    finalized?: number,
}
export const TransmissionTimeAtom: RecoilState<ClockDto> = atom<ClockDto>({
    key: 'TransmissionTimeAtom',
    default: {
        enter: undefined,
        exit: undefined,
        idleTime: undefined,
        confirmed: true,
        finalized: 0,
    },
});
// new
export const TransmissionTimesAtom: RecoilState<ClockDto[]> = atom<ClockDto[]>({
    key: 'TransmissionTimesAtom',
    default: [{
        // enter: undefined,
        // exit: undefined,
        // idleTime: undefined,
        // confirmed: true,
        // finalized: 0,
    }],
});

export const DailyLogAtom: RecoilState<any> = atom<any>({
    key: 'DailyLogAtom',
    default: undefined
});

export const hasInComponyAtom: RecoilState<boolean> = atom<boolean>({
    key: 'hasInComponyAtom',
    default: false
});

export const PinsStateAtom: RecoilState<any> = atom<any>({
    key: 'PinsStateAtom',
    default: []
});

export const SelectedWorkAtom: RecoilState<any> = atom<number>({
    key: 'SelectedWorkAtom',
    default: 0
});

export const ExitTempAtom: RecoilState<number | undefined> = atom<number | undefined>({
    key: 'ExitTempAtom',
    default: undefined
});





const now = Math.floor(Date.now()/60_000)*60
const hours = 3_600
export type WorkType = "work" | "mission" | "remote" | "leave"
export type MissionType = "work" | "onWay" | "sleep" | "optionalUnemployment" | "mandatoryUnemployment" | null
export interface WorkDto {
    id: number;
    date: number;
    type: WorkType;
    start: number;
    end: number;
    inOutLogId: number | null;
    // active: boolean,
    // theme: string,
    projects?: null;
    idleTime?: number;
    notes: string;
    confirmed: boolean;
    missionType?: MissionType,
}

export const LockWorkPageAtom: RecoilState<boolean> = atom<boolean>({
    key: 'LockWorkPageAtom',
    default: false,
});

export const TimeFrameAtom: RecoilState<string[]> = atom<string[]>({
    key: 'TimeFrameAtom',
    default: [],
});

export const TimeSpanDurationAtom: RecoilState<number> = atom({
    key: 'TimeSpanDurationAtom',
    default: 1,
});

export const WorksInDayAtom: RecoilState<WorkDto[]> = atom<WorkDto[]>({
    key: 'WorksInDayAtom',
    default: [
        // {
        //     id: 1, 
        //     type: "work", 
        //     start: now - (hours * 3), 
        //     end: now - (hours * 2), 
        //     active: true, 
        //     theme: "emerald",
        //     projects: ["پروژه 1", "پروژه 2"],
        //     notes: "سلام@@@چطوری@@@خوبم@@@توچطوری@@@عالی@@@باشه",
        //     idleTime: 2_400_000,
        // },
        // {
        //     id: 2, 
        //     type: "mission", 
        //     start: now - (hours * 2), 
        //     end: now - (hours * 1), 
        //     active: true, 
        //     theme: "sky",
        //     projects: ["پروژه 1", "پروژه 2"],
        //     missionType: "optionalUnemployment",
        //     notes: "سلام@@@چطوری@@@خوبم@@@توچطوری@@@عالی@@@باشه",
        // },
        // {
        //     id: 3, 
        //     type: "remote", 
        //     start: now - (hours * 1), 
        //     end: now - (hours * 0), 
        //     active: true, 
        //     theme: "purple",
        //     projects: ["پروژه 1", "پروژه 2"],
        //     idleTime: 600_000,
        //     notes: "سلام@@@چطوری@@@خوبم@@@توچطوری@@@عالی@@@باشه",
        // },
        // {
        //     id: 4, 
        //     type: "leave", 
        //     start: now + (hours * 1), 
        //     end: now + (hours * 2), 
        //     active: true, 
        //     theme: "yellow",
        //     projects: ["پروژه 1", "پروژه 2"],
        //     notes: "سلام@@@چطوری@@@خوبم@@@توچطوری@@@عالی@@@باشه",
        // }
    ],
});