import { get } from "./Axios.config"
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const cookie = cookies.get("ACCESS_TOKEN")


export const checkUserAccess = async () => {
  return await get({
    api: `auth/access_token`,
    config: {
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${cookie}`
      } 
    }
  })
}