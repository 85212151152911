import { useTheme } from "next-themes";
import styles from "./InOutLogs.module.scss"
import { InOutLogDto } from "../../../Recoil/InOutLogAtom";
import useGenerateTimeFrame from "../../../hooks/useGenerateTimeFrame";
import { useRecoilState, useRecoilValue } from "recoil";
import { DailyLogAtom, LockWorkPageAtom, PinsStateAtom, TimeFrameAtom, TimeSpanDurationAtom, WorksInDayAtom } from "../../../Recoil/Works.atom";
import { DateState, GlobalLoadingAtom, MinimumDistanceBetweenInOutLogsStateAtom, MinimumInOutLogTimeDurationStateAtom, ModalOpenAtom, ModalParamsAtom, ModalTypeAtom, ServerDateState, UserDataState } from "../../../Recoil/Atoms";
import { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { Toasts } from "../../../toasts/toasts";
import { ServerDateToDay, TimestampToTime, TimeToTimestamp } from "../../../Utils/convertTime";
import { updateInOutLog } from "../../../axios/InOutLog.api";
import ClockPicker from "../../Common/ClockPicker/ClockPicker";
import Button from "../../Common/Button/Button";
import useNotificationsUpdate from "../../../hooks/useNotificationsUpdate";
const oneDay = 60*60*24;

const InOutLogs = ({
    inOutLogs,
    setInOutLogs,
    log,
    workBoxSize,
    justSee
    // generateTimeFrame,
}: {
    inOutLogs: InOutLogDto[];
    setInOutLogs: React.Dispatch<React.SetStateAction<InOutLogDto[]>>
    log: InOutLogDto;
    workBoxSize: number;
    justSee: boolean
    // generateTimeFrame: ({from, to}: {from: number, to: number}) => void
}) => {


    const calculateTop = (span: number, spanDuration?: number, frame?: string[]) => {
        // if (!timeLog.enter) return 0
        // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
        // const startHoursToTimeSpan = +(TimestampToTime(log.enter)) * 60 * 60
        const startHoursToTimeSpan = (timeFrame.length ? (+(frame ?? timeFrame)?.[0]?.split(":")?.[0]) : 0) * 60 * 60
        const distanceFromZeroTime = span - startHoursToTimeSpan;
        const px = distanceFromZeroTime * workBoxSize / (spanDuration ?? timeSpanDuration);
        return px
    }

    const calculateHeight = (span: number, spanDuration?: number) => {
        const px = span * workBoxSize / (spanDuration ?? timeSpanDuration)
        return px
    }
    
    const {theme} = useTheme()
    const updateNotification = useNotificationsUpdate()
    const generateTimeFrame = useGenerateTimeFrame()
    const [userData, setUserData] = useRecoilState(UserDataState)
    const [works, setWorks] = useRecoilState(WorksInDayAtom)
    const [date, setDate] = useRecoilState(DateState)
    // const minimumWorkTimeDuration = useRecoilValue(MinimumWorkTimeDurationStateAtom)
    const minimumInOutLogTimeDuration = useRecoilValue(MinimumInOutLogTimeDurationStateAtom)
    const [minimumDistanceBetweenInOutLogs, setMinimumDistanceBetweenInOutLogs] = useRecoilState(MinimumDistanceBetweenInOutLogsStateAtom)
    const [pins, setPins] = useRecoilState(PinsStateAtom)
    const [timeFrame, setTimeFrame] = useRecoilState(TimeFrameAtom)
    const [timeSpanDuration, setTimeSpanDuration] = useRecoilState(TimeSpanDurationAtom)
    const serverDate = useRecoilValue(ServerDateState)
    const [modalType, setModalType] = useRecoilState(ModalTypeAtom)
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    const [modalParams, setModalParams] = useRecoilState(ModalParamsAtom);
    // const [firstHeight, setFirstHeight] = useState(0)
    const [firstTop, setFirstTop] = useState(0)
    const [accessMove, setAccessMove] = useState(false)
    const [hasTransition, setHasTransition] = useState(true)
    const [workInstance, setWorkInstance] = useState<InOutLogDto[]>([])
    const [isFirstInOutLog, setIsFirstInOutLog] = useState(false)
    const [isLastInOutLog, setIsLastInOutLog] = useState(false)
    const [hasAfterLog, setHasAfterLog] = useState(false)
    // const [firstStep, setFirstStep] = useState(0)
    const [lock, setLock] = useRecoilState(LockWorkPageAtom)
    const [inOutTime, setInOutTime] = useState({
        start: 0,
        end: 0,
    })
    const [position, setPosition] = useState({
        top: 0,
        // height: calculateHeight(((log.exit ?? (log.date + (24*60*60))) - log.enter)) ?? 0
        height: 0
    })
    const [beforeWork, setBeforeWork] = useState<any>({})
    const [afterWork, setAfterWork] = useState<any>({})
    const [startEndLimitation, setStartEndLimitation] = useState({
        top: 0,
        height: 0
    })
    const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom)
    const [enterClockPickerIsOpen, setEnterclockPickerIsOpen] = useState(false)
    const [exitClockPickerIsOpen, setExitclockPickerIsOpen] = useState(false)
    const time_frame_ref = useRef(timeFrame)
    time_frame_ref.current = timeFrame
    const firstHeight = useRef(0)
    const positionTop = useRef(position.top)
    const firstStep = useRef(0)
    const [accessOpenDescription, setAccessOpenDescription] = useState(false)
    const [globalLoading, setGlobalLoading] = useRecoilState(GlobalLoadingAtom)
    // const [workTime, setWorkTime] = useState({
    //     start: 0,
    //     end: 0,
    // })

    // const pixelToTimeSpan = (px: number) => {
    //     if (!timeLog.enter) return 0
    //     // const today = ServerDateToDay(ele.date);
    //     const today = ele.date;
    //     // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
    //     const startHoursToTimeSpan = +(TimestampToTime(timeLog.enter).split(":")?.[0]) * 60 * 60
    //     const startWorkSpan = today + startHoursToTimeSpan
    //     const span = px * timeSpanDuration / workBoxSize
    //     // return Math.floor((span + startWorkSpan ) / 60000) * 60
    //     return Math.floor((span + startWorkSpan)/60)*60
    // }

    const pixelToTimeSpan = (px: number, spanDuration?: number, frame?: string[]) => {
        if (!log.enter) return 0
        // const today = ServerDateToDay(ele.date);
        const today = log.date;
        // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
        // const startHoursToTimeSpan = +(TimestampToTime(log.date).split(":")?.[0]) * 60 * 60
        const startHoursToTimeSpan = (timeFrame.length ? +((frame ?? timeFrame)?.[0]?.split(":")?.[0]) : 0) * 60 * 60
        const startWorkSpan = today + startHoursToTimeSpan
        const span = px * (spanDuration ?? timeSpanDuration) / workBoxSize
        // return Math.floor((span + startWorkSpan ) / 60000) * 60
        return Math.floor((span + startWorkSpan)/60)*60
    }

    
    const minHeigthCalculate = (duration?: number) => {
        let minHeight = ((duration ?? minimumInOutLogTimeDuration) * workBoxSize / timeSpanDuration)
        // if (log.idleTime && +log.idleTime > minimumWorkTimeDuration) minHeight = (log.idleTime * workBoxSize / timeSpanDuration)
        return minHeight;
    }
    
    let accessRunningInterval = useRef(false)
    let accessRunningRecessive = useRef(false)
    const pointerDown = (e: any) => {
        if (justSee) return;
        setWorkInstance(inOutLogs)
        setHasTransition(false)
        setAccessMove(true)
        setFirstTop(position.top)
        // setFirstHeight(position.height)
        firstHeight.current = position.height
        positionTop.current = position.top
        const firstLog = [...inOutLogs].shift()
        if (log.id === firstLog?.id) { // is first work
            setIsFirstInOutLog(true)
            accessRunningInterval.current = true
            accessRunningRecessive.current = true
        } else {
            setIsFirstInOutLog(false)
            accessRunningInterval.current = false
            accessRunningRecessive.current = false

        }
        const lastLog = [...inOutLogs].pop()
        if (log.id === lastLog?.id) { // is first work
            setIsLastInOutLog(true)
            accessRunningInterval.current = true
            accessRunningRecessive.current = true
        } else {
            setIsLastInOutLog(false)
            accessRunningInterval.current = false
            accessRunningRecessive.current = false

        }
        if (!isMobile) {
            firstStep.current = (e.clientY)
            e.currentTarget.setPointerCapture(e.pointerId);
        } else {
            firstStep.current = (e.targetTouches?.[0].clientY)
        }
    }

    
    const firstStart = useRef(0)
    const firstEnd = useRef(0)
    const pointerMove = (e: any, type: "top" | "down" | "both") => {
        if (justSee) return;
        if (!accessMove || !log.enter) return;
        if (lock) {
            setAccessMove(false)
            Toasts.lockWorks()
            return;
        }
        // runningFunction.current = false
        // const beforeRound = beforeWork ? Math.floor(beforeWork.end/60000)*60000 : 0
        // const afterRound = afterWork ? Math.floor(afterWork.start/60000)*60000 : 0
        // const serverRound = Math.floor(serverDate/60000)*60000
        // const minTop = beforeWork ? calculateTop((beforeRound - ServerDateToDay(beforeRound))) : calculateTop(TimeToTimestamp(timeLog.enter, serverRound) - ServerDateToDay(serverRound))
        // const maxHeight = afterWork ? calculateTop((afterRound - ServerDateToDay(afterRound))) : calculateTop(TimeToTimestamp(timeLog.exit, serverRound) - ServerDateToDay(serverRound)) // workBoxSize
        let top = position.top;
        let height = position.height;
        const thisWorkIndex = inOutLogs.findIndex((e: any) => e.id === log.id)

        // const beforeWork = inOutLogs[thisWorkIndex-1]
        // const afterWork = inOutLogs[thisWorkIndex+1]
        // const beforeRound = beforeWork ? (beforeWork.exit ?? (log.date + (24*60*60))) : 0
        // const afterRound = afterWork ? afterWork.enter : 0

        const beforeRound = beforeWork
        const afterRound = afterWork
        const serverRound = log.date


        const minTop = beforeWork ? calculateTop((beforeRound - ServerDateToDay(beforeRound))) : calculateTop(TimeToTimestamp(timeFrame[0] ?? "00:00", log.date) - log.date)
        
        const hasMission = inOutLogs.find((w: any) => w.type === "mission")
        const isToday = ServerDateToDay(serverDate) <= log.date
        const maxHeight = afterWork ? calculateTop((afterRound - ServerDateToDay(afterRound))) : calculateTop(TimeToTimestamp([...timeFrame].pop() ?? "24:00", log.date) - log.date)
        const minHeight = minHeigthCalculate();

        const maxTop = calculateTop((startEndLimitation.top - date))
        const heightMin = minHeigthCalculate(firstEnd.current)
        // const workBottom = e.target.getBoundingClientRect()?.bottom ?? 0
        // const sensitiveTop = increaseSensitive.current.getBoundingClientRect()?.top ?? 0
        // console.log(ServerDateToDay(timeLog.enter))
        let clientY = e.clientY
        if (isMobile) {
            e.preventDefault()
            clientY = e.targetTouches?.[0]?.clientY ?? firstStep.current
        } 
        const changedTop = clientY - firstStep.current
        if (type === "top") {
            const newTop = firstTop + changedTop
            let newHeight = firstHeight.current - changedTop
            // console.log(firstEnd.current) 
            // console.log(newTop)
            // console.log(minTop)
            if (newTop <= minTop) {
                if (isFirstInOutLog) { // if is first log (inOut or work)
                    accessRunningRecessive.current = true
                    // console.log(-0)
                    if (!accessRunningInterval.current) return
                    // console.log(0)
                    accessRunningInterval.current = false


                    top = minTop;
                    height = firstHeight.current + firstTop - minTop
                    const start = pixelToTimeSpan(top)
                    const end = pixelToTimeSpan(top + height)
                    
                    if (!accessRunningRecessive.current) return;
                    setInOutTime({
                        start,
                        end
                    })
                    
                    if (!accessRunningRecessive.current) return;
                    setPosition({
                        top: minTop,
                        height: firstHeight.current + firstTop - minTop
                    });

                    const recessive = async () => {
                        // console.log(accessRunningRecessive.current)
                        if (!accessRunningRecessive.current) return;

                        await new Promise((resolve, reject) => {
                            setTimeout(() => {resolve(true)}, 200)
                        })

                        const startTimeFrame = +([...time_frame_ref.current].shift()?.split(":")?.[0] ?? 0)
                        const endTimeFrame = +([...time_frame_ref.current].pop()?.split(":")?.[0] ?? 24)
                        if (+startTimeFrame <= 0) return;
                        const loopFrom = endTimeFrame
                        const loopTo = +startTimeFrame - 1
                        const time_frame: string[] = []
                        for (let i=loopFrom; i>=loopTo; i--) {
                            time_frame.unshift(`${String(i).padStart(2, "0")}:00`)
                        }
                        if (!accessRunningRecessive.current) return;
                        setTimeFrame(time_frame)
                        time_frame_ref.current = time_frame
                        const spanDuration = (loopFrom - loopTo) * 60 * 60 // * 1000
                        setTimeSpanDuration(spanDuration)
                        
                        setTimeout(() => {
                            const newEnter = pixelToTimeSpan(minTop, spanDuration, time_frame)
                            const newTop = calculateTop(newEnter - log.date, spanDuration, time_frame)
                            const heightPosition = calculateHeight(((exitCalculator(log.exit)) - newEnter), spanDuration)
                            top = newTop;
                            height = heightPosition
                            firstHeight.current = heightPosition - firstTop
                            setPosition({
                                top: newTop,
                                // ...position,
                                height: heightPosition
                            })
                        }, 0)
                        await recessive()
                    }
                    recessive()
                } else {
                    top = minTop;
                    height = firstHeight.current + firstTop - minTop
                    const start = pixelToTimeSpan(top)
                    const end = pixelToTimeSpan(top + height)
                    setInOutTime({
                        start,
                        end
                    })
                    setPosition({
                        top: minTop,
                        height: firstHeight.current + firstTop - minTop
                    });
                }
            }
            else if (newTop > maxTop) {
                accessRunningRecessive.current = false
                accessRunningInterval.current = true
                top = maxTop;
                height = minHeight
                const start = pixelToTimeSpan(top)
                // const end = pixelToTimeSpan(top + height)
                setInOutTime({
                    ...inOutTime,
                    start,
                    // end
                })
                return setPosition({
                    // ...position,
                    top: maxTop,
                    height: minHeigthCalculate(firstStart.current) 
                });
                // console.log(firstEnd.current)
                // return
            }
            else {
                accessRunningRecessive.current = false
                accessRunningInterval.current = true
                top = newTop;
                height = newHeight
                const start = pixelToTimeSpan(top)
                const end = pixelToTimeSpan(top + height)
                setInOutTime({
                    start,
                    end
                })
                return setPosition({
                    top: newTop,
                    height: newHeight
                })
            }
        } 
        else if (type === "down") {
            const newHeight = firstHeight.current + changedTop
            
            if (newHeight >= workBoxSize - position.top - (workBoxSize - maxHeight)) {
                if (isLastInOutLog) {
                    accessRunningRecessive.current = true
                    if (!accessRunningInterval.current) return
                    accessRunningInterval.current = false
                    height = workBoxSize - position.top - (workBoxSize - maxHeight)
                    const start = pixelToTimeSpan(position.top)
                    const end = pixelToTimeSpan(top + height)
                    if (!accessRunningRecessive.current) return;
                    setInOutTime({
                        ...inOutTime,
                        start,
                        end
                    })
                    if (!accessRunningRecessive.current) return;
                    setPosition({
                        ...position,
                        height: workBoxSize - position.top - (workBoxSize - maxHeight)
                    });

                    const recessive = async () => {
                        if (!accessRunningRecessive.current) return;

                        await new Promise((resolve, reject) => {
                            setTimeout(() => {resolve(true)}, 200)
                        })

                        const endTimeFrame = +([...time_frame_ref.current].pop()?.split(":")?.[0] ?? 24)
                        const startTimeFrame = +([...time_frame_ref.current].shift()?.split(":")?.[0] ?? 0)
                        if (+endTimeFrame > 23) return;
                        const loopFrom = startTimeFrame // timestampToHours(log.enter as number)
                        const loopTo = +endTimeFrame + 1
                        const time_frame: string[] = []
                        for (let i=loopFrom; i<=loopTo; i++) {
                            time_frame.push(`${String(i).padStart(2, "0")}:00`)
                        }
                        if (!accessRunningRecessive.current) return;
                        setTimeFrame(time_frame)
                        time_frame_ref.current = time_frame
                        const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
                        setTimeSpanDuration(spanDuration)
                        setTimeout(() => {
                            const newTop = calculateTop(log.enter - log.date, spanDuration) ?? 0 // calculateTop((ele.end - ele.start), spanDuration) ?? 0
                            // const newHeight = calculateHeight((ele.end - ele.start), spanDuration) ?? 0
                            // if (!accessRunningRecessive.current) return clearTimeout(timeOutRef.current);
                            // console.log(5)
                            // console.log(newTop, [...time_frame].pop())
                            // console.log((newTop))
                            firstStep.current -= (positionTop.current - newTop)
                            setPosition({
                                top: newTop,
                                // ...position,
                                height: workBoxSize - newTop - (workBoxSize - maxHeight)
                            })
                            positionTop.current = newTop

                        }, 0)
                        await recessive()
                    }
                    recessive()
                } else {
                    height = workBoxSize - position.top - (workBoxSize - maxHeight)
                    const start = pixelToTimeSpan(position.top)
                    const end = pixelToTimeSpan(top + height)
                    setInOutTime({
                        ...inOutTime,
                        start,
                        end
                    })
                    return setPosition({
                        ...position,
                        height: workBoxSize - position.top - (workBoxSize - maxHeight)
                    });
                }
            }
            //  else if (newHeight <= minHeight) {
            //     accessRunningRecessive.current = false
            //     accessRunningInterval.current = true
            //     height = minHeight
            //     const start = pixelToTimeSpan(position.top)
            //     const end = pixelToTimeSpan(top + height)
            //     setInOutTime({
            //         ...inOutTime,
            //         start,
            //         end
            //     })
            //     return setPosition({
            //         ...position,
            //         height: minHeight
            //     });

            // }
            else if (newHeight <= heightMin) {
                accessRunningRecessive.current = false
                accessRunningInterval.current = true
                height = minHeight
                const start = pixelToTimeSpan(position.top)
                const end = pixelToTimeSpan(top + heightMin)
                setInOutTime({
                    ...inOutTime,
                    start,
                    end
                })
                return setPosition({
                    ...position,
                    height: heightMin
                });
            }
             else {
                accessRunningRecessive.current = false
                accessRunningInterval.current = true
                height = newHeight
                const start = pixelToTimeSpan(position.top)
                const end = pixelToTimeSpan(top + height)
                setInOutTime({
                    ...inOutTime,
                    start,
                    end
                })
                return setPosition({
                    ...position,
                    height: newHeight
                })
            }

        }
        
    }

    const pointerUp = (e: any, type: "top" | "down") => {
        if (justSee) return;
        if (!accessMove) return;
        if (!isMobile) {
            e.currentTarget.releasePointerCapture(e.pointerId);
        }
        accessRunningInterval.current = false
        accessRunningRecessive.current = false
        setHasTransition(true)
        const cloneInOutLogs = [...inOutLogs]
        const findIndex = cloneInOutLogs.findIndex((item: any) => item.id === log.id)
        const splitInOutLog = {...cloneInOutLogs.splice(findIndex, 1)[0]}
        const enter = pixelToTimeSpan(position.top)
        const exit = log.exit ? pixelToTimeSpan(position.top + position.height) : null
        splitInOutLog.enter = enter
        splitInOutLog.exit = exit
        cloneInOutLogs.splice(findIndex, 0, splitInOutLog)
        setInOutLogs(cloneInOutLogs)
        setAccessMove(false)
        
        const newInOutLog = cloneInOutLogs.find((w: any) => w.id === log.id)
        const oldInOutLog: any = workInstance.find((w: any) => w.id === log.id)
        if (((newInOutLog?.enter !== oldInOutLog.start) || (newInOutLog?.enter !== oldInOutLog.end)) && (!accessOpenDescription)) {
            updateInOutLogHandler({enter, exit})
        } else {
            if (type === "top") setEnterclockPickerIsOpen(true)
            else setExitclockPickerIsOpen(true)
        } 
    }

    const updateInOutLogHandler = ({enter, exit}: any) => {
        // const newInOutLog = cloneInOutLogs.find((w: any) => w.id === log.id)
        // if (newInOutLog.type === "temporary") return; 
        // console.log(enter, exit)
        setGlobalLoading(true)
        updateInOutLog(log.id, {
            enter,
            exit,
        })
            .then(res => {
                const { data } = res
                const cloneInOutLogs = [...inOutLogs]
                const findWorkIndex = cloneInOutLogs.findIndex(w => w.id === data.id)
                cloneInOutLogs.splice(findWorkIndex, 1)
                cloneInOutLogs.splice(findWorkIndex, 0, data)
                const sortInOutLogs = cloneInOutLogs.sort((a, b) => a.enter - b.enter)
                setInOutLogs(sortInOutLogs)

                // const existMissionOrRemote = !!sortInOutLogs.find((w: any) => w.type === "mission" || w.type === "remote")
                // if (existMissionOrRemote) {
                //     generateTimeFrame({
                //         from: 0,
                //         to: 24
                //     })
                // } else {
                //     const startTime = timestampToHours([...sortInOutLogs].shift()?.enter ?? 0)
                //     const endTime = timestampToHours(([...sortInOutLogs].pop()?.exit ?? (dailyLog.date + oneDay)), dailyLog.date)
                //     generateTimeFrame({
                //         from: startTime,
                //         to: endTime < 24 ? endTime + 1 : endTime
                //     })
                // }
                updateNotification({dailyLogId: dailyLog.id, type: "inOutLog"})
                generateTimeFrame(sortInOutLogs, works, date, pins)
                setDailyLog({
                    ...dailyLog,
                    finalized: 0
                })
                setGlobalLoading(false)
                // setTimeLog({
                //     ...timeLog,
                //     finalized: 0
                // })
            })
            .catch(err => {
                console.log(err)
                setInOutLogs(workInstance)
                setGlobalLoading(false)

                // workInstance && setWorks(workInstance)
            })
    }

    const exitCalculator = (exit: number | null) => {
        if (exit) return exit;
        const now = ServerDateToDay(serverDate)
        if (date !== now) return (log.date + (24*60*60))
        const maximumTimeFrame = [...timeFrame].pop() ?? "24:00"
        return TimeToTimestamp(maximumTimeFrame, date)
    }

    useEffect(() => {
        const topPosition = calculateTop(log.enter - log.date)
        // const heightPosition = calculateHeight(((log.exit ?? (log.date + (24*60*60))) - log.enter))
        const heightPosition = calculateHeight(exitCalculator(log.exit) - log.enter) ?? 0
        
        // console.log(log.id, log.enter)
        setPosition({
            top: topPosition,
            height: heightPosition, 
        })
        const start = Math.ceil(pixelToTimeSpan(topPosition))
        const end = Math.ceil(pixelToTimeSpan(topPosition + heightPosition))
        setInOutTime({
            start,
            end
        })
    }, [workBoxSize, timeFrame, inOutLogs, timeSpanDuration])

    const changeEnterHandler = (value: {hours: string, minutes: string}, name: string) => {
        if (justSee) return;
        if (lock) return Toasts.lockWorks()
        const timeValue = {
            [name]: TimeToTimestamp(`${value.hours}:${value.minutes}`, log.date)
        }
        updateInOutLogHandler(timeValue)
    }

    const changeExitHandler = (value: {hours: string, minutes: string}, name: string) => {
        if (justSee) return;
        if (lock) return Toasts.lockWorks()
        const timeValue = {
            [name]: TimeToTimestamp(`${value.hours}:${value.minutes}`, ((log.date + (!(+value.hours + +value.minutes) ? oneDay : 0))))
        }
        updateInOutLogHandler(timeValue)
    }

    const deleteInOutLogHandler = () => {
        if (justSee) return;
        if (lock) return Toasts.lockWorks()
        setIsModalOpen(true)
        setModalType("DeleteInOutLog")
        setModalParams(log.id)
    }
    const deleteExitInOutLogHandler = () => {
        if (justSee) return;
        if (lock) return Toasts.lockWorks()
        setIsModalOpen(true)
        setModalType("DeleteExitInOutLog")
        setModalParams(log.id)
    }


    useEffect(() => {
        const insideWork = works.filter(iw => iw.inOutLogId === log.id) ?? []
        const outsideWork = works.filter(iw => !iw.inOutLogId) ?? []
        
        const insideWorkDetails = insideWork.map(iwd => ({start: iwd.start, end: iwd.end, type: "insideWork"}))
        const outsideWorkDetails = outsideWork.map(iwd => ({start: iwd.start, end: iwd.end, type: "outsideWork"}))
        const inOutLogsDetails = inOutLogs.map(iwd => ({start: iwd.enter, end: (iwd.exit ?? (date + oneDay)), type: "inOutLogs"}))
        const allLogs = [...outsideWorkDetails, ...inOutLogsDetails].sort((a, b) => a.start - b.start)
        // console.log(allLogs)
        // console.log(log)
        const inOutIndex = allLogs.findIndex(l => l.start === log.enter)
        const beforeLog = allLogs[inOutIndex-1]
        const afterLog = allLogs[inOutIndex+1]
        const startFrameSpan = (timeFrame.length ? (+timeFrame?.[0]?.split(":")?.[0]) : 0) * 60 * 60
        const minTime = beforeLog ? beforeLog.end + (beforeLog.type === "inOutLogs" ? minimumDistanceBetweenInOutLogs : 0) : 0 // (date + startFrameSpan)
        const maxTime = afterLog ? afterLog.start - (afterLog.type === "inOutLogs" ? minimumDistanceBetweenInOutLogs : 0) : 0
        setBeforeWork(minTime)
        setAfterWork(maxTime)
        setHasAfterLog(!!afterLog)

        const firstWork: number = [...insideWorkDetails].shift()?.start ?? ((log.exit ?? (date+oneDay)) - minimumInOutLogTimeDuration)
        const lastWork: number = [...insideWorkDetails].pop()?.end ?? (log.enter + minimumInOutLogTimeDuration)
        setStartEndLimitation({
           top: firstWork,
           height: lastWork
        })
        // const sumOfWorks =  insideWorkDetails.reduce((a, b) => a + (b.end - b.start) , 0)
        firstStart.current = (lastWork - firstWork) + ((log.exit ?? (date+oneDay)) - lastWork)
        firstEnd.current = (lastWork - firstWork) + (firstWork - log.enter)
    }, [inOutLogs, works]) 

    // console.log(startEndLimitation)

    return (
        <>
            {log.enter &&
                <div 
                    onMouseDown={() => {
                        if (isMobile) return;
                        setAccessOpenDescription(true)
                    }}
                    onMouseMove={() => {
                        if (isMobile) return;
                        setAccessOpenDescription(false)
                    }}
                    onTouchStart={() => {
                        if (!isMobile) return;
                        setAccessOpenDescription(true)
                    }}
                    onTouchMove={() => {
                        if (!isMobile) return;
                        setAccessOpenDescription(false)
                    }}


               
            // onMouseUp={() => {
            //     if (!accessOpenDescription || isMobile) return;
            //     if (selectedWork === ele.id) setSelectedWork(0)
            //     else setSelectedWork(ele.id)
            // }}
            onMouseLeave={() => {
                if (isMobile) return;
                // if (accessOpenDescription) setSelectedWork(ele.id)
            }}
            // onTouchMove={() => {
            //     if (!isMobile) return;
            //     setAccessOpenDescription(false)
            // }}
            onTouchEnd={() => {
                if (!isMobile) return;
                // if (accessOpenDescription) setSelectedWork(ele.id)
            }}



                    onClick={(e) => {
                        // setSelectedWork(ele.id)
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    className={`${styles.presenceArea} w-full bg-gray-900 bg-opacity-10 absolute left-0 flex flex-col items-center justify-between`}
                    style={position}
                >
                    <div className='w-full h-0 ring-1 ring-green-500 flex items-center justify-between select-none'>
                        <ClockPicker 
                            name="enter"
                            buttonMode="none"
                            value={TimestampToTime(inOutTime.start)}
                            isOpen={enterClockPickerIsOpen}
                            setIsOpen={setEnterclockPickerIsOpen}
                            customButton={
                                <div 
                                    onPointerDown={!isMobile ? pointerDown : () => {}}
                                    onPointerMove={!isMobile ? (e: any) => pointerMove(e, "top") : () => {}}
                                    onPointerUp={!isMobile ? (e) => pointerUp(e, "top") : () => {}}
                                    onPointerLeave={!isMobile ? (e) => pointerUp(e, "top") : () => {}}
                                    onTouchStart={isMobile ? pointerDown : () => {}} // For touch devices
                                    onTouchMove={isMobile ? (e: any) => pointerMove(e, "top") : () => {}}   // For touch devices
                                    onTouchEnd={isMobile ? (e) => pointerUp(e, "top") : () => {}}
                                    onContextMenu={(e) => {
                                        e.preventDefault()
                                        console.log("first")
                                    }}
                                    className='w-[30px] h-[20px] grid place-content-center text-[0.6rem] rounded-full translate-x-[31px] bg-green-500 hover:bg-green-600 transition-color duration-100 ring-1 ring-green-500 cursor-move touch-none'
                                >
                                    {TimestampToTime(inOutTime.start)}
                                </div>
                            }
                            picker={{
                                title: "زمان ورود",
                                onAccept: changeEnterHandler,
                            }}
                        />
                        
                        <Button
                            icon='trash3'
                            iconWidth="1.1rem"
                            iconHeight="1.1rem"
                            color={theme === "dark" ? "dark" : "light"}
                            onClick={deleteInOutLogHandler}
                            classNames={{
                                container: '!w-[30px] !h-[20px] !grid place-content-center text-[0.6rem] rounded-full -translate-x-[31px] ring-1 ring-green-500 !transition-color duration-100 z-10'
                            }}
                        />
                    </div>
                    {log.exit &&
                        <div className='w-full h-0 ring-1 ring-rose-500 flex flex-row-reverse items-center justify-between select-none'>
                        
                            <ClockPicker 
                                name="exit"
                                buttonMode="none"
                                value={TimestampToTime(inOutTime.end, date)}
                                isOpen={exitClockPickerIsOpen}
                                setIsOpen={setExitclockPickerIsOpen}
                                customButton={
                                    <div 
                                        onPointerDown={!isMobile ? pointerDown : () => {}}
                                        onPointerMove={!isMobile ? (e: any) => pointerMove(e, "down") : () => {}}
                                        onPointerUp={!isMobile ? (e) => pointerUp(e, "down") : () => {}}
                                        onPointerLeave={!isMobile ? (e) => pointerUp(e, "down") : () => {}}
                                        onTouchStart={isMobile ? pointerDown : () => {}} // For touch devices
                                        onTouchMove={isMobile ? (e: any) => pointerMove(e, "down") : () => {}}   // For touch devices
                                        onTouchEnd={isMobile ? (e) => pointerUp(e, "down") : () => {}}
                                        className='w-[30px] h-[20px] grid place-content-center text-[0.6rem] rounded-full bg-rose-500 hover:bg-rose-600 transition-color duration-100 text-gray-900 -translate-x-[31px] cursor-move touch-none'
                                    >
                                        {TimestampToTime(inOutTime.end, date)}
                                    </div>
                                }
                                picker={{
                                    title: "زمان خروج",
                                    onAccept: changeExitHandler,
                                }}
                            />
                            {!hasAfterLog &&
                                <Button
                                    icon='trash3'
                                    iconWidth="1.1rem"
                                    iconHeight="1.1rem"
                                    color={theme === "dark" ? "dark" : "light"}
                                    onClick={deleteExitInOutLogHandler}
                                    classNames={{
                                        container: '!w-[30px] !h-[20px] !grid place-content-center text-[0.6rem] rounded-full translate-x-[31px] ring-1 ring-rose-500 !transition-color duration-100 z-10'
                                    }}
                                />
                            }
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default InOutLogs