
import pallet from "../../../Styles/pallet";
import btnPallet from "./btnPallet";
import { FillTypes } from "./Types";


export const ThemeHandler: FillTypes = {
    disable: {
        "--color": btnPallet.colorGrayLine,
        "--hover": btnPallet.colorGrayLine,
        "--borderColor": btnPallet.colorGrayLine,
        "--title": btnPallet.colorGrayText,
        "--titleHover": btnPallet.colorGrayText, 
        "--circle": btnPallet.colorGrayText,
        "--circleHover": btnPallet.colorGrayText,
        "--circleIconColor": btnPallet.colorSearchBox,
        "--circleIconHover": btnPallet.colorSearchBox,
        "--icon": btnPallet.colorGrayText,
        "--iconHover": btnPallet.colorGrayText,
    },
    
    outline: {
        light: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorBaseBlue,
            "--borderColor": btnPallet.colorBaseBlue,
            "--title": btnPallet.colorBaseBlue,
            "--titleHover": btnPallet.colorWhite,
            "--circle": btnPallet.colorBaseBlue,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorBaseBlue,
            "--icon": btnPallet.colorBaseBlue,
            "--iconHover": btnPallet.colorWhite,
        },
        dark: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorBaseBlue,
            "--borderColor": btnPallet.colorBaseBlue,
            "--title": btnPallet.colorBaseBlue,
            "--titleHover": btnPallet.colorWhite,
            "--circle": btnPallet.colorBaseBlue,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorBaseBlue,
            "--icon": btnPallet.colorBaseBlue,
            "--iconHover": btnPallet.colorWhite,
        },
        primary: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorBaseBlue,
            "--borderColor": btnPallet.colorBaseBlue,
            "--title": btnPallet.colorBaseBlue,
            "--titleHover": btnPallet.colorWhite,
            "--circle": btnPallet.colorBaseBlue,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorBaseBlue,
            "--icon": btnPallet.colorBaseBlue,
            "--iconHover": btnPallet.colorWhite,
        },
        secondary: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorBaseOrange,
            "--borderColor": btnPallet.colorBaseOrange,
            "--title": btnPallet.colorBaseOrange,
            "--titleHover": btnPallet.colorWhite, 
            "--circle": btnPallet.colorBaseOrange,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorBaseOrange,
            "--icon": btnPallet.colorBaseOrange,
            "--iconHover": btnPallet.colorWhite,
        },
        tertiary: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorTertiary,
            "--borderColor": btnPallet.colorTertiary,
            "--title": btnPallet.colorTertiary,
            "--titleHover": btnPallet.colorWhite, 
            "--circle": btnPallet.colorTertiary,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorTertiary,
            "--icon": btnPallet.colorTertiary,
            "--iconHover": btnPallet.colorWhite,
        },
        success: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorGreen,
            "--borderColor": btnPallet.colorGreen,
            "--title": btnPallet.colorGreen,
            "--titleHover": btnPallet.colorWhite, 
            "--circle": btnPallet.colorGreen,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorGreen,
            "--icon": btnPallet.colorGreen,
            "--iconHover": btnPallet.colorWhite,
        },
        danger: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorRed,
            "--borderColor": btnPallet.colorRed,
            "--title": btnPallet.colorRed,
            "--titleHover": btnPallet.colorWhite, 
            "--circle": btnPallet.colorRed,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorRed,
            "--icon": btnPallet.colorRed,
            "--iconHover": btnPallet.colorWhite,
        },
        gray: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorDarkGrayText,
            "--borderColor": btnPallet.colorDarkGrayText,
            "--title": btnPallet.colorDarkGrayText,
            "--titleHover": btnPallet.colorWhite, 
            "--circle": btnPallet.colorDarkGrayText,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorDarkGrayText,
            "--icon": btnPallet.colorDarkGrayText,
            "--iconHover": btnPallet.colorWhite,
        },
    },

    info: {
        light: {
            "--color": btnPallet.colorLightBlue,
            "--hover": btnPallet.colorBaseBlue,
            "--borderColor": btnPallet.colorWhite, //
            "--title": btnPallet.colorBaseBlue,
            "--titleHover": btnPallet.colorWhite,
            "--circle": btnPallet.colorBaseBlue,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorBaseBlue,
            "--icon": btnPallet.colorBaseBlue,
            "--iconHover": btnPallet.colorWhite,
        },
        dark: {
            "--color": btnPallet.colorLightBlue,
            "--hover": btnPallet.colorBaseBlue,
            "--borderColor": btnPallet.colorWhite, //
            "--title": btnPallet.colorBaseBlue,
            "--titleHover": btnPallet.colorWhite,
            "--circle": btnPallet.colorBaseBlue,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorBaseBlue,
            "--icon": btnPallet.colorBaseBlue,
            "--iconHover": btnPallet.colorWhite,
        },
        primary: {
            "--color": btnPallet.colorLightBlue,
            "--hover": btnPallet.colorBaseBlue,
            "--borderColor": btnPallet.colorWhite, //
            "--title": btnPallet.colorBaseBlue,
            "--titleHover": btnPallet.colorWhite,
            "--circle": btnPallet.colorBaseBlue,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorBaseBlue,
            "--icon": btnPallet.colorBaseBlue,
            "--iconHover": btnPallet.colorWhite,
        },
        secondary: {
            "--color": btnPallet.colorLightOrange,
            "--hover": btnPallet.colorBaseOrange,
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorBaseOrange,
            "--titleHover": btnPallet.colorWhite,
            "--circle": btnPallet.colorBaseOrange,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorBaseOrange,
            "--icon": btnPallet.colorBaseOrange,
            "--iconHover": btnPallet.colorWhite,
        },
        tertiary: {
            "--color": btnPallet.colorLightTertiary,
            "--hover": btnPallet.colorTertiary,
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorTertiary,
            "--titleHover": btnPallet.colorWhite,
            "--circle": btnPallet.colorTertiary,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorTertiary,
            "--icon": btnPallet.colorTertiary,
            "--iconHover": btnPallet.colorWhite,
        },
        success: {
            "--color": btnPallet.colorLightGreen,
            "--hover": btnPallet.colorGreen,
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorGreen,
            "--titleHover": btnPallet.colorWhite,
            "--circle": btnPallet.colorGreen,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorGreen,
            "--icon": btnPallet.colorGreen,
            "--iconHover": btnPallet.colorWhite,
        },
        danger: {
            "--color": btnPallet.colorLightRed,
            "--hover": btnPallet.colorRed,
            "--borderColor": btnPallet.colorWhite, //
            "--title": btnPallet.colorRed,
            "--titleHover": btnPallet.colorWhite,
            "--circle": btnPallet.colorRed,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorRed,
            "--icon": btnPallet.colorRed,
            "--iconHover": btnPallet.colorWhite,
        },
        gray: {
            "--color": btnPallet.colorLightGrayText,
            "--hover": btnPallet.colorDarkGrayText,
            "--borderColor": btnPallet.colorWhite, //
            "--title": btnPallet.colorDarkGrayText,
            "--titleHover": btnPallet.colorWhite,
            "--circle": btnPallet.colorDarkGrayText,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorDarkGrayText,
            "--icon": btnPallet.colorDarkGrayText,
            "--iconHover": btnPallet.colorWhite,
        },
    },

    light: {
        light: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorWhite,
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorBaseBlue,
            "--titleHover": btnPallet.colorBlueHover,
            "--circle": btnPallet.colorBaseBlue,
            "--circleHover": btnPallet.colorBlueHover,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorBaseBlue,
            "--iconHover": btnPallet.colorBlueHover,
        },
        dark: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorWhite,
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorBaseBlue,
            "--titleHover": btnPallet.colorBlueHover,
            "--circle": btnPallet.colorBaseBlue,
            "--circleHover": btnPallet.colorBlueHover,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorBaseBlue,
            "--iconHover": btnPallet.colorBlueHover,
        },
        primary: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorWhite,
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorBaseBlue,
            "--titleHover": btnPallet.colorBlueHover,
            "--circle": btnPallet.colorBaseBlue,
            "--circleHover": btnPallet.colorBlueHover,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorBaseBlue,
            "--iconHover": btnPallet.colorBlueHover,
        },
        secondary: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorWhite,
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorBaseOrange,
            "--titleHover": btnPallet.colorOrangeHover,
            "--circle": btnPallet.colorBaseOrange,
            "--circleHover": btnPallet.colorOrangeHover,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorBaseOrange,
            "--iconHover": btnPallet.colorOrangeHover,
        },
        tertiary: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorWhite,
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorTertiary,
            "--titleHover": btnPallet.colorDarkTertiary,
            "--circle": btnPallet.colorTertiary,
            "--circleHover": btnPallet.colorDarkTertiary,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorTertiary,
            "--iconHover": btnPallet.colorDarkTertiary,
        },
        success: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorWhite,
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorGreen,
            "--titleHover": btnPallet.colorDarkGreen,
            "--circle": btnPallet.colorGreen,
            "--circleHover": btnPallet.colorDarkGreen,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorGreen,
            "--iconHover": btnPallet.colorDarkGreen,
        },
        danger: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorWhite,
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorRed,
            "--titleHover": btnPallet.colorDarkRed,
            "--circle": btnPallet.colorRed,
            "--circleHover": btnPallet.colorDarkRed,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorRed,
            "--iconHover": btnPallet.colorDarkRed,
        },
        gray: {
            "--color": btnPallet.colorWhite,
            "--hover": btnPallet.colorWhite,
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorGrayText,
            "--titleHover": btnPallet.colorDarkGrayText,
            "--circle": btnPallet.colorGrayText,
            "--circleHover": btnPallet.colorDarkGrayText,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorGrayText,
            "--iconHover": btnPallet.colorDarkGrayText,
        },
    },

    basic: {
        light: {
            "--color": "#d1d5db",
            "--hover": "#9ca3af",
            "--borderColor": "#9ca3af",
            "--title": "#111827",
            "--titleHover": "#111827",
            "--circle": "#111827",
            "--circleHover": "#111827",
            "--circleIconColor": "#d1d5db",
            "--circleIconHover": "#9ca3af",
            "--icon": "#111827",
            "--iconHover": "#111827",
        },
        dark: {
            "--color": "#1f2937",
            "--hover": "#111827",
            "--borderColor": "#111827",
            "--title": "#f3f4f6",
            "--titleHover": "#f3f4f6",
            "--circle": "#f3f4f6",
            "--circleHover": "#f3f4f6",
            "--circleIconColor": "#1f2937",
            "--circleIconHover": "#111827",
            "--icon": "#f3f4f6",
            "--iconHover": "#f3f4f6",
        },
        primary: {
            "--color": btnPallet.colorBaseBlue,
            "--hover": btnPallet.colorBlueHover,
            "--borderColor": btnPallet.colorBlueHover,
            "--title": btnPallet.colorWhite,
            "--titleHover": btnPallet.colorWhite,
            "--circle": btnPallet.colorWhite,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorBaseBlue,
            "--circleIconHover": btnPallet.colorBlueHover,
            "--icon": btnPallet.colorWhite,
            "--iconHover": btnPallet.colorWhite,
        },
        secondary: {
            "--color": btnPallet.colorBaseOrange,
            "--hover": btnPallet.colorOrangeHover,
            "--borderColor": btnPallet.colorOrangeHover,
            "--title": btnPallet.colorWhite,
            "--titleHover": btnPallet.colorWhite, 
            "--circle": btnPallet.colorWhite,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorBaseOrange,
            "--circleIconHover": btnPallet.colorOrangeHover,
            "--icon": btnPallet.colorWhite,
            "--iconHover": btnPallet.colorWhite,
        },
        tertiary: {
            "--color": btnPallet.colorTertiary,
            "--hover": btnPallet.colorDarkTertiary,
            "--borderColor": btnPallet.colorDarkTertiary,
            "--title": btnPallet.colorWhite,
            "--titleHover": btnPallet.colorWhite, 
            "--circle": btnPallet.colorWhite,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorTertiary,
            "--circleIconHover": btnPallet.colorDarkTertiary,
            "--icon": btnPallet.colorWhite,
            "--iconHover": btnPallet.colorWhite,
        },
        success: {
            "--color": btnPallet.colorGreen,
            "--hover": btnPallet.colorDarkGreen,
            "--borderColor": btnPallet.colorDarkGreen,
            "--title": btnPallet.colorWhite,
            "--titleHover": btnPallet.colorWhite, 
            "--circle": btnPallet.colorWhite,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorGreen,
            "--circleIconHover": btnPallet.colorDarkGreen,
            "--icon": btnPallet.colorWhite,
            "--iconHover": btnPallet.colorWhite,
        },
        danger: {
            "--color": btnPallet.colorRed,
            "--hover": btnPallet.colorDarkRed,
            "--borderColor": btnPallet.colorDarkRed,
            "--title": btnPallet.colorWhite,
            "--titleHover": btnPallet.colorWhite, 
            "--circle": btnPallet.colorWhite,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorRed,
            "--circleIconHover": btnPallet.colorDarkRed,
            "--icon": btnPallet.colorWhite,
            "--iconHover": btnPallet.colorWhite,
        },
        gray: {
            "--color": btnPallet.colorGrayText,
            "--hover": btnPallet.colorDarkGrayText,
            "--borderColor": btnPallet.colorDarkGrayText,
            "--title": btnPallet.colorWhite,
            "--titleHover": btnPallet.colorWhite, 
            "--circle": btnPallet.colorWhite,
            "--circleHover": btnPallet.colorWhite,
            "--circleIconColor": btnPallet.colorGrayText,
            "--circleIconHover": btnPallet.colorDarkGrayText,
            "--icon": btnPallet.colorWhite,
            "--iconHover": btnPallet.colorWhite,
        },
    },

    transparent: {
        light: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorBaseBlue,
            "--titleHover": btnPallet.colorBlueHover,
            "--circle": btnPallet.colorBaseBlue,
            "--circleHover": btnPallet.colorBlueHover,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorBaseBlue,
            "--iconHover": btnPallet.colorBlueHover,
        },
        dark: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorBaseBlue,
            "--titleHover": btnPallet.colorBlueHover,
            "--circle": btnPallet.colorBaseBlue,
            "--circleHover": btnPallet.colorBlueHover,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorBaseBlue,
            "--iconHover": btnPallet.colorBlueHover,
        },
        primary: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorBaseBlue,
            "--titleHover": btnPallet.colorBlueHover,
            "--circle": btnPallet.colorBaseBlue,
            "--circleHover": btnPallet.colorBlueHover,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorBaseBlue,
            "--iconHover": btnPallet.colorBlueHover,
        },
        secondary: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorBaseOrange,
            "--titleHover": btnPallet.colorOrangeHover,
            "--circle": btnPallet.colorBaseOrange,
            "--circleHover": btnPallet.colorOrangeHover,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorBaseOrange,
            "--iconHover": btnPallet.colorOrangeHover,
        },
        tertiary: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorTertiary,
            "--titleHover": btnPallet.colorDarkTertiary,
            "--circle": btnPallet.colorTertiary,
            "--circleHover": btnPallet.colorDarkTertiary,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorTertiary,
            "--iconHover": btnPallet.colorDarkTertiary,
        },
        success: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorGreen,
            "--titleHover": btnPallet.colorDarkGreen,
            "--circle": btnPallet.colorGreen,
            "--circleHover": btnPallet.colorDarkGreen,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorGreen,
            "--iconHover": btnPallet.colorDarkGreen,
        },
        danger: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorRed,
            "--titleHover": btnPallet.colorDarkRed,
            "--circle": btnPallet.colorRed,
            "--circleHover": btnPallet.colorDarkRed,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorRed,
            "--iconHover": btnPallet.colorDarkRed,
        },
        gray: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": btnPallet.colorWhite,
            "--title": btnPallet.colorGrayText,
            "--titleHover": btnPallet.colorDarkGrayText,
            "--circle": btnPallet.colorGrayText,
            "--circleHover": btnPallet.colorDarkGrayText,
            "--circleIconColor": btnPallet.colorWhite,
            "--circleIconHover": btnPallet.colorWhite,
            "--icon": btnPallet.colorGrayText,
            "--iconHover": btnPallet.colorDarkGrayText,
        },
    }
}







// import pallet from "../../../Styles/pallet";
// import { FillTypes } from "./Types";


// export const ThemeHandler: FillTypes = {
//     disable: {
//         "--color": pallet.colorLightishGray,
//         "--hover": pallet.colorLightishGray,
//         "--borderColor": pallet.colorLightishGray,
//         "--title": pallet.colorGray,
//         "--titleHover": pallet.colorGray, 
//         "--circle": pallet.colorGray,
//         "--circleHover": pallet.colorGray,
//         "--circleIconColor": pallet.colorLightishGray,
//         "--circleIconHover": pallet.colorLightishGray,
//         "--icon": pallet.colorGray,
//         "--iconHover": pallet.colorGray,
//     },
    
//     outline: {
//         primary: {
//             "--color": pallet.colorLightBlue, //
//             "--hover": pallet.colorDarkBlue,
//             "--borderColor": pallet.colorDarkBlue,
//             "--title": pallet.colorDarkBlue, //
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorBlue, //
//             "--circleHover": pallet.colorLightBlue, //
//             "--circleIconColor": pallet.colorLightBlue, //
//             "--circleIconHover": pallet.colorDarkBlue, //
//             "--icon": pallet.colorDarkBlue, //
//             "--iconHover": pallet.colorWhite,
//         },
//         whitePrimary: {
//             "--color": pallet.colorWhite,
//             "--hover": pallet.colorBlue,
//             "--borderColor": pallet.colorBlue,
//             "--title": pallet.colorBlue,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorBlue,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorBlue,
//             "--icon": pallet.colorBlue,
//             "--iconHover": pallet.colorWhite,
//         },
//         lightPrimary: {
//             "--color": pallet.colorLightBlue,
//             "--hover": pallet.colorBlue,
//             "--borderColor": pallet.colorBlue,
//             "--title": pallet.colorBlue,
//             "--titleHover": pallet.colorLightBlue, 
//             "--circle": pallet.colorBlue,
//             "--circleHover": pallet.colorLightBlue,
//             "--circleIconColor": pallet.colorLightBlue,
//             "--circleIconHover": pallet.colorBlue,
//             "--icon": pallet.colorBlue,
//             "--iconHover": pallet.colorLightBlue,
//         },
//         warning: {
//             "--color": pallet.colorLightOrange,
//             "--hover": pallet.colorDarkOrange,
//             "--borderColor": pallet.colorDarkOrange,
//             "--title": pallet.colorDarkOrange,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorDarkOrange,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorLightOrange,
//             "--circleIconHover": pallet.colorDarkOrange,
//             "--icon": pallet.colorDarkOrange,
//             "--iconHover": pallet.colorWhite,
//         },
//         whiteWarning: {
//             "--color": pallet.colorWhite,
//             "--hover": pallet.colorOrange,
//             "--borderColor": pallet.colorOrange,
//             "--title": pallet.colorOrange,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorOrange,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorOrange,
//             "--icon": pallet.colorOrange,
//             "--iconHover": pallet.colorWhite,
//         },
//         lightWarning: {
//             "--color": pallet.colorLightOrange,
//             "--hover": pallet.colorOrange,
//             "--borderColor": pallet.colorOrange,
//             "--title": pallet.colorOrange,
//             "--titleHover": pallet.colorLightOrange, 
//             "--circle": pallet.colorOrange,
//             "--circleHover": pallet.colorLightOrange,
//             "--circleIconColor": pallet.colorLightOrange,
//             "--circleIconHover": pallet.colorOrange,
//             "--icon": pallet.colorOrange,
//             "--iconHover": pallet.colorLightOrange,
//         },
//         tertiary: {
//             "--color": pallet.colorLightTertiary,
//             "--hover": pallet.colorDarkTertiary,
//             "--borderColor": pallet.colorDarkTertiary,
//             "--title": pallet.colorDarkTertiary,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorTertiary,
//             "--circleHover": pallet.colorLightTertiary,
//             "--circleIconColor": pallet.colorLightTertiary,
//             "--circleIconHover": pallet.colorDarkTertiary,
//             "--icon": pallet.colorDarkTertiary,
//             "--iconHover": pallet.colorWhite,
//         },
//         whiteTertiary: {
//             "--color": pallet.colorWhite,
//             "--hover": pallet.colorTertiary,
//             "--borderColor": pallet.colorTertiary,
//             "--title": pallet.colorTertiary,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorTertiary,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorTertiary,
//             "--icon": pallet.colorTertiary,
//             "--iconHover": pallet.colorWhite,
//         },
//         lightTertiary: {
//             "--color": pallet.colorLightTertiary,
//             "--hover": pallet.colorTertiary,
//             "--borderColor": pallet.colorTertiary,
//             "--title": pallet.colorTertiary,
//             "--titleHover": pallet.colorLightTertiary, 
//             "--circle": pallet.colorTertiary,
//             "--circleHover": pallet.colorLightTertiary,
//             "--circleIconColor": pallet.colorLightTertiary,
//             "--circleIconHover": pallet.colorTertiary,
//             "--icon": pallet.colorTertiary,
//             "--iconHover": pallet.colorLightTertiary,
//         },
//         success: {
//             "--color": pallet.colorLightGreen,
//             "--hover": pallet.colorDarkGreen,
//             "--borderColor": pallet.colorDarkGreen,
//             "--title": pallet.colorDarkGreen,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorDarkGreen,
//             "--circleHover": pallet.colorLightGreen,
//             "--circleIconColor": pallet.colorLightGreen,
//             "--circleIconHover": pallet.colorDarkGreen,
//             "--icon": pallet.colorDarkGreen,
//             "--iconHover": pallet.colorWhite,
//         },
//         whiteSuccess: {
//             "--color": pallet.colorWhite,
//             "--hover": pallet.colorGreen,
//             "--borderColor": pallet.colorGreen,
//             "--title": pallet.colorGreen,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorGreen,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorGreen,
//             "--icon": pallet.colorGreen,
//             "--iconHover": pallet.colorWhite,
//         },
//         lightSuccess: {
//             "--color": pallet.colorLightGreen,
//             "--hover": pallet.colorGreen,
//             "--borderColor": pallet.colorGreen,
//             "--title": pallet.colorGreen,
//             "--titleHover": pallet.colorLightGreen, 
//             "--circle": pallet.colorGreen,
//             "--circleHover": pallet.colorLightGreen,
//             "--circleIconColor": pallet.colorLightGreen,
//             "--circleIconHover": pallet.colorGreen,
//             "--icon": pallet.colorGreen,
//             "--iconHover": pallet.colorLightGreen,
//         },
//         danger: {
//             "--color": pallet.colorLightRed,
//             "--hover": pallet.colorDarkRed,
//             "--borderColor": pallet.colorDarkRed,
//             "--title": pallet.colorDarkRed,
//             "--titleHover": pallet.colorLightRed, 
//             "--circle": pallet.colorDarkRed,
//             "--circleHover": pallet.colorLightRed,
//             "--circleIconColor": pallet.colorLightRed,
//             "--circleIconHover": pallet.colorDarkRed,
//             "--icon": pallet.colorDarkRed,
//             "--iconHover": pallet.colorLightRed,
//         },
//         whiteDanger: {
//             "--color": pallet.colorWhite,
//             "--hover": pallet.colorRed,
//             "--borderColor": pallet.colorRed,
//             "--title": pallet.colorRed,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorRed,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorRed,
//             "--icon": pallet.colorRed,
//             "--iconHover": pallet.colorWhite,
//         },
//         lightDanger: {
//             "--color": pallet.colorLightRed,
//             "--hover": pallet.colorRed,
//             "--borderColor": pallet.colorRed,
//             "--title": pallet.colorRed,
//             "--titleHover": pallet.colorLightRed, 
//             "--circle": pallet.colorRed,
//             "--circleHover": pallet.colorLightRed,
//             "--circleIconColor": pallet.colorLightRed,
//             "--circleIconHover": pallet.colorRed,
//             "--icon": pallet.colorRed,
//             "--iconHover": pallet.colorLightRed,
//         },
//         secondary: {
//             "--color": pallet.colorLightishGray,
//             "--hover": pallet.colorDarkerGray,
//             "--borderColor": pallet.colorDarkGray,
//             "--title": pallet.colorDarkerGray,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorDarkerGray,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorLightishGray,
//             "--circleIconHover": pallet.colorDarkerGray,
//             "--icon": pallet.colorDarkerGray,
//             "--iconHover": pallet.colorWhite,
//         },
//         info: {
//             "--color": pallet.colorLightBlue, //
//             "--hover": pallet.colorDarkBlue,
//             "--borderColor": pallet.colorDarkBlue,
//             "--title": pallet.colorDarkBlue, //
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorBlue, //
//             "--circleHover": pallet.colorLightBlue, //
//             "--circleIconColor": pallet.colorLightBlue, //
//             "--circleIconHover": pallet.colorDarkBlue, //
//             "--icon": pallet.colorDarkBlue, //
//             "--iconHover": pallet.colorWhite,
//         },
//         blackWhite: {
//             "--color": pallet.colorWhite,
//             "--hover": pallet.colorDarkNavy,
//             "--borderColor": pallet.colorDarkNavy,
//             "--title": pallet.colorDarkNavy,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorDarkNavy,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorDarkNavy,
//             "--icon": pallet.colorDarkNavy,
//             "--iconHover": pallet.colorWhite,
//         },
//         dark: {
//             "--color": pallet.colorNavy,
//             "--hover": pallet.colorDarkNavy,
//             "--borderColor": pallet.colorDarkNavy,
//             "--title": pallet.colorWhite,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorWhite,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorNavy,
//             "--circleIconHover": pallet.colorDarkNavy,
//             "--icon": pallet.colorWhite,
//             "--iconHover": pallet.colorWhite,
//         },
//         light: {
//             "--color": pallet.colorWhite,
//             "--hover": pallet.colorLightTertiary,
//             "--borderColor": pallet.colorGray,
//             "--title": pallet.colorGray,
//             "--titleHover": pallet.colorDarkGray, 
//             "--circle": pallet.colorGray,
//             "--circleHover": pallet.colorDarkGray,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorLightTertiary,
//             "--icon": pallet.colorGray,
//             "--iconHover": pallet.colorDarkGray,
//         },
//     },

//     solid: {
//         primary: {
//             "--color": pallet.colorBlue,
//             "--hover": pallet.colorDarkBlue,
//             "--borderColor": pallet.colorDarkBlue,
//             "--title": pallet.colorWhite,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorWhite,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorBlue,
//             "--circleIconHover": pallet.colorDarkBlue,
//             "--icon": pallet.colorWhite,
//             "--iconHover": pallet.colorWhite,
//         },
//         whitePrimary: {
//             "--color": pallet.colorWhite,
//             "--hover": pallet.colorBlue,
//             "--borderColor": pallet.colorBlue,
//             "--title": pallet.colorBlue,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorBlue,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorBlue,
//             "--icon": pallet.colorBlue,
//             "--iconHover": pallet.colorWhite,
//         },
//         lightPrimary: {
//             "--color": pallet.colorLightBlue,
//             "--hover": pallet.colorBlue,
//             "--borderColor": pallet.colorBlue,
//             "--title": pallet.colorBlue,
//             "--titleHover": pallet.colorLightBlue, 
//             "--circle": pallet.colorBlue,
//             "--circleHover": pallet.colorLightBlue,
//             "--circleIconColor": pallet.colorLightBlue,
//             "--circleIconHover": pallet.colorBlue,
//             "--icon": pallet.colorBlue,
//             "--iconHover": pallet.colorLightBlue,
//         },
//         warning: {
//             "--color": pallet.colorOrange,
//             "--hover": pallet.colorDarkOrange,
//             "--borderColor": pallet.colorDarkOrange,
//             "--title": pallet.colorWhite,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorWhite,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorOrange,
//             "--circleIconHover": pallet.colorDarkOrange,
//             "--icon": pallet.colorWhite,
//             "--iconHover": pallet.colorWhite,
//         },
//         whiteWarning: {
//             "--color": pallet.colorWhite,
//             "--hover": pallet.colorOrange,
//             "--borderColor": pallet.colorOrange,
//             "--title": pallet.colorOrange,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorOrange,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorOrange,
//             "--icon": pallet.colorOrange,
//             "--iconHover": pallet.colorWhite,
//         },
//         lightWarning: {
//             "--color": pallet.colorLightOrange,
//             "--hover": pallet.colorOrange,
//             "--borderColor": pallet.colorOrange,
//             "--title": pallet.colorOrange,
//             "--titleHover": pallet.colorLightOrange, 
//             "--circle": pallet.colorOrange,
//             "--circleHover": pallet.colorLightOrange,
//             "--circleIconColor": pallet.colorLightOrange,
//             "--circleIconHover": pallet.colorOrange,
//             "--icon": pallet.colorOrange,
//             "--iconHover": pallet.colorLightOrange,
//         },
//         tertiary: {
//             "--color": pallet.colorTertiary,
//             "--hover": pallet.colorDarkTertiary,
//             "--borderColor": pallet.colorDarkTertiary,
//             "--title": pallet.colorWhite,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorLightTertiary,
//             "--circleHover": pallet.colorLightTertiary,
//             "--circleIconColor": pallet.colorTertiary,
//             "--circleIconHover": pallet.colorDarkTertiary,
//             "--icon": pallet.colorWhite,
//             "--iconHover": pallet.colorWhite,
//         },
//         whiteTertiary: {
//             "--color": pallet.colorWhite,
//             "--hover": pallet.colorTertiary,
//             "--borderColor": pallet.colorTertiary,
//             "--title": pallet.colorTertiary,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorTertiary,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorTertiary,
//             "--icon": pallet.colorTertiary,
//             "--iconHover": pallet.colorWhite,
//         },
//         lightTertiary: {
//             "--color": pallet.colorLightTertiary,
//             "--hover": pallet.colorTertiary,
//             "--borderColor": pallet.colorTertiary,
//             "--title": pallet.colorTertiary,
//             "--titleHover": pallet.colorLightTertiary, 
//             "--circle": pallet.colorTertiary,
//             "--circleHover": pallet.colorLightTertiary,
//             "--circleIconColor": pallet.colorLightTertiary,
//             "--circleIconHover": pallet.colorTertiary,
//             "--icon": pallet.colorTertiary,
//             "--iconHover": pallet.colorLightTertiary,
//         },
//         success: {
//             "--color": pallet.colorGreen,
//             "--hover": pallet.colorDarkGreen,
//             "--borderColor": pallet.colorDarkGreen,
//             "--title": pallet.colorWhite,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorLightGreen,
//             "--circleHover": pallet.colorLightGreen,
//             "--circleIconColor": pallet.colorGreen,
//             "--circleIconHover": pallet.colorDarkGreen,
//             "--icon": pallet.colorWhite,
//             "--iconHover": pallet.colorWhite,
//         },
//         whiteSuccess: {
//             "--color": pallet.colorWhite,
//             "--hover": pallet.colorGreen,
//             "--borderColor": pallet.colorGreen,
//             "--title": pallet.colorGreen,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorGreen,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorGreen,
//             "--icon": pallet.colorGreen,
//             "--iconHover": pallet.colorWhite,
//         },
//         lightSuccess: {
//             "--color": pallet.colorLightGreen,
//             "--hover": pallet.colorGreen,
//             "--borderColor": pallet.colorGreen,
//             "--title": pallet.colorGreen,
//             "--titleHover": pallet.colorLightGreen, 
//             "--circle": pallet.colorGreen,
//             "--circleHover": pallet.colorLightGreen,
//             "--circleIconColor": pallet.colorLightGreen,
//             "--circleIconHover": pallet.colorGreen,
//             "--icon": pallet.colorGreen,
//             "--iconHover": pallet.colorLightGreen,
//         },
//         danger: {
//             "--color": pallet.colorRed,
//             "--hover": pallet.colorDarkRed,
//             "--borderColor": pallet.colorDarkRed,
//             "--title": pallet.colorWhite,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorWhite,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorRed,
//             "--circleIconHover": pallet.colorDarkRed,
//             "--icon": pallet.colorWhite,
//             "--iconHover": pallet.colorWhite,
//         },
//         whiteDanger: {
//             "--color": pallet.colorWhite,
//             "--hover": pallet.colorRed,
//             "--borderColor": pallet.colorRed,
//             "--title": pallet.colorRed,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorRed,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorRed,
//             "--icon": pallet.colorRed,
//             "--iconHover": pallet.colorWhite,
//         },
//         lightDanger: {
//             "--color": pallet.colorLightRed,
//             "--hover": pallet.colorRed,
//             "--borderColor": pallet.colorRed,
//             "--title": pallet.colorRed,
//             "--titleHover": pallet.colorLightRed, 
//             "--circle": pallet.colorRed,
//             "--circleHover": pallet.colorLightRed,
//             "--circleIconColor": pallet.colorLightRed,
//             "--circleIconHover": pallet.colorRed,
//             "--icon": pallet.colorRed,
//             "--iconHover": pallet.colorLightRed,
//         },
//         secondary: {
//             "--color": pallet.colorGray,
//             "--hover": pallet.colorDarkerGray,
//             "--borderColor": pallet.colorDarkGray,
//             "--title": pallet.colorWhite,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorWhite,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorGray,
//             "--circleIconHover": pallet.colorDarkerGray,
//             "--icon": pallet.colorWhite,
//             "--iconHover": pallet.colorWhite,
//         },
//         info: {
//             "--color": pallet.colorBlue,
//             "--hover": pallet.colorDarkBlue,
//             "--borderColor": pallet.colorDarkBlue,
//             "--title": pallet.colorWhite,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorWhite,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorBlue,
//             "--circleIconHover": pallet.colorDarkBlue,
//             "--icon": pallet.colorWhite,
//             "--iconHover": pallet.colorWhite,
//         },
//         blackWhite: {
//             "--color": pallet.colorWhite,
//             "--hover": pallet.colorDarkNavy,
//             "--borderColor": pallet.colorDarkNavy,
//             "--title": pallet.colorDarkNavy,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorDarkNavy,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorDarkNavy,
//             "--icon": pallet.colorDarkNavy,
//             "--iconHover": pallet.colorWhite,
//         },
//         dark: {
//             "--color": pallet.colorNavy,
//             "--hover": pallet.colorDarkNavy,
//             "--borderColor": pallet.colorDarkNavy,
//             "--title": pallet.colorWhite,
//             "--titleHover": pallet.colorWhite, 
//             "--circle": pallet.colorWhite,
//             "--circleHover": pallet.colorWhite,
//             "--circleIconColor": pallet.colorNavy,
//             "--circleIconHover": pallet.colorDarkNavy,
//             "--icon": pallet.colorWhite,
//             "--iconHover": pallet.colorWhite,
//         },
//         light: {
//             "--color": pallet.colorWhite,
//             "--hover": pallet.colorLightTertiary,
//             "--borderColor": pallet.colorGray,
//             "--title": pallet.colorGray,
//             "--titleHover": pallet.colorDarkGray, 
//             "--circle": pallet.colorGray,
//             "--circleHover": pallet.colorDarkGray,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorLightTertiary,
//             "--icon": pallet.colorGray,
//             "--iconHover": pallet.colorDarkGray,
//         },
//     },

//     transparent: {
//         primary: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorDarkBlue,
//             "--title": pallet.colorDarkBlue,
//             "--titleHover": pallet.colorDarkBlue, 
//             "--circle": pallet.colorDarkBlue,
//             "--circleHover": pallet.colorDarkBlue,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorDarkBlue,
//             "--iconHover": pallet.colorDarkBlue,
//         },
//         whitePrimary: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorBlue,
//             "--title": pallet.colorBlue,
//             "--titleHover": pallet.colorBlue, 
//             "--circle": pallet.colorBlue,
//             "--circleHover": pallet.colorBlue,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorBlue,
//             "--iconHover": pallet.colorBlue,
//         },
//         lightPrimary: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorBlue,
//             "--title": pallet.colorBlue,
//             "--titleHover": pallet.colorBlue, 
//             "--circle": pallet.colorBlue,
//             "--circleHover": pallet.colorBlue,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorBlue,
//             "--iconHover": pallet.colorBlue,
//         },
//         warning: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorDarkOrange,
//             "--title": pallet.colorDarkOrange,
//             "--titleHover": pallet.colorDarkOrange, 
//             "--circle": pallet.colorDarkOrange,
//             "--circleHover": pallet.colorDarkOrange,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorDarkOrange,
//             "--iconHover": pallet.colorDarkOrange,
//         },
//         whiteWarning: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorOrange,
//             "--title": pallet.colorOrange,
//             "--titleHover": pallet.colorOrange, 
//             "--circle": pallet.colorOrange,
//             "--circleHover": pallet.colorOrange,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorOrange,
//             "--iconHover": pallet.colorOrange,
//         },
//         lightWarning: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorOrange,
//             "--title": pallet.colorOrange,
//             "--titleHover": pallet.colorOrange, 
//             "--circle": pallet.colorOrange,
//             "--circleHover": pallet.colorOrange,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorOrange,
//             "--iconHover": pallet.colorOrange,
//         },
//         tertiary: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorDarkTertiary,
//             "--title": pallet.colorDarkTertiary,
//             "--titleHover": pallet.colorDarkTertiary, 
//             "--circle": pallet.colorTertiary,
//             "--circleHover": pallet.colorTertiary,
//             "--circleIconColor": pallet.colorLightTertiary,
//             "--circleIconHover": pallet.colorLightTertiary,
//             "--icon": pallet.colorDarkTertiary,
//             "--iconHover": pallet.colorDarkTertiary,
//         },
//         whiteTertiary: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorTertiary,
//             "--title": pallet.colorTertiary,
//             "--titleHover": pallet.colorTertiary, 
//             "--circle": pallet.colorTertiary,
//             "--circleHover": pallet.colorTertiary,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorTertiary,
//             "--iconHover": pallet.colorTertiary,
//         },
//         lightTertiary: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorTertiary,
//             "--title": pallet.colorTertiary,
//             "--titleHover": pallet.colorTertiary, 
//             "--circle": pallet.colorTertiary,
//             "--circleHover": pallet.colorTertiary,
//             "--circleIconColor": pallet.colorLightTertiary,
//             "--circleIconHover": pallet.colorLightTertiary,
//             "--icon": pallet.colorTertiary,
//             "--iconHover": pallet.colorTertiary,
//         },
//         success: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorDarkGreen,
//             "--title": pallet.colorDarkGreen,
//             "--titleHover": pallet.colorDarkGreen, 
//             "--circle": pallet.colorDarkGreen,
//             "--circleHover": pallet.colorDarkGreen,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorDarkGreen,
//             "--iconHover": pallet.colorDarkGreen,
//         },
//         whiteSuccess: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorGreen,
//             "--title": pallet.colorGreen,
//             "--titleHover": pallet.colorGreen, 
//             "--circle": pallet.colorGreen,
//             "--circleHover": pallet.colorGreen,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorGreen,
//             "--iconHover": pallet.colorGreen,
//         },
//         lightSuccess: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorGreen,
//             "--title": pallet.colorGreen,
//             "--titleHover": pallet.colorGreen, 
//             "--circle": pallet.colorGreen,
//             "--circleHover": pallet.colorGreen,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorGreen,
//             "--iconHover": pallet.colorGreen,
//         },
//         danger: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorDarkRed,
//             "--title": pallet.colorDarkRed,
//             "--titleHover": pallet.colorDarkRed, 
//             "--circle": pallet.colorDarkRed,
//             "--circleHover": pallet.colorDarkRed,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorDarkRed,
//             "--iconHover": pallet.colorDarkRed,
//         },
//         whiteDanger: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorRed,
//             "--title": pallet.colorRed,
//             "--titleHover": pallet.colorRed, 
//             "--circle": pallet.colorRed,
//             "--circleHover": pallet.colorRed,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorRed,
//             "--iconHover": pallet.colorRed,
//         },
//         lightDanger: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorRed,
//             "--title": pallet.colorRed,
//             "--titleHover": pallet.colorRed, 
//             "--circle": pallet.colorRed,
//             "--circleHover": pallet.colorRed,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorRed,
//             "--iconHover": pallet.colorRed,
//         },
//         secondary: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorDarkGray,
//             "--title": pallet.colorDarkGray,
//             "--titleHover": pallet.colorDarkGray, 
//             "--circle": pallet.colorDarkGray,
//             "--circleHover": pallet.colorDarkGray,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorDarkGray,
//             "--iconHover": pallet.colorDarkGray,
//         },
//         info: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorDarkBlue,
//             "--title": pallet.colorDarkBlue,
//             "--titleHover": pallet.colorDarkBlue, 
//             "--circle": pallet.colorDarkBlue,
//             "--circleHover": pallet.colorDarkBlue,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorDarkBlue,
//             "--iconHover": pallet.colorDarkBlue,
//         },
//         blackWhite: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorDarkNavy,
//             "--title": pallet.colorDarkNavy,
//             "--titleHover": pallet.colorDarkNavy, 
//             "--circle": pallet.colorDarkNavy,
//             "--circleHover": pallet.colorDarkNavy,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorDarkNavy,
//             "--iconHover": pallet.colorDarkNavy,
//         },
//         dark: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorDarkNavy,
//             "--title": pallet.colorDarkNavy,
//             "--titleHover": pallet.colorDarkNavy, 
//             "--circle": pallet.colorDarkNavy,
//             "--circleHover": pallet.colorDarkNavy,
//             "--circleIconColor": pallet.colorWhite,
//             "--circleIconHover": pallet.colorWhite,
//             "--icon": pallet.colorDarkNavy,
//             "--iconHover": pallet.colorDarkNavy,
//         },
//         light: {
//             "--color": "transparent",
//             "--hover": "transparent",
//             "--borderColor": pallet.colorGray,
//             "--title": pallet.colorLightishGray,
//             "--titleHover": pallet.colorLightishGray, 
//             "--circle": pallet.colorLightishGray,
//             "--circleHover": pallet.colorLightishGray,
//             "--circleIconColor": pallet.colorGray,
//             "--circleIconHover": pallet.colorGray,
//             "--icon": pallet.colorLightishGray,
//             "--iconHover": pallet.colorLightishGray,
//         },
//     }
// }
