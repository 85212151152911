
import { RecoilState, atom, selector } from "recoil";

export type notificationType = {
    closed: any,
    // confirmableId: any,
    // confirmableType: any,
    type: string;
    dailyLogId: number;
    dailyLog: any,
    date: any,
    id: any,
    message: any,
    status: string,
    supervisorId: any,
    user: any,
    userId: any,
    work: any,
    Works?: any,
}
export const AllNotificationsStateAtom: RecoilState<notificationType[]> = atom<notificationType[]>({
    key: 'AllNotificationsStateAtom',
    default: [],
});