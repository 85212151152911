import { useRecoilState, useRecoilValue } from "recoil"
import styles from "./Work.module.scss"
import { DateState, GlobalLoadingAtom, MinimumWorkTimeDurationStateAtom, ServerDateState, ShowAggregationTimeStateAtom, UserDataState } from "../../../Recoil/Atoms"
import { DailyLogAtom, LockWorkPageAtom, PinsStateAtom, TimeFrameAtom, TimeSpanDurationAtom, TransmissionTimeAtom } from "../../../Recoil/Works.atom"
import { useEffect, useRef, useState } from "react"
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { Toasts } from "../../../toasts/toasts";
import { ServerDateToDay, TimestampToTime, TimeToTimestamp } from "../../../Utils/convertTime";
import { updateWork } from "../../../axios/Work.api";
import { workTheme } from "../../../pages/Works/Works"
import TextTag from "../../Common/TextTag/TextTag"
import useNotificationsUpdate from "../../../hooks/useNotificationsUpdate"
import useGenerateTimeFrame from "../../../hooks/useGenerateTimeFrame"
import { inputSubmitAtom } from "../../../Recoil/dirtyAtom"
const oneDay = 60*60*24;

const Work = ({
    ele,
    workBoxSize,
    works,
    setWorks,
    selectedWork,
    setSelectedWork,
    lockTost,
    increaseSensitive,
    increaseExitTimeInterval,
    increaseExitTime,
    inOutLogs,
    justSee
}: any) => {

    const minimumWorkTimeDuration = useRecoilValue(MinimumWorkTimeDurationStateAtom)
    const [timeFrame, setTimeFrame] = useRecoilState(TimeFrameAtom)
    const [timeSpanDuration, setTimeSpanDuration] = useRecoilState(TimeSpanDurationAtom)
    const [lock, setLock] = useRecoilState(LockWorkPageAtom)
    const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom)
    const [showAggregationTime, setShowAggregationTime] = useRecoilState(ShowAggregationTimeStateAtom)
    const [globalLoading, setGlobalLoading] = useRecoilState(GlobalLoadingAtom)
    const [pins, setPins] = useRecoilState(PinsStateAtom)
    // این رو مجبور شدم اضافه کنم برای اینکه بفهمم فرم کی سابمیت میشه تا اینپوت های مربوط به شروع و پایان زمان رو آپدیت کنم. بعدا یه فکر بهتری براش میکنم
    const [formSubmit, setFormSubmit] = useRecoilState(inputSubmitAtom)
    const time_frame_ref = useRef(timeFrame)
    time_frame_ref.current = timeFrame
    const calculateTop = (span: number, spanDuration?: number, frame?: string[]) => {
        // console.log(span, ele.id)
        // // if (!timeLog.enter) return 0
        // // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
        // // const startHoursToTimeSpan = +(TimestampToTime(timeLog.enter).split(":")?.[0]) * 60 * 60
        // // const distanceFromZeroTime = span - startHoursToTimeSpan;
        // const px = span * workBoxSize / (spanDuration ?? timeSpanDuration);
        // return px


        const startHoursToTimeSpan = (timeFrame.length ? (+(frame ?? timeFrame)?.[0]?.split(":")?.[0]) : 0) * 60 * 60
        const distanceFromZeroTime = span - startHoursToTimeSpan;
        const px = distanceFromZeroTime * workBoxSize / (spanDuration ?? timeSpanDuration);
        return px
    }

    const calculateHeight = (span: number, spanDuration?: number) => {
        const px = span * workBoxSize / (spanDuration ?? timeSpanDuration)
        return px
    }

    const pixelToTimeSpan = (px: number, spanDuration?: number, frame?: string[]) => {
        // if (!timeLog.enter) return 0
        // // const today = ServerDateToDay(ele.date);
        // const today = ele.date;
        // // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
        // const startHoursToTimeSpan = +(TimestampToTime(timeLog.enter).split(":")?.[0]) * 60 * 60
        // const startWorkSpan = today + startHoursToTimeSpan
        // const span = px * timeSpanDuration / workBoxSize
        // // return Math.floor((span + startWorkSpan ) / 60000) * 60
        // return Math.floor((span + startWorkSpan)/60)*60

        const today = ele.date;
        // const startHoursToTimeSpan = +timeLog.enter.split(":")?.[0] * 1000 * 60 * 60;
        // const startHoursToTimeSpan = +(TimestampToTime(log.date).split(":")?.[0]) * 60 * 60
        const startHoursToTimeSpan = (timeFrame.length ? +((frame ?? timeFrame)?.[0]?.split(":")?.[0]) : 0) * 60 * 60
        const startWorkSpan = today + startHoursToTimeSpan
        const span = px * (spanDuration ?? timeSpanDuration) / workBoxSize
        // return Math.floor((span + startWorkSpan ) / 60000) * 60
        return Math.floor((span + startWorkSpan)/60)*60
    }

    const minHeigthCalculate = () => {
        let minHeight = (minimumWorkTimeDuration * workBoxSize / timeSpanDuration)
        if (ele.idleTime && +ele.idleTime > minimumWorkTimeDuration) minHeight = (ele.idleTime * workBoxSize / timeSpanDuration)
        return minHeight;
    }
    
    const updateNotification = useNotificationsUpdate()
    const generateTimeFrame = useGenerateTimeFrame()
    const serverDate = useRecoilValue(ServerDateState)
    const [timeLog, setTimeLog] = useRecoilState(TransmissionTimeAtom)
    const [date, setDate] = useRecoilState(DateState)
    const [accessOpenDescription, setAccessOpenDescription] = useState(false)
    const [accessMove, setAccessMove] = useState(false)
    // const [firstStep, setFirstStep] = useState(0)
    const [firstTop, setFirstTop] = useState(0)
    const [firstHeight, setFirstHeight] = useState(0)
    const [hasTransition, setHasTransition] = useState(true)
    const [user, setUser] = useRecoilState(UserDataState)
    const [inOutLog, setInOutLog] = useState<any>(null)
    const [workTime, setWorkTime] = useState({
        start: 0,
        end: 0,
    })
    const [workInstance, setWorkInstance] = useState([])
    const [position, setPosition] = useState({
        top: calculateTop(ele.start - ele.date) ?? 0,
        height: calculateHeight((ele.end - ele.start)) ?? 0
    })
    const [isLastWork, setIsLastWork] = useState(false)
    const [beforeWork, setBeforeWork] = useState<any>({})
    const [afterWork, setAfterWork] = useState<any>({})
    const positionTop = useRef(position.top)
    const positionHeight = useRef(position.height)
    const firstStep = useRef(0)
    // let workInstance: any;

    let accessRunningInterval = useRef(false)
    let accessRunningRecessive = useRef(false)
    const pointerDown = (e: any) => {
        if (justSee) return;
        setWorkInstance(works)
        setHasTransition(false)
        setAccessMove(true)
        setFirstTop(position.top)
        setFirstHeight(position.height)
        positionTop.current = position.top
        positionHeight.current = position.height
        const lastWork = [...works].pop()
        if (ele.id === lastWork.id) {
            setIsLastWork(true)
            accessRunningInterval.current = true
            accessRunningRecessive.current = true
        }
        if (!isMobile) {
            // setFirstStep(e.clientY)
            firstStep.current = e.clientY
            e.currentTarget.setPointerCapture(e.pointerId);
        } else {
            firstStep.current = e.targetTouches?.[0].clientY
            // setFirstStep(e.targetTouches?.[0].clientY)
        }
    }

    let timeOutRef = useRef<any>(null)
    const pointerMove = (e: any, type: "top" | "down" | "both") => {
        if (justSee) return;
        // console.log(e)
        if (!accessMove) return;
        if (lock) {
            setAccessMove(false)
            Toasts.lockWorks()
            return;
        }
        // runningFunction.current = false
        let top = position.top;
        let height = position.height;
        // const thisWorkIndex = works.findIndex((e: any) => e.id === ele.id)
        // const beforeWork = works[thisWorkIndex-1]
        // const afterWork = works[thisWorkIndex+1]
        // const beforeRound = beforeWork ? Math.floor(beforeWork.end/60000)*60000 : 0
        // const afterRound = afterWork ? Math.floor(afterWork.start/60000)*60000 : 0
        // const serverRound = Math.floor(serverDate/60000)*60000
        const beforeRound = beforeWork
        const afterRound = afterWork 
        const serverRound = ele.date
        // const minTop = beforeWork ? calculateTop((beforeRound - ServerDateToDay(beforeRound))) : calculateTop(TimeToTimestamp(timeLog.enter, serverRound) - ServerDateToDay(serverRound))
        // const maxHeight = afterWork ? calculateTop((afterRound - ServerDateToDay(afterRound))) : calculateTop(TimeToTimestamp(timeLog.exit, serverRound) - ServerDateToDay(serverRound)) // workBoxSize
        const minTop = beforeWork ? calculateTop((beforeRound - ServerDateToDay(date))) : calculateTop(28000)
        
        const hasMission = works.find((w: any) => w.type === "mission")
        const isToday = ServerDateToDay(serverDate) <= date
        const maxHeight = afterRound ? calculateTop((afterRound - ServerDateToDay(date))) : inOutLog?.exit ? calculateTop(inOutLog.exit - ele.date) : hasMission ? calculateTop((date + (24*60*60)) - ele.date) : calculateTop(TimeToTimestamp([...timeFrame].pop() ?? "00:00", date) - ele.date)
        const minHeight = minHeigthCalculate();
        // const workBottom = e.target.getBoundingClientRect()?.bottom ?? 0
        // const sensitiveTop = increaseSensitive.current.getBoundingClientRect()?.top ?? 0
        // console.log(afterWork)
        let clientY = e.clientY
        if (isMobile) {
            e.preventDefault()
            clientY = e.targetTouches?.[0]?.clientY ?? firstStep.current
        } 
        const changedTop = clientY - firstStep.current
        if (type === "top") {
            const newTop = firstTop + changedTop
            const newHeight = firstHeight - changedTop
            if (newTop <= minTop) {
                top = minTop;
                height = firstHeight + firstTop - minTop
                const start = pixelToTimeSpan(top)
                const end = pixelToTimeSpan(top + height)
                setWorkTime({
                    start,
                    end
                })
                return setPosition({
                    top: minTop,
                    height: firstHeight + firstTop - minTop
                });
            } else if (newHeight <= minHeight) {
                top = firstTop+firstHeight-minHeight;
                height = minHeight
                const start = pixelToTimeSpan(top)
                const end = pixelToTimeSpan(top + height)
                setWorkTime({
                    start,
                    end
                })
                return setPosition({
                    top: firstTop+firstHeight-minHeight,
                    height: minHeight
                });
            } else {
                top = newTop;
                height = newHeight
                const start = pixelToTimeSpan(top)
                const end = pixelToTimeSpan(top + height)
                setWorkTime({
                    start,
                    end
                })
                return setPosition({
                    top: newTop,
                    height: newHeight
                })
            }
            
        } else if (type === "down") {
            const newHeight = firstHeight + changedTop
            if (newHeight >= workBoxSize - position.top - (workBoxSize - maxHeight)) {
                if (isLastWork && ele.inOutLogId && !inOutLog.exit) {
                    accessRunningRecessive.current = true
                    if (!accessRunningInterval.current) return
                    accessRunningInterval.current = false
                    height = workBoxSize - position.top - (workBoxSize - maxHeight)
                    const start = pixelToTimeSpan(position.top)
                    const end = pixelToTimeSpan(top + height)
                    if (!accessRunningRecessive.current) return;
                    setWorkTime({
                        ...workTime,
                        start,
                        end
                    })
                    if (!accessRunningRecessive.current) return;
                    setPosition({
                        ...position,
                        height: workBoxSize - position.top - (workBoxSize - maxHeight)
                    });

                    const recessive = async () => {
                        if (!accessRunningRecessive.current) return;

                        await new Promise((resolve, reject) => {
                            setTimeout(() => {resolve(true)}, 200)
                        })

                        const endTimeFrame = +([...time_frame_ref.current].pop()?.split(":")?.[0] ?? 24)
                        const startTimeFrame = +([...time_frame_ref.current].shift()?.split(":")?.[0] ?? 0)
                        if (+endTimeFrame > 23) return;
                        const loopFrom = startTimeFrame // timestampToHours(log.enter as number)
                        const loopTo = +endTimeFrame + 1
                        const time_frame: string[] = []
                        for (let i=loopFrom; i<=loopTo; i++) {
                            time_frame.push(`${String(i).padStart(2, "0")}:00`)
                        }
                        if (!accessRunningRecessive.current) return;
                        setTimeFrame(time_frame)
                        time_frame_ref.current = time_frame
                        const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
                        setTimeSpanDuration(spanDuration)
                        setTimeout(() => {
                            const newTop = calculateTop(ele.start - ele.date, spanDuration) ?? 0
                            firstStep.current -= (positionTop.current - newTop)
                            setPosition({
                                top: newTop,
                                height: workBoxSize - newTop - (workBoxSize - maxHeight)
                            })
                            positionTop.current = newTop
                        }, 0)
                        await recessive()
                    }
                    recessive()
                } else {
                    height = workBoxSize - position.top - (workBoxSize - maxHeight)
                    const start = pixelToTimeSpan(position.top)
                    const end = pixelToTimeSpan(top + height)
                    setWorkTime({
                        ...workTime,
                        start,
                        end
                    })
                    return setPosition({
                        ...position,
                        height: workBoxSize - position.top - (workBoxSize - maxHeight)
                    });
                }
            } else if (position.height <= minHeight && newHeight <= minHeight) {
                accessRunningRecessive.current = false
                accessRunningInterval.current = true
                height = minHeight
                const start = pixelToTimeSpan(position.top)
                const end = pixelToTimeSpan(top + height)
                setWorkTime({
                    ...workTime,
                    start,
                    end
                })
                return setPosition({
                    ...position,
                    height: minHeight
                });

            } else {
                accessRunningRecessive.current = false
                accessRunningInterval.current = true
                height = newHeight
                const start = pixelToTimeSpan(position.top)
                const end = pixelToTimeSpan(top + height)
                setWorkTime({
                    ...workTime,
                    start,
                    end
                })
                return setPosition({
                    ...position,
                    height: newHeight
                })
            }

        } else if (type === "both") {
            const newTop = firstTop + changedTop
            if (newTop <= minTop) {
                accessRunningRecessive.current = false
                accessRunningInterval.current = true
                top = minTop
                const start = pixelToTimeSpan(top)
                const end = pixelToTimeSpan(top + position.height)
                setWorkTime({
                    ...workTime,
                    start,
                    end
                })
                return setPosition({
                    ...position,
                    top: minTop,
                })
            } 
            else if (newTop >= workBoxSize - positionHeight.current - (workBoxSize - maxHeight)) {
                if (isLastWork && ele.inOutLogId && !inOutLog.exit) {
                    accessRunningRecessive.current = true
                    if (!accessRunningInterval.current) return
                    accessRunningInterval.current = false
                    top = workBoxSize - position.height - (workBoxSize - maxHeight)
                    const start = pixelToTimeSpan(top)
                    const end = pixelToTimeSpan(top + position.height)
                    if (!accessRunningRecessive.current) return;
                    setWorkTime({
                        ...workTime,
                        start,
                        end
                    })
                    positionTop.current = top;
                    if (!accessRunningRecessive.current) return;
                    setPosition({
                        ...position,
                        top,
                    })
                    const recessive = async () => {
                        if (!accessRunningRecessive.current) return;

                        await new Promise((resolve, reject) => {
                            setTimeout(() => {resolve(true)}, 200)
                        })

                        const endTimeFrame = +([...time_frame_ref.current].pop()?.split(":")?.[0] ?? 24)
                        const startTimeFrame = +([...time_frame_ref.current].shift()?.split(":")?.[0] ?? 0)
                        if (+endTimeFrame > 23) return;
                        const loopFrom = startTimeFrame // timestampToHours(log.enter as number)
                        const loopTo = +endTimeFrame + 1
                        const time_frame: string[] = []
                        for (let i=loopFrom; i<=loopTo; i++) {
                            time_frame.push(`${String(i).padStart(2, "0")}:00`)
                        }
                        if (!accessRunningRecessive.current) return;
                        setTimeFrame(time_frame)
                        time_frame_ref.current = time_frame
                        const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
                        setTimeSpanDuration(spanDuration)
                        const newMaxHeight = calculateTop(TimeToTimestamp([...time_frame].pop() ?? "00:00", date) - ele.date, spanDuration)
                        setTimeout(() => {
                            const newHeight = calculateHeight((ele.end - ele.start), spanDuration) ?? 0
                            const newTop = workBoxSize - newHeight - (workBoxSize - newMaxHeight)
                            setPosition({
                                height: newHeight,
                                top: newTop,
                            })
                            positionTop.current = newTop
                        }, 0)
                        await recessive()
                    }
                    recessive()
                } else {
                    top = workBoxSize - position.height - (workBoxSize - maxHeight)
                    const start = pixelToTimeSpan(top)
                    const end = pixelToTimeSpan(top + position.height)
                    setWorkTime({
                        ...workTime,
                        start,
                        end
                    })
                    positionTop.current = top;
                    return setPosition({
                        ...position,
                        top,
                    })
                }
            }
            else {
                accessRunningRecessive.current = false
                accessRunningInterval.current = true
                top = newTop
                const start = pixelToTimeSpan(top)
                const end = pixelToTimeSpan(top + position.height)
                setWorkTime({
                    ...workTime,
                    start,
                    end
                })
                return setPosition({
                    ...position,
                    top: newTop,
                })
            }

        }
        
    }

    const updateWorkHandler = (cloneWorks: any) => {
        if (justSee) return;
        setGlobalLoading(true)
        const newWork = cloneWorks.find((w: any) => w.id === ele.id)
        if (newWork.type === "temporary") return; 
        updateWork(ele.id, {
            start: newWork.start,
            end: newWork.end,
        })
            .then(res => {
                const { data } = res
                const cloneWorks = [...works]
                const findWorkIndex = cloneWorks.findIndex(w => w.id === data.id)
                cloneWorks.splice(findWorkIndex, 1)
                cloneWorks.splice(findWorkIndex, 0, data)
                const sortWorks = cloneWorks.sort((a, b) => a.start - b.start)
                setWorks(sortWorks)
                setDailyLog({
                    ...dailyLog,
                    finalized: 0
                })
                let notifType;
                if (ele?.type === "mission" || ele?.type === "remote" || ele?.type === "work") notifType = ele?.type
                updateNotification({dailyLogId: dailyLog.id, type: notifType})
                generateTimeFrame(inOutLogs, sortWorks, date, pins)
                setGlobalLoading(false)
                setTimeout(() => {
                    setFormSubmit(!formSubmit)
                }, 0)
            })
            .catch(err => {
                console.log(err)
                setGlobalLoading(false)
                workInstance && setWorks(workInstance)
            })
    }

    const pointerUp = (e: any) => {
        if (justSee) return;
        if (!accessMove) return;
        if (!isMobile) {
            e.currentTarget.releasePointerCapture(e.pointerId);
        }
        accessRunningInterval.current = false
        accessRunningRecessive.current = false
        setHasTransition(true)
        const cloneWorks = [...works]
        const findIndex = cloneWorks.findIndex((item: any) => item.id === ele.id)
        const splitWorks = {...cloneWorks.splice(findIndex, 1)[0]}
        splitWorks.start = pixelToTimeSpan(position.top)
        splitWorks.end = pixelToTimeSpan(position.top + position.height)
        cloneWorks.splice(findIndex, 0, splitWorks)
        setWorks(cloneWorks)
        setAccessMove(false)
        
        const newWork = cloneWorks.find((w: any) => w.id === ele.id)
        const oldWork: any = workInstance.find((w: any) => w.id === ele.id)
        if (((newWork.start !== oldWork.start) || (newWork.end !== oldWork.end)) && (!accessOpenDescription)) {
            updateWorkHandler(cloneWorks)
        }



        
        // const exitTime = findExitTime({
        //     exit: timeLog.exit,
        //     works: cloneWorks,
        //     serverDate,
        //     date,
        //     logicExit: user.logicExit
        // })
        // const loopFrom = timestampToHours(timeLog.enter as number)
        // const loopTo = +exitTime < 24 ? +exitTime+1 : +exitTime
        // const time_frame: string[] = []
        // for (let i=loopFrom; i<=loopTo; i++) {
        //     time_frame.push(`${String(i).padStart(2, "0")}:00`)
        // }
        // // setTimeFrame(time_frame)
        // const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
        // // setTimeSpanDuration(spanDuration)
    }

    useEffect(() => {
        const topPosition = calculateTop(ele.start - ele.date)
        const heightPosition = calculateHeight((ele.end - ele.start))
        setPosition({
            top: topPosition,
            height: heightPosition, 
        })
        const start = Math.ceil(pixelToTimeSpan(topPosition))
        const end = Math.ceil(pixelToTimeSpan(topPosition + heightPosition))
        setWorkTime({
            start,
            end
        })
    }, [workBoxSize, timeLog, timeFrame, works])

    useEffect(() => {
        // console.log(inOutLogs)
        // console.log(inOutLogs)
        const isInCompany = ele.inOutLogId
        if (isInCompany) {
            const {id, date, enter, exit} = inOutLogs.find((w: any) => w.id === ele.inOutLogId)
            const inWorks = works.filter((w: any) => w.inOutLogId && w.inOutLogId === id)
            setInOutLog({id, date, enter, exit})
            const sortWorks = [...inWorks].sort((a: any, b: any) => a.start - b.start)
            const thisWorkIndex = sortWorks.findIndex((e: any) => e.id === ele.id)
                
            
            const beforeWork = sortWorks[thisWorkIndex-1]
            const afterWork = sortWorks[thisWorkIndex+1]
            const minTime = beforeWork ? (beforeWork.end > enter ? beforeWork.end : enter) : enter // enter ? enter : beforeWork ? beforeWork.end : 0
            const maxTime = afterWork ? (afterWork.start < (exit ?? (date + oneDay)) ? afterWork.start : exit) : exit // exit ? exit : afterWork ? afterWork.start : 0
            setBeforeWork(minTime)
            setAfterWork(maxTime)
        } else {
            const outOfCompony = works.filter((w: any) => !w.inOutLogId)
            const outWorks = outOfCompony.map((w: any) => ({
                start: w.start,
                end: w.end,
                type: "work"
            }))
            const inOuts = inOutLogs.map((w: any) => ({
                start: w.enter,
                end: w.exit,
                type: "inOut"
            }))
            const activityDetails = [...outWorks, ...inOuts].sort((a: any, b: any) => a.start - b.start)
            const findActivityIndex: any = [...activityDetails].findIndex((a: any) => a.start === ele.start)
            const beforeWork = activityDetails[findActivityIndex-1]
            const afterWork = activityDetails[findActivityIndex+1]
            const minTime = beforeWork ? beforeWork.end : date
            const maxTime = afterWork ? afterWork.start : 0
            setBeforeWork(minTime)
            setAfterWork(maxTime)
        }
    }, [inOutLogs, works])
    
    const bothRef = useRef<any>(null)

    const colors = {
        emerald: "0 0 3px #059669,0 0 8px #059669,0 0 20px #059669",
        sky: "0 0 3px #0284c7,0 0 8px #0284c7,0 0 20px #0284c7",
        purple: "0 0 3px #9333ea,0 0 8px #9333ea,0 0 20px #9333ea",
        yellow: "0 0 3px #ca8a04,0 0 8px #ca8a04,0 0 20px #ca8a04",
        rose: "0 0 3px #e11d48,0 0 8px #e11d48,0 0 20px #e11d48",
    }

//   useEffect(() => {
//     // const handleTouchMove = (event) => {
//     //   // Prevent the default behavior (like pull-to-refresh) only on this element
//     //   event.preventDefault();

//     //   const touch = event.touches[0];
//     //   setPosition({
//     //     x: touch.clientX,
//     //     y: touch.clientY,
//     //   });
//     // };

//     // Add a non-passive touchmove event listener to the specific div
//     const divElement = bothRef.current;
//     divElement.addEventListener('touchmove', (e: any) => pointerMove(e, "both"), { passive: false });

//     // Cleanup the event listener when component unmounts
//     return () => {
//       divElement.removeEventListener('touchmove', (e: any) => pointerMove(e, "both"));
//     };
//   }, [bothRef.current]);

    return (
        <div
            className={`${selectedWork === ele.id ? styles.progress : ""} text-gray-700 w-full absolute border ${ele.confirmed ? `bg-${workTheme[ele.type as "work"]}-500 border-${workTheme[ele.type as "work"]}-700 ring-${workTheme[ele.type as "work"]}-700` : `bg-rose-500 bg-opacity-20 border-rose-700 ring-rose-700`} rounded-md flex flex-col items-start justify-between transition-[box-shadow] duration-300 ${hasTransition ? "" : ""}`}
            style={{
                top: position.top ?? 0,
                height: position.height ?? 0,
            }}
        >
            {!!ele.idleTime &&
                <div 
                    className={`h-full absolute bg-gray-900 bg-opacity-10 border-e border-${workTheme[ele.type as "work"]}-700`}
                    style={{
                        width: `${(ele.idleTime / (ele.end - ele.start)) * 100}%`
                    }}
                >
                </div>
            }
            <div 
                onMouseDown={() => {
                    if (isMobile) return;
                    setAccessOpenDescription(true)
                }}
                onMouseMove={() => {
                    if (isMobile) return;
                    setAccessOpenDescription(false)
                }}
                onMouseUp={() => {
                    if (!accessOpenDescription || isMobile) return;
                    if (selectedWork === ele.id) setSelectedWork(0)
                    else {
                        setSelectedWork(ele.id)
                        if (showAggregationTime === "up") setShowAggregationTime("center")
                    }
                }}
                onMouseLeave={() => {
                    if (isMobile) return;
                    if (accessOpenDescription) {
                        setSelectedWork(ele.id)
                        if (showAggregationTime === "up") setShowAggregationTime("center")
                    } 
                }}
                onTouchStart={() => {
                    if (!isMobile) return;
                    setAccessOpenDescription(true)
                }}
                onTouchMove={() => {
                    if (!isMobile) return;
                    setAccessOpenDescription(false)
                }}
                onTouchEnd={() => {
                    if (!isMobile) return;
                    if (accessOpenDescription) {
                        setSelectedWork(ele.id)
                        if (showAggregationTime === "up") setShowAggregationTime("center")
                    } 
                }}
                onClick={(e) => {
                    // setSelectedWork(ele.id)
                    e.preventDefault()
                    e.stopPropagation()
                }}
                className='w-full h-full absolute flex flex-col'
            >
                <div
                    className="w-full h-1 hidden md:flex items-center justify-center cursor-n-resize flex-none touch-none z-10"
                    onPointerDown={!isMobile ? pointerDown : () => {}}
                    onPointerMove={!isMobile ? (e: any) => pointerMove(e, "top") : () => {}}
                    onPointerUp={!isMobile ? pointerUp : () => {}}
                    onPointerLeave={!isMobile ? pointerUp : () => {}}
                    onTouchStart={isMobile ? pointerDown : () => {}} // For touch devices
                    onTouchMove={isMobile ? (e: any) => pointerMove(e, "top") : () => {}}   // For touch devices
                    onTouchEnd={isMobile ? pointerUp : () => {}}   
                />
                <div
                    ref={bothRef}
                    className="w-full flex-auto cursor-move touch-none overflow-hidden relative flex items-center justify-center p-2"
                    onPointerDown={!isMobile ? pointerDown : () => {}}
                    onPointerMove={!isMobile ? (e: any) => pointerMove(e, "both") : () => {}}
                    onPointerUp={!isMobile ? pointerUp : () => {}}
                    onPointerLeave={!isMobile ? pointerUp : () => {}}
                    onTouchStart={isMobile ? pointerDown : () => {}} // For touch devices
                    onTouchMove={isMobile ? (e: any) => pointerMove(e, "both") : () => {}}   // For touch devices
                    onTouchEnd={isMobile ? pointerUp : () => {}}   
                >
                    <TextTag
                        dir="ltr" 
                        as='small' 
                        className='w-full h-full flex flex-col md:hidden overflow-hidden !text-gray-800 select-none cursor-move text-start'
                    >
                        {/* {ele.notes && ele.notes.split("@@@").map((note: any) => ` / ${note}`)} */}
                        
                        {ele.notes && ele.notes.split("@@@").map((note: any, index: any) => <span className="border-b border-gray-900 border-opacity-10 text-[0.6rem]" key={index} style={{direction: "rtl", unicodeBidi: "embed"}}>{`${index+1}_ ${note}`}</span> )}
                    </TextTag>
                    {/* <TextTag>{DurationToTime(ele.idleTime, date)}</TextTag> */}
                </div>
                <div
                    className="w-full h-1 hidden md:flex items-center justify-center cursor-n-resize flex-none touch-none z-10"
                    onPointerDown={!isMobile ? pointerDown : () => {}}
                    onPointerMove={!isMobile ? (e: any) => pointerMove(e, "down") : () => {}}
                    onPointerUp={!isMobile ? pointerUp : () => {}}
                    onPointerLeave={!isMobile ? pointerUp : () => {}}
                    onTouchStart={isMobile ? pointerDown : () => {}} // For touch devices
                    onTouchMove={isMobile ? (e: any) => pointerMove(e, "down") : () => {}}   // For touch devices
                    onTouchEnd={isMobile ? pointerUp : () => {}} 
                />
                <div
                    className={`absolute w-10 h-4 right-5 -top-2 touch-none ${ele.confirmed ? `bg-${workTheme[ele.type as "work"]}-600` : `bg-rose-600`} z-[40] rounded-full flex items-center justify-center cursor-pointer p-1 text-[0.6rem] select-none text-white hover:scale-[120%] transition- duration-200`}
                    
                    onPointerDown={!isMobile ? pointerDown : () => {}}
                    onPointerMove={!isMobile ? (e: any) => pointerMove(e, "top") : () => {}}
                    onPointerUp={!isMobile ? pointerUp : () => {}}
                    onPointerLeave={!isMobile ? pointerUp : () => {}}
                    onTouchStart={isMobile ? pointerDown : () => {}} // For touch devices
                    onTouchMove={isMobile ? (e: any) => pointerMove(e, "top") : () => {}}   // For touch devices
                    onTouchEnd={isMobile ? pointerUp : () => {}}  
                >
                    {TimestampToTime(workTime.start)}
                </div>
                <div
                    className={`absolute w-10 h-4 left-5 -bottom-2 touch-none ${ele.confirmed ? `bg-${workTheme[ele.type as "work"]}-600` : `bg-rose-600`} z-[40] rounded-full flex items-center justify-center cursor-pointer p-1 text-[0.6rem] select-none text-white hover:scale-[120%] transition- duration-200`}
                    
                    onPointerDown={!isMobile ? pointerDown : () => {}}
                    onPointerMove={!isMobile ? (e: any) => pointerMove(e, "down") : () => {}}
                    onPointerUp={!isMobile ? pointerUp : () => {}}
                    onPointerLeave={!isMobile ? pointerUp : () => {}}
                    onTouchStart={isMobile ? pointerDown : () => {}} // For touch devices
                    onTouchMove={isMobile ? (e: any) => pointerMove(e, "down") : () => {}}   // For touch devices
                    onTouchEnd={isMobile ? pointerUp : () => {}} 
                >
                    {TimestampToTime(workTime.end, date)}
                </div>
            </div>
        </div>

    )
}

export default Work