import { FormType, UserValuesType } from "./Types"

const validation = (values: UserValuesType, type: FormType) => {
    const error: {
        username: string;
        password: string;
        confirmPassword: string;
    } = {
        username: "",
        password: "",
        confirmPassword: "",
    }

    if (!values.username.trim()) {
        error.username = "نام کاربری را وارد کنید"
    } else if (values.username.length < 5 || values.username.length > 25) {
        error.username = "نام کاربری باید بین 5 تا 25 کاراکتر باشد"
    }

    if (type === "create") {
        if (!values.password.trim()) {
            error.password = "رمز عبور را وارد کنید"
        } else if (values.password.length < 5 || values.password.length > 16) {
            error.username = "رمز عبور باید بین 5 تا 16 کاراکتر باشد"
        }
    } else if (type === "edit") {

    }

    // if (values.password !== values.confirmPassword) {
    //     error.confirmPassword = "تکرار رمز عبور اشتباه است"
    // }

    // if (values.password !== values.confirmPassword) {
    //     error.confirmPassword = "تکرار رمز عبور اشتباه است"
    // }

    return error
}

export default validation