import axios from "axios";
import { useEffect, useState } from "react";
import { useTheme } from "next-themes";
import { useRecoilState, useRecoilValue } from "recoil";
import moment from "moment-jalaali";
import { ServerDateState, UserDataState } from "../../../Recoil/Atoms";
import { getTimeAggregation } from "../../../axios/Reports.api";
import Input from "../../../Components/Common/Input/Input";
import ReactSelect from "../../../Components/Common/ReactSelect/ReactSelect";
import Button from "../../../Components/Common/Button/Button";

const monthOptions = [
    {value: 1, label: "فروردین"},
    {value: 2, label: "اردیبهشت"},
    {value: 3, label: "خرداد"},
    {value: 4, label: "تیر"},
    {value: 5, label: "مرداد"},
    {value: 6, label: "شهریور"},
    {value: 7, label: "مهر"},
    {value: 8, label: "آبان"},
    {value: 9, label: "آذر"},
    {value: 10, label: "دی"},
    {value: 11, label: "بهمن"},
    {value: 12, label: "اسفند"}
]
const Finance = () => {

    const {theme, setTheme} = useTheme()
    const [userData, setUserData] = useRecoilState(UserDataState)
    const serverDate = useRecoilValue(ServerDateState)
    const [values, setValues] = useState({
        year: moment(serverDate * 1000).jYear(),
        month: monthOptions.find(month => month.value === (moment(serverDate * 1000).jMonth())) ?? monthOptions[0],
    })
    const [loading, srLoading] = useState(false)

    const selectChangeHandler = (value: any, name: string) => {
        setValues({
            ...values,
            month: value
        })
    }

    const onSubmit = () => {
        // srLoading(true)
        const start = `${values.year}/${values.month?.value}/${1}`
        const end = `${values.year}/${values.month?.value + 1}/${1}`
        const fromEpoch = moment(start, 'jYYYY/jMM/jDD')
        const toEpoch = moment(end, 'jYYYY/jMM/jDD')
        const from = Math.floor(fromEpoch.valueOf()/60000) * 60
        const to = (Math.floor(toEpoch.valueOf()/60000) * 60) - 60
        
        getTimeAggregation({from, to})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                const fileName = response.headers["x-filename"] ?? "PFK timesheet.xlsx";
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                srLoading(false)
            })
            .catch((err) => {
                console.log(err);
                srLoading(false)
            })
    }

  
    // const [position, setPosition] = useState("{}")


    // useEffect(() => {
    //   navigator.geolocation.watchPosition(
    //     (position) => {
    //         const coords = position.coords;
    //         setPosition(JSON.stringify(coords, null, 4))
    //     },
    //     (err) => {
    //       console.log(err)
    //     }    
    //   )
    // }, [])


    return (
        <div className="p-2 border bg-gray-100 dark:bg-gray-600 border-zinc-400 dark:border-zinc-800 lg:rounded-md">
            {(userData.role === "admin" || userData.role === "manager") && 
                <>
                    <p className="text-gray-500 dark:text-gray-300">گزارش تجمیع ساعات پرسنل</p>
                    <div className="flex flex-col sm:flex-row items-end justify-between gap-0 sm:gap-3 pt-5">
                        <Input
                            name="year"
                            label="سال"
                            type="number"
                            value={values.year}
                            act
                            // error="dasdasd"
                            onChange={(e) => setValues({...values, year: +e.target.value})}
                            classNames={{
                                container: "sm:flex-1 h-[80px]",
                                inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                                input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                                label: "text-sm font-thin mb-1"
                            }}
                        />
                        <ReactSelect
                            name="month"
                            label="ماه"
                            theme={theme}
                            onChange={selectChangeHandler}
                            value={values.month}
                            isSearchable={false}
                            className={"sm:flex-1 w-full h-[80px]"}
                            options={monthOptions}
                        />
                        <Button
                            title="دریافت گزارش اکسل"
                            color="success"
                            loading={loading}
                            onClick={onSubmit}
                            classNames={{
                                container: "!flex-none !w-full sm:!w-48 !h-[38px] !rounded-[4px] sm:mb-[18px] mt-5 sm:mt-0 !px-2",
                                section: "!text-sm"
                            }}
                        />
                    </div>
                </>
            }

            {/* <pre>
                {position}
            </pre> */}
        </div>
    )
}

export default Finance