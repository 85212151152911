import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  DateState,
  MissionTimeFrameStateAtom,
  ServerDateState,
  ShowAggregationTimeStateAtom,
  UserDataState,
} from "../../../Recoil/Atoms";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import TextTag from "../TextTag/TextTag";
import {
  distanceTimesToDuration,
  durationsToTime,
  DurationToTime,
  ServerDateToDay,
  TimestampToTime,
  timeToDuration,
  TimeToTimestamp,
} from "../../../Utils/convertTime";
import {
  DailyLogAtom,
  hasInComponyAtom,
  LockWorkPageAtom,
  TransmissionTimeAtom,
  WorksInDayAtom,
} from "../../../Recoil/Works.atom";
import { InOutLogAtom } from "../../../Recoil/InOutLogAtom";
import TimePicker from "../TimePicker/TimePicker";
import { updateTimeLog } from "../../../axios/DailyLog.api";
import { Toasts } from "../../../toasts/toasts";
import { inputSubmitAtom } from "../../../Recoil/dirtyAtom";
import Icon from "../Icon/Icon";
import { twMerge } from "tailwind-merge";
import Button from "../Button/Button";
import { useTheme } from "next-themes";

export const faWorkType: any = {
  // sumOfInCompony: "جمع حضور",
  // work: "درشرکت",
  // mission: "ماموریت",
  // remote: "دورکاری",
  // leave: "کار شخصی",
  // temporary: "بلاتکلیف",
  // idleTime: "مجموع دراختیار",
  // dayDuration: "طول مدت حضور",
  // useful: "کار مفید",
  // sumOfRemoteDuration: "مجموع دورکاری"

  compony_sum: "مدت زمان حضور در شرکت",
  compony_idleTime: "جمع در اختیار در شرکت",
  compony_useful: "کار درشرکت خالص برای حقوق",
  compony_work: "مجموع کار در شرکت",
  compony_leave: "مجموع کارشخصی",

  remote_sum: "مجموع دورکاری",
  remote_idleTime: "دراختیار داخل دورکاری",
  remote_useful: "دورکاری خالص برای حقوق",
  mission_work: "کار",
  mission_onWay: "طی مسیر",
  mission_sleep: "خواب",
  mission_optionalUnemployment: "بیکاری اختیاری",
  mission_mandatoryUnemployment: "بیکاری اجباری",
};

const faWorkTitle: any = {
  compony_sum: "جمع ساعات کار در شرکت",
  compony_idleTime: "جمع ساعات در اختیار در زمان کار در شرکت",
  compony_useful:
    "جمع ساعات کار درشکرت ، منهای جمع ساعات دراختیار در زمان کار در شرکت",
  compony_work: "جمع ساعات کار در شرکت",
  compony_leave: "جمع ساعات کارشخصی",

  remote_sum: "جمع ساعات دروکاری",
  remote_idleTime: "جمع ساعات در اختیار در زمان دورکاری",
  remote_useful: "جمع ساعات دورکاری ، منهای جمع ساعات دراختیار در زمان دورکاری",
  mission_work: "جمع ساعات کار در ماموریت ، برای",
  mission_onWay: "جمع طی مسیر در زمان ماموریت ، برای",
  mission_sleep: "جمع ساعات خواب در زمان ماموریت",
  mission_optionalUnemployment: "جمع ساعات بیکاری اختیاری در زمان ماموریت",
  mission_mandatoryUnemployment: "جمع ساعات بیکاری اجباری در زمان ماموریت",
};
const WorkTimeAggregations = ({
  forDate,
  justSee = false,
  id = "",
}: {
  forDate?: number;
  justSee?: boolean;
  id?: string;
}) => {
  const workTheme: any = {
    compony_sum: "emerald",
    compony_idleTime: "emerald",
    compony_useful: "emerald",
    compony_work: "emerald",
    compony_leave: "yellow",
    remote_sum: "purple",
    remote_idleTime: "purple",
    remote_useful: "purple",
    mission_work: "sky",
    mission_onWay: "sky",
    mission_sleep: "sky",
    mission_optionalUnemployment: "sky",
    mission_mandatoryUnemployment: "sky",
  };
  const timeLog = useRecoilValue(TransmissionTimeAtom);
  const works = useRecoilValue(WorksInDayAtom);
  const serverDate = useRecoilValue(ServerDateState);
  const missionTimeFrame = useRecoilValue(MissionTimeFrameStateAtom);
  const [allDurations, setAllDurations] = useState<any>({});
  const [missionDurations, setMissionDurations] = useState<any>({});
  const [inComponyDurations, setInComponyDurations] = useState<any>({});
  const [remoteDurations, setRemoteDurations] = useState<any>({});
  const [date, setDate] = useRecoilState(DateState);
  const [user, setUser] = useRecoilState(UserDataState);
  const [inOutLogs, setInOutLogs] = useRecoilState(InOutLogAtom);
  const [lock, setLock] = useRecoilState(LockWorkPageAtom);
  const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom);
  // const hasWork = works.find(w => w.type === "work" || w.type === "remote" || w.type === "leave")
  // const hasMission = works.find(w => w.type === "mission")
  const [hasMission, setHasMission] = useState(false);
  const [hasInCompony, setHasInCompony] = useRecoilState(hasInComponyAtom);
  const [hasRemote, setHasRemote] = useState(false);
  const [hasWork, setHasWork] = useState(false);
  const [sumOfIdleTimeInWorks, setSumOfIdleTimeInWorks] = useState(0);
  // این رو مجبور شدم اضافه کنم برای اینکه بفهمم فرم کی سابمیت میشه تا اینپوت های مربوط به شروع و پایان زمان رو آپدیت کنم. بعدا یه فکر بهتری براش میکنم
  const [formSubmit, setFormSubmit] = useRecoilState(inputSubmitAtom);
  const [showAggregationBox, setShowAggregationBox] = useState<Array<string>>([
    "mission",
    "inCompony",
    "remote",
  ]);
  const [showAggregationTime, setShowAggregationTime] = useRecoilState(
    ShowAggregationTimeStateAtom
  );

  const startHandler = (time: string) => {
    if (lock) return Toasts.lockWorks();
    const idleTime = timeToDuration(time);
    updateTimeLog(date, { idleTime })
      .then((res) => {
        const { idleTime } = res.data;
        setDailyLog({
          ...dailyLog,
          idleTime,
          finalized: 0,
        });
        setFormSubmit(!formSubmit);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const NowDate = ServerDateToDay(serverDate);
    const isInToday = NowDate === (forDate ?? date);
    const sumOfInCompony = inOutLogs.reduce((a, b) => {
      if (b.exit) {
        return a + (b.exit - b.enter);
      } else {
        const inWorks =
          [...works]
            .filter((w) => w.inOutLogId === b.id)
            .sort((c, d) => c.start - d.start)
            .pop()?.end ?? b.enter;
        const maxExitValue = Math.max(inWorks, isInToday ? serverDate : 0);
        if (b.enter < maxExitValue) {
          return a + (maxExitValue - b.enter);
        }
        return a;
      }
    }, 0);

    function getOverlapDuration(time1: any, time2: any) {
      const overlapStart = Math.max(time1.from, time2.from);
      const overlapEnd = Math.min(time1.to, time2.to);
      if (overlapStart <= overlapEnd) {
        const overlapDuration = overlapEnd - overlapStart;
        return overlapDuration;
      }
      return 0;
    }

    let worksIdleTimes = 0;
    let missionsWorks: any = {};

    // Object.entries(missionTimeFrame).forEach(([frames, value]) => {
    //     value.forEach(frame => {
    //         missionsWorks[`mission_work_${frames}`] = 0
    //         missionsWorks[`mission_onWay_${frames}`] = 0
    //     })
    // })

    Object.entries(missionTimeFrame).forEach(([workType, period]) => {
      if (
        !Object.entries(period).length ||
        workType === "mandatoryUnemployment"
      )
        return (missionsWorks[`mission_${workType}`] = 0);
      Object.entries(period).forEach(([periodName, periodData]) => {
        missionsWorks[`mission_${workType}_${periodData?.faName}`] = 0;
      });
    });

    // missionsWorks.mission_sleep = 0
    // missionsWorks.mission_optionalUnemployment = 0
    // missionsWorks.mission_mandatoryUnemployment = 0

    let remoteWorks: any = {
      remote_sum: 0,
      remote_idleTime: 0,
      remote_useful: 0,
    };
    let inComponyWorks: any = {
      compony_sum: sumOfInCompony,
      compony_useful: sumOfInCompony - (dailyLog?.idleTime ?? 0),
      compony_idletime_in_Work: 0,
      compony_work: 0,
      compony_leave: 0,
    };
    works.forEach((work) => {
      if (work.type === "mission") {
        // setHasMission(true)
        // if (work.missionType === "work" || work.missionType === "onWay") {
        //     // Object.entries(missionTimeFrame).forEach(([frames, value]) => {
        //     //     value.forEach(frame => {
        //     //         const [from, to] = frame.split("-")
        //     //         const fromSpan = (forDate ?? date) + (+from*60*60)
        //     //         const toSpan = (forDate ?? date) + (+to*60*60)
        //     //         const overlap = getOverlapDuration({from: work.start, to: work.end}, {from: fromSpan, to: toSpan})
        //     //         missionsWorks[`mission_${work.missionType}_${frames}`] += overlap
        //     //     })
        //     // })
        // } else {
        //     const missionName = `mission_${work.missionType}`
        //     missionsWorks[missionName] += (work.end - work.start)
        // }
        if (
          !Object.entries(missionTimeFrame[work.missionType as "work"] ?? {})
            .length ||
          work.missionType === "mandatoryUnemployment"
        )
          return (missionsWorks[`mission_${work.missionType}`] +=
            work.end - work.start);
        Object.entries(
          missionTimeFrame[work.missionType as "work"] ?? {}
        )?.forEach(([periodName, periodData]) => {
          periodData?.frame.forEach((frame) => {
            const [from, to] = frame.split("-");
            const fromSpan = date + +from * 60 * 60;
            const toSpan = date + +to * 60 * 60;
            const overlap = getOverlapDuration(
              { from: work.start, to: work.end },
              { from: fromSpan, to: toSpan }
            );
            missionsWorks[
              `mission_${work.missionType}_${periodData?.faName}`
            ] += overlap;
          });
        });
      } else if (work.type === "remote") {
        // setHasRemote(true)
        const workDuration = work.end - work.start;
        const useFul = workDuration - (work?.idleTime ?? 0);
        remoteWorks["remote_sum"] += workDuration;
        remoteWorks["remote_idleTime"] += work?.idleTime ?? 0;
        remoteWorks["remote_useful"] += useFul;
        worksIdleTimes += work?.idleTime ?? 0;
      } else if (work.type === "work") {
        // setHasWork(true)
        const workDuration = work.end - work.start;
        const useFul = workDuration - (work?.idleTime ?? 0);
        inComponyWorks["compony_idletime_in_Work"] += work?.idleTime ?? 0;
        inComponyWorks["compony_work"] += useFul;
        inComponyWorks["compony_useful"] -= work?.idleTime ?? 0;
        worksIdleTimes += work?.idleTime ?? 0;
      } else if (work.type === "leave") {
        const workDuration = work.end - work.start;
        inComponyWorks["compony_leave"] += workDuration;
        inComponyWorks["compony_useful"] -= workDuration;
      }
    });
    setHasMission(!!works.find((w) => w.type === "mission"));
    setHasInCompony(!!inOutLogs.length);
    setHasRemote(!!works.find((w) => w.type === "remote"));
    setHasWork(!!works.find((w) => w.type === "work"));

    setMissionDurations(missionsWorks);
    setInComponyDurations(inComponyWorks);
    setRemoteDurations(remoteWorks);
    setSumOfIdleTimeInWorks(worksIdleTimes);
  }, [works, inOutLogs, dailyLog, date]);

  return (
    <>
      <div
        className={`h-10 flex-auto overflow-auto rounded-b-md bg-gray-200 dark:bg-gray-700 transition-[padding] duration-300`}
      >
        <div
          id={id}
          className="h-fit flex flex-col items-start justify-start gap-3 p-3"
        >
          {!!Number(Object.values(missionDurations).join("")) && (
            <Mission
              times={missionDurations}
              show={showAggregationBox}
              setShow={setShowAggregationBox}
            />
          )}
          {!!Number(Object.values(inComponyDurations).join("")) && (
            <InCompony
              times={inComponyDurations}
              show={showAggregationBox}
              setShow={setShowAggregationBox}
            />
          )}
          {!!Number(Object.values(remoteDurations).join("")) && (
            <Remote
              times={remoteDurations}
              show={showAggregationBox}
              setShow={setShowAggregationBox}
            />
          )}
        </div>
      </div>
    </>
  );
};

const PluseIcon = ({ className }: { className?: string }) => (
  <TextTag
    className={twMerge(
      "absolute left-1 bottom-1 text-emerald-600 dark:text-emerald-300 px-2 text-xl opacity-60",
      className
    )}
  >
    +
  </TextTag>
);
const MinusIcon = ({ className }: { className?: string }) => (
  <TextTag
    className={twMerge(
      "absolute left-1 bottom-1 !text-rose-600 px-2 py-1 text-3xl opacity-60",
      className
    )}
  >
    _
  </TextTag>
);
const Equal = ({ className }: { className?: string }) => (
  <TextTag
    className={twMerge(
      "absolute left-1 bottom-0 !text-gray-800 px-2 text-2xl opacity-60",
      className
    )}
  >
    =
  </TextTag>
);

const Mission = ({
  times,
  show,
  setShow,
}: {
  times: any;
  show: Array<string>;
  setShow: Dispatch<SetStateAction<Array<string>>>;
}) => {
  const serverDate = useRecoilValue(ServerDateState);
  const { theme } = useTheme();
  const aggregationBoxRef = useRef<HTMLDivElement | null>(null);
  const [height, setSsetHeight] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setSsetHeight(entry.target.clientHeight);
      }
    });

    if (aggregationBoxRef.current) {
      resizeObserver.observe(aggregationBoxRef.current);
    }

    return () => {
      if (aggregationBoxRef.current) {
        resizeObserver.unobserve(aggregationBoxRef.current);
      }
    };
  }, []);

  // console.log(show.includes("mission"));
  return (
    <div
      className="flex-none w-full bg-gray-300 dark:bg-gray-800 rounded-md ring-[2px] ring-sky-500 ring-opacity-30 overflow-hidden transition-[height] duration-300"
      style={{
        height: show.includes("mission") ? height + 40 : 40,
      }}
    >
      <div
        onClick={() => {
          const hasShow = show.includes("mission");
          if (!hasShow) {
            const cloneShow = [...show];
            const newShow = cloneShow.concat(["mission"]);
            setShow(newShow);
          } else {
            const deleteShow = show.filter((s) => s !== "mission");
            setShow(deleteShow);
          }
        }}
        className="h-10 flex items-center justify-between px-5 cursor-pointer"
      >
        <TextTag className="p-2 text-center">جمع بندی ساعات ماموریت</TextTag>
        <Icon
          name="chevron-down"
          width="20px"
          height="20px"
          color={theme === "dark" ? "white" : "black"}
          classNames={`${
            show.includes("mission") ? "rotate-180" : "rotate-0"
          } transition-all duration-300`}
        />
      </div>
      <div
        ref={aggregationBoxRef}
        className="h-fit flex flex-col xl:flex-row items-stretch justify-center p-2 gap-2 border-t border-sky-700 border-opacity-50"
      >
        <div className="h-fit flex-auto grid grid-cols-[repeat(20,_1fr)] gap-2">
          {Object.entries(times).map(
            ([keys, value]: any) =>
              !!value && (
                <div
                  key={keys}
                  className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-sky-500 !bg-opacity-20 col-span-10 sm:col-span-5 md:col-span-10 lg:col-span-5 xl:col-span-4"
                >
                  {keys.includes("mission") && (
                    <TextTag
                      as="h5"
                      className="text-gray-800 dark:text-gray-200 text-center text-[12px] xl:text-sm"
                    >
                      {`${faWorkType[`mission_${keys.split("_")?.[1]}`]} ${
                        keys.split("_")?.[2] ?? ""
                      }`}
                    </TextTag>
                  )}
                  <TextTag
                    as="h1"
                    className="text-gray-800 dark:text-gray-200 text-[18px] xl:text-2xl"
                  >
                    {durationsToTime(value)}
                  </TextTag>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

const InCompony = ({
  times,
  show,
  setShow,
}: {
  times: any;
  show: Array<string>;
  setShow: Dispatch<SetStateAction<Array<string>>>;
}) => {
  const serverDate = useRecoilValue(ServerDateState);
  const { theme } = useTheme();
  const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom);
  const aggregationBoxRef = useRef<HTMLDivElement | null>(null);
  const [height, setSsetHeight] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setSsetHeight(entry.target.clientHeight);
      }
    });

    if (aggregationBoxRef.current) {
      resizeObserver.observe(aggregationBoxRef.current);
    }

    return () => {
      if (aggregationBoxRef.current) {
        resizeObserver.unobserve(aggregationBoxRef.current);
      }
    };
  }, []);

  return (
    <div
      className="flex-none w-full bg-gray-300 dark:bg-gray-800 rounded-md ring-[2px] ring-emerald-500 ring-opacity-30 overflow-hidden transition-[height] duration-300"
      style={{
        height: show.includes("inCompony") ? height + 40 : 40,
      }}
    >
      <div
        onClick={() => {
          const hasShow = show.includes("inCompony");
          if (!hasShow) {
            const cloneShow = [...show];
            const newShow = cloneShow.concat(["inCompony"]);
            setShow(newShow);
          } else {
            const deleteShow = show.filter((s) => s !== "inCompony");
            setShow(deleteShow);
          }
        }}
        className="h-10 flex items-center justify-between px-5 cursor-pointer"
      >
        <TextTag className="p-2 text-center">
          جمع بندی ساعات حضور در شرکت
        </TextTag>
        <Icon
          name="chevron-down"
          width="20px"
          height="20px"
          color={theme === "dark" ? "white" : "black"}
          classNames={`${
            show.includes("inCompony") ? "rotate-180" : "rotate-0"
          } transition-all duration-300`}
        />
      </div>
      <div
        ref={aggregationBoxRef}
        className="h-fit border-t border-emerald-700 border-opacity-50"
      >
        <div className="flex flex-col xl:flex-row items-stretch justify-center p-2 gap-2 border-b border-dashed border-emerald-700 border-opacity-50">
          <div
            className="flex-auto grid grid-cols-[repeat(18,_1fr)] xl:grid-cols-[repeat(20,_1fr)] gap-2"
            dir="ltr"
          >
            <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-gray-900 dark:bg-gray-300 !bg-opacity-10 col-span-9 sm:col-span-6 xl:col-span-4">
              <PluseIcon className="text-gray-600 dark:text-gray-300" />
              <TextTag
                as="h5"
                className="text-gray-600 dark:text-gray-300 text-center text-[12px] xl:text-sm"
              >
                جمع کارشخصی
              </TextTag>
              <TextTag
                as="h1"
                className="text-gray-600 dark:text-gray-300 text-[18px] xl:text-2xl"
              >
                {durationsToTime(times.compony_leave)}
              </TextTag>
            </div>
            <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-gray-900 dark:bg-gray-300 !bg-opacity-10 col-span-9 sm:col-span-6 xl:col-span-4">
              <PluseIcon className="text-gray-600 dark:text-gray-300" />
              <TextTag
                as="h5"
                className="text-gray-600 dark:text-gray-300 text-center text-[12px] xl:text-sm"
              >
                جمع دراختیار رویداد کاری درشرکت
              </TextTag>
              <TextTag
                as="h1"
                className="text-gray-600 dark:text-gray-300 text-[18px] xl:text-2xl"
              >
                {durationsToTime(times.compony_idletime_in_Work)}
              </TextTag>
            </div>
            <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-gray-900 dark:bg-gray-300 !bg-opacity-10 col-span-9 sm:col-span-6 xl:col-span-4">
              <PluseIcon className="text-gray-600 dark:text-gray-300" />
              <TextTag
                as="h5"
                className="text-gray-600 dark:text-gray-300 text-center text-[12px] xl:text-sm"
              >
                دراختیار اضافه
              </TextTag>
              <TextTag
                as="h1"
                className="text-gray-600 dark:text-gray-300 text-[18px] xl:text-2xl"
              >
                {durationsToTime(dailyLog.idleTime)}
              </TextTag>
            </div>
            <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-rose-500 bg-opacity-10 col-span-9 sm:col-span-6 xl:col-span-4">
              <Equal className="!text-rose-600" />
              <TextTag
                as="h5"
                className="!text-rose-600 text-center text-[12px] xl:text-sm"
              >
                جمع زمان آزاد
              </TextTag>
              <TextTag
                as="h1"
                className="!text-rose-600 text-[18px] xl:text-2xl"
              >
                {durationsToTime(
                  times.compony_leave +
                    times.compony_idletime_in_Work +
                    dailyLog.idleTime
                )}
              </TextTag>
            </div>
            <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-gray-900 dark:bg-gray-300 !bg-opacity-10 col-span-9 sm:col-span-6 xl:col-span-4">
              <TextTag
                as="h5"
                className="text-gray-600 dark:text-gray-300 text-center text-[12px] xl:text-sm"
              >
                زمان خارج رویداد کاری
              </TextTag>
              <TextTag
                as="h1"
                className="text-gray-600 dark:text-gray-300 text-[18px] xl:text-2xl"
              >
                {durationsToTime(
                  times.compony_sum -
                    times.compony_work -
                    times.compony_leave -
                    times.compony_idletime_in_Work
                )}
              </TextTag>
            </div>
          </div>
        </div>
        <div className="flex flex-col xl:flex-row items-stretch justify-center p-2 gap-2">
          <div
            className="flex-auto grid grid-cols-[repeat(20,_1fr)] gap-2"
            dir="ltr"
          >
            <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-emerald-500 bg-opacity-10 col-span-10 sm:col-span-5">
              <PluseIcon />
              <TextTag
                as="h5"
                className="text-emerald-600 dark:text-emerald-300 text-center text-[12px] xl:text-sm"
              >
                مدت زمان حضور درشرکت
              </TextTag>
              <TextTag
                as="h1"
                className="text-emerald-600 dark:text-emerald-300 text-[18px] xl:text-2xl"
              >
                {durationsToTime(times.compony_sum)}
              </TextTag>
            </div>
            <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-rose-500 bg-opacity-10 col-span-10 sm:col-span-5">
              <MinusIcon />
              <TextTag
                as="h5"
                className="!text-rose-600 text-center text-[12px] xl:text-sm"
              >
                جمع زمان آزاد
              </TextTag>
              <TextTag
                as="h1"
                className="!text-rose-600 text-[18px] xl:text-2xl"
              >
                {durationsToTime(
                  times.compony_leave +
                    times.compony_idletime_in_Work +
                    dailyLog.idleTime
                )}
              </TextTag>
            </div>
            <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-emerald-500 col-span-10 sm:col-span-5">
              <Equal />
              <TextTag
                as="h5"
                className="!text-gray-800 text-center text-[12px] xl:text-sm"
              >
                مجموع ساعت کاری خالص برای حقوق
              </TextTag>
              <TextTag
                as="h1"
                className="!text-gray-800 text-[18px] xl:text-2xl"
              >
                {durationsToTime(times.compony_useful)}
              </TextTag>
            </div>
            <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-gray-900 dark:bg-gray-300 !bg-opacity-10 col-span-10 sm:col-span-5">
              {/* <TextTag as="h3" className="absolute right-3 bottom-0 !text-gray-800">=</TextTag> */}
              <TextTag
                as="h5"
                className="text-gray-800 dark:text-gray-400 text-center text-[12px] xl:text-sm"
              >
                جمع رویداد کاری درشرکت
              </TextTag>
              <TextTag
                as="h1"
                className="text-gray-800 dark:text-gray-400 text-[18px] xl:text-2xl"
              >
                {durationsToTime(times.compony_work)}
              </TextTag>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Remote = ({
  times,
  show,
  setShow,
}: {
  times: any;
  show: Array<string>;
  setShow: Dispatch<SetStateAction<Array<string>>>;
}) => {
  const serverDate = useRecoilValue(ServerDateState);
  const { theme } = useTheme();
  const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom);
  const aggregationBoxRef = useRef<HTMLDivElement | null>(null);
  const [height, setSsetHeight] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setSsetHeight(entry.target.clientHeight);
      }
    });

    if (aggregationBoxRef.current) {
      resizeObserver.observe(aggregationBoxRef.current);
    }

    return () => {
      if (aggregationBoxRef.current) {
        resizeObserver.unobserve(aggregationBoxRef.current);
      }
    };
  }, []);

  return (
    <div
      className="flex-none w-full bg-gray-300 dark:bg-gray-800 rounded-md ring-[2px] ring-purple-500 ring-opacity-30 overflow-hidden transition-[height] duration-300"
      style={{
        height: show.includes("remote") ? height + 40 : 40,
      }}
    >
      <div
        onClick={() => {
          const hasShow = show.includes("remote");
          if (!hasShow) {
            const cloneShow = [...show];
            const newShow = cloneShow.concat(["remote"]);
            setShow(newShow);
          } else {
            const deleteShow = show.filter((s) => s !== "remote");
            setShow(deleteShow);
          }
        }}
        className="h-10 flex items-center justify-between px-5 cursor-pointer"
      >
        <TextTag className="p-2 text-center">جمع بندی ساعات دورکاری</TextTag>
        <Icon
          name="chevron-down"
          width="20px"
          height="20px"
          color={theme === "dark" ? "white" : "black"}
          classNames={`${
            show.includes("remote") ? "rotate-180" : "rotate-0"
          } transition-all duration-300`}
        />
      </div>
      <div
        ref={aggregationBoxRef}
        className="h-fit flex flex-col xl:flex-row items-stretch justify-center p-2 gap-2 border-t border-purple-700 border-opacity-50"
      >
        <div
          className="flex-auto grid grid-cols-[repeat(18,_1fr)] gap-2"
          dir="ltr"
        >
          <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-emerald-500 bg-opacity-10 col-span-9 sm:col-span-6">
            <PluseIcon />
            <TextTag
              as="h5"
              className="text-emerald-600 dark:text-emerald-300 text-center text-[12px] xl:text-sm"
            >
              مدت زمان دورکاری
            </TextTag>
            <TextTag
              as="h1"
              className="text-emerald-600 dark:text-emerald-300 text-[18px] xl:text-2xl"
            >
              {durationsToTime(times.remote_sum)}
            </TextTag>
          </div>
          <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-rose-500 bg-opacity-10 col-span-9 sm:col-span-6">
            <MinusIcon />
            <TextTag
              as="h5"
              className="!text-rose-600 text-center text-[12px] xl:text-sm"
            >
              جمع دراختیار دورکاری
            </TextTag>
            <TextTag as="h1" className="!text-rose-600 text-[18px] xl:text-2xl">
              {durationsToTime(times.remote_idleTime)}
            </TextTag>
          </div>
          <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-purple-500 col-span-full sm:col-span-6">
            <Equal />
            <TextTag
              as="h5"
              className="!text-gray-800 text-center text-[12px] xl:text-sm"
            >
              مجموع دورکاری خالص برای حقوق
            </TextTag>
            <TextTag as="h1" className="!text-gray-800 text-[18px] xl:text-2xl">
              {durationsToTime(times.remote_useful)}
            </TextTag>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkTimeAggregations;

// const Mission = ({ times }: any) => {
//   const serverDate = useRecoilValue(ServerDateState);

//   return (
//     <div className="w-full bg-gray-300 dark:bg-gray-800 rounded-md ring-[2px] ring-sky-500 ring-opacity-30">
//       <TextTag className="p-2 text-center border-b border-sky-700 border-opacity-50">
//         تجمیع ساعات ماموریت
//       </TextTag>
//       <div className="flex flex-col xl:flex-row items-stretch justify-center p-2 gap-2">
//         <div className="flex-auto grid grid-cols-[repeat(20,_1fr)] gap-2">
//           {Object.entries(times).map(
//             ([keys, value]: any) =>
//               !!value && (
//                 <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-sky-500 !bg-opacity-20 col-span-10 sm:col-span-5 md:col-span-10 lg:col-span-5 xl:col-span-4">
//                   {keys.includes("mission") && (
//                     <TextTag
//                       as="h5"
//                       className="text-gray-800 dark:text-gray-200 text-center text-[12px] xl:text-sm"
//                     >
//                       {`${faWorkType[`mission_${keys.split("_")?.[1]}`]} ${
//                         keys.split("_")?.[2] ?? ""
//                       }`}
//                     </TextTag>
//                   )}
//                   <TextTag
//                     as="h1"
//                     className="text-gray-800 dark:text-gray-200 text-[18px] xl:text-2xl"
//                   >
//                     {DurationToTime(value, serverDate)}
//                   </TextTag>
//                 </div>
//               )
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// const InCompony = ({ times }: any) => {
//   const serverDate = useRecoilValue(ServerDateState);

//   return (
//     <div className="w-full bg-gray-300 dark:bg-gray-800 rounded-md ring-[2px] ring-emerald-500 ring-opacity-30">
//       <TextTag className="p-2 text-center border-b border-emerald-700 border-opacity-50">
//         جمع ساعات حضور در شرکت
//       </TextTag>
//       <div className="flex flex-col xl:flex-row items-stretch justify-center p-2 gap-2">
//         <div className="flex-auto grid grid-cols-[repeat(20,_1fr)] gap-2">
//           <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-emerald-500 col-span-full xl:col-span-4">
//             <Equal />
//             <TextTag
//               as="h5"
//               className="!text-gray-800 text-center text-[12px] xl:text-sm"
//             >
//               مجموع ساعت کاری خالص برای حقوق
//             </TextTag>
//             <TextTag as="h1" className="!text-gray-800 text-[18px] xl:text-2xl">
//               {DurationToTime(times.compony_useful, serverDate)}
//             </TextTag>
//           </div>

//           <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-emerald-500 bg-opacity-10 col-span-10 sm:col-span-5 xl:col-span-4">
//             <PluseIcon />
//             <TextTag
//               as="h5"
//               className="text-emerald-600 dark:text-emerald-300 text-center text-[12px] xl:text-sm"
//             >
//               مدت زمان حضور درشرکت
//             </TextTag>
//             <TextTag
//               as="h1"
//               className="text-emerald-600 dark:text-emerald-300 text-[18px] xl:text-2xl"
//             >
//               {DurationToTime(times.compony_sum, serverDate)}
//             </TextTag>
//           </div>
//           <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-rose-500 bg-opacity-10 col-span-10 sm:col-span-5 xl:col-span-4">
//             <MinusIcon />
//             <TextTag
//               as="h5"
//               className="!text-rose-600 text-center text-[12px] xl:text-sm"
//             >
//               جمع دراختیار درشرکت
//             </TextTag>
//             <TextTag as="h1" className="!text-rose-600 text-[18px] xl:text-2xl">
//               {DurationToTime(times.compony_idleTime, serverDate)}
//             </TextTag>
//           </div>
//           <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-rose-500 bg-opacity-10 col-span-10 sm:col-span-5 xl:col-span-4">
//             <MinusIcon />
//             <TextTag
//               as="h5"
//               className="!text-rose-600 text-center text-[12px] xl:text-sm"
//             >
//               جمع کار شخصی
//             </TextTag>
//             <TextTag as="h1" className="!text-rose-600 text-[18px] xl:text-2xl">
//               {DurationToTime(times.compony_leave, serverDate)}
//             </TextTag>
//           </div>
//           <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-gray-900 dark:bg-gray-300 !bg-opacity-10 col-span-10 sm:col-span-5 xl:col-span-4">
//             {/* <TextTag as="h3" className="absolute right-3 bottom-0 !text-gray-800">=</TextTag> */}
//             <TextTag
//               as="h5"
//               className="text-gray-800 dark:text-gray-400 text-center text-[12px] xl:text-sm"
//             >
//               جمع رویداد کاری درشرکت
//             </TextTag>
//             <TextTag
//               as="h1"
//               className="text-gray-800 dark:text-gray-400 text-[18px] xl:text-2xl"
//             >
//               {DurationToTime(times.compony_work, serverDate)}
//             </TextTag>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const Remote = ({ times }: any) => {
//   const serverDate = useRecoilValue(ServerDateState);
//   return (
//     <div className="w-full bg-gray-300 dark:bg-gray-800 rounded-md ring-[2px] ring-purple-500 ring-opacity-30">
//       <TextTag className="p-2 text-center border-b border-purple-700 border-opacity-50">
//         تجمیع ساعات دورکاری
//       </TextTag>
//       <div className="flex flex-col xl:flex-row items-stretch justify-center p-2 gap-2">
//         <div className="flex-auto grid grid-cols-[repeat(20,_1fr)] gap-2">
//           <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-purple-500 col-span-full xl:col-span-4">
//             <Equal />
//             <TextTag
//               as="h5"
//               className="!text-gray-800 text-center text-[12px] xl:text-sm"
//             >
//               دورکاری خالص برای حقوق
//             </TextTag>
//             <TextTag as="h1" className="!text-gray-800 text-[18px] xl:text-2xl">
//               {DurationToTime(times.remote_useful, serverDate)}
//             </TextTag>
//           </div>

//           <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-emerald-500 bg-opacity-10 col-span-10 sm:col-span-10 xl:col-span-8">
//             <PluseIcon />
//             <TextTag
//               as="h5"
//               className="text-emerald-600 dark:text-emerald-300 text-center text-[12px] xl:text-sm"
//             >
//               جمع دورکاری
//             </TextTag>
//             <TextTag
//               as="h1"
//               className="text-emerald-600 dark:text-emerald-300 text-[18px] xl:text-2xl"
//             >
//               {DurationToTime(times.remote_sum, serverDate)}
//             </TextTag>
//           </div>
//           <div className="flex flex-col items-center justify-center relative gap-4 p-2 rounded-[6px] bg-rose-500 bg-opacity-10 col-span-10 sm:col-span-10 xl:col-span-8">
//             <MinusIcon />
//             <TextTag
//               as="h5"
//               className="!text-rose-600 text-center text-[12px] xl:text-sm"
//             >
//               جمع دراختیار دورکاری
//             </TextTag>
//             <TextTag as="h1" className="!text-rose-600 text-[18px] xl:text-2xl">
//               {DurationToTime(times.remote_idleTime, serverDate)}
//             </TextTag>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// useEffect(() => {
// let mission: any = {
//     // mission_work1: 0,
//     // mission_work2: 0,
//     // mission_onWay1: 0,
//     // mission_onWay2: 0,
//     mission_sleep: 0,
//     mission_optionalUnemployment: 0,
//     mission_mandatoryUnemployment: 0,
// }
// Object.entries(missionTimeFrame).forEach(([frames, value]) => {
//     value.forEach(frame => {
//         mission[`mission_work_${frames}`] = 0
//         mission[`mission_onWay_${frames}`] = 0
//     })
// })
//     // console.log(mission)
//     let otherWorks: any = {
//         dayDuration: calculateDayDuration(),
//         idleTime: 0,
//         useful: 0
//     }
//     // const _0 = date;
//     // const _6 = date + (6*60*60);
//     // const _22 = date + (22*60*60);
//     // const _24 = date + (24*60*60);
//     // const _0_6 = (6*60*60);
//     // const _6_22 = (16*60*60);
//     // const _22_24 = (2*60*60);

//     function getOverlapDuration(time1: any, time2: any) {
//         const overlapStart = Math.max(time1.from, time2.from);
//         const overlapEnd = Math.min(time1.to, time2.to);
//         if (overlapStart <= overlapEnd) {
//             const overlapDuration = overlapEnd - overlapStart;
//             return overlapDuration;
//         }
//         return 0;
//     }

//     works.forEach(work => {
//         if (work.type === "temporary") return;
//         if (work.type === "mission") {
//             if (work.missionType === "work" || work.missionType === "onWay") {
//                 Object.entries(missionTimeFrame).forEach(([frames, value]) => {
//                     value.forEach(frame => {
//                         const [from, to] = frame.split("-")
//                         const fromSpan = date + (+from*60*60)
//                         const toSpan = date + (+to*60*60)
//                         const overlap = getOverlapDuration({from: work.start, to: work.end}, {from: fromSpan, to: toSpan})
//                         mission[`mission_${work.missionType}_${frames}`] += overlap
//                     })
//                 })
//             } else {
//                 const missionName = `mission_${work.missionType}`
//                 mission[missionName] += (work.end - work.start)
//             }
//         } else {
//             const missionDur = work.end - work.start - (work?.idleTime ?? 0)
//             otherWorks["idleTime"] += (work?.idleTime ?? 0)
//             if (!(work.type in otherWorks)) otherWorks[work.type] = missionDur
//             else otherWorks[work.type] = otherWorks[work.type] + missionDur
//         }
//     })
//     otherWorks.idleTime += (timeLog.idleTime ?? 0)
//     otherWorks.useful += calculateUsefulDuration(otherWorks.idleTime, otherWorks.leave)
//     setMissionDurations(mission)
//     setWorksDurations(otherWorks)

//     // let durations: any = {}
//     // works.forEach(work => {
//     //     const missionName = () => {
//     //         let name: string = work.type
//     //         if (work.type === "mission") name = `${work.type}_${work.missionType}`
//     //         return name;
//     //     }
//     //     if (!("idleTime" in durations)) durations["idleTime"] = (work?.idleTime ?? 0)
//     //     else durations["idleTime"] = durations["idleTime"] + (work?.idleTime ?? 0)
//     //     const workName = missionName()
//     //     if (!(workName in durations)) durations[workName] = (work.end - work.start - (work?.idleTime ?? 0))
//     //     else durations[workName] = durations[workName] + (work.end - work.start - (work?.idleTime ?? 0))
//     // })
//     // const workSort = [
//     //     "mission_work",
//     //     "mission_onWay",
//     //     "mission_optionalUnemployment",
//     //     "mission_mandatoryUnemployment",
//     //     "mission_sleep",
//     //     "work",
//     //     "remote",
//     //     "leave",
//     //     "idleTime",
//     // ]
//     // let sortDurations: any = {}
//     // workSort.forEach(w => sortDurations[w] = durations[w])
//     // setAllDurations(sortDurations)
// }, [works, timeLog])

// const calculateDayDuration = () => {
//     if (!timeLog.enter) return 0;
//     let exit = 0
//     if (timeLog.exit) {
//         exit = timeLog.exit
//     } else if (user.logicExit) {
//         exit = TimeToTimestamp(user.logicExit, date)
//     } else return 0;
//     return (exit - timeLog.enter)
// }

// const calculateUsefulDuration = (idleTime: number, leave: number) => {
//     if (!timeLog.enter) return 0;
//     let exit = date + (24*60*60) - 60
//     if (timeLog.exit) {
//         exit = timeLog.exit
//     } else if (user.logicExit) {
//         exit = TimeToTimestamp(user.logicExit, date)
//     }
//     return (exit - timeLog.enter - idleTime - (leave ?? 0))
// }

// // const calculateUsefulDuration = () => {
// //     if (!timeLog.enter) return "00:00";
// //     let exit = date + (24*60*60) - 60
// //     if (timeLog.exit) {
// //         exit = timeLog.exit
// //     } else if (user.logicExit) {
// //         exit = TimeToTimestamp(user.logicExit, date)
// //     }
// //     return DurationToTime(
// //         (exit - (timeLog.enter ?? 0)) // کل فعالیت
// //         - (allDurations.idleTime + (timeLog.idleTime ?? 0)) // در اختیار
// //         - (allDurations.leave ?? 0), // مزخصی
// //         date
// //     )
// // }

{
  /* <div className='w-full grid grid-cols-1 2xl:grid-cols-3 items-center justify-center gap-4 px-3 pt-3'>
                    <div 
                        className={`w-full flex flex-1 flex-full items-center justify-between  gap-2 rounded-md bg-gray-200 dark:bg-gray-800 ring-2 ring-gray-900 py-2 px-5`}
                    >
                        <TextTag as="h5" className=''>
                            طول مدت فعالیت
                        </TextTag>
                        <TextTag as="h3" className=''>
                            {calculateDayDuration()}
                        </TextTag>
                    </div>
                    
                    <div 
                        className={`w-full flex flex-1 flex-full items-center justify-between  gap-2 rounded-md bg-gray-200 dark:bg-gray-800 ring-2 ring-gray-900 py-2 px-5`}
                    >
                        <TextTag as="h5" className=''>
                            مجموع دراختیار
                        </TextTag>
                        <TextTag as="h3" className=''> 
                            {DurationToTime((allDurations.idleTime + (timeLog.idleTime ?? 0)), date)}
                        </TextTag>
                    </div>
                    
                    <div 
                        className={`w-full flex flex-1 flex-full items-center justify-between  gap-2 rounded-md bg-gray-200 dark:bg-gray-800 ring-2 ring-gray-900 py-2 px-5`}
                    >
                        <TextTag as="h5" className=''>
                            کار مفید
                        </TextTag>
                        <TextTag as="h3" className=''>
                            {calculateUsefulDuration()}
                        </TextTag>
                    </div>
                </div>

                <div className='w-full flex flex-wrap items-start justify-center gap-4 p-3'>
                    {Object.entries(allDurations).map(([keys, value]: any) => !!(value && keys !== "idleTime") &&
                        <div 
                            key={keys}
                            className={`w-32 h-24 flex flex-col items-center justify-between gap-2 rounded-md bg-gray-200 dark:bg-gray-800 ring-2 ring-${workTheme[keys.split("_")?.[0]]}-700 py-2 px-5`}
                        >
                            <div className='flex flex-col items-center justify-center'>
                                <TextTag as="h5" className='pe-1'>
                                    {faWorkType[keys.split("_")?.[0]]}
                                </TextTag>
                                {keys.includes("mission") &&
                                    <TextTag as="small" className=''>
                                        {`(${faMissionType[keys.split("_")?.[1]]})`}
                                    </TextTag>
                                }
                            </div>
                            <TextTag as="h3" className=''> 
                                {DurationToTime(value, serverDate)}
                            </TextTag>

                        </div>
                    )}
                </div> */
}
