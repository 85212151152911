import { UserValuesType } from "../Components/UserForm/Types";
import { get, put, post, deleted, patch } from "./Axios.config"
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const insertDailyLog = async (data: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await post({
        api: `inout_log`,
        data,
        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const updateInOutLog = async (id: number, data: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await patch({
        api: `inout_log/${id}`,
        data,
        config: {
            params: { With: ["InOut", "Works", 'InOut_Works'] },
            headers: {
                "Content-Type": "application/json",
                'Cache-Control': "no-store",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const deleteInOutLog = async (id: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await deleted({
        api: `inout_log/${id}`,
        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}


// export const finalizedUpdate = async (data: any) => {
//     const cookie = cookies.get("ACCESS_TOKEN")
//     const { date, shot } = data
//     console.log(shot.get("image"))
//     return await put({
//         api: `time_log/${date}`,
//         data: shot,
//         config: {
//             headers: {
//                 "Content-Type": "multipart/form-data",
//                 "Authorization": `Bearer ${cookie}`
//             } 
//         }
//     })
// }

// export const getTimeLog = async (date: number) => {
//     const cookie = cookies.get("ACCESS_TOKEN")
//     return await get({
//         api: `time_log/${date}`,
//         config: {
//             params: { With: ["Works"] },
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${cookie}`
//             } 
//         }
//     })
// }
