import { get, put, post, deleted, patch } from "./Axios.config"
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const getTimeAggregation = async (timeFrame: {from: number, to: number}) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `reports/times_aggregation`,
        config: {
            responseType: "blob",
            params: timeFrame,
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}