import { useTheme } from "next-themes";
import Works, { faMissionType, faWorkType } from "../../pages/Works/Works"
import ClockPicker from "../Common/ClockPicker/ClockPicker"
import { PickerChangeHandlerContext, TimeValidationError } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { distanceTimes, DurationToTime, ServerDateToDay, timestampToHours, TimestampToTime, timeToDuration, TimeToTimestamp } from "../../Utils/convertTime";
import { FiltersInput } from "../../pages/Users/Users.page";
import ReactSelect from "../Common/ReactSelect/ReactSelect";
import { useEffect, useRef, useState } from "react";
import Input from "../Common/Input/Input";
import { DateState, ServerDateState, UserDataState } from "../../Recoil/Atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import TextTag from "../Common/TextTag/TextTag";
import InputList from "../Common/InputList/InputList";
import TimePicker from "../Common/TimePicker/TimePicker";
import Button from "../Common/Button/Button";
import Icon from "../Common/Icon/Icon";
import { isMobile } from "react-device-detect";
import { LockWorkPageAtom, WorkDto } from "../../Recoil/Works.atom";
import { Toasts } from "../../toasts/toasts";
import { inputSubmitAtom } from "../../Recoil/dirtyAtom";
const oneHours = 1 * 60 * 60 * 1000

const workTheme = {
    work: "emerald",
    mission: "sky",
    remote: "purple",
    leave: "yellow",
    temporary: "rose",
}

const WorkForm = ({
    work,
    sizeChanged,
    onClose,
    active,
    setActive,
    onAccept,
    onDelete,
    justSee = false
}: {
    work: WorkDto,
    sizeChanged: (id: number) => void
    onClose?: (work: WorkDto) => void,
    active?: boolean
    setActive?: (id: number) => void;
    onAccept?: (item: any, cb?: Function) => void;
    onDelete?: (id: number) => void;
    justSee?: boolean
}) => {
    const serverDate = useRecoilValue(ServerDateState)
    const [userData, setUserData] = useRecoilState(UserDataState)
    const [lock, setLock] = useRecoilState(LockWorkPageAtom)
    const {theme, setTheme} = useTheme();
    const [enterClockPickerIsOpen, setEnterclockPickerIsOpen] = useState(false)
    const [exitClockPickerIsOpen, setExitclockPickerIsOpen] = useState(false)
    // این رو مجبور شدم اضافه کنم برای اینکه بفهمم فرم کی سابمیت میشه تا اینپوت های مربوط به شروع و پایان زمان رو آپدیت کنم. بعدا یه فکر بهتری براش میکنم
    const [formSubmit, setFormSubmit] = useRecoilState(inputSubmitAtom)
    const inputListRef = useRef<HTMLInputElement | null>(null)

    // const [hasDuration, setHasDuration] = useState({
    //     first: false,
    //     last: false,
    // })
    const [workDuration, setWorkDuration] = useState({
        first: 0,
        last: 0,
    })
    const [remainder, setRemainder] = useState({
        first: 0,
        last: 0,
    })
    const [values, setValues] = useState<any>({
        start: work.start,
        end: work.end,
        type: "work",
        project: "project_1",
        idleTime: work.idleTime ??  0,
        notes: [],
        missionType: "work",
        // inRoad6_22: 0,
        // inRoad22_6: 0,
        // work6_22: 0,
        // work22_6: 0,
        // sleep: 0,
        // optionalUnemployment: 0,
        // mandatoryUnemployment: 0,
    })
    const [isChanged, setIsChanged] = useState(false)
    const [date, setDate] = useRecoilState(DateState)

    // // کثیف کاری شدید
    // const [formSubmit, setFormSubmit] = useState(false)
    const WorkTypeOptions = [
        {value: "work", label: "درشرکت", isDisabled: !work.inOutLogId},
        {value: "mission", label: "ماموریت", isDisabled: (!userData.missionEligibility || work.inOutLogId)},
        {value: "remote", label: "دورکاری", isDisabled: (!userData.remoteEligibility || work.inOutLogId)},
        {value: "leave", label: "کار شخصی", isDisabled: !work.inOutLogId}
    ]
    const ProjectTypeOptions = [
        {value: "project_1", label: "پروژه 1"},
        {value: "project_2", label: "پروژه 2"},
        {value: "project_3", label: "پروژه 3"},
        {value: "project_4", label: "پروژه 4"}
    ]
    const MissionTypeOptions = [
        {value: "work", label: "کار"},
        {value: "onWay", label: "طی مسیر"},
        {value: "sleep", label: "خواب"},
        {value: "optionalUnemployment", label: "بیکاری اختیاری"},
        {value: "mandatoryUnemployment", label: "بیکاری اجباری"}
    ]

    type clockNameType = "start" | "end" | "inRoad6_22" | "inRoad22_6" | "work6_22" | "work22_6" | "sleep" | "optionalUnemployment" | "mandatoryUnemployment";
    const startHandler = (value: {hours: string, minutes: string}, name: "start" | "end", cb: Function) => {
        if (lock) return Toasts.lockWorks()
        // const idleTime = timeToDuration(time)
        // const hours = value?.get("hours") ?? 0
        // const minutes = value?.get("m") ?? 0
        // console.log(`${hours}:${minutes}`)
        const {hours, minutes} = value
        const timeSelected = date + (+hours*60*60) + (+minutes*60)
        // setValues({
        //     ...values,
        //     [name]: timeSelected
        // })
        // setIsChanged(true)
        if (onAccept) onAccept({id: work.id, [name]: timeSelected}, cb)
        // console.log(idleTime, name)
    }

    
    const startHandler_b = (time: string, name: string, cb: Function) => {
        if (lock) return Toasts.lockWorks()
        const idleTime = timeToDuration(time)
        // setValues({
        //     ...values,
        //     idleTime
        // })
        // setIsChanged(true)
        console.log(name);
        if (onAccept) onAccept({id: work.id, [name]: idleTime}, cb)
    }

    const workTypeSelect = (e: any, name: string) => {
        if (lock) return Toasts.lockWorks()
        // setValues({
        //     ...values,
        //     [name]: e.value
        // })
        // setIsChanged(true)
        const lastValue = {
            id: work.id, 
            [name]: e.value
        }
        if (name === "type") lastValue.missionType = "work"
        if (onAccept) onAccept(lastValue)
    }

    const workFormFields: FiltersInput = !active ? [] : [
        {
            type: ClockPicker,
            props: {
                name: "start",
                label: "زمان شروع",
                isOpen: enterClockPickerIsOpen,
                setIsOpen: setEnterclockPickerIsOpen,
                classNames: {
                    container: "h-[80px]"
                },
                isDisable: justSee,
                value: TimestampToTime(values.start),
                // formSubmit,
                onChange: (value: any) => {
                    const {hours, minutes, name} = value
                    const newStart = `${hours}:${minutes}`
                    const newStartTimestamp = TimeToTimestamp(newStart, date)
                    setValues({
                        ...values,
                        start: newStartTimestamp
                    })
                },
                // button: {
                //     title: TimestampToTime(values.start),
                //     color: theme as "dark",
                //     fill: "basic",
                //     classNames: {
                //         container: "!w-full !h-[38px] !rounded-[4px] transition-all duration-200 !border !border-gray-400 dark:!border-gray-950",
                //         section: "!text-[1rem]"
                //     }
                // },
                picker: {
                    title: "زمان شروع",
                    onAccept: startHandler,
                }
            }
        },
        {
            type: ClockPicker,
            props: {
                name: "end",
                label: "زمان پایان",
                isOpen: exitClockPickerIsOpen,
                setIsOpen: setExitclockPickerIsOpen,
                classNames: {
                    container: "h-[80px]"
                },
                isDisable: justSee,
                value: TimestampToTime(values.end),
                // formSubmit,
                onChange: (value: any) => {
                    const {hours, minutes, name} = value
                    const newStart = `${hours}:${minutes}`
                    const newStartTimestamp = TimeToTimestamp(newStart, date)
                    setValues({
                        ...values,
                        end: newStartTimestamp
                    })
                },
                // button: {
                //     title: TimestampToTime(values.end),
                //     color: theme as "dark",
                //     fill: "basic",
                //     classNames: {
                //         container: "!w-full !h-[38px] !rounded-[4px] transition-all duration-200 !border !border-gray-400 dark:!border-gray-950",
                //         section: "!text-[1rem]"
                //     }
                // },
                buttonMode: "handler",
                picker: {
                    title: "زمان پایان",
                    onAccept: startHandler,
                }
            }
        },
        {
            type: ReactSelect,
            // hidden: work.confirmed,
            props: {
                label: "نوع کار",
                name: "type",
                isSearchable: false,
                theme: theme,
                onChange: workTypeSelect,
                value: WorkTypeOptions.find(work => work.value === values.type),
                isDisabled: justSee,
                className: "h-[80px]",
                // act: act.role,
                // error: "رول را وارد کنید",
                options: WorkTypeOptions.filter(option => !option.isDisabled)
            }
        },
        {
            type: TimePicker,
            hidden: (values.type === "leave" || values.type === "mission"),
            props: {
                name: "idleTime",
                label: "دراختیار",
                value: TimestampToTime(ServerDateToDay(serverDate) + values.idleTime),
                disabled: justSee,
                // formSubmit,
                onChange: (value: any) => {
                    const {hours, minutes, name} = value
                    const newStart = `${hours}:${minutes}`
                    const newStartTimestamp = timeToDuration(newStart)
                    setValues({
                        ...values,
                        idleTime: newStartTimestamp
                    })
                },
                picker: {
                    onAccept: startHandler_b,
                }
            }
        },
        {
            type: ReactSelect,
            hidden: values.type !== "mission",
            props: {
                label: "نوع ماموریت",
                name: "missionType",
                isSearchable: false,
                theme: theme,
                isDisabled: justSee,
                onChange: workTypeSelect,
                value: MissionTypeOptions.find(work => work.value === values.missionType),
                // isDisabled: !editAccess,
                className: "h-[80px]",
                // act: act.role,
                // error: "رول را وارد کنید",
                options: MissionTypeOptions
            }
        },
        {
            type: ReactSelect,
            hidden: true, // values.type === "leave",
            props: {
                label: "پروژه",
                name: "project",
                isSearchable: false,
                theme: theme,
                isDisabled: justSee,
                onChange: workTypeSelect,
                value: ProjectTypeOptions.find(work => work.value === values.project),
                // isDisabled: !editAccess,
                className: "h-[80px]",
                // act: act.role,
                // error: "رول را وارد کنید",
                options: ProjectTypeOptions
            }
        },
    ]

    const workDurationCalculate = ({start, end}: {start: number, end: number}) => {
        let OneDuration = 0; // 6 => 22
        let towDuration = 0; // 22 => 6
        const _0 = 0
        const _6 = oneHours * 6
        const _22 = oneHours * 22
        const _24 = oneHours * 24

        if (start >= _6 && end <= _22) { // اگر فقط در دوره اول بود
            OneDuration = end - start
        } else if ((start >= _0 && end < _6) || (start > _22 && end <= _24)) { // اگر فقط در دوره دوم بود
            towDuration = end - start
        } else if (start < _6 && end > _22) { // اگر از دوره 2 تا دوره 2 امتداد داشت
            towDuration += ((_6 - start) + (end - _22))
            OneDuration += (oneHours * 16)
        } else if (start < _6 && end < _22) { // اگر از دوره 2 بور تا 1
            towDuration += (_6 - start)
            OneDuration += (end - _6)
        } else if (start > _6 && end > _22) { // اگر از دوره 1 بود  تا 2
            towDuration += (end - _22)
            OneDuration += (_22 - start)
        }
    
        return { OneDuration, towDuration }
    }

    const insertDefaultData = () => {
        if (!work) return;
        // let defaultType = "work"
        // if (!work.inOutLogId) defaultType = "remote"
        setValues({
            ...values,
            start: work.start,
            end: work.end,
            type: work.type,
            project: "project_1",
            idleTime: work.idleTime ??  0,
            notes: work.notes ? work.notes?.split("@@@") : [],
            missionType: work.missionType ?? "work"
        })

        const {OneDuration, towDuration} = workDurationCalculate({
            start: work.start - ServerDateToDay(serverDate), 
            end: work.end - ServerDateToDay(serverDate)
        })
        setWorkDuration({
            first: OneDuration,
            last: towDuration,
        })
        setRemainder({
            first: OneDuration,
            last: towDuration,
        })
        setIsChanged(false)
    }

    useEffect(() => {
        insertDefaultData()
    }, [work])
    
    useEffect(() => {
        sizeChanged(work.id)
        // if (values.type.value !== "mission") return;
        // let first = workDuration.first - (values.inRoad6_22 + values.work6_22) 
        // let last = workDuration.last -  (values.inRoad22_6 + values.work22_6) 
        // if (values.optionalUnemployment) {
        //     if (first - values.optionalUnemployment >= 0) {
        //         first -= values.optionalUnemployment
        //     } else {
        //         last -= Math.abs(first - values.optionalUnemployment)
        //         first = 0
        //     }
        // }
        // if (values.mandatoryUnemployment) {
        //     if (first - values.mandatoryUnemployment >= 0) {
        //         first -= values.mandatoryUnemployment
        //     } else {
        //         last -= Math.abs(first - values.mandatoryUnemployment)
        //         first = 0
        //     }
        // } 
        // if (values.sleep) {
        //     if (first - values.sleep >= 0) {
        //         first -= values.sleep
        //     } else {
        //         last -= Math.abs(first - values.sleep)
        //         first = 0
        //     }
        // } 
        // setRemainder({
        //     first,
        //     last,
        // })
    }, [values, isChanged])

    useEffect(() => {
        if (!active) return;



    const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              console.log(inputListRef.current);
              setTimeout(() => {
                  inputListRef.current?.focus()
              }, 500)
            } else {
            }
          });
        },
        { threshold: 0.1 }
      );
  
      if (inputListRef.current) {
        observer.observe(inputListRef.current);
      }
  
      // پاکسازی
      return () => {
        if (inputListRef.current) {
          observer.unobserve(inputListRef.current);
        }
      };
    }, [active])
    
    return (
        <div className={`overflow-auto md:overflow-visible h-full md:h-fit pb-10 md:pb-0`}>
            
            <div 
                className={`w-full h-24 ${active ? (work.confirmed ? `bg-${workTheme[work.type as "work"]}-500 text-gray-800` : `bg-rose-500 text-gray-800`) : "bg-gray-100 dark:bg-gray-800"} border-b ${work.confirmed ? `border-${workTheme[work.type as "work"]}-700` : `border-rose-700`} sticky top-0 z-[9999] md:z-[99] flex items-center justify-between rounded-t-[4px] transition-colors duration-300 shadow-xl`}
                onClick={() => {
                    if (!setActive) return;
                    if (active) return setActive(0)
                    return setActive(work.id)
                }}
            >
                <div className={`flex-auto md:flex-none w-32 h-full flex flex-row md:flex-col items-center justify-around py-1 px-2 md:border-e ${work.confirmed ? `border-${workTheme[work.type as "work"]}-700` : "border-rose-700"}`}>
                    <TextTag as='h5' className={`pe-2  ${active ? "text-gray-800 dark:text-gray-800" : "text-gray-500 dark:text-gray-400"}`}>{faWorkType[work.type]}</TextTag>
                    {(work.type === "mission" && work?.missionType) &&
                        <TextTag as='h5' className={`pe-2  ${active ? "text-gray-800 dark:text-gray-800" : "text-gray-500 dark:text-gray-400"}`}>{faMissionType[work?.missionType]}</TextTag>
                    }
                    <TextTag as='h5' className={`pe-2  ${active ? "text-gray-800 dark:text-gray-800" : "text-gray-500 dark:text-gray-400"}`}>{DurationToTime(work.end - work.start, serverDate)}</TextTag>
                </div>
                <TextTag
                    dir="ltr"
                    className={`hidden md:flex flex-col h-full flex-auto items-end justify-start overflow-auto p-2 leading-6 text-start gap-1 ${active ? "text-gray-800 dark:text-gray-800" : "text-gray-500 dark:text-gray-400"} text-sm`}
                >
                    {work.notes && work.notes.split("@@@").map((note, index) => <span className="border-b border-gray-900 border-opacity-10 text-[0.8rem]" key={index} style={{direction: "rtl", unicodeBidi: "embed"}}>{`${index+1}_ ${note}`}</span> )}
                </TextTag>
                <div className={`flex-none w-fit md:w-14 h-full flex md:flex-col-reverse items-center justify-around p-1 gap-1 border-s md:border-none  ${work.confirmed ? `border-${workTheme[work.type as "work"]}-700` : "border-rose-700"}`}>
                    {(active) && // x
                        <div className="w-12 h-full flex flex-col items-stretch gap-1">
                            {/* <div 
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    insertDefaultData()
                                    setIsChanged(false)
                                    if (onClose) onClose(work)
                                }}
                                className={`w-full flex-auto border grid md:hidden place-content-center rounded-[3px] border-gray-300 bg-gray-200 hover:bg-gray-300 dark:border-gray-900 dark:bg-gray-800 dark:hover:bg-gray-700 cursor-pointer transition-colors`}
                            >
                                <Icon
                                    name="x-lg"
                                    width="1.2rem"
                                    height="1.2rem"
                                    color="black"
                                    // classNames={`after:!bg-${workTheme[work.type as "work"]}-900`}
                                    classNames={`after:!bg-gray-900 dark:after:!bg-gray-100`}
                                />
                            </div> */}
                            <div 
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    if (lock) return Toasts.lockWorks()
                                    // const type = work.confirmed ? "create" : "update"
                                    // const destructureValues = {
                                    //     id: work.id,
                                    //     ...values,
                                    //     notes: values.notes.join(("@@@"))
                                    // }
                                    if (onAccept) {
                                        const newValues = {
                                            id: work.id, 
                                            start: values.start,
                                            end: values.end,
                                            idleTime: values.idleTime,
                                            confirmed: true,
                                        }
                                        if (!justSee) {
                                            onAccept(newValues, (newData: any) => {
                                                setFormSubmit(!formSubmit)
                                                setIsChanged(false)
                                                if (onClose) onClose(work)
                                            })
                                        } else {
                                            if (onClose) onClose(work)
                                        }
                                    } 
                                }}
                                className={`w-full flex-auto border grid place-content-center rounded-[3px] border-gray-300 bg-gray-200 hover:bg-gray-300 dark:border-gray-900 dark:bg-gray-800 dark:hover:bg-gray-700 cursor-pointer transition-colors`}
                            >
                                <Icon
                                    name={justSee ? "x-lg" : "check2"}
                                    width="1.3rem"
                                    height="1.3rem"
                                    color="black"
                                    // classNames={`after:!bg-${workTheme[work.type as "work"]}-900`}
                                    classNames={`after:!bg-gray-900 dark:after:!bg-gray-100`}
                                />
                            </div>
                            {!justSee && (
                                <div 
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        insertDefaultData()
                                        if (lock) return Toasts.lockWorks()
                                        if (onDelete) onDelete(work.id)
                                    }}
                                    className={`w-full flex-auto border grid place-content-center rounded-[3px] border-gray-300 bg-gray-200 hover:bg-gray-300 dark:border-gray-900 dark:bg-gray-800 dark:hover:bg-gray-700 cursor-pointer transition-colors`}
                                >
                                    <Icon
                                        name="trash3"
                                        width="1.2rem"
                                        height="1.2rem"
                                        color="black"
                                        // classNames={`after:!bg-${workTheme[work.type as "work"]}-900`}
                                        classNames={`after:!bg-gray-900 dark:after:!bg-gray-100`}
                                    />
                                </div>
                            )}
                        </div>
                    }
                    {/* {(active && !work.confirmed) && // check
                        <div className="w-12 h-full flex items-stretch">
                            <div 
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    if (lock) return Toasts.lockWorks()
                                    // const type = work.confirmed ? "create" : "update"
                                    // const destructureValues = {
                                    //     id: work.id,
                                    //     ...values,
                                    //     notes: values.notes.join(("@@@"))
                                    // }
                                    if (onAccept) {
                                        const newValues = {
                                            id: work.id, 
                                            start: values.start,
                                            end: values.end,
                                            idleTime: values.idleTime,
                                            confirmed: true,
                                        }
                                        onAccept(newValues, (newData: any) => {
                                            setFormSubmit(!formSubmit)
                                        })
                                    } 
                                }}
                                className={`w-full flex-auto border grid place-content-center rounded-[3px] border-gray-300 bg-gray-200 hover:bg-gray-300 dark:border-gray-900 dark:bg-gray-800 dark:hover:bg-gray-700 cursor-pointer transition-colors`}
                            >
                                <Icon
                                    name="check2"
                                    width="1.3rem"
                                    height="1.3rem"
                                    color="black"
                                    // classNames={`after:!bg-${workTheme[work.type as "work"]}-900`}
                                    classNames={`after:!bg-gray-900 dark:after:!bg-gray-100`}
                                />
                            </div>
                        </div>
                    } */}
                </div>
                {/* <Button
                    expand="block"
                    color={theme === "dark" ? "dark" : "light"}
                    icon="chevron-down"
                    iconWidth="1.2rem"
                    iconHeight="1.2rem"
                    // fill="transparent"
                    // iconColor={!active ? "white" : "black"}
                    iconRotate={!active ? "0" : "180"}
                    onClick={() => {
                        if (!setActive) return;
                        if (active) return setActive(0)
                            return setActive(work.id)
                        // editNote(note)
                        // inputRef.current?.focus()
                    }}
                    classNames={{
                        container: "!w-6 !h-6 !flex !items-center !justify-center !rounded-full m-auto px-2 duration-200 !flex-none absolute bottom-1 right-1",
                        section: "!text-lg"
                    }}
                /> */}
            </div>
            {!!active &&
                <div className={`w-full text-md grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-4 gap-3 px-2 py-4 ${work.confirmed ? `bg-${workTheme[work.type as "work"]}-500` : `bg-rose-500`} bg-opacity-5`}>
                    {workFormFields.map((ele, index) => !ele.hidden &&
                        <ele.type key={index} {...ele.props}/>
                    )}
                </div>
            }
            <div className={`w-full text-md grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-4 gap-3 p-2 ${work.confirmed ? `bg-${workTheme[work.type as "work"]}-500` : `bg-rose-500`} bg-opacity-5`}>
                {/* {workFormFields.map((ele, index) => !ele.hidden &&
                    <ele.type key={index} {...ele.props}/>
                )} */}
                <InputList 
                    label="گزارش خلاصه کار"
                    ref={inputListRef}
                    value={values.notes}
                    workId={work.id}
                    disabled={justSee}
                    onChange={(notes: any) => {
                        if (lock) return Toasts.lockWorks()
                        // console.log(notes)
                        // console.log(values)
                        // setValues({
                        //     ...values,
                        //     notes
                        // })
                        if (onAccept) onAccept({id: work.id, notes: notes.join("@@@")})
                        // setIsChanged(true)
                    }}
                    // changeHandler={(value: any) => console.log(value)}
                    // classNames={{
                    //     label: "font-thin text-sm font-thin mb-1"
                    // }}
                    // value={values}
                    // setValue={setValues}
                />
            </div>
        </div>
    )
}

export default WorkForm



// برای محاسیه اینکه کار مورد نظر مربوط به کدوم دوره ساعتی هستش 6 => 22 یا 22 => 6
// setHasDuration({
//     first: (
//         (timestampToHours(work.start) >= 6 && timestampToHours(work.start) <= 21) || 
//         (timestampToHours(work.end) >= 6 && timestampToHours(work.end) <= 21) ||
//         (work.end - work.start >= (oneHours*6)) 
//     ),
//     last: (   
//         (
//             (timestampToHours(work.start) >= 22 && timestampToHours(work.start) <= 23) || 
//             (timestampToHours(work.start) >= 0 && timestampToHours(work.start) <= 5)
//         ) || 
//         (
//             (timestampToHours(work.end) >= 22 && timestampToHours(work.end) <= 23) || 
//             (timestampToHours(work.end) >= 0 && timestampToHours(work.end) <= 5)
//         )
//     ) 
// })