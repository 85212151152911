import axios, { AxiosRequestConfig } from 'axios';
import { Toast } from '../Utils/Toast';
const apiUrl = process.env.REACT_APP_BASE_URL;
const defaultTimeout = 20000;
const apiInstance = axios.create();



apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.data?.error) Toast("error", error.response.data.error)
    // throw (error)
    return Promise.reject(error);
  }
);

const request = async (
    endpoint: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    data?: any,
    config?: AxiosRequestConfig
) => {
    return await apiInstance({
        method: method,
        url: `${apiUrl}${endpoint}`,
        data,
        timeout: defaultTimeout,
        ...config,
    });
};

export const get = async ({
    api,
    config,
}: {
    api: string;
    config?: AxiosRequestConfig;
}) => request(api, 'GET', null, config);

export const post = ({
    api,
    data,
    config,
}: {
    api: string;
    data:any
    config?: AxiosRequestConfig;
}) => request(api, 'POST', data , config);

export const deleted = ({
    api,
    data,
    config,
}: {
    api: string;
    data?:any;
    config?: AxiosRequestConfig;
}) => request(api, 'DELETE', data , config);

export const put = ({
    api,
    data,
    config,
}: {
    api: string;
    data: any;
    config?: AxiosRequestConfig;
}) => request(api, 'PUT', data, config);

export const patch = ({
    api,
    data,
    config,
}: {
    api: string;
    data: any;
    config?: AxiosRequestConfig;
}) => request(api, 'PATCH', data, config);
