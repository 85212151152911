
import React, { createContext, PropsWithChildren, useContext } from 'react';
import { IconsName } from '../Components/Common/IconT/Types';

const SvgIconContext = createContext<any>({});

export const SvgIconProvider = ({ children }: PropsWithChildren) => {

    // const reqSvgs = require.context("../assets/icons", true, /\.svg$/);
    // const svgIcons = reqSvgs.keys().reduce((filename: any, path: string) => {
    //     const iconName = path.replaceAll("./", "").replaceAll(".svg", "")
    //     const Icon = reqSvgs(path);
    //     filename[iconName] = { filename: iconName, Icon: Icon.default || Icon };
    //     return filename;
    // }, {});

    const reqSvgs = require.context("../assets/icons", true, /\.svg$/);

    const svgIcons = reqSvgs.keys().reduce((images: any, path: any) => {
      images[path.replace("./", "").replace(".svg", "")] = reqSvgs(path);
      return images;
    }, {});

    return (
        <SvgIconContext.Provider value={svgIcons}>
            {children}
        </SvgIconContext.Provider>
    );
};

export const useSvgIcons = (): IconsName => {
    return useContext(SvgIconContext);
};