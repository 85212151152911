import { useLocation } from "react-router-dom"
import { SetterOrUpdater, useRecoilState, useResetRecoilState } from "recoil"
import { ModalOpenAtom, ModalParamsAtom, ModalTypeAtom, ShowMenuState, UserDataState } from "../../Recoil/Atoms"
import { AllNotificationsStateAtom } from "../../Recoil/confirmsAtom"
import { useTheme } from "next-themes"
import { memo, useEffect, useRef, useState } from "react"
import Icon from "../Common/Icon/Icon"
import TextTag from "../Common/TextTag/TextTag"
import { Link } from "react-router-dom"

type MenuType = {
    title: string;
    href: string;
    icon: string;
    access?: boolean;
    sub?: {
        title: string;
        href: string;
        access?: boolean;
    }[]
}
const MenuComponent = () => {

    const [showMenu, setShowMenu] = useRecoilState(ShowMenuState)
    const [userData, setUserData] = useRecoilState(UserDataState)

    const menuList: MenuType[] = [
        {title: "داشبورد", href: '', icon: "grid-1x2-fill", access: true},
        {title: "پروفایل", href: 'profile', icon: "person-fill-gear", access: true},
        {title: "کار روزانه", href: 'worksReport', icon: "hammer", access: true},
        {title: "گزارش ها", href: 'search', icon: "clipboard-data-fill", sub: [
            {title: "تجمیع ساعات پرسنل", href: "finance", access: true},
            {title: "گزارش روزانه", href: "daily-report", access: true},
        ], access: true},
        {title: "کاربران", href: 'users', icon: "people-fill", access: true},
        // {title: "پروژه‌ها", href: 'projects', icon: "rocket-takeoff-fill"},
        {title: "اعلان ها", href: 'notifications', icon: "bell-fill", access: true},
    ]

    


    return (
        <div className="w-0 lg:w-60 lg:px-3 flex-none transition-all duration-200 z-[230] lg:z-[200]">
            {showMenu &&
                <div 
                    onClick={() => setShowMenu(!showMenu)}
                    className="w-screen h-dvh z-[999] fixed right-0 top-0 backdrop-blur-lg bg-gray-900 bg-opacity-20 lg:hidden block" 
                />
            }
            <div 
                className={`${showMenu ? "right-0" : "-right-full"} w-60 h-dvh lg:w-full lg:h-[calc(100vh-100px)] p-2 lg:p-0 fixed lg:sticky top-0 lg:top-20 transition-[right] duration-500 z-[999]`}
            >
                <div className="h-full flex flex-col items-stretch justify-start p-2 gap-3 bg-gray-50 dark:bg-gray-600 rounded-md">
                    <div className="flex-none flex py-4 gap-8 flex-col items-center justify-center border-b border-gra-200 dark:border-gray-700">
                        <Icon
                            name="person-fill"
                            width="80px"
                            height="80px"
                            color="#4b5563"
                            classNames="bg-gray-200 dark:bg-gray-700 rounded-full ring-8 ring-gray-100 dark:ring-gray-600 shadow-2xl"
                        />
                        <TextTag as="h4" className="w-full flex items-center justify-center text-gray-500 dark:text-gray-300 drop-shadow-xl">
                            {userData.username}
                        </TextTag>
                    </div>
                    <div className="flex-auto flex flex-col gap-2 overflow-auto">
                        {menuList.map(item => {
                            if (!item.access) return;
                            const hasSubMenu = item.sub?.length
                            const propsType = hasSubMenu ? "SubMenu" : "LinkMenu"
                            const SubMenuProps = {
                                item
                            };
                            const LinkMenuProps = {
                                item,
                                setShowMenu
                            }
                            const MenuComponent = hasSubMenu ? () => SubMenu(SubMenuProps) : () => LinkMenu(LinkMenuProps);
                            return (
                                <MenuComponent key={item.href} />
                            )
                        }
                        )}
                    </div>
                    <LogOutBtn />
                </div>
            </div>
            <ScrollToZero />
        </div>
    )
}

const LinkMenu = ({item, setShowMenu}: {item: MenuType, setShowMenu: SetterOrUpdater<any>}) => {
    
    const location = useLocation()
    const {theme, setTheme} = useTheme()
    const [allNotifications, setAllNotifications] = useRecoilState(AllNotificationsStateAtom)

    return (
        <Link 
            to={`/${item.href}`}
            preventScrollReset={false}
            unstable_viewTransition
            onClick={() => setShowMenu(false)}
            className={`border-1 rounded-md p-2 flex items-center justify-start cursor-pointer transition-all duration-200 relative
                ${location.pathname === `/${item.href}` ? 
                    "dark:bg-gray-800 bg-gray-300" : 
                    "dark:bg-gray-700 dark:hover:bg-gray-800 bg-gray-200 hover:bg-gray-300"
                }
                dark:border-gray-700 border-gray-300
            `}
        >
            {(item.href === "notifications" && !!allNotifications.length) &&
                <span className="absolute flex items-center justify-center left-3 top-1/2 -translate-y-1/2 h-4 w-4 z-10">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-500 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-rose-600"></span>
                </span>
            }
            <Icon
                name={item.icon as "x"}
                width="1.6rem"
                height="1.6rem"
                color={theme === "dark" ? "white" : "black"}
            />
            <TextTag as="h5" className="px-2">{item.title}</TextTag>
        </Link>
    )
}

const SubMenu = ({item}: {item: MenuType}) => {
    const location = useLocation()
    const {theme, setTheme} = useTheme()
    const [showSubs, setShowSubs] = useState(false)
    const [showMenu, setShowMenu] = useRecoilState(ShowMenuState)
    const subMenuRef = useRef<HTMLDivElement | null>(null)
    return (
        <div
            className={`border-1 rounded-md flex flex-col items-stretch justify-between relative overflow-hidden bg-gray-200 dark:bg-gray-700 border !bg-opacity-50 border-gray-200 dark:border-gray-700
                ${location.pathname === `/${item.href}` ? 
                    "dark:bg-gray-800 bg-gray-300" : 
                    ""
                }
            `}
        >
            <div onClick={() => setShowSubs(prev => !prev)} className="flex items-center justify-between p-2 dark:bg-gray-700 dark:hover:bg-gray-800 bg-gray-200 hover:bg-gray-300 select-none transition-colors duration-200 cursor-pointer">
                <div className="flex items-center justify-start">
                    <Icon
                        name={item.icon as "x"} 
                        width="1.6rem"
                        height="1.6rem"
                        color={theme === "dark" ? "white" : "black"}
                    />
                    <TextTag as="h5" className="px-2">{item.title}</TextTag>
                </div>
                <Icon
                    name="chevron-down"
                    width="15px"
                    height="15px"
                    color={theme === "dark" ? "white" : "black"}
                    classNames={`${showSubs ? "rotate-0" : "rotate-90"} transition-transform duration-300 translate-x-1`}
                />
            </div>
            <div className="w-full flex flex-col transition-[height] duration-300" style={{height: showSubs ? subMenuRef.current?.clientHeight : 0}}>
                <div ref={subMenuRef} className="w-full h-fit flex flex-col items-stretch justify-start gap-2 p-2">
                    {item.sub?.map(sub => sub.access &&
                        <Link key={sub.href} to={sub.href} 
                            preventScrollReset={false}
                            unstable_viewTransition
                            onClick={() => setShowMenu(false)}
                            className={`p-2 rounded-md relative transition-colors duration-200
                            ${location.pathname === `/${sub.href}` ? 
                                "dark:bg-gray-800 bg-gray-300" : 
                                "dark:bg-gray-700 dark:hover:bg-gray-800 bg-gray-200 hover:bg-gray-300"
                            }`}
                        >
                            <TextTag as="small">{sub.title}</TextTag>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    )
}

const LogOutBtn  = () => {
    const {theme, setTheme} = useTheme()
    const [modalType, setModalType] = useRecoilState(ModalTypeAtom)
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    return (
        <button
            onClick={() => {
                setIsModalOpen(true)
                setModalType("LogOut")
            }}
            className="!flex-none flex items-center justify-between w-full h-10 px-2 transition-all duration-200 rounded-md dark:bg-gray-700 dark:hover:bg-gray-800 bg-gray-200 hover:bg-gray-300"
        >
            <Icon
                name="box-arrow-left"
                width="28px"
                height="28px"
                color={theme === "dark" ? "white" : "black"}
                classNames="flex-none w-fit rotate-180"
            />
            <TextTag as="h5" className="block flex-auto">خروج از حساب کاربری</TextTag>
        </button>
    )
}

const ScrollToZero = () => {

    const location = useLocation()
    const [scroll, setScroll] = useState(0)
    const recordScrollTop = () => {
        setScroll(document.documentElement.scrollTop)
    }

    useEffect(() => {
        window.scroll({top: 0, behavior: "smooth"});
        window.addEventListener("scroll", recordScrollTop )
        return () => {
            window.removeEventListener("scroll", recordScrollTop)
        }
    }, [location.pathname])

    return (
        <>
        {!!scroll && 
            <div 
                onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}
                className="rounded-full w-10 aspect-square fixed left-10 bottom-10 bg-gray-900 dark:bg-gray-500 !bg-opacity-40 backdrop-blur-md hover:bg-gray-500 shadow-md shadow-gray-500 dark:shadow-gray-800 grid place-content-center cursor-pointer"
            >
                <Icon
                    name="chevron-double-up"
                    color="white"
                    width="28px"
                    height="28px"
                    classNames="opacity-70 group-hover:opacity-100 transition-all duration-200"
                />
            </div>
        }
        </>
    )
}
export default memo(MenuComponent);