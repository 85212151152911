import { useRecoilState, useRecoilValue } from "recoil"
import Button from "../../Button/Button"
import TextTag from "../../TextTag/TextTag"
import WorkTimeAggregations from "../../WorkTimeAggregations/WorkTimeAggregations"
import { DateState, ModalOpenAtom, ModalParamsAtom, ServerDateState, UserDataState } from "../../../../Recoil/Atoms"
import { useTheme } from "next-themes"
import Icon from "../../Icon/Icon"
import { deleteWork } from "../../../../axios/Work.api"
import { DailyLogAtom, PinsStateAtom, TimeFrameAtom, TimeSpanDurationAtom, TransmissionTimeAtom, WorksInDayAtom } from "../../../../Recoil/Works.atom"
import { findExitTime } from "../../../../Utils/FindExiteTime"
import { timestampToHours } from "../../../../Utils/convertTime"
import useGenerateTimeFrame from "../../../../hooks/useGenerateTimeFrame"
import { InOutLogAtom } from "../../../../Recoil/InOutLogAtom"
import useNotificationsUpdate from "../../../../hooks/useNotificationsUpdate"

const DeleteWork = () => {
    const updateNotification = useNotificationsUpdate()
    const generateTimeFrame = useGenerateTimeFrame()
    const serverDate = useRecoilValue(ServerDateState)
    const [inOutLogs, setInOutLogs] = useRecoilState(InOutLogAtom)
    const [user, setUser] = useRecoilState(UserDataState)
    const [date, setDate] = useRecoilState(DateState)
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    const [modalParams, setModalParams] = useRecoilState(ModalParamsAtom);
    const [works, setWorks] = useRecoilState(WorksInDayAtom)
    const [timeLog, setTimeLog] = useRecoilState(TransmissionTimeAtom)
    const [timeFrame, setTimeFrame] = useRecoilState(TimeFrameAtom)
    const [timeSpanDuration, setTimeSpanDuration] = useRecoilState(TimeSpanDurationAtom)
    const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom)
    const [pins, setPins] = useRecoilState(PinsStateAtom)
    const {theme} = useTheme()

    const deleteHandler = () => {
        deleteWork(modalParams)
            .then(res => {
                const newWorks = [...works].filter(w => w.id !== modalParams)
                const deletedWork = works.find(w => w.id === modalParams)
                setWorks(newWorks)
                setIsModalOpen(false)
                const notPrefix = "workNote_"
                localStorage.removeItem(`${notPrefix}${modalParams}`)
                let notifType;
                if (deletedWork?.type === "mission" || deletedWork?.type === "remote" || deletedWork?.type === "work") notifType = deletedWork?.type
                updateNotification({dailyLogId: dailyLog.id, type: notifType})
                generateTimeFrame(inOutLogs, newWorks, date, pins)
                setDailyLog({
                    ...dailyLog,
                    finalized: 0
                })


                
                // const exitTime = findExitTime({
                //     exit: timeLog.exit,
                //     works: newWorks,
                //     serverDate,
                //     date,
                //     logicExit: user.logicExit
                // })
                // const loopFrom = timestampToHours(timeLog.enter as number)
                // const loopTo = +exitTime < 24 ? +exitTime+1 : +exitTime
                // const time_frame: string[] = []
                // for (let i=loopFrom; i<=loopTo; i++) {
                //     time_frame.push(`${String(i).padStart(2, "0")}:00`)
                // }
                // setTimeFrame(time_frame)
                // const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
                // setTimeSpanDuration(spanDuration)
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div className="flex flex-col bg-gray-100 dark:bg-gray-700 rounded-md">
            <div className="flex-none flex items-center justify-center p-2">
                <Icon
                    name="exclamation-circle"
                    width="5rem"
                    height="5rem"
                    color="#f43f5e"
                />
            </div>
            <TextTag as="h4" className="py-6 px-16">
                واقعا میخوای حذفش کنی?!
            </TextTag>
            <div className=" flex items-center justify-center gap-3 p-3">
                <Button
                    title="آره"
                    color={theme === "dark" ? "dark" : "light"}
                    onClick={deleteHandler}
                    classNames={{
                        container: "!w-28 !h-10 !rounded-[3px]",
                        section: "!text-sm"
                    }}
                />
                <Button
                    title="نه"
                    color={theme === "dark" ? "dark" : "light"}
                    onClick={() => setIsModalOpen(false)}
                    classNames={{
                        container: "!w-28 !h-10 !rounded-[3px]",
                        section: "!text-sm"
                    }}
                />

            </div>
        </div>
    )
}

export default DeleteWork