import {
  ChangeEvent,
  Fragment,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import DatePicker from "../../../Components/Common/DatePicker/DatePicker";
import moment from "moment-jalaali";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  GlobalLoadingAtom,
  ServerDateState,
  UserDataState,
} from "../../../Recoil/Atoms";
import {
  Calendar,
  DayRange,
  DayValue,
} from "@hassanmojab/react-modern-calendar-datepicker";
import {
  getDailyReportsPageService,
  getDailyReportsWithAggregation,
  getEmploys,
} from "../../../axios/reports/DailyRepor";
import TextTag from "../../../Components/Common/TextTag/TextTag";
import Dialog from "../../../Components/Common/Dialog/Dialog";
import Button from "../../../Components/Common/Button/Button";
import { useTheme } from "next-themes";
import Loading from "../../../Components/Common/Loading/Loading";
import Icon from "../../../Components/Common/Icon/Icon";
import WorksComponent from "../../../Components/Works/Works";
import Switch from "../../../Components/Common/Switch/Switch";
import { ServerDateToDay } from "../../../Utils/convertTime";
import ReactPaginate from "react-paginate";
import DashboardAggregation from "./Aggregations";

const DailyReport = () => {
  const serverDate = useRecoilValue(ServerDateState);
  const defaultDate = () => {
    const fromM = moment();
    const fromYear = fromM.jYear();
    const fromMonth = fromM.jMonth() + 1;
    const fromDay = 1;

    const toM = moment();
    const toYear = toM.jYear();
    const toMonth = toM.jMonth() + 1;
    const toDay = toM.jDate();

    return {
      from: {
        year: fromYear,
        month: fromMonth,
        day: fromDay,
      },
      to: {
        year: toYear,
        month: toMonth,
        day: toDay,
      },
    };
  };
  const [userData, setUserData] = useRecoilState(UserDataState);
  const [users, setUsers] = useState([]);
  const [selectedRange, setSelectedRange] = useState<DayRange>(defaultDate());
  const [usersSelected, setUserSelected] = useState<Array<number>>([userData]);
  const [onlyFinalized, setOnlyFinalized] = useState(true);
  const [dailyLogs, setDailyLogs] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [aggregation, setAggregation] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [globalLoading, setGlobalLoading] = useRecoilState(GlobalLoadingAtom);
  const limit = useRef(50);

  const handleRangeChange = (newRange: any) => {
    setSelectedRange(newRange);
  };

  const selectUserHandler = (usersId: number[]) => {
    setUserSelected(usersId);
  };

  const searchHandler = (date: { from: DayValue; to: DayValue }) => {
    setGlobalLoading(true);
    const { from, to } = date;
    if (!from || !to) return;

    const fromDate = `${from?.year}/${from.month}/${from.day}`;
    const toDate = `${to?.year}/${to.month}/${to.day}`;
    const fromSpan = moment(fromDate, "jYYYY/jMM/jDD").valueOf();
    const toSpan = moment(toDate, "jYYYY/jMM/jDD").valueOf();
    const usersId = usersSelected.map((u: any) => u.id);
    getDailyReportsWithAggregation({
      from: fromSpan / 1000,
      to: toSpan / 1000,
      usersId,
      onlyFinalized,
      limit: limit.current,
      page: 0,
    })
      .then((res) => {
        setDailyLogs(res.data.logs);
        setAggregation(res.data.aggregation.times);
        setCount(res.data.count);
        setGlobalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setGlobalLoading(false);
      });
  };

  // console.log(aggregation);
  const switchChangeHandler = () => {
    setOnlyFinalized((prev) => !prev);
  };

  const handlePageClick = ({ selected }: { selected: number }) => {
    setGlobalLoading(true);
    const { from, to } = selectedRange;
    if (!from || !to) return;

    const fromDate = `${from?.year}/${from.month}/${from.day}`;
    const toDate = `${to?.year}/${to.month}/${to.day}`;
    const fromSpan = moment(fromDate, "jYYYY/jMM/jDD").valueOf();
    const toSpan = moment(toDate, "jYYYY/jMM/jDD").valueOf();
    const page = selected;
    const usersId = usersSelected.map((u: any) => u.id);
    getDailyReportsPageService({
      from: fromSpan / 1000,
      to: toSpan / 1000,
      usersId,
      onlyFinalized,
      limit: limit.current,
      page,
    })
      .then((res) => {
        setDailyLogs(res.data);
        setGlobalLoading(false);
        const headerElement = document.getElementById("reportHeaderElement")
        headerElement?.scrollIntoView({behavior: "smooth"})
        // window.scrollTo({top: 0, behavior: "smooth"})
      })
      .catch((err) => {
        console.log(err);
        setGlobalLoading(false);
      });
  };

  useEffect(() => {
    getEmploys()
      .then((res) => {
        setUsers(res.data.rows.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
    searchHandler(defaultDate());
  }, []);

  return (
    <div className="px-2 pb-16 flex flex-col items-stretch justify-start bg-gray-100 dark:bg-gray-600 rounded-none lg:rounded-md">
      <div className="w-full py-2 flex items-center justify-between border-b border-zinc-300 dark:border-zinc-700">
        <h3 className="text-xl text-gray-500 dark:text-gray-300">
          گزارش روزانه
        </h3>
      </div>
      <div className="">
        <div className="grid grid-cols-4 xl:grid-cols-8 p-2 gap-5 items-end">
          {!!users.length ? (
            <UsersSelect
              users={users}
              onChange={selectUserHandler}
              value={usersSelected}
            />
          ) : (
            <div className="w-full h-16 col-span-2 xl:col-span-3 bg-gray-900 bg-opacity-30 animate-pulse rounded-lg" />
          )}
          <DatePicker
            label="بازه زمانی"
            value={selectedRange}
            onChange={handleRangeChange}
            shouldHighlightWeekends
            maximumDate={{
              year: Number(moment(serverDate * 1000).format("jYYYY")),
              month: Number(moment(serverDate * 1000).format("jMM")),
              day: Number(moment(serverDate * 1000).format("jDD")),
            }}
            locale="fa" // add this
            containerClassName="col-span-2"
            calendarClassName="!text-gray-800 !shadow-2xl !shadow-[rgba(0,0,0,0.4)]"
            calendarTodayClassName="!border-2 !border-gray-500"
            inputClassName={`!w-full !h-10 !rounded-md duration-200 outline-none !text-lg w-36 cursor-pointer !bg-gray-200 !text-gray-700 !text-sm !border-gray-300 hover:!bg-gray-300 hover:!text-gray-800 dark:!bg-gray-800 dark:!text-gray-200 dark:!border-gray-900 dark:hover:!bg-gray-900 !p-0`}
            calendarSelectedDayClassName="!bg-gray-700 !text-gray-100 dark:!bg-gray-200 dark:!text-gray-700"
            wrapperClassName=""
          />
          <Switch
            label="فقط روز های بسته شده"
            name={"remoteEligibility"}
            onClick={switchChangeHandler}
            value={onlyFinalized}
            classNames={{
              container: "h-full px-2 md:m-auto col-span-full sm:col-span-2 items-center xl:items-end xl:pb-2",
            }}
          />
          <Button
            title="جستوجو"
            color="primary"
            loading={globalLoading}
            onClick={() => searchHandler(selectedRange)}
            classNames={{
              container: "flex-none !w-full !h-10 !rounded-md col-span-full sm:col-span-1 px-2",
              section: "!text-sm",
            }}
          />
        </div>
      </div>

      <DashboardAggregation aggregation={aggregation} />
      <DailyLogList dailyLogs={dailyLogs} />
      <ReactPaginate
        previousLabel={"»"}
        nextLabel={"«"}
        breakLabel={"..."}
        pageCount={Math.ceil(count / limit.current)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={1}
        onPageChange={handlePageClick}
        className="flex flex-row-reverse items-center justify-center gap-2 py-10 select-none"
        // containerClassName={"flex flex-row-reverse items-center justify-center gap-2 bg-red-400 p-2"}

        pageClassName="" // all pages
        pageLinkClassName="w-7 h-7 md:w-10 md:h-10 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-800 rounded-full flex items-center justify-center text-gray-700 dark:text-gray-300"
        activeClassName={""}
        activeLinkClassName="w-7 h-7 md:w-10 md:h-10 !bg-sky-500 rounded-full flex items-center justify-center !text-gray-900"
        breakClassName="" // ...
        breakLinkClassName="w-7 h-7 md:w-10 md:h-10 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-800 rounded-full flex items-center justify-center text-gray-700 dark:text-gray-300" // ...
        nextClassName=""
        nextLinkClassName="w-7 h-7 md:w-10 md:h-10 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-800 rounded-full flex items-center justify-center text-gray-700 dark:text-gray-300 text-3xl"
        previousClassName=""
        previousLinkClassName="w-7 h-7 md:w-10 md:h-10 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-800 rounded-full flex items-center justify-center text-gray-700 dark:text-gray-300 text-3xl"
        disabledClassName=""
        disabledLinkClassName=""
      />
    </div>
  );
};

const DailyLogList = ({ dailyLogs }: { dailyLogs: any[] }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="flex flex-col items-stretch justify-start mt-10">
      <div className="w-full">
        <div className="w-full h-10 sticky top-20 bg-gray-300 dark:bg-gray-800 bg-opacity-70 backdrop-blur-lg items-center justify-center sm:px-2 py-2 text-sm grid grid-cols-[repeat(18,_1fr)]  md:grid-cols-[repeat(20,_1fr)] shadow-md shadow-[#00000026] rounded-[4px] z-10">
          <TextTag className="col-span-6 md:col-span-5 grid place-content-center border-l border-zinc-400 dark:border-zinc-700 whitespace-nowrap">
            نام
          </TextTag>
          <TextTag className="col-span-6 md:col-span-5 grid place-content-center border-l border-zinc-400 dark:border-zinc-700">
            تاریخ
          </TextTag>
          <TextTag className="col-span-6 md:col-span-5 grid place-content-center md:border-l border-zinc-400 dark:border-zinc-700">
            بسته شده
          </TextTag>
          <TextTag className="col-span-1 md:col-span-5 hidden md:grid place-content-center">
            تعداد اصلاحیه
          </TextTag>
        </div>
        {!loading ? (
          dailyLogs.length ? (
            <div className="flex flex-col items-stretch justify-start gap-2 py-4">
              {dailyLogs.map((log: any, index) => (
                <DailyLog key={log.id} log={log} index={index} />
              ))}
            </div>
          ) : (
            <TextTag as="h3" className="w-full text-center py-32 text-gray-400">
              اطلاعات موجود نیست
            </TextTag>
          )
        ) : (
          <Loading className="w-full h-52 grid place-content-center opacity-20" />
        )}
      </div>
    </div>
  );
};

const DailyLog = ({ log, index }: any) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { theme } = useTheme();
  return (
    <div
      key={log.id}
      className={`h-14 bg-gray-200 rounded-md dark:bg-gray-700 items-center justify-start sm:px-2 py-2 text-sm grid grid-cols-[repeat(18,_1fr)]  md:grid-cols-[repeat(20,_1fr)]`}
    >
      <TextTag className="col-span-6 md:col-span-5 grid place-content-center border-l border-zinc-300 dark:border-zinc-800 whitespace-nowrap overflow-hidden">
        {log.User.username}
      </TextTag>
      <TextTag
        onClick={() => setModalOpen(true)}
        className="col-span-6 md:col-span-5 grid place-content-center border-l border-zinc-300 dark:border-zinc-800 text-gray-600 dark:text-gray-300 hover:!text-sky-500 cursor-pointer transition-colors"
      >
        {moment(log?.date * 1000).format("jYYYY/jM/jD")}
      </TextTag>
      <span className="col-span-6 md:col-span-5 text-center grid place-content-center md:border-l border-zinc-300 dark:border-zinc-800">
        <span
          className={`${
            !!log?.finalized ? "text-emerald-500" : "text-rose-500"
          }`}
        >
          {!!log?.finalized
            ? moment(log?.finalized * 1000)
                .format("jYYYY/jM/jD - HH:mm")
                .split(" - ")
                .reverse()
                .join(" - ")
            : "بسته نشده"}
        </span>
      </span>
      <TextTag className="col-span-6 md:col-span-5 hidden md:grid place-content-center">
        {log.finalizedCount}
      </TextTag>

      {modalOpen && (
        <Dialog open={modalOpen} setOpen={setModalOpen}>
          <div className="flex flex-col items-stretch w-[calc(100vw-20px)] lg:w-[1000px] xl:w-[1200px] h-[800px] bg-gray-200 dark:bg-gray-700 p-2 rounded-lg">
            <div className="flex-none flex items-center justify-between px-2 pb-2 pt-1">
              <TextTag>{log.User.username}</TextTag>
              <div className="flex items-center justify-center gap-2">
                <Button
                  icon="x-lg"
                  color={theme === "dark" ? "dark" : "light"}
                  iconWidth="1.3rem"
                  iconHeight="1.3rem"
                  iconColor={theme === "dark" ? "lightgray" : "gray"}
                  iconHover={theme === "dark" ? "white" : "black"}
                  onClick={() => setModalOpen(false)}
                  classNames={{
                    container:
                      "!w-8 !h-8 !rounded-md !flex !items-center !justify-center !overflow-hidden ring-1 ring-gray-400 dark:ring-gray-700",
                  }}
                />
              </div>
            </div>
            <div className="flex-auto">
              <WorksComponent userId={log.User.id} forDate={log.date} justSee />
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

const UsersSelect = ({ users, onChange, value }: any) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [filterUser, setFilterUser] = useState(users);
  const [selectedUser, setSelectedUser] = useState<Array<number>>([]);
  const { theme } = useTheme();
  // console.log(value);

  // console.log(users)
  const searchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const newUsers = users.filter((user: any) => user.username.includes(value));
    setFilterUser(newUsers);
  };

  const selectHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (!name || isNaN(Number(name))) return;
    const findUser = users.find((user: any) => user.id === Number(name));
    if (!findUser) return;
    const cloneSelectedUser = [...selectedUser];
    if (checked) {
      const existUserId = !!cloneSelectedUser.find((i) => i === Number(name));
      if (!existUserId) cloneSelectedUser.push(findUser);
      setSelectedUser(cloneSelectedUser);
    } else {
      const newUsersId = cloneSelectedUser.filter(
        (i: any) => i.id !== findUser.id
      );
      setSelectedUser(newUsersId);
    }
  };

  useEffect(() => {
    setSelectedUser(value);
    setFilterUser(users);
  }, [modalOpen]);

  return (
    <div className="w-full col-span-2 xl:col-span-3">
      <TextTag className="block pb-1 px-2">انتخاب کاربران</TextTag>
      <div
        onClick={() => setModalOpen(true)}
        className=" h-10 bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-900 p-2 text-gray-600 dark:text-gray-300 border border-gray-300 dark:border-gray-900 whitespace-nowrap overflow-hidden text-ellipsis rounded-md transition-colors duration-300 cursor-pointer"
      >
        {!!selectedUser.length
          ? selectedUser.map((s: any) => (
              <Fragment key={s.id}>{s.username} - </Fragment>
            ))
          : "همه"}
      </div>
      <Dialog open={modalOpen} setOpen={setModalOpen}>
        <div className="w-[calc(100dvw-20px)] max-w-[1000px] h-[600px] flex flex-col items-stretch justify-start bg-gray-100 dark:bg-gray-700 rounded-lg m-auto">
          <div className="flex-none flex items-center justify-between p-2 border-b border-gray-200 dark:border-gray-600">
            <TextTag className="text-start">انتخاب کاربران</TextTag>
            <Button
              icon="x"
              iconWidth="100%"
              iconHeight="100%"
              color="gray"
              fill="transparent"
              onClick={() => setModalOpen(false)}
              classNames={{
                container: "!w-8 !h-8",
              }}
            />
          </div>
          <div className="flex-none h-14 flex items-stretch justify-center p-2 gap-2">
            <input
              type="text"
              placeholder="جستوجو..."
              defaultValue=""
              onChange={searchHandler}
              className="flex-auto w-1 rounded-[10px] px-3 bg-gray-300 dark:bg-gray-800 outline-none"
            />
            <Button
              title="همه"
              color={theme as "dark"}
              onClick={() => setSelectedUser(users)}
              classNames={{
                container: "flex-none !w-20 !h-full",
                section: "!text-[12px]",
              }}
            />
            <Button
              title="هیچکدام"
              color={theme as "dark"}
              onClick={() => setSelectedUser([])}
              classNames={{
                container: "flex-none !w-20 !h-full",
                section: "!text-[12px]",
              }}
            />
          </div>

          <div className="flex-auto overflow-auto p-3">
            <div className="flex flex-wrap items-start justify-center gap-5">
              {filterUser.map((user: any) => (
                <div
                  key={user.id}
                  className={`w-48 relative ring-1 py-2 px-5 rounded-full ${
                    !!selectedUser.find((i: any) => i.id === user.id)
                      ? "bg-sky-500 !text-gray-800 ring-sky-600"
                      : "bg-gray-200 dark:bg-gray-800 ring-gray-300 dark:ring-gray-900 text-gray-600 dark:text-gray-200 !bg-opacity-50 hover:!bg-opacity-100"
                  }`}
                >
                  <span className="select-none">{user.username}</span>
                  <input
                    name={user.id}
                    type="checkbox"
                    checked={!!selectedUser.find((i: any) => i.id === user.id)}
                    className="absolute w-full h-full right-0 top-0 opacity-0 cursor-pointer"
                    onChange={selectHandler}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="p-2 gap-2 flex items-center justify-start border-t border-gray-200 dark:border-gray-600">
            <Button
              color={theme as "dark"}
              title="انتخاب"
              onClick={() => {
                onChange(selectedUser);
                setModalOpen(false);
              }}
              classNames={{
                container: "!w-32 !h-10",
                section: "!text-[13px]",
              }}
            />
            <Button
              color={theme as "dark"}
              title="انصراف"
              onClick={() => {
                setSelectedUser(value);
                setModalOpen(false);
              }}
              classNames={{
                container: "!w-32 !h-10",
                section: "!text-[13px]",
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default DailyReport;
