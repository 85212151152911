// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
// serviceWorkerRegistration.register();
// reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter } from 'react-router-dom';
import ThemesProvider from './Components/Provider/theme.provider';
import { Toaster } from 'react-hot-toast';
import { RecoilRoot } from 'recoil';
import { SvgIconProvider } from './providers/Icon.provider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
    <SvgIconProvider>
      <ThemesProvider>
        <BrowserRouter>
          <RecoilRoot>
            <App />
          </RecoilRoot>
        </BrowserRouter>
        <Toaster position='bottom-left' containerStyle={{zIndex: 9999}}/>
      </ThemesProvider>
    </SvgIconProvider>
  // </React.StrictMode>
);

// Change this line
// serviceWorkerRegistration.register();

reportWebVitals();
