import UserForm from "../../UserForm/UserForm";
import UserFilter from "../../UserFilter/UserFilter";
import ProjectForm from "../../ProjectForm/ProjectForm";
import ProjectFilter from "../../ProjectFilter/ProjectFilter";
import WorkTimeAggregations from "../WorkTimeAggregations/WorkTimeAggregations";
import TimeAggregationsModal from "./TimeAggrecations/TimeAggregations.modal";
import DeleteWork from "./DeleteWork/DeleteWork";
import ClosedDay from "./ClosedDay/ClosedDay";
import DeleteInOutLog from "./DeleteInOutLog/DeleteInOutLog";
import DeleteExitInOutLog from "./DeleteExitInOutLog/DeleteExitInOutLog";
import UnclosedDays from "./UnclosedDays/UnclosedDays";
import LogOutAccess from "./LogOut/LogOut";
import CloseDayAccess from "./CloseDayAccess/CloseDayAccess";

export const AllDialog = {
    AddUser: {
        element: UserForm,
        props: {
            title: "create"
        }
    },
    EditUser: {
        element: UserForm,
        props: {
            title: "edit"
        }
    },
    UserFilter: {
        element: UserFilter,
    },
    AddProject: {
        element: ProjectForm,
        props: {
            title: "create"
        }
    },
    EditProject: {
        element: ProjectForm,
        props: {
            title: "edit"
        }
    },
    ProjectFilter: {
        element: ProjectFilter,
    },
    WorkTimeAggregations: {
        element: TimeAggregationsModal,
    },
    DeleteWork: {
        element: DeleteWork,
    },
    ClosedDay: {
        element: ClosedDay,
    },
    DeleteInOutLog: {
        element: DeleteInOutLog,
    },
    DeleteExitInOutLog: {
        element: DeleteExitInOutLog,
    },
    UnclosedDays: {
        element: UnclosedDays,
    },
    LogOut: {
        element: LogOutAccess,
    },
    CloseDayAccess: {
        element: CloseDayAccess,
    }
}