import { WorkDto } from "../Recoil/Works.atom";
import { ServerDateToDay, timestampToHours, TimestampToTime, TimeToTimestamp } from "./convertTime";

export const findExitTime = ({
    exit, 
    works, 
    serverDate, 
    date, 
    logicExit
}: {
    exit?: number;
    works: WorkDto[];
    serverDate: number;
    date: number;
    logicExit: string;
}): string => {
    if (exit) return TimestampToTime(exit).split(":")?.[0]
    const hasMission = works.find(w => w.type === "mission")
    if (hasMission) return "24"

    const isToday = ServerDateToDay(serverDate) <= date
    const lastWorkEnd = works.reduce((a, b) => b.end > a ? b.end : a, 0) 
    const logicExitsStamp = TimeToTimestamp(logicExit, date)
    if (isToday) {
        const maxTime = Math.max(serverDate, lastWorkEnd, logicExitsStamp)
        return String(timestampToHours(maxTime))
    } else {
        const maxTime = Math.max(lastWorkEnd, logicExitsStamp)
        return String(timestampToHours(maxTime))
    }
}