"use client"

import React, { useEffect, useState, forwardRef } from 'react'
import TextTag from '../TextTag/TextTag';
import { CSSProperties } from "react";
import { twMerge } from 'tailwind-merge';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string
    loading?: boolean
    fill?: "transparent"
    error?: string
    act?: boolean
    theme?: string
    hasSequence?: boolean
    classNames?: {
        container?: string,
        label?: string,
        inputBox?: string,
        input?: string,
        error?: string,
    }
    styles?: {
        container?: CSSProperties,
        label?: CSSProperties,
        inputBox?: CSSProperties,
        input?: CSSProperties,
        error?: CSSProperties,
    }
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {

    const {
        label,
        error, 
        act, 
        hasSequence = false,
        classNames, 
        styles,
        theme = "light",
        ...otherProps
    } = props;

    const containerDefaultClass = "w-full h-28 relative select-none"
    const containerOverwriteClass = classNames?.container ? " " + classNames.container : ""
    const containerTwMerge = twMerge(containerDefaultClass, containerOverwriteClass)

    const labelDefaultClass = "mb-2 px-2 capitalize font-bold text-start"
    const labelOverwriteClass = classNames?.label ? " " + classNames.label : ""
    const labelTwMerge = twMerge(labelDefaultClass, labelOverwriteClass)
    
    const inputBoxDefaultClass = `w-full h-[50px] relative flex items-center justify-center overflow-hidden rounded-md bg-rose-400 border bg-gray-200 dark:bg-gray-800 ${(error && act) ? "border-rose-500 " : "dark:border-charcoal-900 border-solitude-200"}`
    const inputBoxOverwriteClass = classNames?.inputBox ? " " + classNames.inputBox : ""
    const inputBoxTwMerge = twMerge(inputBoxDefaultClass, inputBoxOverwriteClass)

    const deleteArrowInput = "disable-input-arrow"
    const inputDefaultClass = `w-full h-full flex-auto py-2 px-4 outline-none transition-all text-md text-gary-800 dark:text-gray-200 placeholder:text-solitude-300 dark:placeholder:text-charcoal-500 ${(error && act) ? "bg-rose-400 bg-opacity-10" : "bg-solitude-200 dark:bg-charcoal-900"} !border-0`
    const inputOverwriteClass = classNames?.input ? " " + classNames.input : ""
    const inputTwMerge = twMerge(deleteArrowInput, inputDefaultClass, inputOverwriteClass)

    const [inputType, setInputType] = useState(otherProps.type)
    const showPasHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        inputType === "password" ? setInputType("text") : setInputType("password")
    }

    return (
        <div 
            className={containerTwMerge}
            style={styles?.container}
        >
            {label && 
                <TextTag
                    as='h5'
                    className={labelTwMerge}
                    style={styles?.label}
                >
                    {label}
                </TextTag>
            }
            <div className={inputBoxTwMerge}>
                {/* {hasSequence &&
                    <Icon
                        name='search'
                        className='h-full aspect-square flex-none rounded-none bg-solitude-300 dark:bg-charcoal-800 p-2 text-solitude-600 dark:text-charcoal-300 cursor-pointer opacity-75 hover:opacity-100 transition-all'
                    />
                } */}
                <input
                    ref={ref}
                    className={inputTwMerge} 
                    style={styles?.input}
                    {...otherProps}
                    type={inputType}
                />
                {props.type === "password" &&
                    <Button
                        icon={inputType === "password" ? "eye-fill" : "eye-slash"}
                        // name=""
                        color="gray"
                        iconColor={"#9ca3af"}
                        iconHover={"#6b7280"}
                        fill='transparent'
                        onClick={showPasHandler}
                        classNames={{
                            container: "!w-10 aspect-squire"
                        }}
                    />
                }
            </div>
            {(error && act) && 
                <TextTag
                    as='span'
                    className={`w-full px-2 py-1 text-rose-500 dark:text-rose-500 text-end block`}
                    style={styles?.error}
                >
                    {error}
                </TextTag>
            }
            
        </div>
    )
})

export default Input;