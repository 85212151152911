import { useEffect, useState } from "react"
import Button from "../../Components/Common/Button/Button"
import { GetProjects } from "../../axios/Projects.api"
import { useRecoilState } from "recoil"
import { ProjectsAtom, SearchProjectsParamsAtom } from "../../Recoil/projects.atom"
import Loading from "../../Components/Common/Loading/Loading"
import TextTag from "../../Components/Common/TextTag/TextTag"
import Switch from "../../Components/Common/Switch/Switch"
import { ModalOpenAtom, ModalParamsAtom, ModalTypeAtom } from "../../Recoil/Atoms"
import { ProjectDatabaseDto } from "./dto/databaseStructure"
import { useTheme } from "next-themes"
import { typeSearchProjectsParamsType } from "../../Components/ProjectFilter/types"
import Icon from "../../Components/Common/Icon/Icon"

export default function Projects() {

    const {theme, setTheme} = useTheme()
    const [loading, setLoading] = useState(false)
    const [projects, setProjects] = useRecoilState(ProjectsAtom)
    const [modalType, setModalType] = useRecoilState(ModalTypeAtom)
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    const [modalParams, setModalParams] = useRecoilState(ModalParamsAtom);
    const [searchProjectsParams, setSearchProjectsParams] = useRecoilState(SearchProjectsParamsAtom)

    const addProject = () => {
        setModalType("AddProject")
        setIsModalOpen(true)
    }
    const viewProjectHandler = (user: ProjectDatabaseDto) => {
        setIsModalOpen(true)
        setModalType("EditProject")
        setModalParams(user.id)
    }

    const getProjectsHandler = (newParams?: typeSearchProjectsParamsType) => {
        setLoading(true)
        GetProjects(newParams)
            .then(res => {
                setLoading(false)
                setProjects(res.data.rows)
            })
            .catch(err => console.log(err))
    }

    const deleteFilterHandler = (key: keyof typeSearchProjectsParamsType) => {
        const newParams = {...searchProjectsParams}
        delete newParams[key]
        setSearchProjectsParams(newParams)
        getProjectsHandler(newParams)
    }
    
    useEffect(() => {
        getProjectsHandler()
    }, [])

    const faImportance = {
        "1": "خیلی مهم",
        "2": "مهم",
        "3": "متوسط",
        "4": "کم اهمیت",
        "5": "بی اهمیت",
    }

    const faFilterValue = {
        name: "نام پروژه",
        active: "وضعیت فعال",
        importance: "اهمیت",
        description: "توضیحات",
    }

    return (
        <div className="px-2 border bg-gray-100 dark:bg-gray-600 border-zinc-400 dark:border-zinc-800 rounded-md">
            <div className="w-full py-2 flex items-center justify-between border-b border-zinc-300 dark:border-zinc-700">
                <h3 className="text-xl text-gray-500 dark:text-gray-300">پروژه‌ها</h3>
                <div className="flex items-center justify-center gap-2">
                    <Button
                        title="فیلتر"
                        icon="text-right"
                        direction="row_reverse"
                        color={theme as "dark"}
                        // expand="full"
                        loading={false}
                        iconWidth="1.8rem"
                        iconHeight="1.8rem"
                        onClick={() => {
                            setIsModalOpen(true)
                            setModalType("ProjectFilter")
                        }}
                        classNames={{
                            container: "!w-32 !h-10 !rounded-[4px] !flex !px-2 !py-0 transition-all duration-200",
                            section: "!text-sm"
                        }}
                    />
                    <Button
                        title="افزودن پروژه"
                        icon="plus-lg"
                        direction="row_reverse"
                        color={theme as "dark"}
                        // expand="full"
                        loading={false}
                        iconWidth="1.3rem"
                        iconHeight="1.3rem"
                        onClick={addProject}
                        classNames={{
                            container: "!w-32 !h-10 !rounded-[4px] !flex !px-2 !py-0 transition-all duration-200",
                            section: "!text-sm"
                        }}
                    />
                </div>
            </div>
            <div className="w-full flex flex-wrap items-center justify-start gap-4 p-2">
                {Object.entries(searchProjectsParams).map(([keyy, value]) =>
                    <div key={keyy} className="p-2 gap-2 flex items-center justify-between bg-sky-500 bg-opacity-50 rounded-full ring-1 ring-sky-600 hover:bg-opacity-60 transition-all">
                        <TextTag as="span">{faFilterValue[keyy as "name"]}</TextTag>
                        <Button
                            icon="x"
                            color="gray"
                            fill="transparent"
                            iconColor={theme === "dark" ? "white" : "#075985"}
                            iconHover={theme === "dark" ? "white" : "#075985"}
                            onClick={() => deleteFilterHandler(keyy as keyof typeSearchProjectsParamsType)}
                            classNames={{
                                container: "!w-6 !h-4 !rounded-full"
                            }}
                        />
                    </div>
                )}
            </div>
            <div className="w-full">
                <div className="w-full h-10 sticky top-20 bg-gray-500 bg-opacity-30 backdrop-blur-lg items-center justify-center sm:px-2 py-2 text-sm grid grid-cols-6 sm:grid-cols-[repeat(12,_minmax(0,_1fr))] shadow-md shadow-[#00000026] rounded-[4px] z-10">
                    <TextTag className="col-span-2 grid place-content-center border-l border-zinc-400 dark:border-zinc-700 whitespace-nowrap">نام</TextTag>
                    <TextTag className="col-span-2 grid place-content-center border-l border-zinc-400 dark:border-zinc-700">اهمیت</TextTag>
                    <TextTag className="col-span-2 grid place-content-center border-l border-zinc-400 dark:border-zinc-700">وضعیت</TextTag>
                    <TextTag className="col-span-6 place-content-center hidden sm:grid">توضیحات</TextTag>
                    {/* <span className="grid place-content-center"></span> */}
                </div>

                {!loading ? 
                    projects.length ?
                        projects.map((project, index) => 
                        <div key={index} className={`h-14 ${index !== projects.length - 1 ? "border-b border-zinc-300 dark:border-zinc-700" : ""} items-center justify-start sm:px-2 py-2 text-sm grid grid-cols-6 sm:grid-cols-[repeat(12,_minmax(0,_1fr))]`}>
                            <TextTag 
                                onClick={() => viewProjectHandler(project)}
                                // className="col-span-2 grid place-content-center border-l border-zinc-300 dark:border-zinc-700 whitespace-nowrap overflow-hidden"
                                className="col-span-2 grid place-content-center border-l border-zinc-300 dark:border-zinc-700 whitespace-nowrap overflow-hidden hover:!text-sky-500 cursor-pointer transition-colors"
                            >
                                {project.name}
                            </TextTag>
                            <TextTag className="col-span-2 grid place-content-center border-l border-zinc-300 dark:border-zinc-700 whitespace-nowrap overflow-hidden">
                                {faImportance[project.importance as "1"]}
                            </TextTag>
                            <span className="col-span-2 grid place-content-center border-l border-zinc-300 dark:border-zinc-700">
                                {/* <Switch
                                    value={project.active}
                                    disabled
                                /> */}
                                <Icon
                                    name={project.active ? "check-circle" : "x-circle"}
                                    width="2.2rem"
                                    height="2.2rem"
                                    color={project.active ? "#10b981" : "#f43f5e"}
                                    // classNames="w-10 text-gray-800"
                                />
                            </span>
                            <TextTag className="col-span-6 whitespace-nowrap overflow-hidden text-start px-4 py-2 hidden sm:grid">
                                {project.description}
                            </TextTag>
                            {/* <span className="grid place-content-center cursor-pointer group">
                                <Button
                                    icon="eye-fill"
                                    color="gray"
                                    fill="transparent"
                                    iconColor={"#9ca3af"}
                                    iconHover={"#6b7280"}
                                    onClick={() => viewProjectHandler(project)}
                                    classNames={{
                                        container: "!w-10 !h-10 "
                                    }}
                                />
                            </span> */}
                            
                        </div> 
                        ) :
                        <TextTag as="h3" className="w-full text-center py-32 text-gray-400">پروژه یافت نشد</TextTag> :
                    <Loading 
                        className="w-full h-52 grid place-content-center opacity-20"
                    />
                }
            </div>
        </div>
    )
}