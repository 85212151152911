
import { twMerge } from 'tailwind-merge';
import TextTag from '../TextTag/TextTag';
import { MouseEvent, MouseEventHandler } from 'react';
interface SwitchProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "onClick"> {
    // name: keyof IconsName,
    // className?: string;
    // styles?: CSSProperties
    value?: boolean;
    disabled?: boolean;
    onClick: (e: MouseEvent<HTMLDivElement>, name: string) => void
    label?: string;
    name: string;
    classNames?: {
        container?: string
    }
}


const Switch: React.FC<SwitchProps> = ({
    value, 
    disabled,
    onClick,
    label,
    name,
    classNames,
    ...otherProps
}: SwitchProps) => {
    
    const containerDefaultClass = "flex items-end justify-end gap-3 cursor-pointer flex-row-reverse select-none"
    const containerOverwriteClass = classNames?.container ? " " + classNames.container : ""
    const containerTwMerge = twMerge(containerDefaultClass, containerOverwriteClass)

    return (
        <div 
            className={containerTwMerge}
            onClick={disabled ? console.warn : (e) => onClick(e, name)}
            {...otherProps}
        >
            {/* <div className="w-full flex items-center justify-center flex-row-reverse gap-2 border mb-1 p-2"> */}
                <TextTag>{label}</TextTag>
                <div className={`w-12 h-6 rounded-full relative shadow-inner transition-all duration-300 ${value ? "bg-emerald-400 shadow-emerald-500" : "bg-gray-300 dark:bg-gray-400 shadow-gray-400 dark:shadow-gray-500"} `}>
                    <div className={`w-4 aspect-square rounded-full absolute top-1 transition-all duration-300 shadow-md bg-gray-700 ${value ? "right-7  shadow-emerald-500" : "right-1 shadow-gray-400 dark:shadow-gray-500"}`} />
                </div>
            {/* </div> */}
        </div>
    )
}

export default Switch