import { get, put, post, deleted, patch } from "../Axios.config"
import Cookies from 'universal-cookie';
const cookies = new Cookies();


export const getEmploys = async () => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `users/getUserWithEmployees`,
        config: {
            params: {active: true},
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const getDailyReportsWithAggregation = async (params: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `reports/daily_reports_with_aggregation`,
        config: {
            params,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const getDailyReportsPageService = async (params: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `reports/daily_reports_page`,
        config: {
            params,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}