import { useRecoilState } from "recoil"
import Button from "../../Button/Button"
import TextTag from "../../TextTag/TextTag"
import WorkTimeAggregations from "../../WorkTimeAggregations/WorkTimeAggregations"
import { DateState, ModalOpenAtom, ModalParamsAtom, UserDataState } from "../../../../Recoil/Atoms"
import { useTheme } from "next-themes"
import Icon from "../../Icon/Icon"
import { deleteWork } from "../../../../axios/Work.api"
import { WorksInDayAtom } from "../../../../Recoil/Works.atom"
import moment from 'moment-jalaali'

const DeleteWork = () => {
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    const [modalParams, setModalParams] = useRecoilState(ModalParamsAtom);
    const [works, setWorks] = useRecoilState(WorksInDayAtom)
    const [user, setUser] = useRecoilState(UserDataState)
    const [date, setDate] = useRecoilState(DateState)
    const {theme} = useTheme()

    return (
        <div className="w-[330px] flex flex-col bg-gray-100 dark:bg-gray-700 rounded-md">
            <div className="flex-none flex items-center justify-center p-2">
                <Icon
                    name="check-circle"
                    width="5rem"
                    height="5rem"
                    color="#10b981"
                />
            </div>
            
            <div className="p-3">
                <TextTag as="h4" className="flex items-start justify-start pb-4">
                    همکار محترم
                </TextTag>
                <div className="flex items-center justify-start gap-2">
                    <TextTag as="h4" className="">
                        {user.username}
                    </TextTag>
                </div>
                <TextTag as="h5" className="text-start mt-5">
                    گزارش فعالیت شما برای تاریخ 
                    <TextTag as="u" className="px-1 text-md"> {moment(date*1000).format('jYYYY/jM/jD').split(" - ").reverse().join(" - ")} </TextTag>
                    با موفقیت بسته ، و جزئیات آن ، تا 24 ساعت آینده به تلگرام شما ارسال خواهد شد
                </TextTag>
            </div>
            <div className=" flex items-center justify-start gap-3 p-3 mt-3">
                <Button
                    title="بستن"
                    color={theme === "dark" ? "dark" : "light"}
                    onClick={() => setIsModalOpen(false)}
                    classNames={{
                        container: "!w-28 !h-10 !rounded-[3px] !flex-none",
                        section: "!text-sm"
                    }}
                />
                <TextTag as="small" className="flex-auto text-start text-orange-400 dark:text-orange-400">
                    درصورت عدم دریافت جزئیات گزرارش در تلگرام ، لطفا به پشتیانی اطلاع  دهید
                </TextTag>

            </div>
        </div>
    )
}

export default DeleteWork