import { UserValuesType } from "../Components/UserForm/Types";
import { get, put, post, deleted, patch } from "./Axios.config"
import Cookies from 'universal-cookie';
const cookies = new Cookies();



export const closeMonth = async (data: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await post({
        api: `monthlyLog`,
        data,
        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const getMonthlyLogByMonth = async (params: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `monthlyLog`,
        config: {
            params,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}