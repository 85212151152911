import { useRecoilState, useRecoilValue } from "recoil";
import {
  DailyLogAtom,
  LockWorkPageAtom,
  TransmissionTimeAtom,
  WorkDto,
} from "../../../Recoil/Works.atom";
import {
  DateState,
  NumberInputFocusedAtom,
  ServerDateState,
  ShowAggregationTimeStateAtom,
} from "../../../Recoil/Atoms";
import { useEffect, useRef } from "react";
import { useTheme } from "next-themes";
import Button from "../../Common/Button/Button";
import WorkTimeAggregations from "../../Common/WorkTimeAggregations/WorkTimeAggregations";
import notFound from "../../../assets/notExist.png";
import DescriptionsAccordion from "../DescriptionsAccordion/DescriptionsAccordion";
import TextTag from "../../Common/TextTag/TextTag";
import { InOutLogAtom } from "../../../Recoil/InOutLogAtom";
import { ServerDateToDay, TimestampToTime, timeToDuration } from "../../../Utils/convertTime";
import TimePicker from "../../Common/TimePicker/TimePicker";
import { updateTimeLog } from "../../../axios/DailyLog.api";
import { Toasts } from "../../../toasts/toasts";
import { inputSubmitAtom } from "../../../Recoil/dirtyAtom";

const Descriptions = ({
  works,
  setWorks,
  selectedWork,
  setSelectedWork,
  overflow,
  submitWorkForm,
  deleteHandler,
  globalLoading,
  forDate,
  justSee,
}: {
  works: WorkDto[];
  setWorks: React.Dispatch<React.SetStateAction<WorkDto[]>>;
  selectedWork: number;
  setSelectedWork: React.Dispatch<React.SetStateAction<number>>;
  overflow: object;
  submitWorkForm: (item: any) => void;
  deleteHandler: (id: number) => void;
  globalLoading: boolean;
  forDate?: number;
  justSee: boolean;
}) => {
  const scrollTpRef = useRef<any>(undefined);
  const [numberInputFocused, setNumberInputFocused] = useRecoilState(
    NumberInputFocusedAtom
  );
  const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom);
  const [timeLog, setTimeLog] = useRecoilState(TransmissionTimeAtom);
  const [lock, setLock] = useRecoilState(LockWorkPageAtom);
  const descriptionRef = useRef<any>(null);
  const serverDate = useRecoilValue(ServerDateState);
  const { theme, setTheme } = useTheme();
  // const temporaryWork = works.find((work) => work.type === "temporary");
  const [date, setDate] = useRecoilState(DateState);
  const [inOutLogs, setInOutLogs] = useRecoilState(InOutLogAtom);
  const [showAggregationTime, setShowAggregationTime] = useRecoilState(
    ShowAggregationTimeStateAtom
  );
  // این رو مجبور شدم اضافه کنم برای اینکه بفهمم فرم کی سابمیت میشه تا اینپوت های مربوط به شروع و پایان زمان رو آپدیت کنم. بعدا یه فکر بهتری براش میکنم
  const [formSubmit, setFormSubmit] = useRecoilState(inputSubmitAtom);

  // useEffect(() => {
  //     if (!isMobile) {
  //         const findIndex = works.findIndex(w => w.id === selectedWork)
  //         const scrollTo = findIndex*100
  //         scrollTpRef.current = setTimeout(() => {
  //             descriptionRef.current?.scrollTo({top: scrollTo, behavior: "smooth"});
  //         }, 400)
  //     }
  //     return () => {
  //         clearInterval(scrollTpRef.current)
  //     }
  // }, [selectedWork])
  // const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
  //     event.preventDefault(); // جلوگیری از اسکرول
  // };

  const startHandler = (time: string) => {
    if (lock) return Toasts.lockWorks();
    const idleTime = timeToDuration(time);
    updateTimeLog(date, { idleTime })
      .then((res) => {
        const { idleTime } = res.data;
        setDailyLog({
          ...dailyLog,
          idleTime,
          finalized: 0,
        });
        setFormSubmit(!formSubmit);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (
        descriptionRef.current &&
        descriptionRef.current.contains(event.target as Node) &&
        numberInputFocused
      ) {
        event.preventDefault(); // جلوگیری از اسکرول
      }
    };

    const divElement = descriptionRef.current;
    if (divElement) {
      divElement.addEventListener("wheel", handleWheel, { passive: false });
    }

    const clientHeight = document.documentElement.clientHeight
    if (clientHeight <= 800) setShowAggregationTime("down")

    return () => {
      if (divElement) {
        divElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  return (
    <div
      className={`flex-auto rounded-md hidden md:flex flex-col ${
        (showAggregationTime !== "down") ? "gap-0" : " gap-4"
      } transition-[gap]`}
    >
      <div
        ref={descriptionRef}
        className={`w-full h-5 flex-auto border-b border-gray-200 dark:border-gray-700 ${
          (showAggregationTime !== "down") ? "" : "pb-2"
        } transition-[padding] overflow-auto`}
        // onWheelCapture={handleWheel}
      >
        {
          works.length ? (
            works.map((work) => (
              <DescriptionsAccordion
                key={work.id}
                work={work}
                selectedWork={selectedWork}
                setSelectedWork={setSelectedWork}
                overflow={overflow}
                works={works}
                setWorks={setWorks}
                submitWorkForm={submitWorkForm}
                deleteHandler={deleteHandler}
                justSee={justSee}
              />
            ))
          ) : globalLoading ? (
            <></>
          ) : (
            <div className="w-full h-full grid place-content-center">
              <img src={notFound} className="w-96 opacity-70" />
              <TextTag as="h3" className="text-center p-5 !text-opacity-50">
                رویداد کاری وجود ندارد !!
              </TextTag>
            </div>
          )
          // <Placeholder />
          // <></>
        }
      </div>
      {!!(inOutLogs.length || works.length) && (
        <div
          className={`w-full ${
            (showAggregationTime === "down") ? (!!inOutLogs.length ? "h-[54px]" : "h-[0px]") : (showAggregationTime === "center") ? "h-[210px]" : "h-full"
          } transition-[height] duration-500 flex-none flex flex-col rounded-md relative z-[999]`}
        >
        {!!inOutLogs.length && (
          <form className="flex-none p-2 bg-gray-100 dark:bg-gray-600 rounded-md">
            <TimePicker
              name="idleTime"
              insideLabel="در اختیار اضافه (در شرکت)"
              disabled={justSee}
              value={TimestampToTime(
                ServerDateToDay(serverDate) + (dailyLog?.idleTime ?? 0)
              )}
              picker={{
                onAccept: startHandler,
              }}
            />
          </form>
        )}
          <WorkTimeAggregations forDate={forDate} justSee={justSee} />
          <Button
            icon="chevron-down"
            color={theme === "dark" ? "dark" : "light"}
            iconWidth="15px"
            iconHeight="15px"
            iconRotate="180"
            onClick={() => {
              setShowAggregationTime("up")
            }}
            classNames={{
              container:
                "absolute !w-10 !h-3 !grid !place-content-center !rounded-t-md !rounded-b-none -top-3 right-4 !border-t !border-s !border-e border-gray-400 dark:border-gray-900",
            }}
          />
          <Button
            icon="dash-lg"
            color={theme === "dark" ? "dark" : "light"}
            iconWidth="15px"
            iconHeight="15px"
            // iconRotate={showAggregationTime ? "0" : "180"}
            onClick={() => setShowAggregationTime("center")}
            classNames={{
              container:
                "absolute !w-10 !h-3 !grid !place-content-center !rounded-t-md !rounded-b-none -top-3 right-16 !border-t !border-s !border-e border-gray-400 dark:border-gray-900",
            }}
          />
          <Button
            icon="chevron-down"
            color={theme === "dark" ? "dark" : "light"}
            iconWidth="15px"
            iconHeight="15px"
            // iconRotate={showAggregationTime ? "0" : "180"}
            onClick={() => {
              setShowAggregationTime("down")
            }}
            classNames={{
              container:
                "absolute !w-10 !h-3 !grid !place-content-center !rounded-t-md !rounded-b-none -top-3 right-28 !border-t !border-s !border-e border-gray-400 dark:border-gray-900",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Descriptions;
