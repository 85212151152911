import React, { useLayoutEffect, useState } from "react";
import styles from "./Button.module.scss";

import { ButtonType, ButtonDefaultProps, StylePropertiesType } from "./Types";
import Icon from "../Icon/Icon";
import { ThemeHandler } from "./Theme";

const Button = (props: ButtonType) => {

  const {
    isBadge = false,
    disabled = false,
    color = "primary",
    iconWidth = "1.5rem",
    iconHeight = "1.5rem",
    isIconCircle = false,
    fill = "basic",
    shape = "round",
    expand = "default",
    direction = "row",
    loading = false,
    description = "",
    ...otherProps
  }: ButtonType = props

  const [firstStyle, setFirstStyle] = useState<StylePropertiesType>({
    "--color": "",
    "--hover": "",
    "--title": "",
    "--titleHover": "",
    "--borderSize": "",
    "--borderColor": "",
    "--icon": "",
    "--iconHover": "",
  })

  useLayoutEffect(() => {
    if (disabled) {
      setFirstStyle({
        ...ThemeHandler.disable,
        "--borderSize": otherProps.outLineSize ?? "",
        [otherProps.outlineColor ? "--borderColor" : ""]: otherProps.outlineColor ?? "",
      })
    } else {
        const newTheme = {
            ...ThemeHandler[fill][color],
            "--borderSize": fill === "outline" ? otherProps?.outLineSize ?? "2px" : otherProps?.outLineSize ?? "0px",
            [otherProps.outlineColor ? "--borderColor" : ""]: otherProps.outlineColor ?? "",
            [otherProps.iconColor ? "--icon" : ""]: otherProps.iconColor,
            [otherProps.iconHover ? "--iconHover" : ""]: otherProps.iconHover,
            [otherProps.iconColor ? "--circleIconColor" : ""]: otherProps.iconColor,
            [otherProps.iconHover ? "--circleIconHover" : ""]: otherProps.iconHover,
            [otherProps.titleColor ? "--title" : ""]: otherProps.titleColor,
            [otherProps.titleHover ? "--titleHover" : ""]: otherProps.titleHover,
        }
      
        if (isBadge) {
            newTheme["--hover"] = ThemeHandler[fill][color]["--color"];
            newTheme["--titleHover"] = ThemeHandler[fill][color]["--title"];
            newTheme["--circleHover"] = ThemeHandler[fill][color]["--circle"];
            newTheme["--circleIconHover"] = ThemeHandler[fill][color]["--circleIconColor"];
            newTheme["--iconHover"] = ThemeHandler[fill][color]["--icon"];
        }
        
        if (!isIconCircle) {
            delete newTheme["--circle"];
            delete newTheme["--circleHover"];
            delete newTheme["--circleIconColor"];
            delete newTheme["--circleIconHover"];
        }
    
      setFirstStyle(newTheme)
    }
  }, [props])


  return (
    <button
      cy-id={otherProps.cyBtnId}
      type={otherProps.type ?? "button"}
      onClick={(isBadge || loading) ? undefined : otherProps.onClick}
      className={`${styles.container} ${
        styles.fill}${
        shape ? " " + styles[`shape_${shape}`] : ""}${
        expand ? " " + styles[`expand_${expand}`] : ""}${
        (!disabled || (isBadge)) ? "" : " " + styles.disable}${
        otherProps.centered ? " " + styles.centered : ""}${
        direction ? " " + styles[direction] : ""}${
        isIconCircle ? " " + styles.circleIcon : ""}${
        otherProps.classNames?.container ? " " + otherProps.classNames.container : ""}
        `
      }
      style={{...firstStyle, ...otherProps.style?.container}}
      disabled={disabled}
      title={description}
    >
      {(otherProps.icon || loading) && (
          <Icon
            cyIconId={otherProps.cyIconId}
            name={loading ? "loadingAnimation" : otherProps.icon ?? "loadingAnimation"}
            width={iconWidth}
            height={iconHeight}
            isCircle={isIconCircle}
            rotate={otherProps.iconRotate}
            // color={otherProps.disabled ? "gray" : otherProps.iconColor}
            // background={otherProps.iconBackground}        
            classNames={`${styles.icon}${otherProps.classNames?.icon ? " " + otherProps.classNames?.icon : ""}`}
            style={otherProps.style?.icon}
          />
      )}

      {otherProps.title && <section className={otherProps.classNames?.section}>{otherProps.title}</section>}

    </button>
  );
};

// Button.defaultProps = ButtonDefaultProps;

export default Button;
