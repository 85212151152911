module.exports = {
    bg: {
        "400": {
            teal: "bg-teal-400",
            yellow: "bg-yellow-400",
            red: "bg-red-400",
            sky: "bg-sky-400",
            gray: "bg-gray-400",
            purple: "bg-purple-400",

            slate: "bg-slate-400",
            zinc: "bg-zinc-400",
            neutral: "bg-neutral-400",
            stone: "bg-stone-400",
            orange: "bg-orange-400",
            amber: "bg-amber-400",
            lime: "bg-lime-400",
            green: "bg-green-400",
            emerald: "bg-emerald-400",
            cyan: "bg-cyan-400",
            blue: "bg-blue-400",
            indigo: "bg-indigo-400",
            violet: "bg-violet-400",
            fuchsia: "bg-fuchsia-400",
            pink: "bg-pink-400",
            rose: "bg-rose-400",
        },
        "500": {
            teal: "bg-teal-500",
            yellow: "bg-yellow-500",
            red: "bg-red-500",
            sky: "bg-sky-500",
            gray: "bg-gray-500",
            purple: "bg-purple-500",

            slate: "bg-slate-500",
            zinc: "bg-zinc-500",
            neutral: "bg-neutral-500",
            stone: "bg-stone-500",
            orange: "bg-orange-500",
            amber: "bg-amber-500",
            lime: "bg-lime-500",
            green: "bg-green-500",
            emerald: "bg-emerald-500",
            cyan: "bg-cyan-500",
            blue: "bg-blue-500",
            indigo: "bg-indigo-500",
            violet: "bg-violet-500",
            fuchsia: "bg-fuchsia-500",
            pink: "bg-pink-500",
            rose: "bg-rose-500",
        }
    },
    ring: {
        "600": {
            teal: "ring-teal-600",
            yellow: "ring-yellow-600",
            red: "ring-red-600",
            sky: "ring-sky-600",
            gray: "ring-gray-600",
            purple: "ring-purple-600",

            slate: "ring-slate-600",
            zinc: "ring-zinc-600",
            neutral: "ring-neutral-600",
            stone: "ring-stone-600",
            orange: "ring-orange-600",
            amber: "ring-amber-600",
            lime: "ring-lime-600",
            green: "ring-green-600",
            emerald: "ring-emerald-600",
            cyan: "ring-cyan-600",
            blue: "ring-blue-600",
            indigo: "ring-indigo-600",
            violet: "ring-violet-600",
            fuchsia: "ring-fuchsia-600",
            pink: "ring-pink-600",
            rose: "ring-rose-600",
        }
    },
    hover: {
        bg: {
            teal: "hover:bg-teal-500",
            yellow: "hover:bg-yellow-500",
            red: "hover:bg-red-500",
            sky: "hover:bg-sky-500",
            gray: "hover:bg-gray-500",
            purple: "hover:bg-purple-500",

            slate: "hover:bg-slate-500",
            zinc: "hover:bg-zinc-500",
            neutral: "hover:bg-neutral-500",
            stone: "hover:bg-stone-500",
            orange: "hover:bg-orange-500",
            amber: "hover:bg-amber-500",
            lime: "hover:bg-lime-500",
            green: "hover:bg-green-500",
            emerald: "hover:bg-emerald-500",
            cyan: "hover:bg-cyan-500",
            blue: "hover:bg-blue-500",
            indigo: "hover:bg-indigo-500",
            violet: "hover:bg-violet-500",
            fuchsia: "hover:bg-fuchsia-500",
            pink: "hover:bg-pink-500",
            rose: "hover:bg-rose-500",
        },
        ring: {
            teal: "hover:ring-teal-600",
            yellow: "hover:ring-yellow-600",
            red: "hover:ring-red-600",
            sky: "hover:ring-sky-600",
            gray: "hover:ring-gray-600",
            purple: "hover:ring-purple-600",

            slate: "hover:ring-slate-600",
            zinc: "hover:ring-zinc-600",
            neutral: "hover:ring-neutral-600",
            stone: "hover:ring-stone-600",
            orange: "hover:ring-orange-600",
            amber: "hover:ring-amber-600",
            lime: "hover:ring-lime-600",
            green: "hover:ring-green-600",
            emerald: "hover:ring-emerald-600",
            cyan: "hover:ring-cyan-600",
            blue: "hover:ring-blue-600",
            indigo: "hover:ring-indigo-600",
            violet: "hover:ring-violet-600",
            fuchsia: "hover:ring-fuchsia-600",
            pink: "hover:ring-pink-600",
            rose: "hover:ring-rose-600",
        }
    },
    justify: "justify-start justify-end"
}
