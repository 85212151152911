import { useRecoilState, useRecoilValue } from "recoil";
import useGenerateTimeFrame from "../../hooks/useGenerateTimeFrame";
import {
  DateState,
  GlobalLoadingAtom,
  MinimumWorkTimeDurationStateAtom,
  ModalOpenAtom,
  ModalParamsAtom,
  ModalTypeAtom,
  ServerDateState,
  ShowAggregationTimeStateAtom,
  UserDataState,
} from "../../Recoil/Atoms";
import { useEffect, useRef, useState } from "react";
import {
  DailyLogAtom,
  ExitTempAtom,
  hasInComponyAtom,
  LockWorkPageAtom,
  MissionType,
  PinsStateAtom,
  SelectedWorkAtom,
  TimeFrameAtom,
  TimeSpanDurationAtom,
  WorkDto,
  WorksInDayAtom,
  WorkType,
} from "../../Recoil/Works.atom";
import { InOutLogAtom, InOutLogDto } from "../../Recoil/InOutLogAtom";
import moment from "moment-jalaali";
import { useTheme } from "next-themes";
import {
  DurationToTime,
  ServerDateToDay,
  timestampToMinutes,
  TimestampToTime,
  timeToDuration,
  todayCalculate,
} from "../../Utils/convertTime";
import {
  DayValue,
  DayRange,
  Day,
} from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { addWork, updateWork } from "../../axios/Work.api";
import {
  finalizedUpdate,
  getDailyLog,
  getUnclosedDays,
  updateTimeLog,
} from "../../axios/DailyLog.api";
import domtoimage from "dom-to-image";
import { Toast } from "../../Utils/Toast";
import { addPin } from "../../axios/Pin.api";
import Button from "../Common/Button/Button";
import DatePicker from "../Common/DatePicker/DatePicker";
import TextTag from "../Common/TextTag/TextTag";
import WorksBar from "./WorksBar/WorksBar";
import DescriptionsMobile from "./DescriptionsMobile/DescriptionsMobile";
import Descriptions from "./Descriptions/Descriptions";
import Dialog from "../Common/Dialog/Dialog";
import TimePickerTouch from "../Common/TimePickerTouch/TimePickerTouch";
import { findExitTime } from "../../Utils/FindExiteTime";
import { useLocation } from "react-router-dom";
import useNotificationsUpdate from "../../hooks/useNotificationsUpdate";
import { UnClosedDaysState } from "../../Recoil/dailyLogAtom";
import { Toasts } from "../../toasts/toasts";
import { BeforeMonthIsClosedStateAtom } from "../../Recoil/monthlyLogAtom";
import { Link } from "react-router-dom";

const oneMinutes = 1 * 60;
const _15Minutes = oneMinutes * 15;
const oneHours = 1 * 60 * 60;
const hours = 3600;
const oneDay = 60 * 60 * 24;
const now = Math.floor(Date.now() / 60000) * 60000;

export const faWorkType: any = {
  sumOfInCompony: "جمع حضور",
  work: "درشرکت",
  mission: "ماموریت",
  remote: "دورکاری",
  leave: "کار شخصی",
  temporary: "بلاتکلیف",
  idleTime: "مجموع دراختیار",
  dayDuration: "طول مدت حضور",
  useful: "کار مفید",
  sumOfRemoteDuration: "مجموع دورکاری",
};
export const faMissionType: any = {
  work1: "کار دوره اول",
  work2: "کار دوره دوم",
  onWay1: "طی مسیر دوره اول",
  onWay2: "طی مسیر دوره دوم",
  work: "کار",
  onWay: "طی مسیر",
  sleep: "خواب",
  optionalUnemployment: "بیکاری اختیاری",
  mandatoryUnemployment: "بیکاری اجباری",
};

const tailwind = [
  "bg-yellow-500",
  "bg-purple-500",
  "bg-emerald-500",
  "bg-rose-500",
  "bg-sky-500",
  "bg-orange-500",
  "bg-teal-500",
  "bg-yellow-600",
  "bg-purple-600",
  "bg-emerald-600",
  "bg-rose-600",
  "bg-sky-600",
  "bg-orange-600",
  "bg-teal-600",
  "border-yellow-700",
  "border-purple-700",
  "border-emerald-700",
  "border-rose-700",
  "border-sky-700",
  "border-orange-700",
  "border-teal-700",
  "ring-yellow-700",
  "ring-purple-700",
  "ring-emerald-700",
  "ring-rose-700",
  "ring-sky-700",
  "ring-orange-700",
  "ring-teal-700",
  "text-yellow-500",
  "text-purple-500",
  "text-emerald-500",
  "text-rose-500",
  "text-sky-500",
  "text-orange-500",
  "after:!bg-yellow-900",
  "after:!bg-purple-900",
  "after:!bg-emerald-900",
  "after:!bg-rose-900",
  "after:!bg-sky-900",
  "after:!bg-orange-900",
  "ring-gray-700",
];

export const workTheme = {
  work: "emerald",
  mission: "sky",
  remote: "purple",
  leave: "yellow",
  temporary: "rose",
};

export default function WorksComponent({
  userId,
  forDate,
  justSee = false,
}: {
  userId?: number;
  forDate?: number;
  justSee?: boolean;
}) {
  const updateNotification = useNotificationsUpdate();
  const generateTimeFrame = useGenerateTimeFrame();
  const location: any = useLocation();
  const serverDate = useRecoilValue(ServerDateState);
  const [user, setUser] = useRecoilState(UserDataState);
  const [selectedWork, setSelectedWork] = useRecoilState(SelectedWorkAtom);
  const [overflow, setOverflow] = useState({});
  const [works, setWorks] = useRecoilState(WorksInDayAtom);
  const [lock, setLock] = useRecoilState(LockWorkPageAtom);
  // const [timeLog, setTimeLog] = useRecoilState(TransmissionTimeAtom)
  const [modalType, setModalType] = useRecoilState(ModalTypeAtom);
  const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
  const [modalParams, setModalParams] = useRecoilState(ModalParamsAtom);
  const [timeFrame, setTimeFrame] = useRecoilState(TimeFrameAtom);
  const [timeSpanDuration, setTimeSpanDuration] =
    useRecoilState(TimeSpanDurationAtom);
  const [globalLoading, setGlobalLoading] = useRecoilState(GlobalLoadingAtom);
  const [exitTemp, setExitTemp] = useRecoilState(ExitTempAtom);
  const [unClosedDays, setUnClosedDays] = useRecoilState(UnClosedDaysState);

  const [inOutLogs, setInOutLogs] = useRecoilState(InOutLogAtom);
  const [dailyLog, setDailyLog] = useRecoilState(DailyLogAtom);
  const [pins, setPins] = useRecoilState(PinsStateAtom);
  const minimumWorkTimeDuration = useRecoilValue(
    MinimumWorkTimeDurationStateAtom
  );

  const [date, setDate] = useRecoilState(DateState);
  const isNow =
    moment(serverDate * 1000).format("jYYYY/jMM/jDD") ===
    moment(date * 1000).format("jYYYY/jMM/jDD");
  const { theme, setTheme } = useTheme();
  const today = todayCalculate(serverDate);
  const [open, setOpen] = useState(false);
  const ref = useRef<any>(null);
  const [file, setFile] = useState<any>(null);
  const [image, setImage] = useState<any>(null);
  const dayName = moment(date * 1000);
  const [showAggregationTime, setShowAggregationTime] = useRecoilState(
    ShowAggregationTimeStateAtom
  );
  const [hasInCompony, setHasInCompony] = useRecoilState(hasInComponyAtom);
  const [unClosedModalOpen, setUnClosedModalOpen] = useState(false);
  const [beforeMonthIsClosed, setBeforeMonthIsClosed] = useRecoilState(
    BeforeMonthIsClosedStateAtom
  );
  const cloneWorksRef = useRef<WorkDto[]>([]);
  // const [image, takeScreenshot] = useScreenshot();

  // const [timeLogs, setTimeLogs] = useRecoilState(TransmissionTimesAtom)
  const lastDaySelected =
    Number(
      JSON.parse(localStorage.getItem("pfk_time_tracker") as string)
        ?.defaultDate
    ) || serverDate;
  const [day, setDay] = useState<DayValue>({
    year: Number(moment((forDate ?? lastDaySelected) * 1000).format("jYYYY")),
    month: Number(moment((forDate ?? lastDaySelected) * 1000).format("jMM")),
    day: Number(moment((forDate ?? lastDaySelected) * 1000).format("jDD")),
  });
  const [loading, setLoading] = useState<any>(false);

  const changeDayHandler = (type: any) => {
    if (justSee) return;
    const datSelected =
      type === "plus"
        ? date + oneDay
        : type === "minus"
        ? date - oneDay
        : today;
    const zeroTime = ServerDateToDay(datSelected);
    setDate(zeroTime);
    localStorage.setItem(
      "pfk_time_tracker",
      JSON.stringify({ defaultDate: String(zeroTime) })
    );
    setDay({
      year: Number(moment(zeroTime * 1000).format("jYYYY")),
      month: Number(moment(zeroTime * 1000).format("jMM")),
      day: Number(moment(zeroTime * 1000).format("jDD")),
    });
    getDailyLogHandler(zeroTime);
    setSelectedWork(0);
    setWorks([]);
    // setTimeFrame([])
    setPins([]);
    setInOutLogs([]);
  };

  const datePickerChangeHandler = (dateSelected: any) => {
    if (justSee) return;
    setDay(dateSelected);
    const jalaaliDate = `${dateSelected?.year}-${dateSelected?.month}-${dateSelected?.day}`;
    const solar =
      moment(jalaaliDate, "jYYYY-jM-jD").format("YYYY-MM-DD") + "T00:00:00";
    const todayTimestamp = new Date(solar).getTime() / 1000;
    setDate(todayTimestamp);
    localStorage.setItem(
      "pfk_time_tracker",
      JSON.stringify({ defaultDate: String(todayTimestamp) })
    );
    getDailyLogHandler(todayTimestamp);
    setSelectedWork(0);
    setWorks([]);
    // setTimeFrame([])
    setPins([]);
    setInOutLogs([]);
  };

  const submitWorkForm = (item: any, cb?: Function) => {
    // submitWorkFormType[workType](work)
    const {
      id,
      confirmed,
      start,
      end,
      type,
      idleTime,
      projects,
      missionType,
      notes,
    } = item;
    const workData: any = {
      id,
      confirmed: true,
      date,
      start,
      end: end === date ? date + oneDay : end,
      type,
      idleTime,
      projects,
      notes,
      missionType,
    };

    // if (type === "mission") workData.missionType = missionType

    updateWork(id, workData)
      .then((res) => {
        const { data } = res;
        const cloneWorks = [...works];
        const findWorkIndex = cloneWorks.findIndex((w) => w.id === data.id);
        const updatedWork = works.find((w) => w.id === modalParams);
        cloneWorks.splice(findWorkIndex, 1);
        cloneWorks.splice(findWorkIndex, 0, data);
        const sortWorks = cloneWorks.sort((a, b) => a.start - b.start);
        setWorks(sortWorks);
        setDailyLog({
          ...dailyLog,
          finalized: 0,
        });
        let notifType;
        if (
          updatedWork?.type === "mission" ||
          updatedWork?.type === "remote" ||
          updatedWork?.type === "work"
        )
          notifType = updatedWork?.type;
        updateNotification({ dailyLogId: dailyLog.id, type: notifType });
        generateTimeFrame(inOutLogs, sortWorks, date, pins);
        cb && cb(workData);
        // const exitTime = findExitTime({
        //     exit: timeLog.exit,
        //     works: sortWorks,
        //     serverDate,
        //     date,
        //     logicExit: user.logicExit
        // })
        // const loopFrom = timestampToHours(timeLog.enter as number)
        // const loopTo = +exitTime < 24 ? +exitTime+1 : +exitTime
        // const time_frame: string[] = []
        // for (let i=loopFrom; i<=loopTo; i++) {
        //     time_frame.push(`${String(i).padStart(2, "0")}:00`)
        // }
        // setTimeFrame(time_frame)
        // const spanDuration = (loopTo - loopFrom) * 60 * 60 // * 1000
        // setTimeSpanDuration(spanDuration)
        // const  data: WorkDto[] = Array.from(res.data)
        // const newWorks = data.sort((a, b) => a.start - b.start)
        // setSelectedWork(0)
        // setWorks(newWorks)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteHandler = (id: number) => {
    setIsModalOpen(true);
    setModalType("DeleteWork");
    setModalParams(id);
    setSelectedWork(0);
  };

  // new
  // const generateTimeFrame = ({from, to}: {from: number, to: number}) => {
  //     const time_frame: string[] = []
  //     for (let i=from; i<=to; i++) {
  //         time_frame.push(`${String(i).padStart(2, "0")}:00`)
  //     }
  //     const spanDuration = (to - from) * 60 * 60 // * 1000
  //     setTimeSpanDuration(spanDuration)
  //     setTimeFrame(time_frame)
  // }
  const getDailyLogHandler = (todayTimestamp: number) => {
    setGlobalLoading(true);
    getDailyLog(todayTimestamp, userId)
      .then((res) => {
        const { InOutLogs, Works, Pins, ...otherData } = res.data;
        const sortInOutLog = ([...InOutLogs] as InOutLogDto[]).sort(
          (a, b) => a.enter - b.enter
        );
        const pinSort = Pins.sort((a: any, b: any) => a.time - b.time);
        setInOutLogs(sortInOutLog);
        generateTimeFrame(sortInOutLog, Works, todayTimestamp, pinSort);
        setPins(pinSort);
        setDailyLog(otherData);
        const sortWorks = (Works as WorkDto[]).sort(
          (a, b) => a.start - b.start
        );
        setWorks(sortWorks);
        setGlobalLoading(false);
        if (otherData.finalized) {
          setLock(true);
        } else {
          setLock(false);
        }
      })
      .catch((err) => {
        console.log(err);
        generateTimeFrame(inOutLogs, works, date, pins);
        setInOutLogs([]);
        setDailyLog({});
        setGlobalLoading(false);
        setLock(false);
      });
  };

  const dayFinalizeHandler = async () => {
    setSelectedWork(0);
    setShowAggregationTime("down");
    setIsModalOpen(true);
    setModalType("CloseDayAccess");
  };

  useEffect(() => {
    // if (!selectedWork) return
    const overflow: any = {};
    works.forEach((work) => {
      overflow[work.id] = false;
    });
    setOverflow({
      ...overflow,
    });
    const timeOut = setTimeout(() => {
      setOverflow({
        ...overflow,
        [selectedWork]: true,
      });
    }, 300);
    // const description = document.getElementById(`description_${selectedWork}`)
    // description?.scrollIntoView()
    return () => {
      clearInterval(timeOut);
    };
  }, [selectedWork]);

  // useEffect(() => {
  //     // const inDate = ServerDateToDay(lastDaySelected)
  //     // getTimeHandler(inDate)
  //     const startTime = timestampToHours([...inOutLogs].shift()?.enter ?? 0)
  //     const endTime = timestampToHours(([...inOutLogs].pop()?.exit ?? (date + (24*60*60))), date)
  //     generateTimeFrame({
  //         from: startTime,
  //         to: endTime < 24 ? endTime + 1 : endTime
  //     })
  //     // setDate(inDate)
  //     // console.log(inOutLogs)
  // }, [inOutLogs])

  useEffect(() => {
    // if (userId) return;
    setInOutLogs([]);
    setPins([]);
    setDailyLog([]);
    setWorks([]);
    // setDay({
    //     year: Number(moment(lastDaySelected*1000).format('jYYYY')),
    //     month: Number(moment(lastDaySelected*1000).format('jMM')),
    //     day: Number(moment(lastDaySelected*1000).format('jDD')),
    // })
    const inDate = forDate ?? ServerDateToDay(lastDaySelected);
    getDailyLog(inDate, userId)
      .then((res) => {
        const { InOutLogs, Works, Pins, ...otherData } = res.data;
        const sortInOutLog = ([...InOutLogs] as InOutLogDto[]).sort(
          (a, b) => a.enter - b.enter
        );
        const pinSort = Pins.sort((a: any, b: any) => a.time - b.time);
        setInOutLogs(sortInOutLog);
        generateTimeFrame(sortInOutLog, Works, inDate, pinSort);
        setPins(pinSort);
        setDailyLog(otherData);
        const sortWorks = (Works as WorkDto[]).sort(
          (a, b) => a.start - b.start
        );
        setWorks(sortWorks);
        setGlobalLoading(false);
        const isNotificationMode = location?.state?.isNotificationMode ?? false;
        if (otherData?.finalized && !justSee && !isNotificationMode) {
          const today = ServerDateToDay(serverDate);
          setDate(today);
          localStorage.setItem(
            "pfk_time_tracker",
            JSON.stringify({ defaultDate: String(today) })
          );
          setDay({
            year: Number(moment(serverDate * 1000).format("jYYYY")),
            month: Number(moment(serverDate * 1000).format("jMM")),
            day: Number(moment(serverDate * 1000).format("jDD")),
          });
          setLock(true);
          getDailyLogHandler(today);
        } else {
          setDate(inDate);
          setLock(false);
        }
        // setDate(inDate)
        // setLock(false)
      })
      .catch((err) => {
        console.log(err);
        generateTimeFrame(inOutLogs, works, date, pins);
        setInOutLogs([]);
        setDailyLog({});
        setGlobalLoading(false);
        setLock(false);
      });

    if (!justSee) {
      getUnclosedDays()
        .then((res) => {
          const unclosedDays = res.data.rows;
          setUnClosedDays(unclosedDays);
          if (!!unclosedDays.length || !beforeMonthIsClosed) {
            setUnClosedModalOpen(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // getDailyLogHandler(inDate)
    // if (dailyLog?.finalized) {
    //     const today = ServerDateToDay(serverDate)
    //     setDate(today)
    //     localStorage.setItem("pfk_time_tracker", JSON.stringify({defaultDate: String(today)}))
    //     setDay({
    //         year: Number(moment(serverDate*1000).format('jYYYY')),
    //         month: Number(moment(serverDate*1000).format('jMM')),
    //         day: Number(moment(serverDate*1000).format('jDD')),
    //     })
    // }
    // else {
    //     setDate(inDate)
    // }
  }, []);

  // useEffect(() => {
  //     const cloneWorks = [...works]
  //     console.log(works.length && cloneWorks?.sort((a, b) => a.start - b.start))
  // }, [works])
  // console.log(works)

  const activeFinalizedButton = () => {
    const existInOutLog = !!inOutLogs.length;
    const existWork = !!works.length;
    const existOpenInOutLog = !!inOutLogs.find((inOut) => !inOut.exit);
    return (!existInOutLog && !existWork) || existOpenInOutLog;
  };

  const pinTimeHandler = () => {
    addPin()
      .then((res) => {
        setPins(res.data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addNewWork = (start: number) => {
    let cloneWorks = [...works];
    cloneWorksRef.current = [...works];
    const beforeWork = [...cloneWorks]
      .sort((a, b) => b.end - a.end)
      ?.find((work) => work.end < start);
    const afterWork = [...cloneWorks]
      .sort((a, b) => a.end - b.end)
      ?.find((work) => work.start > start);
    const beforeIndex = [...cloneWorks].findIndex(
      (work) => work.id === (beforeWork?.id ?? 0)
    );
    const findInOutLog = inOutLogs.find(
      (i) => i.enter <= start && (i.exit ?? date + oneDay) >= start
    );
    let inOutLogId: number | null = null;
    let type: WorkType = "work";
    let missionType: MissionType = null;

    if (findInOutLog) {
      inOutLogId = findInOutLog.id;
      const insideWorks =
        works.filter((iw) => iw.inOutLogId === findInOutLog.id) ?? [];
      const insideWorksDetails = insideWorks.map((iwd) => ({
        start: iwd.start,
        end: iwd.end,
      }));
      const allInsideLog = [
        { start: findInOutLog.enter, end: findInOutLog.enter },
        ...insideWorksDetails,
        {
          start: findInOutLog.exit ?? date + oneDay,
          end: findInOutLog.exit ?? date + oneDay,
        },
      ];
      const beforeWork =
        allInsideLog
          .sort((a, b) => b.end - a.end)
          ?.find((work) => work.end < start)?.end ?? findInOutLog.enter;
      const afterWork =
        allInsideLog
          .sort((a, b) => a.end - b.end)
          ?.find((work) => work.start > start)?.start ??
        findInOutLog.exit ??
        date + oneDay;
      if (afterWork - beforeWork < minimumWorkTimeDuration) {
        Toast("error", "فضای کافی برای ایجاد کار موجود نمیباشد");
        setGlobalLoading(false);
        return;
      }
      const conflictEndWithAfter = start + minimumWorkTimeDuration - afterWork;
      if (conflictEndWithAfter > 0) {
        start -= conflictEndWithAfter;
      }
    } else {
      if (user.remoteEligibility) {
        type = "remote";
      } else if (user.missionEligibility) {
        type = "mission";
        missionType = "work";
      } else {
        setGlobalLoading(false);
        return Toast("error", "شما دسترسی ایجاد ماموریت و دورکاری را ندارید");
      }
      const outsideWork = works.filter((iw) => !iw.inOutLogId) ?? [];
      const outsideWorkDetails = outsideWork.map((iwd) => ({
        start: iwd.start,
        end: iwd.end,
        type: "outsideWork",
      }));
      const inOutLogsDetails = inOutLogs.map((iwd) => ({
        start: iwd.enter,
        end: iwd.exit ?? date + oneDay,
        type: "inOutLogs",
      }));
      const allLogs = [...outsideWorkDetails, ...inOutLogsDetails].sort(
        (a, b) => a.start - b.start
      );
      const beforeWork =
        allLogs.sort((a, b) => b.end - a.end)?.find((work) => work.end < start)
          ?.end ?? date;
      const afterWork =
        allLogs
          .sort((a, b) => a.end - b.end)
          ?.find((work) => work.start > start)?.start ?? date + oneDay;
      if (afterWork - beforeWork < minimumWorkTimeDuration) {
        setGlobalLoading(false);
        Toast("error", "فضای کافی برای ایجاد کار موجود نمیباشد");
        return;
      }
      const conflictEndWithAfter = start + minimumWorkTimeDuration - afterWork;
      if (conflictEndWithAfter > 0) {
        start -= conflictEndWithAfter;
      }
    }

    const newWork: WorkDto = {
      id: -1,
      date,
      type,
      missionType,
      start,
      inOutLogId,
      end: start + minimumWorkTimeDuration,
      projects: null,
      idleTime: 0,
      notes: "",
      confirmed: false,
    };
    cloneWorks.splice(beforeIndex + 1, 0, newWork);
    // setWorks(cloneWorks)

    const { id, ...requestWork } = newWork;
    addWork(requestWork)
      .then((res) => {
        const { newWorksList, lastWork } = res.data;
        const data: WorkDto[] = Array.from(newWorksList);
        const newWorks = data.sort((a, b) => a.start - b.start);
        setSelectedWork(0);
        setWorks(newWorks);

        setSelectedWork(lastWork.id);
        setOverflow({
          [lastWork.id]: true,
        });
        generateTimeFrame(inOutLogs, newWorks, date, pins);
        setDailyLog({
          ...dailyLog,
          finalized: 0,
        });
        setGlobalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setWorks(cloneWorksRef.current);
        setGlobalLoading(false);
      });
  };

  const addWorkByButton = () => {
    if (justSee) return;
    if (lock) return Toasts.lockWorks();
    const existWork = works.find(
      (work) => work.start <= serverDate && work.end > serverDate
    );
    if (existWork)
      return Toast("error", "برای زمان فعلی قبلا رویداد کاری ثبت شده");
    setGlobalLoading(true);
    addNewWork(serverDate);
  };

  useEffect(() => {
    if (!timestampToMinutes(serverDate)) {
      generateTimeFrame(inOutLogs, works, date, pins);
    }
  }, [serverDate]);

  const ChangeDayBtn = [
    { title: "روز بعد", icon: "plus-lg", type: "plus", disabled: isNow },
    { title: "امروز", icon: "arrow-down", type: "toDay", disabled: isNow },
    { title: "روز قبل", icon: "dash", type: "minus", disabled: false },
  ];

  return (
    <div
      ref={ref}
      id="workPageDetails"
      className={`flex flex-col h-full gap-1 md:gap-2 p-3 md:p-2 lg:p-0 dark:bg-gray-700 bg-gray-200 ${
        globalLoading ? "" : ""
      }`}
    >
      <div className="flex-none md:bg-gray-100 md:dark:bg-gray-600 rounded-md flex flex-col md:flex-row items-center justify-between p-0 md:p-1 lg:z-[210] gap-1">
        <div className="w-full flex flex-1 items-center justify-between gap-1 ">
          <div className="flex items-center justify-center gap-1 ">
            <Button
              // title={btn.title}
              icon="chevron-double-right"
              color={theme === "dark" ? "dark" : "light"}
              expand="block"
              onClick={() => changeDayHandler("plus")}
              loading={loading}
              description="روز بعد"
              // outLineSize="1px"
              // outlineColor={theme === "dark" ? "black" : "lightgray"}
              disabled={isNow || justSee}
              classNames={{
                container:
                  "!w-8 !h-8 !p-0 !px-2 !m-0 !rounded-md duration-200 !flex !items-center !justify-center",
                section:
                  "!text-sm !flex !items-center !justify-center !overflow-hidden",
              }}
            />
            <DatePicker
              value={day as any}
              onChange={datePickerChangeHandler}
              shouldHighlightWeekends
              maximumDate={{
                year: Number(moment(serverDate * 1000).format("jYYYY")),
                month: Number(moment(serverDate * 1000).format("jMM")),
                day: Number(moment(serverDate * 1000).format("jDD")),
              }}
              locale="fa" // add this
              calendarClassName="!text-gray-800 !shadow-2xl !shadow-[rgba(0,0,0,0.4)]"
              calendarTodayClassName="!border-2 !border-gray-500"
              inputClassName="
                                !w-28 !h-8 !rounded-md duration-200 outline-none !text-lg cursor-pointer
                                !bg-gray-300 !text-gray-700 !border-gray-300 hover:!bg-gray-400 hover:!text-gray-800
                                dark:!bg-gray-800 dark:!text-gray-200 dark:!border-gray-900 dark:hover:!bg-gray-900 !p-0 !m-0
                            "
              calendarSelectedDayClassName="!bg-gray-700 !text-gray-100 dark:!bg-gray-200 dark:!text-gray-700"
              containerClassName="w-fit"
            />
            <TextTag className="w-[70px] h-8 grid place-content-center rounded-md bg-gray-300 dark:bg-gray-800 transition-colors">
              {dayName.format("dddd")}
            </TextTag>
            <Button
              // title={btn.title}
              icon="arrow-down"
              color={theme === "dark" ? "dark" : "light"}
              expand="block"
              onClick={() => changeDayHandler("toDay")}
              loading={loading}
              description="امروز"
              // outLineSize="1px"
              // outlineColor={theme === "dark" ? "black" : "lightgray"}
              disabled={isNow || justSee}
              classNames={{
                container:
                  "!w-8 !h-8 !p-0 !px-2 !m-0 !rounded-md duration-200 !flex !items-center !justify-center",
                section:
                  "!text-sm !flex !items-center !justify-center !overflow-hidden",
              }}
            />
            <Button
              // title={btn.title}
              icon="chevron-double-left"
              color={theme === "dark" ? "dark" : "light"}
              expand="block"
              onClick={() => changeDayHandler("minus")}
              loading={loading}
              description="روز قبل"
              // outLineSize="1px"
              // outlineColor={theme === "dark" ? "black" : "lightgray"}
              disabled={justSee}
              classNames={{
                container:
                  "!w-8 !h-8 !p-0 !px-2 !m-0 !rounded-md duration-200 !flex !items-center !justify-center",
                section:
                  "!text-sm !flex !items-center !justify-center !overflow-hidden",
              }}
            />

            {ServerDateToDay(serverDate) === date && (
              <Button
                color={theme === "dark" ? "dark" : "light"}
                title="ایجاد رویداد کاری"
                // color={"primary"}
                expand="block"
                icon="plus-lg"
                // onClick={() => changeDayHandler(btn.type)}
                loading={loading}
                description="ایجاد رویداد کاری برای زمان فعلی"
                // outLineSize="1px"
                // outlineColor={theme === "dark" ? "black" : "lightgray"}
                // direction={btn.type === "plus" ? "row_reverse" : "row"}
                disabled={justSee}
                onClick={addWorkByButton}
                classNames={{
                  container:
                    "!h-8 !flex-none !p-0 !px-0 !m-0 !rounded-md duration-200 !hidden sm:!flex !items-center !justify-center",
                  section:
                    "!text-sm !flex !items-center !justify-center !overflow-hidden",
                  icon: "ps-1",
                }}
              />
            )}
          </div>
          <div className="w-fit flex flex-none items-center md:justify-end justify-between gap-1">
            {/* <TextTag className='w-[220px] h-8 grid place-content-center rounded-md bg-gray-300 dark:bg-gray-800 transition-colors'>
                            {`بسته شده در تاریخ: ${"1403/12/30"}`}
                        </TextTag> */}
            {ServerDateToDay(serverDate) === date && (
              <Button
                // icon={btn.icon as any}
                // color={theme === "dark" ? "dark" : "light"}
                color={theme === "dark" ? "dark" : "light"}
                expand="block"
                icon="pin-angle-fill"
                // onClick={() => changeDayHandler(btn.type)}
                loading={loading}
                description="پین زمان فعلی"
                // outLineSize="1px"
                // outlineColor={theme === "dark" ? "black" : "lightgray"}
                // direction={btn.type === "plus" ? "row_reverse" : "row"}
                disabled={justSee}
                onClick={pinTimeHandler}
                classNames={{
                  container:
                    "!w-8 !h-8 !flex-none !p-0 !px-0 !m-0 !rounded-md duration-200 !flex !items-center !justify-center",
                  section:
                    "!text-sm !flex !items-center !justify-center !overflow-hidden",
                }}
              />
            )}
            {(!!unClosedDays.length || !beforeMonthIsClosed) && (
              <Button
                // icon={btn.icon as any}
                icon="exclamation-triangle-fill"
                // color={theme === "dark" ? "dark" : "light"}
                title="هشدارها"
                color={theme === "dark" ? "danger" : "danger"}
                expand="block"
                // onClick={() => changeDayHandler(btn.type)}
                loading={loading}
                description="لیست روزهایی که نبسته اید"
                disabled={justSee}
                // outLineSize="1px"
                // outlineColor={theme === "dark" ? "black" : "lightgray"}
                // direction={btn.type === "plus" ? "row_reverse" : "row"}
                onClick={() => setUnClosedModalOpen(true)}
                classNames={{
                  container:
                    "!w-8 xl:!w-fit !h-8 !flex-none !p-0 xl:!ps-2 !m-0 !rounded-md duration-200 !hidden md:!flex !items-center !justify-center animate-tada xl:animate-none",
                  section:
                    "!text-sm !items-center !justify-center !overflow-hidden !hidden xl:!flex",
                  icon: "xl:animate-tada animate-none",
                }}
              />
            )}
            <Button
              title={
                !dailyLog?.finalized
                  ? "بستن روز"
                  : `بسته شده در : ${moment(dailyLog?.finalized * 1000)
                      .format("jYYYY/jM/jD - HH:mm")
                      .split(" - ")
                      .reverse()
                      .join(" - ")}`
              }
              // icon={btn.icon as any}
              // color={theme === "dark" ? "dark" : "light"}
              color={
                dailyLog?.finalized
                  ? theme === "dark"
                    ? "dark"
                    : "light"
                  : "success"
              }
              expand="block"
              // onClick={() => changeDayHandler(btn.type)}
              loading={loading}
              disabled={activeFinalizedButton() || justSee}
              description="برای بستن روز، حتما باید یک ورود و خروج یا یک کار ایجاد کنید"
              // outLineSize="1px"
              // outlineColor={theme === "dark" ? "black" : "lightgray"}
              // direction={btn.type === "plus" ? "row_reverse" : "row"}
              onClick={dayFinalizeHandler}
              classNames={{
                container:
                  "!w-full md:!w-[220px] !h-8 !p-0 !px-0 !m-0 !rounded-md duration-200 !hidden md:!flex !items-center !justify-center",
                section:
                  "!text-sm !flex !items-center !justify-center !overflow-hidden",
              }}
            />
            <Button
              // icon={btn.icon as any}
              // color={theme === "dark" ? "dark" : "light"}
              color={theme === "dark" ? "dark" : "light"}
              expand="block"
              icon={lock || justSee ? "lock-fill" : "unlock-fill"}
              // onClick={() => changeDayHandler(btn.type)}
              loading={loading}
              description="قفل کودک"
              disabled={justSee}
              // outLineSize="1px"
              // outlineColor={theme === "dark" ? "black" : "lightgray"}
              // direction={btn.type === "plus" ? "row_reverse" : "row"}
              onClick={() => setLock(!lock)}
              classNames={{
                container:
                  "!w-8 !h-8 !flex-none !p-0 !px-0 !m-0 !rounded-md duration-200 !hidden md:!flex !items-center !justify-center",
                section:
                  "!text-sm !flex !items-center !justify-center !overflow-hidden",
              }}
            />
          </div>
        </div>
      </div>

      {/* <TimePicker 
                name="idleTime"
                // label="در اختیار کلی"
                value={`${TimestampToTime(ServerDateToDay(serverDate) + (timeLog.idleTime ?? 0))}`}
                picker={{
                    onAccept: (e) => {console.log(e)},
                }}
            /> */}
      <div className="flex gap-2 flex-auto">
        <WorksBar
          selectedWork={selectedWork}
          setSelectedWork={setSelectedWork}
          setOverflow={setOverflow}
          timeFrame={timeFrame}
          setTimeFrame={setTimeFrame}
          timeSpanDuration={timeSpanDuration}
          setTimeSpanDuration={setTimeSpanDuration}
          findExitTime={findExitTime}
          justSee={justSee}
          addNewWork={addNewWork}
          addWorkByButton={addWorkByButton}
          // generateTimeFrame={generateTimeFrame}
        />
        {/* <Projects /> */}
        <DescriptionsMobile
          selectedWork={selectedWork}
          setSelectedWork={setSelectedWork}
          overflow={overflow}
          submitWorkForm={submitWorkForm}
          deleteHandler={deleteHandler}
          forDate={forDate}
          justSee={justSee}
        />
        <Descriptions
          works={works}
          setWorks={setWorks}
          selectedWork={selectedWork}
          setSelectedWork={setSelectedWork}
          overflow={overflow}
          submitWorkForm={submitWorkForm}
          deleteHandler={deleteHandler}
          globalLoading={globalLoading}
          forDate={forDate}
          justSee={justSee}
        />
      </div>

      {hasInCompony && (
        <div className="flex gap-1 md:!hidden h-10">
          <Button
            // {...button}
            title={
              <div className="w-full flex items-center justify-between">
                <span>دراختیار اضافه(درشرکت)</span>
                <span>
                  {TimestampToTime(
                    ServerDateToDay(serverDate) + (dailyLog?.idleTime ?? 0)
                  )}
                </span>
              </div>
            }
            color={theme === "dark" ? "dark" : "light"}
            disabled={justSee}
            onClick={() => setOpen(true)}
            classNames={{
              container:
                "!flex-1 !h-8 !border !border-gray-400 dark:!border-gray-900 !rounded-md !flex !items-center !justify-center !p-2 lg:!z-[200]",
              section:
                "!text-sm !flex !items-center !justify-center !overflow-hidden",
            }}
          />
          {/* <div className='flex-1 flex items-center justify-between gap-2 bg-gray-300 dark:bg-gray-800 border border-gray-400 dark:border-gray-900 rounded-md px-3'>
                        <TextTag>
                            جمع دراختیار کارها
                        </TextTag>
                        <TextTag>
                            23:59
                        </TextTag>
                    </div> */}
          <Dialog open={open} setOpen={setOpen} dir="ltr">
            <TimePickerTouch
              // label={"در اختیار کلی"}
              onAccess={(time: string) => {
                const idleTime = timeToDuration(time);
                updateTimeLog(date, { idleTime })
                  .then((res) => {
                    const { confirmed, enter, exit, idleTime, finalized } =
                      res.data;
                    setDailyLog({
                      // confirmed,
                      enter,
                      exit,
                      idleTime,
                      finalized,
                    });
                    setOpen(false);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
              onCancel={() => {
                setOpen(false);
              }}
              value={TimestampToTime(
                ServerDateToDay(serverDate) + (dailyLog?.idleTime ?? 0)
              )}
            />
          </Dialog>
        </div>
      )}
      <div className="w-full flex items-start justify-center gap-1 md:hidden">
        {(!!unClosedDays.length || !beforeMonthIsClosed) && (
          <Button
            icon="exclamation-triangle-fill"
            // color={theme === "dark" ? "dark" : "light"}
            // title="روز های بسته نشده"
            color={theme === "dark" ? "danger" : "danger"}
            expand="block"
            // onClick={() => changeDayHandler(btn.type)}
            loading={loading}
            description="لیست روزهایی که نبسته اید"
            disabled={justSee}
            // outLineSize="1px"
            // outlineColor={theme === "dark" ? "black" : "lightgray"}
            // direction={btn.type === "plus" ? "row_reverse" : "row"}
            onClick={() => setUnClosedModalOpen(true)}
            classNames={{
              container:
                "!w-8 !h-8 !flex-none !p-0 !px-0 !m-0 !rounded-md duration-200 !flex !items-center !justify-center animate-tada",
              section:
                "!text-sm !flex !items-center !justify-center !overflow-hidden",
            }}
          />
        )}
        <Button
          title={
            !dailyLog?.finalized
              ? "بستن روز"
              : `بسته شده در : ${moment(dailyLog?.finalized * 1000)
                  .format("jYYYY/jM/jD - HH:mm")
                  .split(" - ")
                  .reverse()
                  .join(" - ")}`
          }
          // icon={btn.icon as any}
          // color={theme === "dark" ? "dark" : "light"}
          color={
            dailyLog?.finalized
              ? theme === "dark"
                ? "dark"
                : "light"
              : "success"
          }
          expand="block"
          // onClick={() => changeDayHandler(btn.type)}
          loading={loading}
          // disabled={!dailyLog?.enter || !dailyLog?.exit}
          disabled={activeFinalizedButton() || justSee}
          description="برای بستن روز، حتما باید یک ورود و خروج یا یک کار ایجاد کنید"
          // outLineSize="1px"
          // outlineColor={theme === "dark" ? "black" : "lightgray"}
          // direction={btn.type === "plus" ? "row_reverse" : "row"}
          onClick={dayFinalizeHandler}
          classNames={{
            container:
              "!flex-auto md:!w-[220px] !h-8 !p-0 !px-0 !m-0 !rounded-md duration-200 !flex !items-center !justify-center",
            section:
              "!text-[0.7rem] !flex !items-center !justify-center !overflow-hidden",
          }}
        />
        <Button
          title="تجمیع ساعات"
          // icon={btn.icon as any}
          color={theme === "dark" ? "dark" : "light"}
          expand="block"
          // onClick={() => changeDayHandler(btn.type)}
          loading={loading}
          // disabled={!dailyLog?.enter}
          // outLineSize="1px"
          // outlineColor={theme === "dark" ? "black" : "lightgray"}
          // direction={btn.type === "plus" ? "row_reverse" : "row"}
          onClick={() => {
            setIsModalOpen(true);
            setModalType("WorkTimeAggregations");
          }}
          classNames={{
            container:
              "!w-20 sm:!w-32 !h-8 !p-0 !px-0 !m-0 !border !border-gray-400 dark:!border-gray-900 !rounded-md duration-200 !flex !items-center !justify-center",
            section:
              "!text-[0.7rem] !flex !items-center !justify-center !overflow-hidden whitespace-nowrap",
          }}
        />
        <Button
          // icon={btn.icon as any}
          // color={theme === "dark" ? "dark" : "light"}
          color={theme === "dark" ? "dark" : "light"}
          expand="block"
          icon={lock ? "lock-fill" : "unlock-fill"}
          // onClick={() => changeDayHandler(btn.type)}
          loading={loading}
          description="قفل کودک"
          disabled={justSee}
          // outLineSize="1px"
          // outlineColor={theme === "dark" ? "black" : "lightgray"}
          // direction={btn.type === "plus" ? "row_reverse" : "row"}
          onClick={() => setLock(!lock)}
          classNames={{
            container:
              "!w-8 !h-8 !flex-none !p-0 !px-0 !m-0 !rounded-md duration-200 !flex !items-center !justify-center",
            section:
              "!text-sm !flex !items-center !justify-center !overflow-hidden",
          }}
        />
      </div>

      <Dialog open={unClosedModalOpen} setOpen={setUnClosedModalOpen}>
        <div className="w-[calc(100vw-40px)] max-w-[700px] flex flex-col bg-gray-100 dark:bg-gray-700 rounded-md ring-1 ring-rose-500 ring-opacity-50">
          <div className="flex-none flex flex-row-reverse items-center justify-between p-2 border-gray-200 dark:border-gray-800">
            <Button
              icon="x-lg"
              color="gray"
              fill="transparent"
              iconWidth="1.3rem"
              iconHeight="1.3rem"
              iconColor={theme === "dark" ? "lightgray" : "gray"}
              iconHover={theme === "dark" ? "white" : "black"}
              onClick={() => setUnClosedModalOpen(false)}
              classNames={{
                container: "!w-5 !h-5",
              }}
            />
          </div>
          <div className="flex-auto flex flex-col items-stretch justify-start">
            <UnClosedMonth setOpen={setUnClosedModalOpen} />
            <UnClosedDay
              setOpen={setUnClosedModalOpen}
              setDay={setDay}
              getDailyLogHandler={getDailyLogHandler}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const UnClosedMonth = (data: any) => {
  const { theme } = useTheme();
  const [beforeMonthIsClosed, setBeforeMonthIsClosed] = useRecoilState(
    BeforeMonthIsClosedStateAtom
  );
  if (beforeMonthIsClosed) return null;
  return (
    <div className="flex-none flex flex-col items-center justify-start border-b border-dashed border-gray-300 dark:border-gray-800 gap-5 pb-10">
      <TextTag as="h4" className="p-4 !text-rose-500">
        لطفا ماه قبلی خود را ببندید
      </TextTag>
      <Link
        to={"/"} 
        title="برو به داشبورد"
        className="w-32 h-12 flex items-center justify-center bg-gray-300 hover:bg-gray-400 dark:bg-gray-800 dark:hover:bg-gray-900 rounded-md transition-all duration-200 text-gray-800 dark:text-gray-200"
      >
        برو به داشبورد
      </Link>
    </div>
  );
};

const UnClosedDay = ({ setOpen, setDay, getDailyLogHandler }: any) => {
  const [date, setDate] = useRecoilState(DateState);
  const [selectedWork, setSelectedWork] = useRecoilState(SelectedWorkAtom);
  const [works, setWorks] = useRecoilState(WorksInDayAtom);
  const [pins, setPins] = useRecoilState(PinsStateAtom);
  const [inOutLogs, setInOutLogs] = useRecoilState(InOutLogAtom);
  const [unClosedDays, setUnClosedDays] = useRecoilState(UnClosedDaysState);
  if (!unClosedDays.length) return null
  return (
    <div className="flex-auto flex flex-col items-center justify-start gap-5 pb-10">
      <TextTag as="h4" className="p-4 !text-rose-500">
       لیست روزهای کاری که نبسته اید
      </TextTag>
      <div className="flex flex-wrap items-start justify-center gap-3 p-2">
        {unClosedDays.map((day) => (
          <div
            key={day.id}
            onClick={() => {
              setDate(day.date);
              localStorage.setItem(
                "pfk_time_tracker",
                JSON.stringify({ defaultDate: String(day.date) })
              );
              setDay({
                year: Number(moment(day.date * 1000).format("jYYYY")),
                month: Number(moment(day.date * 1000).format("jMM")),
                day: Number(moment(day.date * 1000).format("jDD")),
              });
              getDailyLogHandler(day.date);
              setSelectedWork(0);
              setWorks([]);
              // setTimeFrame([])
              setPins([]);
              setInOutLogs([]);
              setOpen(false);
              // get(day.date)
            }}
            className="w-32 border border-gray-400 dark:border-gray-800 bg-gray-300 dark:bg-gray-800 !bg-opacity-50 hover:!bg-opacity-100 transition-all duration-200 rounded-md p-4 cursor-pointer"
          >
            <TextTag>
              {moment(day?.date * 1000)
                .format("jYYYY/jM/jD")
                .split(" - ")
                .reverse()
                .join(" - ")}
            </TextTag>
          </div>
        ))}
      </div>
    </div>
  );
};
