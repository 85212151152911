import { useState } from "react"
import { useTheme } from "next-themes"
import moment from "moment-jalaali"
import { durationsToTime, DurationToTime, TimestampToTime, TimeToTimestamp } from "../../../Utils/convertTime"
import { faWorkType } from "../../Works/Works"
import TextTag from "../../../Components/Common/TextTag/TextTag"
import Button from "../../../Components/Common/Button/Button"
import Dialog from "../../../Components/Common/Dialog/Dialog"
import { getDailyLog } from "../../../axios/DailyLog.api"
import WorksComponent from "../../../Components/Works/Works"
import Textarea from "../../../Components/Common/Textarea/Textarea"
import { Link, useNavigate } from "react-router-dom";
import { DateState, enterExitSensitivityStateAtom } from "../../../Recoil/Atoms"
import { useRecoilState } from "recoil"
import { notificationType } from "../../../Recoil/confirmsAtom"

const faTitle = {
    inOutLog: "زمان ورود و خروج",
    remote: "دورکاری",
    mission: "ماموریت",
    work: "رویداد کاری در شرکت"
}
const BeCorrected = ({
    notification,
    // onAccept,
    // onUnAccept,
    // loading,
}: {
    notification: notificationType;
    // onAccept: (notif: any) => void;
    // onUnAccept: (notif: any, message: string) => void;
    // loading: boolean;
}) => {
    const faNotificationType = {
        WorkModel: 'کار',
        DailyLogsModel: "ساعت ورود و خروج",
    }
    const navigate = useNavigate()
    const [enterExitSensitivityState, setEnterExitSensitivityState] = useRecoilState(enterExitSensitivityStateAtom)
    const [accordion, setAccordion] = useState(false)
    const {theme, setTheme} = useTheme()
    const [modalOpen, setModalOpen] = useState(false)
    const [showMessageBox, setShowMessageBox] = useState(false)
    const [message, setMessage] = useState("")
    const [date, setDate] = useRecoilState(DateState)
    

    const workEvent = (notification: notificationType) => {
        const {type, date, dailyLog, user} = notification
        const workDuration = dailyLog.Works?.reduce((a: any, b: any) => b.type === type ? (a + (b.end - b.start)) : a , 0)
        return (
            <div className="flex items-start justify-start flex-wrap gap-2">
                <span>{faTitle[type as "inOutLog"]}</span>
                <span>|</span>
                <span className="whitespace-nowrap">{`برای تاریخ : ${moment(date*1000).format('jYYYY/jM/jD')}`}</span>
                <span>|</span>
                <span className="whitespace-nowrap">{`به مدت ${durationsToTime(workDuration)}`}</span>
            </div>
        )
    }
    
    const description = {
            inOutLog: (notification: notificationType) => {
                const {type, date, dailyLog, user} = notification
                const cloneInOutLog = dailyLog ? [...dailyLog.InOutLogs].sort((a, b) => a.enter - b.enter) : []
                const firstEnter = [...cloneInOutLog]?.shift()?.enter
                const lastEnter = [...cloneInOutLog]?.pop()?.exit
                let entryTimeExceeded = 0
                let exitTimeExceeded = 0
                const userLogicEnter = TimeToTimestamp(user?.logicEnter, date) 
                const userLogicExit = TimeToTimestamp(user?.logicExit, date)
                if (firstEnter < (userLogicEnter - enterExitSensitivityState)) entryTimeExceeded = ((userLogicEnter) - firstEnter)
                if (lastEnter > (userLogicExit + enterExitSensitivityState)) exitTimeExceeded = (lastEnter - (userLogicExit))
                return (
                    <div className="flex items-start justify-start flex-wrap gap-2">
                        <span>{faTitle[type as "inOutLog"]}</span>
                        <span>|</span>
                        <span className="whitespace-nowrap">{`برای تاریخ : ${moment(date*1000).format('jYYYY/jM/jD')}`}</span>
                        <span>|</span>
                        <span className="whitespace-nowrap">{`ورود : ${TimestampToTime(firstEnter)}`}</span>
                        <span>|</span>
                        <span className="whitespace-nowrap">{`خروج ${TimestampToTime(lastEnter)}`}</span>
                        <span>|</span>
                        <span className="whitespace-nowrap">{`به مدت ${durationsToTime(lastEnter - firstEnter)}`}</span>
                        <span>|</span>
                        <span className="whitespace-nowrap">{`ورود زود هنگام : ${durationsToTime(entryTimeExceeded)}`}</span>
                        <span>|</span>
                        <span className="whitespace-nowrap">{`خروج دیر هنگام : ${durationsToTime(exitTimeExceeded)}`}</span>
                    </div>
                )
            },
        remote: workEvent,
        mission: workEvent,
        work: workEvent,
        // WorkModel: ({
        //     type, 
        //     date, 
        //     start, 
        //     end,
        //     user
        // }: {
        //     type?: any;
        //     date?: any;
        //     start?: any;
        //     end?: any;
        //     dailyLog?: any;
        //     user?: any;
        // }) => {
        //     return <div className="flex items-start justify-start flex-wrap gap-2 h-fit">
        //         <span>کار</span>
        //         <span>|</span>
        //         <span className="whitespace-nowrap">{`با نوع : ${faWorkType[type]}`}</span>
        //         <span>|</span>
        //         <span className="whitespace-nowrap">{`برای تاریخ : ${moment(date*1000).format('jYYYY/jM/jD')}`}</span>
        //         <span>|</span>
        //         <span className="whitespace-nowrap">{`شروع : ${TimestampToTime(start)}`}</span>
        //         <span>|</span>
        //         <span className="whitespace-nowrap">{`پایان : ${TimestampToTime(end)}`}</span>
        //         <span>|</span>
        //         <span className="whitespace-nowrap">{`به مدت : ${DurationToTime(end - start, date)}`}</span>
        //     </div>
        // },
        // DailyLogsModel: ({
        //     type, 
        //     date, 
        //     start, 
        //     end,
        //     dailyLog,
        //     user
        // }: {
        //     type?: any;
        //     date?: any;
        //     start?: any;
        //     end?: any;
        //     dailyLog?: any;
        //     user?: any;
        // }) => {
        //     const cloneInOutLog = dailyLog ? [...dailyLog.InOutLogs].sort((a, b) => a.enter - b.enter) : []
        //     const firstEnter = [...cloneInOutLog]?.shift()?.enter
        //     // console.log(cloneInOutLog);
        //     const lastEnter = [...cloneInOutLog]?.pop()?.exit
        //     let entryTimeExceeded = 0
        //     let exitTimeExceeded = 0
        //     const mmmm = 7200
        //     const userLogicEnter = TimeToTimestamp(user?.logicEnter, date) 
        //     const userLogicExit = TimeToTimestamp(user?.logicExit, date)
        //     if (firstEnter < (userLogicEnter - mmmm)) entryTimeExceeded = ((userLogicEnter) - firstEnter)
        //     if (lastEnter > (userLogicExit + mmmm)) exitTimeExceeded = (lastEnter - (userLogicExit))
        //     // console.log(userLogicEnter, userLogicExit)
        //     // console.log(firstEnter, lastEnter)
        //     // console.log(entryTimeExceeded, exitTimeExceeded)
        //     // console.log(user)
        //     return <div className="flex items-start justify-start flex-wrap gap-2">
        //         <span>زمان ورود و خروج</span>
        //         <span>|</span>
        //         <span className="whitespace-nowrap">{`برای تاریخ : ${moment(date*1000).format('jYYYY/jM/jD')}`}</span>
        //         <span>|</span>
        //         <span className="whitespace-nowrap">{`ورود : ${TimestampToTime(firstEnter)}`}</span>
        //         <span>|</span>
        //         <span className="whitespace-nowrap">{`خروج ${TimestampToTime(lastEnter)}`}</span>
        //         <span>|</span>
        //         <span className="whitespace-nowrap">{`به مدت ${DurationToTime(lastEnter - firstEnter, date)}`}</span>
        //         {/* <span>|</span>
        //         <span className="whitespace-nowrap">{`تجاوز از ورود : ${DurationToTime(entryTimeExceeded, date)}`}</span>
        //         <span>|</span>
        //         <span className="whitespace-nowrap">{`تجاوز از خروج : ${DurationToTime(exitTimeExceeded, date)}`}</span> */}
        //     </div>
        // }
    } 

    return (
        <Link
            to={`/worksReport`}
            state={{ isNotificationMode: "notification" }}
            onClick={(e) => {
                localStorage.setItem("pfk_time_tracker", JSON.stringify({defaultDate: String(notification.date)}))
                // navigate("/worksReport")
            }}
            tabIndex={0}
            className={`mt-2 border border-orange-600 border-opacity-50 rounded-md overflow-hidden`}
        >
            <div 
                className={`h-20 flex items-stretch justify-between p-1 bg-orange-500 bg-opacity-20 hover:bg-opacity-30 transition-all`}
            >
                <div className="flex-auto flex items-center justify-start gap-2">
                    <TextTag as="span" className="w-28 flex-none select-none text-[0.8rem] border-e border-orange-600 border-opacity-50 text-center p-1 text-orange-700 dark:text-orange-400">اصلاح کنید</TextTag>
                    <div className="flex flex-col items-stretch justify-center">
                        <div className="flex items-center justify-start">
                            <TextTag 
                                as="span" 
                                className="flex-auto flex items-center justify-start select-none text-[0.8rem] p-2 overflow-auto h-full text-orange-700 dark:text-orange-400"
                            >
                                {description?.[notification.type as "inOutLog"](notification)}
                            </TextTag>
                        </div>
                        <TextTag className="p-2 text-orange-700 dark:text-orange-400">
                            {`پیام ناظر : ${notification.message}`}
                        </TextTag>
                    </div>
                </div>
            </div>
        
        </Link>
    )
}

export default BeCorrected