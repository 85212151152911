import { ChangeEvent, useEffect, useState } from "react"
import Icon from "../Common/Icon/Icon"
import Input, { InputProps } from "../Common/Input/Input"
import { useTheme } from "next-themes"
import Button from "../Common/Button/Button"
import TextTag from "../Common/TextTag/TextTag"
import { useRecoilState } from "recoil"
import { UserDataState } from "../../Recoil/Atoms"
import ReactSelect from "../Common/ReactSelect/ReactSelect"
import { FiltersInput } from "../../pages/Users/Users.page"
import Switch from "../Common/Switch/Switch"
import validation from "./Validation"
import { changePassword } from "../../axios/Users.api"
import { Toast } from "../../Utils/Toast"

const ProfileComponent = () => {
    return (
        <div className="w-full h-full bg-gray-100 dark:bg-gray-600 border border-gray-300 dark:border-gray-800 lg:rounded-md overflow-hidden">
            <div className="h-72 md:h-96 flex flex-col items-stretch justify-center transition-[height]">
                <div 
                    className="flex-auto"
                    style={{
                        backgroundImage: "url('./profileBg.jpg')",
                        backgroundPosition: "center",
                        backgroundSize: "cover"
                    }}
                ></div>
                <div className="h-0 flex-none grid place-content-center">
                    <div className="w-[200px] h-[200px] rounded-full grid place-content-center">
                        <Icon
                            name="person-fill"
                            width="200px"
                            height="200px"
                            color="#4b5563"
                            classNames="bg-gray-200 dark:bg-gray-700 rounded-full ring-8 ring-gray-100 dark:ring-gray-600 shadow-2xl"
                        />
                    </div>
                </div>
            </div>
            <div className="py-10">
                <UserData />
                <ChangePasswordForm />
            </div>
        </div>
    )
}

const UserData = () => {
    const {theme, setTheme} = useTheme()
    const [userData, setUserData] = useRecoilState(UserDataState)

    const RoleOptions = [
        {value: "admin", label: "ادمین"},
        {value: "manager", label: "مدیر"},
        {value: "user", label: "کاربر"}
    ]
    const ApprovalOptions = [
        {value: "auto", label: "اتوماتیک"},
        {value: "supervisor", label: "ناظر"},
    ]
    
    const publicFilter: FiltersInput = [
        {
            type: Input, 
            props: {
                label: "نام کاربری",
                value: userData.username,
                name: "username",
                onChange: () => {},
                placeholder: "نام کاربری",
                disabled: true,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "نقش کاربر",
                name: "role",
                theme: theme,
                // onChange: (e: any, name: any) => {},
                value: RoleOptions.find(op => op.value === userData.role),
                isDisabled: true,
                className: "h-[80px]",
                isSearchable: false,
                // act: act.role,
                // error: "رول را وارد کنید",
                options: RoleOptions
            }
        },
        {
            type: Input, 
            props: {
                label: "شماره موبایل",
                name: "phone",
                type: "number",
                placeholder: "شماره موبایل",
                value: userData.phone,
                // onChange: inputChangeHandler,
                min: 0,
                disabled: true,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400 no-spinner",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: Input, 
            props: {
                label: "چت آیدی",
                name: "chatId",
                type: "number",
                placeholder: "چت آیدی",
                value: userData.chatId,
                // onChange: inputChangeHandler,
                min: 0,
                disabled: true,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400 no-spinner",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
    ]
    const delayPermissions = [
        {
            type: Input,
            props: {
                label: "تعداد روزهای مجاز تغییر گزارش روزانه",
                name: "accessTimeChange",
                placeholder: "برحسب روز",
                // onChange: inputChangeHandler,
                value: userData.accessTimeChange,
                type: "number",
                min: 0,
                disabled: true,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: Input,
            props: {
                label: "تعداد روزهای مجاز تاخیر ثبت گزارش",
                name: "timeRecordDelays",
                placeholder: "برحسب روز",
                // onChange: inputChangeHandler,
                value: userData.timeRecordDelays,
                type: "number",
                min: 0,
                disabled: true,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
    ]
    const confirms = [
        {
            type: ReactSelect,
            props: {
                label: "روش تایید گزارش کار حضوری",
                name: "inComponyApprovalMethod",
                theme: theme,
                // onChange: selectChangeHandler,
                value: ApprovalOptions.find(op => op.value === userData.inComponyApprovalMethod),
                isSearchable: false,
                className: "h-[80px]",
                isDisabled: true,
                options: ApprovalOptions
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "روش تایید گزارش ماموریت",
                name: "missionApprovalMethod",
                theme: theme,
                // onChange: selectChangeHandler,
                value: ApprovalOptions.find(op => op.value === userData.missionApprovalMethod),
                isSearchable: false,
                className: "h-[80px]",
                isDisabled: true,
                options: ApprovalOptions
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "روش تایید گزارش دورکاری",
                name: "remoteApprovalMethod",
                theme: theme,
                // onChange: selectChangeHandler,
                value: ApprovalOptions.find(op => op.value === userData.remoteApprovalMethod),
                isSearchable: false,
                className: "h-[80px]",
                isDisabled: true,
                options: ApprovalOptions
            }
        },
    ]
    const workPermission: FiltersInput = [
        {
            type: Switch,
            props: {
                label: "مجوز دورکاری",
                name: "remoteEligibility",
                // onClick: switchChangeHandler,
                value: userData.remoteEligibility,
                disabled: true,
                classNames: {
                    container: "h-[50px] px-2 pb-2"
                }
            }
        },
        {
            type: Switch,
            props: {
                label: "مجوز ماموریت رفتن",
                name: "missionEligibility",
                // onClick: switchChangeHandler,
                value: userData.missionEligibility,
                disabled: true,
                classNames: {
                    container: "h-[50px] px-2 pb-2"
                }
            }
        },
    ]
    
    return (
        <div className="opacity-70">
            <TextTag as="h4" className="text-start p-3 mt-20"> اطلاعات هویتی </TextTag>
            <div className="w-full items-center justify-center text-md grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-3 px-2 py-4 border-b border-dashed border-gray-300 dark:border-gray-700">
                {publicFilter.map((ele, index) => 
                    <ele.type key={index} {...ele.props}/>
                )}
            </div>
            <TextTag as="h4" className="text-start p-3"> مجوزها </TextTag>
            <div className="w-full items-center justify-center text-md grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-3 px-2 py-4 ">
                {delayPermissions.map((ele, index) => 
                    <ele.type key={index} {...ele.props}/>
                )}
            </div>
            <div className="w-full items-center justify-center text-md grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-3 px-2 py-4">
                {confirms.map((ele, index) => 
                    <ele.type key={index} {...ele.props}/>
                )}
            </div>
            <div className="w-full items-center justify-center text-md grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-3 px-2 py-4 border-b border-dashed border-gray-300 dark:border-gray-700">
                {workPermission.map((ele, index) => 
                    <ele.type key={index} {...ele.props}/>
                )}
            </div>
        </div>
    )
}

const ChangePasswordForm = () => {
    const {theme, setTheme} = useTheme()
    const [submitLoading, setSubmitLoading] = useState(false)
    const [values, setValues] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    })
    const [error, setError] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    })
    const [act, setAct] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
    })

    const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const blurHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setAct({
            ...act,
            [e.target.name]: true
        })
    }

    const inputs = [
        {
            type: Input, 
            props: {
                label: "رمز عبور فعلی",
                name: "oldPassword",
                value: values.oldPassword,
                error: error.oldPassword,
                act: act.oldPassword,
                type: "password",
                autoComplete: "off",
                placeholder: "رمز عبور فعلی",
                onChange: inputChangeHandler,
                onBlur: blurHandler,
                theme: theme,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: Input, 
            props: {
                label: "رمز عبور جدید",
                name: "newPassword",
                value: values.newPassword,
                error: error.newPassword,
                act: act.newPassword,
                type: "password",
                autocomplete: "off",
                placeholder: "رمز عبور جدید",
                onChange: inputChangeHandler,
                onBlur: blurHandler,
                theme: theme,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: Input, 
            props: {
                label: "تکرار رمز عبور",
                name: "confirmPassword",
                value: values.confirmPassword,
                error: error?.confirmPassword,
                act: act.confirmPassword,
                type: "password",
                autocomplete: "off",
                placeholder: "تکرار رمز عبور",
                onChange: inputChangeHandler,
                onBlur: blurHandler,
                theme: theme,
                classNames: {
                    container: "h-[80px]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
    ]

    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const hasError = !!Object.values(error).join("")
        if (hasError) {
            return setAct({
                oldPassword: true,
                newPassword: true,
                confirmPassword: true
            })
        }
        setSubmitLoading(true)
        changePassword(values)
            .then((res) => {
                setValues({
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                })
                setAct({
                    oldPassword: false,
                    newPassword: false,
                    confirmPassword: false
                })
                Toast("success", "رمز شما با موفقیت تغییر کرد")
                setSubmitLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setSubmitLoading(false)
            })
    }


    useEffect(() => {
        setError(validation(values))
    }, [values])


    return (
        <form 
            onSubmit={submitHandler}
            autoComplete="off"
            className="flex flex-col items-stretch justify-between" 
        >
                <TextTag as="h4" className="text-start p-3"> تغییر رمز عبور </TextTag>
            <div className="w-full items-center justify-center text-md grid grid-cols-1 md:grid-cols-3 gap-3 px-2 py-4">
                {inputs.map((ele, index) => 
                    <ele.type key={index} {...ele.props}/>
                )}
            </div>
            <Button
                    // icon="x-lg"
                    color="primary"
                    title="ارسال"
                    direction="row_reverse"
                    // onClick={title === "create" ? createHandler : editHandler}
                    type="submit"
                    loading={submitLoading}
                    classNames={{
                        container: "!w-32 !h-10 m-auto !rounded-[4px] !px-3 transition-all mt-10",
                        section: "!text-lg !overflow-hidden"
                    }}
                />
        </form>
    )
}

export default ProfileComponent