import { useRecoilState, useRecoilValue } from "recoil";
import { PinsStateAtom, TimeFrameAtom, TimeSpanDurationAtom, WorkDto } from "../Recoil/Works.atom";
import { ServerDateToDay, timestampToHours, TimeToTimestamp } from "../Utils/convertTime";
import { DateState, ServerDateState, UserDataState } from "../Recoil/Atoms";
import { InOutLogDto } from "../Recoil/InOutLogAtom";
const oneDay = 24*60*60


// const useGenerateTimeFrame = () => {
//     const [timeSpanDuration, setTimeSpanDuration] = useRecoilState(TimeSpanDurationAtom)
//     const [timeFrame, setTimeFrame] = useRecoilState(TimeFrameAtom)
//     return ({from, to}: {from: number, to: number}) => {
//         const time_frame: string[] = []
//         for (let i=from; i<=to; i++) {
//             time_frame.push(`${String(i).padStart(2, "0")}:00`)
//         }
//         const spanDuration = (to - from) * 60 * 60 // * 1000
//         setTimeSpanDuration(spanDuration)
//         setTimeFrame(time_frame)
//     };
// };

const useGenerateTimeFrame = () => {
    // const [date, setDate] = useRecoilState(DateState)
    const serverDate = useRecoilValue(ServerDateState)
    const [userData, setUserData] = useRecoilState(UserDataState)
    const [timeSpanDuration, setTimeSpanDuration] = useRecoilState(TimeSpanDurationAtom)
    const [timeFrame, setTimeFrame] = useRecoilState(TimeFrameAtom)
    const [pins, setPins] = useRecoilState(PinsStateAtom)
    return (inOutLog: InOutLogDto[], works: WorkDto[], isInDate: number, pins: any) => {
        let from = 0;
        let to = 24;
        const NowDate = ServerDateToDay(serverDate)
        const existMissionOrRemote = !!works?.find((w: any) => w.type === "mission" || w.type === "remote")
        if (!existMissionOrRemote && !!inOutLog.length) {
            const endOfDay = isInDate + oneDay
            const firstEnterLog = ([...inOutLog].shift()?.enter) ?? 0
            const lastEnterLog = [...inOutLog].pop()?.enter ?? 0
            const firstPin = ([...pins].shift()?.time) ?? endOfDay
            const lastPin = [...pins].pop()?.time ?? 0
            if (isInDate === NowDate) {
                const userLogicExitToSpa = TimeToTimestamp(userData.logicExit, isInDate) ?? endOfDay
                const logicExitToHours = timestampToHours(userLogicExitToSpa)
                const addTwoHoursToLogicExit = logicExitToHours === 24 ? 24 : (logicExitToHours + 1)
                const userLogicExitHoursToTimestamp = (addTwoHoursToLogicExit * 60 * 60) + NowDate
                const addFourHoursToLastEnter = lastEnterLog + (4*60*60)
                const minimumDistanceBetweenLastEnterAndWorkFrameEnd = addFourHoursToLastEnter > endOfDay ? endOfDay : addFourHoursToLastEnter
                const lastExitLog = [...inOutLog].pop()?.exit ?? userLogicExitHoursToTimestamp
                const inComponyWorks = works.filter(work => work.inOutLogId)
                const sortInComponyWorks = inComponyWorks.sort((a, b) => a.start - b.start)
                const lastInComponyWork = [...sortInComponyWorks].pop()?.end ?? NowDate
                const minimumStart = timestampToHours(Math.min(firstEnterLog, firstPin, serverDate))
                const maximumEnd = timestampToHours(Math.max(lastExitLog, lastPin, serverDate, minimumDistanceBetweenLastEnterAndWorkFrameEnd, lastInComponyWork), isInDate)
                from = minimumStart
                to = maximumEnd < 24 ? maximumEnd + 1 : maximumEnd
            } else {
                const lastExitLog = [...inOutLog].pop()?.exit ?? endOfDay
                const minimumStart = timestampToHours(Math.min(firstEnterLog, firstPin))
                const maximumEnd = timestampToHours(Math.max(lastExitLog, lastPin), isInDate)
                from = minimumStart
                to = maximumEnd < 24 ? maximumEnd + 1 : maximumEnd
            }
        }
        const time_frame: string[] = []
        for (let i=from; i<=to; i++) {
            time_frame.push(`${String(i).padStart(2, "0")}:00`)
        }
        const spanDuration = (to - from) * 60 * 60 // * 1000
        setTimeSpanDuration(spanDuration)
        setTimeFrame(time_frame)
    };
};


export default useGenerateTimeFrame;
