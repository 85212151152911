import BaseDatePicker, {
  Day,
  DayRange,
  DayValue,
  Calendar,
} from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import TextTag from "../TextTag/TextTag";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
// import "./DatePicker.scss"

interface BaseDatePickerProps
  extends React.ComponentProps<typeof BaseDatePicker> {
  label?: ReactNode;
  containerClassName?: string;
  // value: DayRange
}

const DatePicker: React.FC<BaseDatePickerProps> = (props) => {
  const { label, containerClassName, ...otherProps } = props;
  return (
    <div className={twMerge("w-full h-full flex flex-col items-stretch justify-start", containerClassName ?? "")}>
      {label && <TextTag className="pb-1 px-2">{label}</TextTag>}
      <BaseDatePicker
        {...otherProps}
        // value={{from: {day: 1, month: 1, year: 1}, to: {day: 1, month: 1, year: 1}}}
      />
    </div>

    // <div className='translate-y-32'>
    //     <Calendar
    //         // value={selectedDay}
    //         // onChange={setSelectedDay}
    //         shouldHighlightWeekends
    //         locale="fa" // add this
    //     />
    // </div>
  );
};

export default DatePicker;
