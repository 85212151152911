import { useEffect, useState } from "react"
import { FiltersInput } from "../../pages/Users/Users.page"
import Input from "../Common/Input/Input"
import ReactSelect from "../Common/ReactSelect/ReactSelect"
import { useTheme } from "next-themes"
import TextTag from "../Common/TextTag/TextTag"
import Button from "../Common/Button/Button"
import { ModalOpenAtom } from "../../Recoil/Atoms"
import { useRecoilState } from "recoil"
import { GetEmployees } from "../../axios/Users.api"
import { EmployeesAtom, SearchEmployeesParamsAtom } from "../../Recoil/users.atom"
import { typeSearchEmployeesParamsType } from "../../pages/Users/Types"

const UserFilter = () => {

    const {theme, setTheme} = useTheme()
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    const [loading, setLoading] = useState(false)
    const [searchEmployeesParams, setSearchEmployeesParams] = useRecoilState(SearchEmployeesParamsAtom)
    const defaultSelectValue = {value: "null", label: "بی تاثیر"}
    const [employees, setEmployees] = useRecoilState(EmployeesAtom)
    const [values, setValues] = useState({
        username: "",
        chatId: "",
        phone: "",
        role: defaultSelectValue,
        active: defaultSelectValue,
        accessTimeChange: "",
        timeRecordDelays: "",
        remoteEligibility: defaultSelectValue,
        missionEligibility: defaultSelectValue,
        inComponyApprovalMethod: defaultSelectValue,
        remoteApprovalMethod: defaultSelectValue,
        missionApprovalMethod: defaultSelectValue,
        logicEnter: defaultSelectValue,
        logicExit: defaultSelectValue,
    })
    

    const inputChangeHandler = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const selectChangeHandler = (e: any, name: string) => {
        setValues({
            ...values,
            [name]: e
        })
    }

    const timeOptions = [
        defaultSelectValue,
        {value: "00:00", label: "00:00"},
        {value: "01:00", label: "01:00"},
        {value: "02:00", label: "02:00"},
        {value: "03:00", label: "03:00"},
        {value: "04:00", label: "04:00"},
        {value: "05:00", label: "05:00"},
        {value: "06:00", label: "06:00"},
        {value: "07:00", label: "07:00"},
        {value: "08:00", label: "08:00"},
        {value: "09:00", label: "09:00"},
        {value: "10:00", label: "10:00"},
        {value: "11:00", label: "11:00"},
        {value: "12:00", label: "12:00"},
        {value: "13:00", label: "13:00"},
        {value: "14:00", label: "14:00"},
        {value: "15:00", label: "15:00"},
        {value: "16:00", label: "16:00"},
        {value: "17:00", label: "17:00"},
        {value: "18:00", label: "18:00"},
        {value: "19:00", label: "19:00"},
        {value: "20:00", label: "20:00"},
        {value: "21:00", label: "21:00"},
        {value: "22:00", label: "22:00"},
        {value: "23:00", label: "23:00"},
    ]
    const roleOption = [
        defaultSelectValue,
        {value: "manager", label: "مدیر"},
        {value: "user", label: "کاربر"},
    ]
    const activeOption = [
        defaultSelectValue,
        {value: "1", label: "فعال"},
        {value: "0", label: "غیر فعال"},
    ]
    const remoteEligibilityOption = [
        defaultSelectValue,
        {value: "1", label: "دارد"},
        {value: "0", label: "ندارد"},
    ]
    const missionEligibilityOption = [
        defaultSelectValue,
        {value: "1", label: "دارد"},
        {value: "0", label: "ندارد"},
    ]
    const inComponyApprovalMethodOptions = [
        defaultSelectValue,
        {value: "auto", label: "اتوماتیک"},
        {value: "supervisor", label: "ناظر"},
    ]
    const remoteApprovalMethodOptions = [
        defaultSelectValue,
        {value: "auto", label: "اتوماتیک"},
        {value: "supervisor", label: "ناظر"},
    ]
    const missionApprovalMethodOptions = [
        defaultSelectValue,
        {value: "auto", label: "اتوماتیک"},
        {value: "supervisor", label: "ناظر"},
    ]
    const publicFilter: FiltersInput = [
        {
            type: Input, 
            props: {
                label: "نام کاربری",
                name: "username",
                placeholder: "نام کاربری",
                value: values.username,
                onChange: inputChangeHandler,
                classNames: {
                    container: "h-[unset]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: Input,
            props: {
                label: "چت آیدی",
                name: "chatId",
                placeholder: "چت آیدی",
                value: values.chatId,
                onChange: inputChangeHandler,
                classNames: {
                    container: "h-[unset]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: Input,
            props: {
                label: "شماره موبایل",
                name: "phone",
                placeholder: "شماره موبایل",
                value: values.phone,
                onChange: inputChangeHandler,
                classNames: {
                    container: "h-[unset]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "نقش کاربر",
                name: "role",
                theme: theme,
                onChange: selectChangeHandler,
                isSearchable: false,
                value: values.role,
                options: roleOption
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "وضعیت فعال",
                name: "active",
                theme: theme,
                onChange: selectChangeHandler,
                isSearchable: false,
                value: values.active,
                options: activeOption,
            }
        },
    ]
    const permissionFilter: FiltersInput = [
        {
            type: Input,
            props: {
                label: "تعداد روزهای مجاز تاخیر ثبت گزارش روزانه",
                name: "timeRecordDelays",
                placeholder: "برحسب روز",
                onChange: inputChangeHandler,
                value: values.timeRecordDelays,
                classNames: {
                    container: "h-[unset]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: Input,
            props: {
                label: "تعداد روزهای مجاز تغییر گزارش روزانه",
                name: "accessTimeChange",
                placeholder: "برحسب روز",
                onChange: inputChangeHandler,
                value: values.accessTimeChange,
                classNames: {
                    container: "h-[unset]",
                    inputBox: "border-gray-400 dark:border-gray-900 h-[38px] rounded-[4px]",
                    input: "bg-gray-200 dark:bg-gray-800 placeholder:text-gray-400 dark:placeholder:text-gray-400",
                    label: "text-sm font-thin mb-1"
                }
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "مجوز دورکاری",
                name: "remoteEligibility",
                isSearchable: false,
                theme: theme,
                onChange: selectChangeHandler,
                value: values.remoteEligibility,
                options: remoteEligibilityOption,
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "مجوز ماموریت رفتن",
                name: "missionEligibility",
                isSearchable: false,
                theme: theme,
                onChange: selectChangeHandler,
                value: values.missionEligibility,
                options: missionEligibilityOption,
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "روش تایید گزارش کار حضوری",
                name: "inComponyApprovalMethod",
                isSearchable: false,
                theme: theme,
                onChange: selectChangeHandler,
                value: values.inComponyApprovalMethod,
                options: inComponyApprovalMethodOptions,
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "روش تایید گزارش ماموریت",
                name: "missionApprovalMethod",
                isSearchable: false,
                theme: theme,
                onChange: selectChangeHandler,
                value: values.missionApprovalMethod,
                options: missionApprovalMethodOptions,
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "روش تایید گزارش دورکاری",
                name: "remoteApprovalMethod",
                isSearchable: false,
                theme: theme,
                onChange: selectChangeHandler,
                value: values.remoteApprovalMethod,
                options: remoteApprovalMethodOptions,
            }
        },

    ]
    const logicFilter: FiltersInput = [
        {
            type: ReactSelect,
            props: {
                label: "مرز ساعت ورود زودهنگام",
                name: "logicEnter",
                isSearchable: false,
                theme: theme,
                onChange: selectChangeHandler,
                value: values.logicEnter,
                options: timeOptions
            }
        },
        {
            type: ReactSelect,
            props: {
                label: "مرز ساعت خروج دیرهنگام",
                name: "logicExit",
                isSearchable: false,
                theme: theme,
                onChange: selectChangeHandler,
                value: values.logicExit,
                options: timeOptions
            }
        }

    ]
    
    const getEmployeesHandler = (newParams: typeSearchEmployeesParamsType) => {
        setLoading(true)
        GetEmployees(newParams)
        .then((res) => {
            console.log(res)
            setLoading(false)
            setEmployees(res.data.rows)
        })
        .catch((err) => {
            setLoading(false)
            // console.log(err)
        })
    }

    const searchHandler = () => {
        const newParams: any = {}
        Object.entries(values).forEach(([key, value]) => {
            if (typeof value === "object" && value.value !== "null") {
                newParams[key] = value.value
            } else if (typeof value === "string" && value.trim()) {
                newParams[key] = value
            }
        })
        setSearchEmployeesParams(newParams)
        getEmployeesHandler(newParams)
        setIsModalOpen(false)
    }

    useEffect(() => {
        const username = ("username" in searchEmployeesParams && searchEmployeesParams.username) ? searchEmployeesParams.username : ""
        const role = roleOption.find(key => key.value === searchEmployeesParams.role) ?? defaultSelectValue;
        const chatId = ("chatId" in searchEmployeesParams && searchEmployeesParams.chatId) ? searchEmployeesParams.chatId : ""
        const phone = ("phone" in searchEmployeesParams && searchEmployeesParams.phone) ? searchEmployeesParams.phone : ""
        const active = activeOption.find(key => key.value === searchEmployeesParams.active) ?? defaultSelectValue;
        const accessTimeChange = ("accessTimeChange" in searchEmployeesParams && searchEmployeesParams.accessTimeChange) ? searchEmployeesParams.accessTimeChange : ""
        const timeRecordDelays = ("timeRecordDelays" in searchEmployeesParams && searchEmployeesParams.timeRecordDelays) ? searchEmployeesParams.timeRecordDelays : ""
        const inComponyApprovalMethod = inComponyApprovalMethodOptions.find(key => key.value === searchEmployeesParams.inComponyApprovalMethod) ?? defaultSelectValue;
        const remoteApprovalMethod = remoteApprovalMethodOptions.find(key => key.value === searchEmployeesParams.remoteApprovalMethod) ?? defaultSelectValue;
        const missionApprovalMethod = missionApprovalMethodOptions.find(key => key.value === searchEmployeesParams.missionApprovalMethod) ?? defaultSelectValue;
        const remoteEligibility = remoteEligibilityOption.find(key => key.value === searchEmployeesParams.remoteEligibility) ?? defaultSelectValue;
        const missionEligibility = missionEligibilityOption.find(key => key.value === searchEmployeesParams.missionEligibility) ?? defaultSelectValue;
        const logicEnter = timeOptions.find(key => key.value === searchEmployeesParams.logicEnter) ?? defaultSelectValue;
        const logicExit = timeOptions.find(key => key.value === searchEmployeesParams.logicExit) ?? defaultSelectValue;
        setValues({
            ...values,
            username,
            role,
            chatId,
            phone,
            active,
            accessTimeChange,
            timeRecordDelays,
            inComponyApprovalMethod,
            remoteApprovalMethod,
            missionApprovalMethod,
            remoteEligibility,
            missionEligibility,
            logicEnter,
            logicExit,
        })
    }, [])

    return (
        <div className="w-[calc(100vw-20px)] xl:w-[1200px] bg-white dark:bg-gray-600 rounded-md">
            <div className="border-b border-gray-300 dark:border-gray-800 flex items-center justify-between">
                <TextTag as="h4" className="w-fit p-2 text-gray-500 dark:text-gray-300">
                    فیلتر کاربران
                </TextTag>
                <Button
                    icon="x-lg"
                    color="tertiary"
                    shape="pill"
                    fill="transparent"
                    onClick={() => setIsModalOpen(false)}
                    classNames={{
                        container: "!w-fit !h-fit me-2"
                    }}
                />
            </div>
            
            <form className="w-full p-2">
                <div className="w-full items-center justify-center text-md grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-5 gap-y-10 px-2 py-5 border-b border-dashed border-gray-300 dark:border-gray-700">
                    {publicFilter.map((ele, index) => 
                        <ele.type key={index} {...ele.props}/>
                    )}
                </div>

                <div className="w-full items-center justify-center text-md grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-5 gap-y-10 px-2 py-5 border-b border-dashed border-gray-300 dark:border-gray-700">
                    {permissionFilter.map((ele, index) => 
                        <ele.type key={index} {...ele.props}/>
                    )}
                </div>

                <div className="w-full items-center justify-center text-md grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-5 gap-y-10 px-2 py-5">
                    {logicFilter.map((ele, index) => 
                        <ele.type key={index} {...ele.props}/>
                    )}
                </div>
                
                <Button
                    color="primary"
                    title="جستوجو"
                    direction="row_reverse"
                    onClick={searchHandler}
                    loading={loading}
                    classNames={{
                        container: "!w-full !h-12 mt-10 !rounded-[4px] !px-3 transition-all",
                        section: "!text-lg"
                    }}
                />
            </form>
        </div>
    )
}

export default UserFilter