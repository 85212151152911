import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import AllRoutes from './app.routes';
import NavbarComponent from './Components/Navbar/Navbar.component';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AccessPeriodToCloseTheMonthStateAtom, AccessToCloseMonthBeforeEndOfMonthStateAtom, DateState, enterExitSensitivityStateAtom, MinimumDistanceBetweenInOutLogsStateAtom, MinimumInOutLogTimeDurationStateAtom, MinimumWorkTimeDurationStateAtom, MissionTimeFrameStateAtom, ServerDateState, UserDataState, WorksState } from './Recoil/Atoms';
import { checkUserAccess } from './axios/App.api';
import ServerTimeIntervalUtil from './Utils/ServerTimeInterval';
import { useTheme } from 'next-themes';
import Loading from './Components/Common/Loading/Loading';
import { timestampToMinutes } from './Utils/convertTime';
import useGenerateTimeFrame from './hooks/useGenerateTimeFrame';
import { InOutLogAtom, InOutLogDto } from './Recoil/InOutLogAtom';
import { WorkDto, WorksInDayAtom } from './Recoil/Works.atom';
import { AllNotificationsStateAtom } from './Recoil/confirmsAtom';
import { getAllNotifications } from './axios/Notifications.api';
import { getServerDate } from './axios/Users.api';
import { BeforeMonthIsClosedStateAtom } from './Recoil/monthlyLogAtom';
const cookies = new Cookies();
// import "./App.css"
// import themes from './config/config.theme';
require('./config/config.theme')

function App() {

  const generateTimeFrame = useGenerateTimeFrame()
  const [serverDate, setServerDate] = useRecoilState(ServerDateState)
  const [inOutLogs, setInOutLogs] = useRecoilState(InOutLogAtom)
  const [userData, setUserData] = useRecoilState(UserDataState)
  const [date, setDate] = useRecoilState(DateState)
  const [works, setWorks] = useRecoilState(WorksInDayAtom)
  const [minimumWorkTimeDuration, setMinimumWorkTimeDuration] = useRecoilState(MinimumWorkTimeDurationStateAtom)
  const [minimumInOutLogTimeDuration, setMinimumInOutLogTimeDuration] = useRecoilState(MinimumInOutLogTimeDurationStateAtom)
  const [minimumDistanceBetweenInOutLogs, setMinimumDistanceBetweenInOutLogs] = useRecoilState(MinimumDistanceBetweenInOutLogsStateAtom)
  const [enterExitSensitivityState, setEnterExitSensitivityState] = useRecoilState(enterExitSensitivityStateAtom)
  // const [hasNotification, setHasNotification] = useRecoilState(HasNotificationStateAtom)
  const setMissionTimeFrame = useSetRecoilState(MissionTimeFrameStateAtom)
  const [accessPeriodToCloseTheMonth, setAccessPeriodToCloseTheMonth] = useRecoilState(AccessPeriodToCloseTheMonthStateAtom)
  const [allNotifications, setAllNotifications] = useRecoilState(AllNotificationsStateAtom)
  const [beforeMonthIsClosed, setBeforeMonthIsClosed] = useRecoilState(BeforeMonthIsClosedStateAtom)
  const [accessToCloseMonthBeforeEndOfMonth, setAccessToCloseMonthBeforeEndOfMonth] = useRecoilState(AccessToCloseMonthBeforeEndOfMonthStateAtom)
  // const [serverDate, setServerDate] = useRecoilState(ServerDateState)
  const navigate = useNavigate()
  const location = useLocation()
  const serverTimeRef = useRef(0)
  const intervalRef = useRef<any>(null)
  const interval = 1000 * 60; // 1 minutes
  const {theme, setTheme} = useTheme()
  const [loading, setLoading] = useState(true)

  // const [systemTheme, setSystemTheme] = useState("")
  // useEffect(() => {
  //     console.log()
  // }, [])


  // const destructureWorkData = (works: any) => {
  //   const WORK_SUB_PERMISSION: any = {};
  //   const WORK_FA: any = {}
  //   const WORK_EFFECT: any = {}
  //   const WORK_COLOR: any = {}

  //   works.forEach((work: any) => {
  //       WORK_SUB_PERMISSION[work.value] = work.sub
  //       WORK_FA[work.value] = work.name
  //       WORK_EFFECT[work.value] = work.effect
  //       WORK_COLOR[work.value] = work.color
  //   })

  //   setWorks({
  //     ALL_WORKS: works,
  //     WORK_SUB_PERMISSION,
  //     WORK_FA,
  //     WORK_EFFECT,
  //     WORK_COLOR,
  //   })
  // }

  const systemThemeChecker = () => {
    if (theme === "system") {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
      if (systemTheme) setTheme("dark")
      else setTheme("light")
    }
  }

  // useEffect(() => {
  //   if (timestampToMinutes(serverDate) === 0) {
  //     console.log(serverDate)
  //     // generateTimeFrame(inOutLogs, works, serverDate)
  //   }
  // }, [serverDate])

  const getServerDateHandler = () => {
    if (!userData.id) return
    getServerDate()
      .then(res => {
        setServerDate(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    const cookie = cookies.get("ACCESS_TOKEN")
    if (!cookie) {
      setLoading(false)
      navigate("/login")
      return;
    } 
    checkUserAccess()
      .then(res => {
        // console.log(res);
        const {
          serverDate, 
          user, 
          minimumWorkTimeDuration, 
          missionTimeFrames, 
          minimumInOutLogTimeDuration, 
          minimumDistanceBetweenInOutLogs, 
          hasNotification,
          enterExitSensitivity,
          accessPeriodToCloseTheMonth,
          beforeMonthIsClosed,
          accessToCloseMonthBeforeEndOfMonth
        } = res.data
        setServerDate(serverDate)
        setUserData(user)
        setMinimumWorkTimeDuration(minimumWorkTimeDuration)
        setMissionTimeFrame(missionTimeFrames)
        setMinimumInOutLogTimeDuration(minimumInOutLogTimeDuration)
        setMinimumDistanceBetweenInOutLogs(minimumDistanceBetweenInOutLogs)
        setEnterExitSensitivityState(enterExitSensitivity)
        setAccessPeriodToCloseTheMonth(accessPeriodToCloseTheMonth)
        setAccessToCloseMonthBeforeEndOfMonth(accessToCloseMonthBeforeEndOfMonth)
        setBeforeMonthIsClosed(beforeMonthIsClosed)
        // setHasNotification(hasNotification)
        setLoading(false)
        if (location.pathname.includes("/login") || location.pathname.includes("/register")) navigate("/")
      })
      .catch(err => {
        // console.log(err);
        navigate("/login")
        setLoading(false)
      })

      getAllNotifications()
        .then((res) => {
            setAllNotifications(res.data)
            // const allNotifications = res.data;
            // const beConfirmed = allNotifications.filter((n: any) => n.userId !== userData.id)
            // const beCorrected = allNotifications.filter((n: any) => (n.userId == userData.id && n.status === "unconfirmed"))
            // const isPending = allNotifications.filter((n: any) => (n.userId == userData.id && n.status === "isPending"))
            // setAllNotifications({
            //     beConfirmed,
            //     beCorrected,
            //     isPending
            // })
        })
        .catch((err) => {
            console.log(err)
        })

    intervalRef.current = setInterval(() => {
      setServerDate((prev: any) => {
        const newSeverDate = prev + 60
        // if (timestampToMinutes(newSeverDate) === 0) {
        //   generateTimeFrame(inOutLog.current, worksRef.current, newSeverDate)
        // }
        return newSeverDate
      })
    }, interval)
    systemThemeChecker()

    return () => {
      clearInterval(intervalRef.current)
    }
  } , [])

  useEffect(() => {
    window.addEventListener("focus", getServerDateHandler)
    return () => {
      window.removeEventListener("focus", getServerDateHandler)
    }
  }, [userData])

  return (
    <>
      {loading ? 
      // <></> :
          <Loading 
              className="w-full h-dvh grid place-content-center bg-gray-200 dark:bg-gray-800"
          /> :
        <div className='min-h-dvh dark:bg-gray-700 bg-gray-200'> 
          <Navbar />
          <AllRoutes />
        </div>
      }
    </>
  );
}

export default App;


const Navbar = () => {
  const location = useLocation()

  if (!location.pathname.includes("/login") && !location.pathname.includes("/register")) {
    return <>
      <NavbarComponent />
      <div className='h-16' />
    </>
  } else return <></>
}

// const Loading = () => {
//   return(
//     <div className='w-screen h-screen bg-gray-200 dark:bg-gray-800 grid place-content-center '>
//       <div className='w-[500px] aspect-square loader'>

//       </div>
//     </div>
//   )
// }