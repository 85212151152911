import { Link } from "react-router-dom"
import Button from "../../Components/Common/Button/Button"
import TextTag from "../../Components/Common/TextTag/TextTag"
import Icon from "../../Components/Common/Icon/Icon"
import { useTheme } from "next-themes"
import { useRecoilState } from "recoil"
import { DateState, ModalOpenAtom, ModalTypeAtom, UserDataState } from "../../Recoil/Atoms"
import { useEffect, useState } from "react"
import { getAllNotifications, getSupervisorNotifications, getUserNotifications } from "../../axios/Notifications.api"
import { DurationToTime, TimestampToTime, TimeToTimestamp } from "../../Utils/convertTime"
import { faWorkType } from "../Works/Works"
import moment from "moment-jalaali"
import * as NotificationTypes from "./NotificationsAggregationsTypes"
import Dialog from "../../Components/Common/Dialog/Dialog"
import { confirmActivity, notConfirmActivity } from "../../axios/Confirm.api"
import { Toast } from "../../Utils/Toast"
import { AllNotificationsStateAtom } from "../../Recoil/confirmsAtom"
import notFound from "../../assets/notExist.png"

type NotificationType = {
    id: number,
    senderId: number,
    forUser: {username: string}
    receiverId: number,
    message: string,
    workId: number,
    timeLogId: number,
    getter_seen: boolean,
    status: "refactored" | "pending"
    type: "timeLog" | "mission" | "remote"
}
export default function Notification() {
    
    const {theme, setTheme} = useTheme()
    const [userData, setUserData] = useRecoilState(UserDataState)
    const [modalType, setModalType] = useRecoilState(ModalTypeAtom)
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    const [supervisorNotifications, setSupervisorNotifications] = useState([])
    const [userNotifications, setUserNotifications] = useState([])
    const [allNotifications, setAllNotifications] = useRecoilState(AllNotificationsStateAtom)

    useEffect(() => {
        // getSupervisorNotifications()
        //     .then((res) => {
        //         // console.log(res)
        //         setSupervisorNotifications(res.data.rows)
        //     })
        //     .catch((err) => console.log(err))

        // getUserNotifications()
        //     .then((res) => {
        //         // console.log(res)
        //         setUserNotifications(res.data.rows)
        //     })
        //     .catch((err) => console.log(err))

        
        // getAllNotifications()
        //     .then((res) => {
        //         setAllNotifications(res.data)
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })
    }, [])


    // const acceptHandler = (notif: any) => {
    //     setActionNotificationLoading(true)
    //     confirmActivity(notif.id)
    //         .then(res => {
    //             const cloneAllNotification = [...allNotifications]
    //             const filterAllNotifications = cloneAllNotification.filter(notification => notification.id !== notif.id)
    //             setAllNotifications(filterAllNotifications)
    //             setActionNotificationLoading(false)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //             setActionNotificationLoading(false)
    //         })
    // }

    // const unAcceptHandler = (notif: any, message: string) => {
    //     if (!message) return Toast("error", "نوشتن پیام الزامیست")
    //     notConfirmActivity(notif.id, message)
    //         .then(res => {
    //             console.log(res)
    //             const cloneAllNotification = [...allNotifications]
    //             const filterAllNotifications = cloneAllNotification.filter(notification => notification.id !== notif.id)
    //             setAllNotifications(filterAllNotifications)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }
    // console.log(allNotifications)
    // console.log([...supervisorNotifications, ...userNotifications]);




    


    return (
        <div className="px-2 border bg-gray-100 dark:bg-gray-600 border-zinc-400 dark:border-zinc-800 rounded-none lg:rounded-md">
            <div className="w-full py-2 flex items-center justify-between border-b border-zinc-300 dark:border-zinc-700">
                <h3 className="text-xl text-gray-500 dark:text-gray-300">اعلان ها</h3>
            </div>
            <div className="pb-2 flex flex-col gap-0 transition-all duration-500 overflow-hidden">
                {!allNotifications.length ?
                    <div className='w-full h-full grid place-content-center'>
                        <img src={notFound} className='w-96 opacity-70'/>
                        <TextTag as="h3" className="text-center p-5 !text-opacity-50">اعلانی وجود ندارد !!</TextTag>
                    </div>  :
                    allNotifications.map((not) => {
                        let status: "beConfirmed" | "beCorrected" | "isPending" = "beConfirmed"
                        if (+not.userId !== userData.id) status = "beConfirmed"
                        else if (+not.userId === userData.id && not.status === "unConfirmed") status = "beCorrected"
                        else if (+not.userId === userData.id && not.status === "isPending") status = "isPending"
                        const NotificationType = NotificationTypes[status]
                        return (
                            <NotificationType
                                key={not.id}
                                notification={not}
                            />
                        )
                    }
                )}
            </div>
        </div>
    )
}