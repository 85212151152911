import TextTag from "../Common/TextTag/TextTag";

const DashboardCharts = () => {
  return (
    <div className="flex flex-col items-stretch justify-between flex-auto gap-3">
      <TextTag as="h1" className="h-72 md:h-full rounded-lg flex items-center justify-center bg-gray-50 dark:bg-gray-800 !text-opacity-20">
        نمودار خطی
      </TextTag>
      <TextTag as="h1" className="h-72 md:h-full rounded-lg flex items-center justify-center bg-gray-50 dark:bg-gray-800 !text-opacity-20">
        نمودار میله ای
      </TextTag>
    </div>
  );
};

export default DashboardCharts;
