import { useRecoilState, useResetRecoilState } from "recoil"
import Button from "../../Button/Button"
import TextTag from "../../TextTag/TextTag"
import { ModalOpenAtom, UserDataState } from "../../../../Recoil/Atoms"
import { useTheme } from "next-themes"
import Icon from "../../Icon/Icon"
import { LogOut } from "../../../../axios/Auth.api"
import Cookies from "universal-cookie"
import { useNavigate } from "react-router-dom"
const cookies = new Cookies();

const LogOutAccess = () => {

    const navigation = useNavigate()
    const {theme} = useTheme()
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    const resetUserDate = useResetRecoilState(UserDataState)

    const logoutHandler = () => {
        LogOut()
        .then(res => {
            cookies.remove("ACCESS_TOKEN")
            resetUserDate()
            setIsModalOpen(false)
            navigation("/login")
            
        })
        .catch(err => {
            console.log(err)
            setIsModalOpen(false)
        })
    }

    return (
        <div className="flex flex-col bg-gray-100 dark:bg-gray-700 rounded-md">
            <div className="flex-none flex items-center justify-center p-2">
                <Icon
                    name="exclamation-circle"
                    width="5rem"
                    height="5rem"
                    color="#f43f5e"
                />
            </div>
            <TextTag as="h4" className="py-6 px-16">
                میخواهید از زمانگار خراج شوید?!
            </TextTag>
            <div className=" flex items-center justify-center gap-3 p-3">
                <Button
                    title="خروج"
                    color={theme === "dark" ? "dark" : "light"}
                    onClick={logoutHandler}
                    classNames={{
                        container: "!w-28 !h-10 !rounded-[3px]",
                        section: "!text-sm"
                    }}
                />
                <Button
                    title="انصراف"
                    color={theme === "dark" ? "dark" : "light"}
                    onClick={() => setIsModalOpen(false)}
                    classNames={{
                        container: "!w-28 !h-10 !rounded-[3px]",
                        section: "!text-sm"
                    }}
                />

            </div>
        </div>
    )
}

export default LogOutAccess