import { UserValuesType } from "../Components/UserForm/Types";
import { get, put, post, deleted, patch } from "./Axios.config"
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const createUser = async (data: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await post({
        api: `users`,
        data,
        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const GetUsers = async () => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `users/getAll`,
        config: {
            params: { With: ["Supervisors", 'Monitored'] },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const GetEmployees = async (paramsData?: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `users/getEmployees`,
        config: {
            params: { With: ["Supervisors"], ...paramsData },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const getUserById = async (id: number) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `users/${id}`,
        config: {
            params: { With: ["Supervisors", "Monitored"] },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const editUser = async (id: number, data: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await patch({
        api: `users/${id}`,
        data,
        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const changePassword = async (data: any) => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await put({
        api: `users`,
        data,
        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}

export const getServerDate = async () => {
    const cookie = cookies.get("ACCESS_TOKEN")
    return await get({
        api: `users/getServerDate`,
        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookie}`
            } 
        }
    })
}