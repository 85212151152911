import { useRecoilState } from "recoil"
import Button from "../../Button/Button"
import TextTag from "../../TextTag/TextTag"
import WorkTimeAggregations from "../../WorkTimeAggregations/WorkTimeAggregations"
import { ModalOpenAtom } from "../../../../Recoil/Atoms"
import { useTheme } from "next-themes"

const TimeAggregationsModal = ({forDate}: {forDate?: number}) => {
    const [isModalOpen, setIsModalOpen] = useRecoilState(ModalOpenAtom);
    const {theme} = useTheme()

    return (
        <div className="w-[calc(100vw-40px)] max-w-[700px] h-[calc(100vh-40px)] flex flex-col bg-gray-100 dark:bg-gray-600 rounded-md">
            <div className="flex-none flex items-center justify-between p-2 border-b border-gray-200 dark:border-gray-700">
                <TextTag as="span">
                    جمع بندی ساعات کاری
                </TextTag>
                <Button
                    icon="x-lg"
                    color="gray"
                    fill="transparent"
                    iconWidth="1.3rem"
                    iconHeight="1.3rem"
                    iconColor={theme === "dark" ? "lightgray" : "gray"}
                    iconHover={theme === "dark" ? "white" : "black"}
                    onClick={() => setIsModalOpen(false)}
                    classNames={{
                        container: "!w-5 !h-5"
                    }}
                />
            </div>
            <WorkTimeAggregations forDate={forDate} id={"timeAggregationDomToImageId"}/>
        </div>
    )
}

export default TimeAggregationsModal