import { ChangeEvent, useState } from "react";
import InputValidate from "../Common/InputValidate/InputValidate";
import Button from "../Common/Button/Button";
import Icon from "../Common/Icon/Icon";
import { Link } from "react-router-dom";
import {registerFormValidation} from "./AuthForm.validation";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../Utils/Toast";
import Cookies from 'universal-cookie';
import { AuthForm } from "../../axios/Auth.api";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AccessPeriodToCloseTheMonthStateAtom, AccessToCloseMonthBeforeEndOfMonthStateAtom, enterExitSensitivityStateAtom, MinimumDistanceBetweenInOutLogsStateAtom, MinimumInOutLogTimeDurationStateAtom, MinimumWorkTimeDurationStateAtom, MissionTimeFrameStateAtom, ServerDateState, UserDataState } from "../../Recoil/Atoms";
import TextTag from "../Common/TextTag/TextTag";
import { AllNotificationsStateAtom } from "../../Recoil/confirmsAtom";
import { BeforeMonthIsClosedStateAtom } from "../../Recoil/monthlyLogAtom";
// import { token } from "stylis";
const cookies = new Cookies();

const RegisterForm = ({formFields, type}: any) => {

    const navigate = useNavigate()
    const setServerDate = useSetRecoilState(ServerDateState)
    const [userData, setUserData] = useRecoilState(UserDataState)
    const setMinimumWorkTimeDuration = useSetRecoilState(MinimumWorkTimeDurationStateAtom)
    const setMissionTimeFrame = useSetRecoilState(MissionTimeFrameStateAtom)
    const [minimumInOutLogTimeDuration, setMinimumInOutLogTimeDuration] = useRecoilState(MinimumInOutLogTimeDurationStateAtom)
    const [minimumDistanceBetweenInOutLogs, setMinimumDistanceBetweenInOutLogs] = useRecoilState(MinimumDistanceBetweenInOutLogsStateAtom)
    const [enterExitSensitivityState, setEnterExitSensitivityState] = useRecoilState(enterExitSensitivityStateAtom)
    const [accessPeriodToCloseTheMonth, setAccessPeriodToCloseTheMonth] = useRecoilState(AccessPeriodToCloseTheMonthStateAtom)
    const [allNotifications, setAllNotifications] = useRecoilState(AllNotificationsStateAtom)
    const [beforeMonthIsClosed, setBeforeMonthIsClosed] = useRecoilState(BeforeMonthIsClosedStateAtom)
    const [accessToCloseMonthBeforeEndOfMonth, setAccessToCloseMonthBeforeEndOfMonth] = useRecoilState(AccessToCloseMonthBeforeEndOfMonthStateAtom)
    // const [hasNotification, setHasNotification] = useRecoilState(HasNotificationStateAtom)
    const [value, setValue] = useState<any>({
        username: "",
        password: "",
    })
    const [focus, setFocus] = useState<any>({})
    const [error, setError] = useState<any>({})
    const [act, setAct] = useState<any>({})
    const [loading, setLoading] = useState(false)

    const changeHandler = (e: any) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }

    const focusHandler = (e: any) => {
        setFocus({
            ...focus,
            [e.target.name]: true
        })
    }

    const blurHandler = (e: any) => {
        setFocus({
            ...focus,
            [e.target.name]: false
        });
        setAct({
            ...act,
            [e.target.name]: true
        })
    }

    let err = {}
    const validHandler = (inputValue: string, inputName: string) => {
        err = {
            ...err,
            [inputName]: 
                registerFormValidation[inputName as "username" | "password"] ? 
                registerFormValidation[inputName as "username" | "password"]({inputValue, inputName}) : "",
        }
        setError(err)
    }

    const submitHandler = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        const errors = Object.values(error).join("")
        if (errors) return setAct({
            username: true,
            password: true,
        })
        setLoading(true)
        
        AuthForm(type, value)
        .then(res => {
            const { 
                user, 
                serverDate, 
                minimumWorkTimeDuration, 
                missionTimeFrames, 
                minimumInOutLogTimeDuration, 
                minimumDistanceBetweenInOutLogs, 
                // hasNotification, 
                allNotifications,
                enterExitSensitivity,
                accessPeriodToCloseTheMonth,
                beforeMonthIsClosed,
                accessToCloseMonthBeforeEndOfMonth
            } = res.data
            setAllNotifications(allNotifications)
            setServerDate(serverDate)
            const token = user.token
            cookies.set('ACCESS_TOKEN', token, { path: '/' , maxAge: (60 * 60 * 24 * 7)}); // 7 days
            const userData = {...user}
            delete userData.token
            setUserData(userData)
            setMinimumWorkTimeDuration(minimumWorkTimeDuration)
            setMissionTimeFrame(missionTimeFrames)
            setMinimumInOutLogTimeDuration(minimumInOutLogTimeDuration)
            setMinimumDistanceBetweenInOutLogs(minimumDistanceBetweenInOutLogs)
            setEnterExitSensitivityState(enterExitSensitivity)
            setAccessPeriodToCloseTheMonth(accessPeriodToCloseTheMonth)
            setAccessToCloseMonthBeforeEndOfMonth(accessToCloseMonthBeforeEndOfMonth)
            setBeforeMonthIsClosed(beforeMonthIsClosed)
            // setHasNotification(hasNotification)
            navigate("/worksReport")
            setLoading(false)
        })
        .catch(err => {
            setLoading(false)
        })
    }

    return (
        <div className="w-full h-screen sm:h-[calc(100vh-32px)] flex flex-col items-center justify-center">
            <div className="w-full sm:w-[500px] h-full sm:h-fit flex items-center justify-center border border-gray-300 dark:border-gray-900 rounded-none sm:rounded-lg shadow-none sm:shadow-2xl shadow-gray-400 dark:shadow-gray-900 bg-gray-50 dark:bg-gray-800">
                <div className="w-full">
                    <TextTag as="h3" className="text-xl font-bold opacity-70 px-5 py-2">ورود</TextTag>
                    <form className="p-5">
                        {formFields.map((item: any) => 
                            <InputValidate 
                                key={item.name}
                                label={item.title}
                                name={item.name}
                                value={value[item.name]}
                                focus={focus[item.name]}
                                error={error[item.name]}
                                type={item.type}
                                act={act[item.name]}
                                onChange={changeHandler}
                                onFocus={focusHandler}
                                onBlur={blurHandler}
                                isValid={validHandler}
                                classNames={{
                                    // container: styles.container,
                                    input: "text-gray-600 dark:text-gray-300 dark:border-gray-900",
                                    label: "!text-gray-600 dark:!text-gray-300 dark:!bg-gray-800 !bg-rose-200",
                                    error: "dark:!bg-gray-800 !bg-rose-100",
                                }}
                                styles={{
                                    // container: {color: "red"},
                                    // input: {color: "red"},
                                    // label: {color: "red"},
                                    // error: {fontSize: "20px"},
                                }}
                            />
                        )}
                        {/* <Link to={`/${type === "login" ? "register" : "login"}`} className="text-sky-500 text-start block">
                            {type === "login" ? "ثبت نام" : "ورود"}
                        </Link> */}
                        <Button 
                            title="ورود"
                            type="submit"
                            direction="row_reverse"
                            expand="full"
                            loading={loading}
                            onClick={submitHandler}
                            classNames={{
                                container: "h-16 !rounded-md !flex m-auto mt-16 px-2 ",
                                section: "!text-lg"
                            }}
                        />
                    </form>
                </div>
            </div>
            
        </div>
    )
}

export default RegisterForm;