import dayjs, { Dayjs } from "dayjs";
import { ButtonType } from "../Button/Types";
import {
  LocalizationProvider,
  PickerChangeHandlerContext,
  StaticTimePicker,
  TimePicker,
  TimeValidationError,
} from "@mui/x-date-pickers";
import { useTheme } from "next-themes";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import Button from "../Button/Button";
import Dialog from "../Dialog/Dialog";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextTag from "../TextTag/TextTag";
import {
  timestampToHours,
  timestampToMinutes,
  TimestampToTime,
} from "../../../Utils/convertTime";
import { useRecoilState, useRecoilValue } from "recoil";
import { NumberInputFocusedAtom, ServerDateState } from "../../../Recoil/Atoms";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import Input from "../Input/Input";
import { inputSubmitAtom } from "../../../Recoil/dirtyAtom";

const ClockPicker = ({
  name,
  insideLabel,
  label,
  classNames,
  // button,
  picker,
  value,
  onChange,
  error,
  act,
  buttonMode = "handler",
  customButton = <></>,
  isOpen = false,
  setIsOpen,
  isDisable = false,
}: // formSubmit
{
  name: string;
  insideLabel?: string;
  label?: string;
  // button: ButtonType,
  classNames?: {
    container?: string;
    button?: string;
  };
  picker: {
    title: string;
    onAccept: (
      value: { hours: string; minutes: string },
      name: string,
      cb?: Function
    ) => void;
  };
  value?: string;
  onChange?: (value: any) => void;
  error?: string;
  act?: boolean;
  buttonMode: "simple" | "handler" | "none";
  customButton?: React.ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDisable?: boolean;
  // formSubmit?: boolean
}) => {
  const { theme, setTheme } = useTheme();
  // const [open, setOpen] = useState(false)
  const serverDate = useRecoilValue(ServerDateState);
  const [time, setTime] = useState({
    hours: "00",
    minutes: "00",
  });
  // این رو مجبور شدم اضافه کنم برای اینکه بفهمم فرم کی سابمیت میشه تا اینپوت های مربوط به شروع و پایان زمان رو آپدیت کنم. بعدا یه فکر بهتری براش میکنم
  const [inputSubmit, setInputSubmit] = useState(false);

  const acceptHandler = (
    value: Dayjs | null,
    context: PickerChangeHandlerContext<TimeValidationError>
  ) => {
    const hours = value?.get("hours").toString().padStart(2, "0") ?? "00";
    const minutes = value?.get("m").toString().padStart(2, "0") ?? "00";
    picker.onAccept({ hours, minutes }, name);
    setIsOpen(false);
  };

  const changeHandler = (
    value: Dayjs | null,
    context: PickerChangeHandlerContext<TimeValidationError>
  ) => {
    const hours = value?.get("hours").toString().padStart(2, "0") ?? "00";
    const minutes = value?.get("m").toString().padStart(2, "0") ?? "00";
    setTime({
      hours,
      minutes,
    });
  };

  const keyboardSubmitHandler = (e: any) => {
    if (e.code === "NumpadEnter") {
      picker.onAccept({ hours: time.hours, minutes: time.minutes }, name);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const hours = value
      ? value.split(":")?.[0]
      : String(timestampToHours(serverDate)).padStart(2, "0");
    const minutes = value
      ? value.split(":")?.[1]
      : String(timestampToMinutes(serverDate)).padStart(2, "0");
    setTime({
      hours,
      minutes,
    });
  }, [value, isOpen]);

  // console.log(dayjs().hour((value ? +value.split(":")?.[0] : timestampToHours(serverDate))).minute((value ? +value.split(":")?.[1] : timestampToMinutes(serverDate))).get("hours"))
  return (
    <div
      tabIndex={0}
      // onFocus={() => console.log("onFocus")}
      // onBlur={() => console.log("onBlur")}
      className={classNames?.container}
      onKeyDown={keyboardSubmitHandler}
    >
      {label && (
        <TextTag as="p" className="px-2 text-sm mb-1">
          {label}
        </TextTag>
      )}
      {buttonMode === "simple" ? (
        <TextTag
          className={`${
            isDisable ? "opacity-40" : ""
          } w-full h-[38px] rounded-[4px] flex items-center justify-center transition-all duration-200 !border !border-gray-400 dark:!border-gray-950 bg-gray-300 dark:bg-gray-800 p-1 gap-2 cursor-pointer ${
            classNames?.button
          }`}
          onClick={!isDisable ? () => setIsOpen(true) : () => {}}
        >
          {insideLabel}
        </TextTag>
      ) : buttonMode === "handler" ? (
        <OuterTimeInput
          inputName={name}
          value={value}
          onChange={onChange}
          setOpen={setIsOpen}
          onAccept={picker.onAccept}
          insideLabel={insideLabel}
          className={classNames?.button}
          isDisable={isDisable}
          // formSubmit={formSubmit}
          inputSubmit={inputSubmit}
          setInputSubmit={setInputSubmit}
        />
      ) : (
        customButton
      )}
      <Dialog open={isOpen} setOpen={setIsOpen} dir="ltr">
        {isOpen && (
          <div className="w-[320px] h-fit rounded-md overflow-hidden bg-white dark:bg-gray-800">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem
                sx={{
                  "& .MuiPickersLayout-root": {
                    // فکر کردم بزای باکس اصلیه
                    backgroundColor:
                      theme === "dark"
                        ? "#1f2937!important"
                        : "white!important", // Change toolbar background color
                    border: "0!important",
                    // backgroundColor: 'red', // Change toolbar background color
                    // padding: "20px!important",
                    marginTop: "0!important",
                    // borderRadius: "10px",
                    // overflow: "hidden"
                  },
                  "& .MuiStack-root": {
                    // marginTop: "0!important",
                    // padding: "20px!important",
                  },
                }}
              >
                <TextTag
                  as="h5"
                  className="text-end p-2 border-b border-gray-200 dark:border-gray-700"
                >
                  {picker.title}
                </TextTag>
                <InnerTimeInput time={time} setTime={setTime} />
                <StaticTimePicker
                  displayStaticWrapperAs="desktop"
                  ampm={false}
                  localeText={{
                    toolbarTitle: picker.title,
                    okButtonLabel: "انتخاب",
                    cancelButtonLabel: "انصراف",
                  }}
                  orientation="portrait"
                  // onAccept={acceptHandler}
                  onChange={changeHandler}
                  // onError={acceptHandler}
                  // onViewChange={acceptHandler}
                  // openTo={acceptHandler}
                  value={dayjs().hour(+time.hours).minute(+time.minutes)}
                  defaultValue={dayjs()
                    .hour(value ? +time.hours : timestampToHours(serverDate))
                    .minute(
                      value ? +time.minutes : timestampToMinutes(serverDate)
                    )}
                  // value={dayjs().hour((value ? +value.split(":")?.[0] : timestampToHours(serverDate))).minute((value ? +value.split(":")?.[1] : timestampToMinutes(serverDate)))}
                  // defaultValue={dayjs().hour(8).minute(40)}

                  // onClose={() => setOpen(false)}
                  // onChange={(e) => console.log(e)}
                  sx={{
                    "& .MuiPickersLayout-root": {
                      // فکر کردم بزای باکس اصلیه
                      backgroundColor:
                        theme === "dark"
                          ? "#1f2937!important"
                          : "white!important", // Change toolbar background color
                      border: "0!important",
                      // backgroundColor: 'red', // Change toolbar background color
                      // padding: "20px",
                      // borderRadius: "10px",
                      // overflow: "hidden"
                    },
                    "& .MuiPickersToolbar-root": {
                      // استایل هدر
                      backgroundColor: theme === "dark" ? "#1f2937" : "white", // Change toolbar background color
                      padding: "10px",
                      // color: "red"
                    },
                    "& .MuiDialogActions-root": {
                      // دکمه سابمیت
                      backgroundColor: theme === "dark" ? "#1f2937" : "white", // Change toolbar background color
                      borderTop: `1px solid ${
                        theme === "dark" ? "#374151" : "#e5e7eb"
                      }`,
                      padding: "10px",
                      // color: "red"
                    },
                    "& .MuiTimeClock-root": {
                      //  کانتینر ساعت
                      backgroundColor: theme === "dark" ? "#1f2937" : "white", // Change toolbar background color
                    },
                    "& .MuiClock-root": {
                      // باکس ساعت
                      backgroundColor: theme === "dark" ? "#1f2937" : "white", // Change toolbar background color
                      borderRadius: "100%",
                    },
                    "& .MuiClock-clock": {
                      // خوده ساعت
                      borderRadius: "100%",
                      // backgroundColor: theme === "dark" ? '#6b7280' : "#d1d5db",
                      // color: "red"
                    },
                    "& .MuiClock-squareMask": {
                      // خوده ساعت یه لول داخل تر
                      borderRadius: "100%",
                      backgroundColor: theme === "dark" ? "#111827" : "#e5e7eb",
                      // color: "red"
                    },
                    "& .MuiTypography-root": {
                      // اینپوت اعداد
                      // backgroundColor: "red",
                      // color: theme === "dark" ? '#334155' : "white",
                      // "&:focus": {
                      //     color: 'green', // Change toolbar background color
                      // },
                      color:
                        theme === "dark"
                          ? "#e5e7eb!important"
                          : "#1f2937!important", // Change toolbar background color
                      opacity: 0.6,
                    },
                    "& .Mui-selected": {
                      color:
                        theme === "dark"
                          ? "#e5e7eb!important"
                          : "#1f2937!important",
                      opacity: 1,
                      // backgroundColor: "red",
                      // color: 'green!important', // Change toolbar background color
                    },
                    "& .MuiTypography-overline": {
                      // لیبل
                      width: "100%",
                      textAlign: "center",
                      color:
                        theme === "dark"
                          ? "#f9fafb!important"
                          : "#030712!important", // Change toolbar background color
                      fontWeight: "400",
                      fontSize: "1rem",
                      borderBottom: `1px solid ${
                        theme === "dark" ? "#374151" : "#e5e7eb"
                      }`,
                    },
                    "& .MuiPickersToolbar-content": {
                      // کانتینر اینپوت اعداد
                      // backgroundColor: "white",
                      // color: 'blue', // Change toolbar background color
                    },

                    "& .MuiClockNumber-root": {
                      // اعداد داخل ساعت
                      color: theme === "dark" ? "#e5e7eb" : "#1f2937",
                      // backgroundColor: "white",
                      // color: 'blue', // Change toolbar background color
                    },

                    // '& .MuiClockPointer-thumb': { //یه نقطه وسط بیلبیلک
                    //     backgroundColor: "red",
                    //     color: 'red', // Change toolbar background color
                    // },

                    "& .MuiButtonBase-root": {
                      // باکس همه باتن ها
                      // backgroundColor: "red",
                      color:
                        theme === "dark"
                          ? "#e5e7eb!important"
                          : "#1f2937!important",
                      opacity: 1,
                    },
                    "& .Mui-disabled": {
                      // باکس همه باتن ها
                      // backgroundColor: "red",
                      // color:  theme === "dark" ? '#e5e7eb' : "#1f2937",
                      color:
                        theme === "dark"
                          ? "#e5e7eb!important"
                          : "#1f2937!important",
                      opacity: 0.5,
                    },

                    "& .MuiButton-root": {
                      // باکس همه باتن ها
                      // backgroundColor: "red",
                      // color:  theme === "dark" ? '#e5e7eb' : "#1f2937",
                    },
                    "& .MuiButton-colorPrimary": {
                      // کار نمیکنه
                      // color: 'red', // Change text color
                      // backgroundColor: 'red', // Change toolbar background color
                    },
                  }}
                />
                <div className=" flex items-center justify-end p-2 gap-1 !mt-0">
                  <Button
                    title="انصراف"
                    color={theme === "dark" ? "dark" : "light"}
                    onClick={() => setIsOpen(false)}
                    classNames={{
                      container:
                        "!w-20 !h-8 !rounded-[3px] transition-all duration-300",
                      section:
                        "!text-[0.8rem] !flex !items-center !justify-center !overflow-hidden",
                    }}
                  />
                  <Button
                    title="ذخیره"
                    color={theme === "dark" ? "dark" : "light"}
                    onClick={() => {
                      picker.onAccept(
                        { hours: time.hours, minutes: time.minutes },
                        name,
                        (newData: any) => {
                          // console.log(newData)
                          setInputSubmit((prev: any) => !prev);
                        }
                      );
                      setIsOpen(false);
                    }}
                    classNames={{
                      container:
                        "!w-20 !h-8 !rounded-[3px] transition-all duration-300",
                      section:
                        "!text-[0.8rem] !flex !items-center !justify-center !overflow-hidden",
                    }}
                  />
                </div>
              </DemoItem>
            </LocalizationProvider>
          </div>
        )}
      </Dialog>
      {error && act && (
        <TextTag
          as="small"
          className={`w-full px-2 py-1 text-rose-500 dark:text-rose-500 text-end block`}
          // style={styles?.error}
        >
          {error}
        </TextTag>
      )}
    </div>
  );
};

const InnerTimeInput = ({
  time,
  setTime,
}: {
  time: {
    hours: string;
    minutes: string;
  };
  setTime: React.Dispatch<
    React.SetStateAction<{
      hours: string;
      minutes: string;
    }>
  >;
}) => {
  const { theme } = useTheme();
  const serverDate = useRecoilValue(ServerDateState);
  const hoursRef = useRef<any>(null);
  const minutesRef = useRef<any>(null);
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { hours, minutes } = time;
    let { value, name } = e.target;
    if (+value < 10) value = String(+value).padStart(2, "0");
    else value = String(+value);
    // if (name === "hours" && +value > 23) value = "00"
    // if (name === "minutes" && +value > 59) value = "00"

    if (name === "hours") {
      if (+value > 23) value = "00";
      else if (+value < 0) value = "23";
    }
    if (name === "minutes") {
      if (+value > 59) {
        if (+hours === 23) {
          return setTime({
            hours: "00",
            minutes: "00",
          });
        } else {
          return setTime({
            hours: String(+hours + 1).padStart(2, "0"),
            minutes: "00",
          });
        }
      } else if (+value < 0) {
        if (+hours === 0) {
          return setTime({
            hours: "23",
            minutes: "59",
          });
        } else {
          return setTime({
            hours: String(+hours - 1).padStart(2, "0"),
            minutes: "59",
          });
        }
      }
    }
    // if (name === "hours" && +value > 10) minutesRef.current.focus()

    setTime({
      ...time,
      [name]: value,
    });
  };

  const keyDownHandler = (e: any) => {
    if (e.code === "ArrowRight") {
      minutesRef.current.focus();
      setTimeout(() => {
        minutesRef.current.select();
      }, 10);
    } else if (e.code === "ArrowLeft") {
      hoursRef.current.focus();
      setTimeout(() => {
        hoursRef.current.select();
      }, 10);
    }
  };

  return (
    <div
      tabIndex={0}
      onKeyDown={keyDownHandler}
      className="flex items-center justify-between gap-2 p-2 text-4xl text-gray-300 !mt-0"
    >
      <div className="flex items-center justify-start gap-2">
        <Input
          ref={hoursRef}
          title="ساعت"
          name="hours"
          type="number"
          value={time.hours}
          onChange={changeHandler}
          onFocus={() => {
            const btn = document.querySelector(".MuiIconButton-edgeEnd");
            const relatedDiv = btn?.closest("button");
            relatedDiv?.click();
            setTimeout(() => {
              hoursRef.current.focus();
            }, 0);
          }}
          classNames={{
            container:
              "w-14 h-16 rounded-[4px] bg-gray-900 bg-opacity-10 dark:bg-opacity-20 ring-gray-400 text-center outline-none no-spinner overflow-hidden",
            inputBox: "h-full rounded-none border-0 !bg-transparent",
            input: `!border-gray-900 !bg-transparent p-0 text-center text-gray-600 dark:text-gray-200 no-spinner transition-all !bg-opacity-0 focus:!bg-gray-900 focus:!bg-opacity-20`,
          }}
        />
        <TextTag className="text-4xl">:</TextTag>
        <Input
          ref={minutesRef}
          title="دقیقه"
          name="minutes"
          type="number"
          value={time.minutes}
          onChange={changeHandler}
          onFocus={() => {
            const btn = document.querySelector(".MuiIconButton-edgeStart");
            const relatedDiv = btn?.closest("button");
            relatedDiv?.click();
            setTimeout(() => {
              minutesRef.current.focus();
            }, 0);
          }}
          classNames={{
            container:
              "w-14 h-16 rounded-[4px] bg-gray-900 bg-opacity-10 dark:bg-opacity-20 ring-gray-400 text-center outline-none no-spinner overflow-hidden",
            inputBox: "h-full rounded-none border-0 !bg-transparent",
            input: `!border-gray-900 !bg-transparent p-0 text-center text-gray-600 dark:text-gray-200 no-spinner transition-all !bg-opacity-0 focus:!bg-gray-900 focus:!bg-opacity-20`,
          }}
        />
      </div>
      {/* <Button
                title="الان"
                color={theme === "dark" ? "dark" : "light"}
                classNames={{
                    container: "!w-14 !h-16 !rounded-[4px]",
                    section: "!text-sm"
                }}
            /> */}
      <TextTag
        onClick={() => {
            const hours = String(timestampToHours(serverDate)).padStart(2, "0")
            const minutes = String(timestampToMinutes(serverDate)).padStart(2, "0")
            setTime({
                hours,
                minutes
            })
        }}
        className="w-14 h-16 bg-gray-900 bg-opacity-10 dark:bg-opacity-20 text-lg gird place-content-center rounded-[4px] hover:!bg-opacity-30 transition-colors cursor-pointer"
      >
        الان
      </TextTag>
    </div>
  );
};

const OuterTimeInput = ({
  inputName,
  value,
  onChange,
  setOpen,
  onAccept,
  insideLabel,
  className,
  isDisable,
  inputSubmit,
  setInputSubmit,
}: any) => {
  const minutesRef = useRef<any>(null);
  const hoursRef = useRef<any>(null);
  const [numberInputFocused, setNumberInputFocused] = useRecoilState(
    NumberInputFocusedAtom
  );
  const [time, setTime] = useState({
    hours: "-",
    minutes: "-",
  });
  const [keepTime, setKeepTime] = useState({
    hours: "-",
    minutes: "-",
  });
  const [loading, setLoading] = useState(false);
  const timeChanged =
    `${keepTime.hours}:${keepTime.minutes}` !== `${time.hours}:${time.minutes}`;

  // این رو مجبور شدم اضافه کنم برای اینکه بفهمم فرم کی سابمیت میشه تا اینپوت های مربوط به شروع و پایان زمان رو آپدیت کنم. بعدا یه فکر بهتری براش میکنم
  const [formSubmit, setFormSubmit] = useRecoilState(inputSubmitAtom);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (isDisable) return;
    const { hours, minutes } = time;
    let { value, name } = e.target;
    if (+value < 10) value = String(+value).padStart(2, "0");
    else value = String(+value); // چه حرکت عجیبی زدم اینجا
    // if (name === "hours" && +value > 23) value = "00"
    // if (name === "minutes" && +value > 59) value = "00"

    if (name === "hours") {
      if (+value > 23) value = "00";
      else if (+value < 0) value = "23";
    }
    if (name === "minutes") {
      if (+value > 59) {
        if (+hours === 23) {
          setTime({
            hours: "00",
            minutes: "00",
          });
          onChange &&
            onChange({
              name: inputName,
              hours: "00",
              minutes: "00",
            });
          return;
        } else {
          setTime({
            hours: String(+hours + 1).padStart(2, "0"),
            minutes: "00",
          });
          onChange &&
            onChange({
              name: inputName,
              hours: String(+hours + 1).padStart(2, "0"),
              minutes: "00",
            });
          return;
        }
      } else if (+value < 0) {
        if (+hours === 0) {
          setTime({
            hours: "23",
            minutes: "59",
          });
          onChange &&
            onChange({
              name: inputName,
              hours: "23",
              minutes: "59",
            });
          return;
        } else {
          setTime({
            hours: String(+hours - 1).padStart(2, "0"),
            minutes: "59",
          });
          onChange &&
            onChange({
              name: inputName,
              hours: String(+hours - 1).padStart(2, "0"),
              minutes: "59",
            });
          return;
        }
      }
    }
    // if (name === "hours" && +value > 10) minutesRef.current.focus()

    setTime({
      ...time,
      [name]: value,
    });
    onChange &&
      onChange({
        ...time,
        name: inputName,
        [name]: value,
      });
  };

  // const resetHandler = () =>{
  //     const splitValue = value?.split(":") ?? ["-", "-"]
  // }
  // useEffect(() => {
  //     console.log("first")
  //     resetHandler()
  // }, [value])

  useEffect(() => {
    const splitValue = value?.split(":") ?? ["-", "-"];
    // resetHandler()
    setTime({
      hours: splitValue[0],
      minutes: splitValue[1],
    });
    setKeepTime({
      hours: splitValue[0],
      minutes: splitValue[1],
    });
  }, [formSubmit]);

  useEffect(() => {
    const splitValue = value?.split(":") ?? ["-", "-"];
    setTime({
      hours: splitValue[0],
      minutes: splitValue[1],
    });
    setKeepTime({
      hours: splitValue[0],
      minutes: splitValue[1],
    });
  }, [inputSubmit]);

  // console.log(keepTime);
  // useEffect(() => {
  //     const splitValue = value?.split(":") ?? ["-", "-"]
  //     const timeIsNoQuantity = time.hours === "-"
  //     const keepTimeIsNoQuantity = keepTime.hours === "-"
  //     timeIsNoQuantity && setTime({
  //         hours: splitValue[0],
  //         minutes: splitValue[1],
  //     })
  //     keepTimeIsNoQuantity && setKeepTime({
  //         hours: splitValue[0],
  //         minutes: splitValue[1],
  //     })
  // }, [value])

  const keyDownHandler = (e: any) => {
    if (e.code === "ArrowRight") {
      minutesRef.current.focus();
      setTimeout(() => {
        minutesRef.current.select();
      }, 10);
    } else if (e.code === "ArrowLeft") {
      hoursRef.current.focus();
      setTimeout(() => {
        hoursRef.current.select();
      }, 10);
    }
  };

  return (
    <div
      onKeyDown={keyDownHandler}
      className={`w-full h-[38px] rounded-[4px] flex items-center justify-between transition-all duration-200 !border !border-gray-400 dark:!border-gray-950 bg-gray-300 dark:bg-gray-800 p-1 gap-2 ${className}`}
    >
      {insideLabel && (
        <TextTag className="w-fit flex-none px-1">{insideLabel}</TextTag>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onAccept(
            { hours: time.hours, minutes: time.minutes },
            inputName,
            (newData: any) => {
              // console.log(newData)
              setInputSubmit((prev: any) => !prev);
            }
          );
          // setLoading(true)
        }}
        className="flex-auto flex items-center justify-between gap-1 w-20 h-full border-gray-400 dark:border-gray-900"
      >
        <div
          className="flex items-center justify-center gap-1 w-20 h-full"
          dir="ltr"
        >
          <Input
            ref={hoursRef}
            title="ساعت"
            type="text"
            name="hours"
            onFocus={() => {
              hoursRef.current.type = "number";
              hoursRef.current.select();
              setNumberInputFocused(true);
            }}
            onBlur={() => {
              hoursRef.current.type = "text";
              setNumberInputFocused(false);
            }}
            // defaultValue={hours}
            // onWheel={handleWheelOnInput}
            value={time.hours}
            onChange={changeHandler}
            classNames={{
              container: "h-full !border-none",
              inputBox: "h-full rounded-none border-0 !bg-transparent",
              input: `!border-gray-900 !bg-transparent p-0 text-center text-lg text-gray-600 dark:text-gray-200 no-spinner ${
                timeChanged ? "text-yellow-700 dark:text-yellow-500" : ""
              }`,
            }}
          />
          <TextTag>:</TextTag>
          <Input
            ref={minutesRef}
            title="دقیقه"
            type="text"
            name="minutes"
            onFocus={() => {
              minutesRef.current.type = "number";
              minutesRef.current.select();
              setTimeout(() => setNumberInputFocused(true), 0);
            }}
            onBlur={() => {
              minutesRef.current.type = "text";
              setNumberInputFocused(false);
            }}
            // defaultValue={minutes}
            value={time.minutes}
            onChange={changeHandler}
            classNames={{
              container: "h-full",
              inputBox: "h-full rounded-none border-0 !bg-transparent",
              input: `!border-gray-900 !bg-transparent p-0 text-center text-lg text-gray-600 dark:text-gray-200 no-spinner ${
                timeChanged ? "text-yellow-700 dark:text-yellow-500" : ""
              }`,
            }}
          />
        </div>
        <div className="w-fit flex items-center justify-center gap-1">
          {timeChanged &&
            keepTime.hours !== "-" &&
            keepTime.minutes !== "-" && (
              <Button
                icon="check2"
                iconWidth="1.7rem"
                iconHeight="1.7rem"
                color="gray"
                fill="transparent"
                type="submit"
                loading={loading}
                classNames={{
                  container:
                    "!w-6 !h-6 !flex !items-center !justify-center !flex-none",
                }}
              />
            )}
          {timeChanged &&
            keepTime.hours !== "-" &&
            keepTime.minutes !== "-" && (
              <Button
                icon="x"
                iconWidth="1.7rem"
                iconHeight="1.7rem"
                color="gray"
                fill="transparent"
                type="reset"
                onClick={() => {
                  const splitValue = value?.split(":") ?? ["-", "-"];
                  setTime({
                    hours: keepTime.hours,
                    minutes: keepTime.minutes,
                  });
                }}
                // loading
                classNames={{
                  container:
                    "!w-6 !h-6 !flex !items-center !justify-center !flex-none",
                }}
              />
            )}
        </div>
      </form>
      <Button
        icon="clock"
        color="gray"
        fill="transparent"
        disabled={isDisable}
        onClick={() => setOpen(true)}
        classNames={{
          container: "!w-6 !h-6 !flex !items-center !justify-center",
        }}
      />
    </div>
  );
};

export default ClockPicker;
